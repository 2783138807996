import { api } from '../api/api'

export const securityApi = api.injectEndpoints({
  tagTypes: ['SecModules', 'BusinessModules', 'Roles', 'RoleDetails', 'Users', 'OpdConfig'],

  endpoints: build => ({
    getSecModules: build.query({
      query: () => 'security/getSecModules',
      providesTags: ['SecModules'],
    }),
    getBussFunctions: build.query({
      query: body => `security/getBussFunctions/${body}`,
      providesTags: ['BusinessModules'],
    }),
    createModule: build.mutation({
      query: body => ({
        url: 'security/createModule',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SecModules'],
    }),
    updateModule: build.mutation({
      query: body => ({
        url: 'security/updateModule',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SecModules'],
    }),
    createBussFunc: build.mutation({
      query: body => ({
        url: 'security/createBussFunc',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BusinessModules'],
    }),
    updateBussFunc: build.mutation({
      query: body => ({
        url: 'security/updateBussFunc',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BusinessModules'],
    }),
    getAllRoles: build.query({
      query: () => 'security/getAllRoles',
      providesTags: ['Roles'],
    }),
    createRole: build.mutation({
      query: body => ({
        url: 'security/createRole',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roles'],
    }),
    getRoleWiseBussFunc: build.query({
      query: body => `security/getRoleWiseBussFunc/${body}`,
      providesTags: ['RoleDetails'],
    }),
    createRoleDetail: build.mutation({
      query: body => ({
        url: 'security/createRoleDetail',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RoleDetails'],
    }),
    getBussFunctionsM: build.mutation({
      query: body => `security/getBussFunctions/${body}`,
    }),
    lovAllUsers: build.query({
      query: () => 'security/lovAllUsers',
    }),
    getUser: build.mutation({
      query: body => `security/getUser/${body}`,
    }),
    lovRoleProjectWise: build.mutation({
      query: body => `security/lovRoleProjectWise/${body}`,
    }),
    transferUser: build.mutation({
      query: body => ({
        url: 'security/transferUser',
        method: 'POST',
        body,
      }),
    }),
    getAllUsers: build.query({
      query: () => 'security/getAllUsers',
      providesTags: ['Users'],
    }),
    lovRole: build.query({
      query: () => 'security/lovRole',
    }),
    lovUserPolicy: build.query({
      query: () => 'security/lovUserPolicy',
    }),
    lovEmployee: build.query({
      query: () => 'security/lovEmployee',
    }),
    updateUser: build.mutation({
      query: body => ({
        url: 'security/updateUser',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    createUser: build.mutation({
      query: body => ({
        url: 'security/createUser',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    getDashboardData: build.query({
      query: body => `security/getDashboardData/${body}`,
    }),
    lovUsers: build.query({
      query: () => 'security/lovUsers',
    }),
    lovCompany: build.query({
      query: () => 'security/lovCompany',
    }),
    getOrgOpdWardConfig: build.query({
      query: body => `security/getOrgOpdWardConfignew/${body}`,
      providesTags: ['OpdConfig'],
    }),
    saveOrgOpdWardConfig: build.mutation({
      query: body => ({
        url: 'security/createOrgOpdWardConfig',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OpdConfig'],
    }),
    updateOrgOpdWardConfig: build.mutation({
      query: body => ({
        url: 'security/updateOrgOpdWardConfig',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OpdConfig'],
    }),
    getProjectEqptList: build.query({
      query: () => 'https://medix.fauji.org.pk/reports/getProjectEqptList/ALL/ALL',
      // providesTags: ['Roles'],
    }),
  }),
})

export const {
  useGetSecModulesQuery,
  useGetBussFunctionsQuery,
  useCreateModuleMutation,
  useUpdateModuleMutation,
  useCreateBussFuncMutation,
  useUpdateBussFuncMutation,
  useGetAllRolesQuery,
  useCreateRoleMutation,
  useGetBussFunctionsMMutation,
  useGetRoleWiseBussFuncQuery,
  useCreateRoleDetailMutation,
  useLovAllUsersQuery,
  useGetAllResponsibilityQuery,
  useGetUserMutation,
  useLovRoleProjectWiseMutation,
  useTransferUserMutation,
  useGetAllUsersQuery,
  useLovRoleQuery,
  useLovUserPolicyQuery,
  useLovEmployeeQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetDashboardDataQuery,
  useLovUsersQuery,
  useLovCompanyQuery,
  useGetOrgOpdWardConfigQuery,
  useSaveOrgOpdWardConfigMutation,
  useUpdateOrgOpdWardConfigMutation,
  useGetProjectEqptListQuery,
} = securityApi
