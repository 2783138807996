import StoreConfiguration from "../features/storeConfigurators/pages/StoreConfiguration";



export const storeConfigurationRoutes = [
	{
		path: 'StoreConfiguration',
		element: <StoreConfiguration/>,
	},
	
]
