import { api } from "../api/api";

const InventoryConfigurationApi = api.injectEndpoints({
  endpoints: (build) => ({
    lovInvBrands: build.query({
      query: (body) => `security/lovInvBrands`,
    }),

    lovInvStrength: build.query({
      query: (body) => `security/lovInvStrength`,
    }),

    lovInvClass: build.query({
      query: (body) => `security/lovInvClass`,
    }),

    lovInvGenerics: build.query({
      query: (body) => `security/lovInvGenerics`,
    }),

    lovInvMainGrp: build.query({
      query: (body) => `security/lovInvMainGrp`,
    }),
    lovInvManufacture: build.query({
      query: (body) => `security/lovInvManufacture`,
    }),

    lovInvType: build.query({
      query: (body) => `security/lovInvType`,
    }),
    lovInvUom: build.query({
      query: (body) => `security/lovInvUom`,
    }),

    lovNat: build.query({
      query: (body) => `pos/lovNat`,
    }),

    getInvBrand: build.query({
      query: () => `security/getInvBrand`,
    }),

    CreateInvBrand: build.mutation({
      query: (body) => ({
        url: "security/createInvBrand",
        method: "POST",
        body,
      }),
    }),

    UpdateInvBrand: build.mutation({
      query: (body) => ({
        url: `security/updateInvBrand`,
        method: "POST",
        body,
      }),
    }),

    getInvClass: build.query({
      query: () => `security/getInvClass`,
    }),
    CreateInvClass: build.mutation({
      query: (body) => ({
        url: "security/createInvClass",
        method: "POST",
        body,
      }),
    }),

    UpdateInvClass: build.mutation({
      query: (body) => ({
        url: `security/updateInvClass`,
        method: "POST",
        body,
      }),
    }),

    getInvGengrp: build.query({
      query: () => `security/getInvGengrp`,
    }),

    CreateInvGenGrp: build.mutation({
      query: (body) => ({
        url: "security/createInvGenGrp",
        method: "POST",
        body,
      }),
    }),

    UpdateInvGengrp: build.mutation({
      query: (body) => ({
        url: `security/updateInvGengrp`,
        method: "POST",
        body,
      }),
    }),
    getInvmaingrp: build.query({
      query: () => `security/getInvmaingrp`,
    }),
    CreateInvMainGrp: build.mutation({
      query: (body) => ({
        url: "security/createInvMainGrp",
        method: "POST",
        body,
      }),
    }),

    UpdateInvMaingrp: build.mutation({
      query: (body) => ({
        url: `security/updateInvMaingrp`,
        method: "POST",
        body,
      }),
    }),

    getInvManufacture: build.query({
      query: () => `security/getInvManufacture`,
    }),
    CreateInvManufacture: build.mutation({
      query: (body) => ({
        url: "security/createInvManufacture",
        method: "POST",
        body,
      }),
    }),

    UpdateInvManufacture: build.mutation({
      query: (body) => ({
        url: `security/updateInvManufacture`,
        method: "POST",
        body,
      }),
    }),

    getInvType: build.query({
      query: () => `security/getInvType`,
    }),
    CreateInvType: build.mutation({
      query: (body) => ({
        url: "security/createInvType",
        method: "POST",
        body,
      }),
    }),

    UpdateInvType: build.mutation({
      query: (body) => ({
        url: `security/updateInvType`,
        method: "POST",
        body,
      }),
    }),

    getInvStrenght: build.query({
      query: () => `security/getInvStrenght`,
    }),

    CreateInvStrength: build.mutation({
      query: (body) => ({
        url: "security/createInvStrength",
        method: "POST",
        body,
      }),
    }),

    UpdateInvStrength: build.mutation({
      query: (body) => ({
        url: `security/updateInvStrength`,
        method: "POST",
        body,
      }),
    }),

    getInvUom: build.query({
      query: () => `security/getInvUom`,
    }),

    CreateInvUom: build.mutation({
      query: (body) => ({
        url: "security/createInvUom",
        method: "POST",
        body,
      }),
    }),

    UpdateInvUom: build.mutation({
      query: (body) => ({
        url: `security/updateInvUom`,
        method: "POST",
        body,
      }),
    }),

    getInvnature: build.query({
      query: () => `security/getInvnature`,
    }),

    CreateInvNature: build.mutation({
      query: (body) => ({
        url: "security/createInvNature",
        method: "POST",
        body,
      }),
    }),

    UpdateInvNature: build.mutation({
      query: (body) => ({
        url: `security/updateInvNature`,
        method: "POST",
        body,
      }),
    }),

    getInvcodedetail: build.query({
      query: () => `pos/getInvcodedetail`,
    }),

    CreateInventoryCode: build.mutation({
      query: (body) => ({
        url: "security/createInventoryCode",
        method: "POST",
        body,
      }),
    }),

    UpdateInventoryCode: build.mutation({
      query: (body) => ({
        url: `security/updateInventoryCode`,
        method: "POST",
        body,
      }),
    }),

    getInvvendor: build.query({
      query: () => `security/getInvvendor`,
    }),

    CreateInvVendor: build.mutation({
      query: (body) => ({
        url: "security/createInvVendor",
        method: "POST",
        body,
      }),
    }),

    UpdateInvVendor: build.mutation({
      query: (body) => ({
        url: `security/updateInvVendor`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetInvBrandQuery,
  useCreateInvBrandMutation,
  useUpdateInvBrandMutation,
  useGetInvClassQuery,
  useCreateInvClassMutation,
  useUpdateInvClassMutation,
  useGetInvGengrpQuery,
  useCreateInvGenGrpMutation,
  useUpdateInvGengrpMutation,
  useGetInvmaingrpQuery,
  useCreateInvMainGrpMutation,
  useUpdateInvMaingrpMutation,
  useGetInvManufactureQuery,
  useCreateInvManufactureMutation,
  useUpdateInvManufactureMutation,
  useGetInvTypeQuery,
  useCreateInvTypeMutation,
  useUpdateInvTypeMutation,
  useGetInvStrenghtQuery,
  useCreateInvStrengthMutation,
  useUpdateInvStrengthMutation,
  useGetInvUomQuery,
  useCreateInvUomMutation,
  useUpdateInvUomMutation,
  useGetInvcodedetailQuery,
  useCreateInventoryCodeMutation,
  useUpdateInventoryCodeMutation,
  useGetInvnatureQuery,
  useCreateInvNatureMutation,
  useUpdateInvNatureMutation,
  useLovInvBrandsQuery,
  useLovInvClassQuery,
  useLovInvManufactureQuery,
  useLovInvGenericsQuery,
  useLovInvMainGrpQuery,
  useLovInvUomQuery,
  useLovInvTypeQuery,
  useLovInvStrengthQuery,
  useGetInvvendorQuery,
  useCreateInvVendorMutation,
  useUpdateInvVendorMutation,
  useLovNatQuery,
} = InventoryConfigurationApi;
