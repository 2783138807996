import { Button, Checkbox, Collapse, Form, Input,Modal,Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import EditVitals from './EditVitals';
import PatientVitals from './PatientVitals';


import { useGetPatientCurrentVitalsMutation, usePatientVitalsMutation } from '../ettServices';
import openNotification from '../../../utils/notification';
import { useSelector } from 'react-redux';
const PerformEtt=({rowData,saveett})=> {
  const co = useSelector((state) => state.auth.user.co);
    const { Panel } = Collapse;
    const [tabledata, setTabledata] = useState([]);
    const [vitals, setVitals] = useState([]);
    const [showEditVitalsModal, setShowEditVitalsModal] = useState(false);
    const [form] = Form.useForm();
    const onChange = (date, dateString, checkedValues) => {
        console.log(date, dateString, "checked = ", checkedValues);
      };
    const [getPatientCurrentVitals,{ data: patientCurrentVitals, isLoading: VitalLoading },
    ] = useGetPatientCurrentVitalsMutation();
    const [
      addPatientVitals,
      { isSuccess: updateSuccess, isLoading: updateLoading, data: patientVitals },
    ] = usePatientVitalsMutation();
    useEffect(() => {
      if (patientVitals) {
        if (
          new Date(patientVitals?.data?.visitdate).toDateString() ===
          new Date().toDateString()
        ) {
          setShowEditVitalsModal(false);
          setVitals(patientVitals);
        }
      }
    }, [patientVitals]);
    useEffect(() => {
      if (updateSuccess) {
       openNotification("success", <>Vitals Added Successfully</>, 10);
      }
    }, [updateSuccess]);
     
      const columns = [
        {
          title: "Stage",
          dataIndex: "stage",
          key: "stage",
        },
        {
          title: "Speed",
          dataIndex: "speed",
          key: "speed",
        },
        {
          title: "Grade",
          dataIndex: "grade",
          key: "grade",
        },
        {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
        },
        {
          title: "BP",
          dataIndex: "bp",
          key: "bp",
          render: (text, record) => (
            <Input
              value={text}
              onChange={(e) =>
                handleInputChange(record.key, "bp", e.target.bp)
              }
            />
          ),
        },
        {
          title: "HR",
          dataIndex: "hr",
          key: "hr",
          render: (text, record) => (
            <Input
              value={text}
              onChange={(e) =>
                handleInputChange(record.key, "hr", e.target.hr)
              }
            />
          ),
        },
        {
          title: "Symptoms",
          dataIndex: "symptom",
          key: "symptom",
          render: (text, record) => (
            <Input
              value={text}
              onChange={(e) =>
                handleInputChange(record.key, "symptom", e.target.symptom)
              }
            />
          ),
        },
    
      ];
      const dummydata = [
        {
          stage: "1",
          speed:"1.7 MH",
          grade:"10%",
          duration:"0.3 Min",
          // normalrange: "20-31 mm",
        },
        {
          stage: "2",
          speed:"2.5",
          grade:"12%",
          duration:"0.3 Min",
          normalrange: "19-40 mm",
        },
        {
          stage: "3",
          speed:"3.4 MH",
          grade:"14%",
          duration:"0.3 Min",
          normalrange: "36-56 mm",
        },
        {
          stage: "4",
          speed:"4.2 MH",
          grade:"16%",
          duration:"0.3 Min",
          normalrange: "20-35 mm",
        },
        {
          stage: "5",
          speed:"5.0 MH",
          grade:"18%",
          duration:"0.3 Min",
          normalrange: "7-23 mm",
        },
        {
          stage: "Intermediate",
         
        },  {
          stage: "02 Min",
          
        },  {
          stage: "05 Min",
         
        },  {
          stage: "10 Min",
          
        },
      ];
      const handleInputChange = (key, dataIndex, value) => {
        // Update the data array with the new value
        const newData = [...tabledata];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          newData[index][dataIndex] = value;
          setTabledata(newData);
        }
      };
      useEffect(() => {
        if (rowData)
            console.log("test",rowData)
          form.setFieldsValue({
            regnum:rowData?.regnum,
            patientname:rowData?.patientname,
            patienttype: rowData?.patienttype,
            admnum:rowData?.admnum,
            wardname:rowData?.wardname,
            adviseddate:moment(rowData?.adviseddate).format("DD-MMM-YYYY"),
     
          })
      }, [rowData,form]) 

      const onFinish = (values) =>{
        saveett({
             co:co,
             regnum:rowData?.regnum,
             reqnum:rowData?.reqnum,
             patienttype: rowData?.patienttype,
             test:"E",
             adviseddate:moment(rowData?.adviseddate).format("DD-MMM-YYYY"),
            ...values,
            message: 'B',
          })
        
        }
     
  return (
    <section >
       <h1 className="col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        ETT Report Entry
      </h1>
      <Form 
       form={form}
       onFinish={onFinish}
       id='performETT'
      layout="vertical">
      <div className="flex gap-2 flex-col lg:flex-row "> 
      <div className="">
        <div className="border border-americanSilver m-2 "  >
<h1 className="text-center text-lg border border-gainsboro font-semibold bg-bg text-white ">
                  Patient Information
      </h1>
          <div className="grid grid-cols-3 m-2  gap-2">
          <Form.Item label="MR.No" name="regnum"> 
              <Input disabled  placeholder="MR.No" />
            </Form.Item>
            <Form.Item label="Patient Name" name="patientname"> 
              <Input disabled placeholder="Patient Name" />
              </Form.Item>
            <Form.Item label="Patient Type" name="patienttype"> 
              <Input disabled   placeholder="Patient Type" />
              </Form.Item>
            <Form.Item label="IP/Ward Name" name="wardname"> 
              <Input disabled placeholder="IP/Ward Name" />
            </Form.Item>
            <Form.Item label="Admission Number" name="admnum"> 
              <Input disabled placeholder="Admission Number" />
            </Form.Item> 
              <Form.Item label="Advised Date" name="adviseddate"> 
              <Input disabled /> 
            </Form.Item>
            {/* </div> */}
          </div>

          {/* Second Row */}
        
          </div>
          {/* Third Row */}
          <div className='p-2'>
          <Collapse>
          <Panel
        key="1"
        header={
          <div className="text-white">Patient Vitals</div>
 }
        className="bg-[#3d92bc] custom-panel"
      > 
      <PatientVitals vital={vitals} 
       VitalLoading={VitalLoading} 

      />
    
        {/* <PatientVitals /> */}
      </Panel>
          </Collapse>

          </div>
          <div className='p-2'>
          <Collapse >
          <Panel
        key="1"
        header={
          <div className="text-white">Treadmill Exercise Test</div>
 }
        className="bg-[#3d92bc] custom-panel"
      >
      <div className="grid grid-cols-3 gap-2">
          <div className="border border-americanSilver ">
          <h1 className="text-center text-md border border-gainsboro font-semibold bg-bg text-white ">
                  Reason for Test
      </h1>
        
            <div className="col-span-1">
              <Form.Item className="m-0" label="" name="addoptions"> 
                <Checkbox.Group className="grid grid-cols-1 m-2 gap-1"
                  options={["Diagnosis of Chest Pain", "Assess Exercise Tolerance", "Unmasking of Arrhythmia", "Assess Report to treatment", "Research"]}
                  onChange={onChange}/>
                <Checkbox.Group options={[]} disabled onChange={onChange} />
              </Form.Item>        
              <div className="m-2">
              <TextArea  placeholder="Other options to add"  autoSize={{ minRows: 2, maxRows: 2}} />
              </div>
              </div>
            </div>
            <div className="border border-americanSilver grid grid-cols-1 gap-1">
            <h1 className="text-center text-md border border-gainsboro font-semibold bg-bg text-white ">
               Therapy Patient Receiving
      </h1>
            <div className=" col-span-1">
              <Form.Item className="m-0" label="" name="addoptions"> 
                <Checkbox.Group className="grid grid-cols-1 m-2 gap-1"
                  options={["Digoxin", "B-Blockers", "Antihyperstensive", "Anti Arythmic", "Tranquilizers"]}
                  onChange={onChange}/>
                   <Checkbox.Group options={[]} disabled onChange={onChange} />
              </Form.Item>
              <div className="m-2">
              <TextArea placeholder="Other Options to add" autoSize={{ minRows: 2, maxRows: 2}}/>
              </div>
            </div>
            </div>
            <div className="border border-americanSilver gap-1 grid grid-cols-1 ">
            <h1 className="text-center text-md border border-gainsboro font-semibold bg-bg text-white ">
               Resting ECG
      </h1>
            <div className=" col-span-1">
              <Form.Item className="m-0" label="" name="addoptions"> 
                <Checkbox.Group className="grid grid-cols-1 m-2 gap-1" 
                  options={["Normal", "Non-Specific T-changes", "QRS>0.10 Sec(RBBB>LBBB)", "Old Infarct Pattern", "ST Depression"]}
                  onChange={onChange}/>
                   <Checkbox.Group options={[]} disabled onChange={onChange} />
              </Form.Item>
              <div className="m-2">
              <TextArea  placeholder="Other options to add"  autoSize={{ minRows: 2, maxRows: 2}} />
              </div>
            </div>
            </div>

            <div className="border border-americanSilver  ">
          <h1 className="text-center text-md border border-gainsboro font-semibold bg-bg text-white ">
                Test End Point
      </h1>
        
            <div className="">
              <Form.Item className="" label="" name="addoptions"> 
                <Checkbox.Group className="grid grid-cols-1 m-2 gap-1"
                  options={["100% T.H.R", "90% T.H.R", "Target Heart Rate", "Chest Pain", "Fatigue","Dyspnoea","Claudication","ECG Change"]}
                  onChange={onChange}/>
                <Checkbox.Group options={[]} disabled onChange={onChange} />
              </Form.Item>        
              <div className="m-2">
              <TextArea  placeholder="Other options to add"  autoSize={{ minRows: 2, maxRows: 2}} />
              </div>
              </div>
              
            </div>
            <div className="border border-americanSilver grid-cols-1 ">
          <h1 className="text-center text-md border border-gainsboro font-semibold bg-bg text-white ">
               Final Interpretation
      </h1>
        
            <div className="">
              <Form.Item className="m-0" label="" name="addoptions"> 
                <Checkbox.Group className="grid grid-cols-1 m-2 gap-1"
                  options={["Positive", "Negative", "Border Line"]}
                  onChange={onChange}/>
                <Checkbox.Group options={[]} disabled onChange={onChange} />
              </Form.Item>        
              <div className="m-2 mt-36">
              <TextArea  placeholder="Other options to add"  autoSize={{ minRows: 2, maxRows: 2}} />
              </div>
              </div>
              
            </div>
            <div className="border border-americanSilver gap-1 grid grid-cols-1 ">
          
            <div className="grid grid-cols-1 p-1">
            <Form.Item label="" name="exercisetime"> Exercise Time
              <Input  placeholder="Add Exercise Time" />
            </Form.Item>
            <Form.Item label="" name="mins"> Mins
              <Input  placeholder="Add Mins" />
            </Form.Item>
            <Form.Item label="" name="sec"> Seconds
              <Input  placeholder="Add Sec" />
            </Form.Item>
            <div className=''>
            <Form.Item label="Comments" name="comments"> 
            <TextArea  placeholder="Add Comments"  autoSize={{ minRows: 2, maxRows: 2}} > 
            </TextArea>
            </Form.Item>
            </div>

            </div>
            </div>

          </div> 
      </Panel>
          </Collapse>
          </div>   
             
          {/* Fourth Row */}
          <div className="grid grid-cols-4">

            <div className="col-span-4 border border-americanSilver m-2 gap-2  overflow-auto">         
                <Table
                columns= {columns}
                 dataSource={dummydata}
                  pagination={false}
                scroll={{ y: 200 }}
                ></Table>      
            </div>
          </div>
        </div>
        <div className="lg:order-3 order-1 flex flex-col justify-above w-full lg:w-fit p-1">
        <Button
            className=" border-none bg-[white] text-right underline italic"
            // disabled={!selectedPatient}
            onClick={() => setShowEditVitalsModal(true)}
          >
            Edit Vitals
          </Button>
          <div className="flex justify-center lg:justify-center rounded-md">
            <img
              src="https://www.w3schools.com/howto/img_avatar.png"
              alt="patient"
              className="w-[10rem] h-[10rem] object-cover my-2 rounded-sm"
            />
          </div>
          <div className="text-xs mb-2 text-center">
            <h3 className="text-sm font-semibold">Name</h3>
            <p>MR Number: Age: Gender: </p>
            <p>Married - Self</p>
            <p className="underline">Patient Lock Time 09-Jun-2023 11:45am</p>
          </div>
          <div className="flex flex-col gap-1 justify-center">
          <Button type='primary' className='btn-success-primary'  htmlType='submit'>
              Test Completed
            </Button>



            <Button danger type="default">
              Reset
            </Button>
          </div>
        </div>
      </div>
      </Form>
      <Modal
        title="Edit Vitals"
        open={showEditVitalsModal}
        onCancel={() => setShowEditVitalsModal(false)}
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="editVital"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          // <Button key="2" onClick={() => setShowEditVitalsModal(false)}>
          //   Cancel
          // </Button>,
        ]}
        centered
        width={1000}
        maskClosable={false}
        destroyOnClose
      >
        <EditVitals
          // onFinish={handleModalOk}
          vital={vitals}
          // selectedPatient={patientData}
          addPatientVitals={addPatientVitals}
        />
      </Modal>
      

    </section>
  )
}

export default PerformEtt