
import PatientRegister from "../features/registration/pages/PatientRegister";
import PatientSearch from "../features/registration/pages/PatientSearch";



export const registrationRoutes = [
	{
		path: 'PatientSearch',
		element: <PatientSearch/>,

	},

	{
		path: '/PatientRegister',
		element: <PatientRegister/>,

	},

	
	
   
	
]
