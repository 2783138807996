import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetCurrPatientVisitQuery } from "../DashboardService";

const PatientLineChart = () => {
  const { data: patientData } = useGetCurrPatientVisitQuery();

  // Check if patientData exists before accessing its data property
  const beniData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.beni),
    })) || [];
  const othersData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.others),
    })) || [];
  const cneData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.cne),
    })) || [];
  const emplData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.empl),
    })) || [];

  // Highcharts configuration options for the line chart
  const options = {
    credits: false,
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: patientData?.data?.map((item) => item.month) || [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Beni",
        data: beniData.map((item) => item.value),
        color: "#2CAFFE",
      },
      {
        name: "Others",
        data: othersData.map((item) => item.value),
        color: "#004291",
      },
      {
        name: "CNE",
        data: cneData.map((item) => item.value),
        color: "#3D92BC",
      },
      {
        name: "EMPL",
        data: emplData.map((item) => item.value),
        color: "#5752C8",
      },
    ],
  };

  return (
    <div className="border border-border rounded-xl shadow-lg mx-2 my-2">
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white">
        Visited Patient (Current Month)
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PatientLineChart;
