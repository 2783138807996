import { Button, DatePicker, Form, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetfinishedinvoicesdetailMutation,
  useGetFinishedPurchaseInvoicesMutation,
} from "../inventorypurchaseService";
import moment from "moment";
export const PurchaseFinishList = () => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [tableDataDetail, setTableDataDetail] = useState([]);
  const [showdetailmodal, setShowdetailmodal] = useState(false);
  const [savemaster, { data: getmasterdata }] =
    useGetFinishedPurchaseInvoicesMutation();
  const [
    savedetail,
    { data: getdetail, isSuccess: detailsuccess, isError: detailError },
  ] = useGetfinishedinvoicesdetailMutation();
  useEffect(() => {
    if (getdetail) {
      setTableDataDetail(getdetail?.data);
    }
  }, [getdetail]);
  useEffect(() => {
    if (detailsuccess) {
      setShowdetailmodal(true);
    }
  }, [detailsuccess]);
  useEffect(() => {
    if (detailError) {
      setTableDataDetail([]);
    }
  }, [detailError]);
  const columnsdetail = [
    {
      title: "Purchase ID",
      dataIndex: "purchaseid",
    },
    {
      title: "Sequence Number",
      dataIndex: "seqnum",
    },
    {
      title: "Purchase Number",
      dataIndex: "purchasenum",
    },
    {
      title: "Item ID",
      dataIndex: "itemid",
    },
    {
      title: "Item Name",
      dataIndex: "itemname",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Purchase Price",
      dataIndex: "purchaseprice",
    },
    {
      title: "Sale Price",
      dataIndex: "saleprice",
    },
    {
      title: "Total Price",
      dataIndex: "totalprice",
    },
    {
      title: "Tax %",
      dataIndex: "taxpct",
    },
    {
      title: "Discount Amount",
      dataIndex: "discountamount",
    },
  ];
  useEffect(() => {
    savemaster({
      fromdate: moment().format("DD-MMM-YYYY"),
      todate: moment().format("DD-MMM-YYYY"),
      message: "B",
    });
  }, []); //eslint-disable-line
  useEffect(() => {
    if (getmasterdata) {
      setTableData(getmasterdata?.data);
    }
  }, [getmasterdata]);
  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invnum",
    },
    {
      title: "Invoice Date",
      dataIndex: "credate",
      render: (_, { credate }) => moment(credate).format("DD-MMM-YYYY"),
    },
    // {
    //   title: "Ref No",
    //   dataIndex: "refno",
    // },
    {
      title: "Supplier",
      dataIndex: "suppliername",
    },
    {
      title: "Net Total",
      dataIndex: "totalamount",
    },
    // {
    //   title: "Computer",
    //   dataIndex: "computer",
    // },
    {
      title: "Payment",
      dataIndex: "payableamnt",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Time",
      dataIndex: "credate",
      render: (_, { credate }) => moment(credate).format("HH:mm:ss"),
    },
    // {
    //   title: "Remarks",
    //   dataIndex: "remarks",
    // },
  ];
  const onFinish = (values) => {
    savemaster({
      fromdate: moment(values?.fromdate).format("DD-MMM-YYYY"),
      todate: moment(values?.todate).format("DD-MMM-YYYY"),
      message: "B",
    });
  };
  return (
    <section>
      <div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className=" grid sm:grid-cols-3 grid-cols-1 gap-1">
            <Form.Item name="fromdate" label="From Date">
              <DatePicker format={"DD-MMM-YYYY"} defaultValue={moment()} />
            </Form.Item>
            <Form.Item name="todate" label="To Date">
              <DatePicker format={"DD-MMM-YYYY"} defaultValue={moment()} />
            </Form.Item>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div>
        <Table
          size="small"
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "fit-content" }}
          rowKey="purchaseid"
          onRow={(record) => ({
            onClick: () => {
              savedetail({ purchaseid: record.purchaseid });
            },
          })}
        />
      </div>
      <Modal
        title="Purchase Finish Detail"
        open={showdetailmodal}
        onCancel={() => setShowdetailmodal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowdetailmodal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Table
          size="small"
          columns={columnsdetail}
          dataSource={tableDataDetail}
          scroll={{ x: "fit-content" }}
        />
      </Modal>
    </section>
  );
};
