import { useState, useEffect } from "react";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import { EyeFilled, PlusOutlined } from "@ant-design/icons";
import {
  useCreateRoleDetailMutation,
  useCreateRoleMutation,
  useGetAllRolesQuery,
} from "../securityService";
import useTableFilters from "../../../hooks/useTableFilter";
import AddRole from "../components/roles/AddRole";
import AddRoleDetail from "../components/roles/AddRoleDetail";
import ViewRoleDetails from "../components/roles/ViewRoleDetails";

const Roles = () => {
  const { data, isLoading } = useGetAllRolesQuery();
  const [createRole, { isSuccess: addRoleSuccess, isLoading: addRoleLoading }] =
    useCreateRoleMutation();
  const [
    createRoleDetail,
    { isSuccess: addRoleDetailSuccess, isLoading: addRoleDetailLoading },
  ] = useCreateRoleDetailMutation();
  const [rowData, setRowData] = useState();
  const [showNewRoleModal, setShowNewRoleModal] = useState(false);
  const [showViewRoleModal, setShowViewRoleModal] = useState(false);
  const [showNewRoleDetailModal, setShowNewRoleDetailModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (addRoleSuccess) setShowNewRoleModal(false);
  }, [addRoleSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addRoleDetailSuccess) setShowNewRoleDetailModal(false);
  }, [addRoleDetailSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Role Code",
      dataIndex: "code",
      ...filter("code"),
      sorter: (a, b) => a?.code?.localeCompare(b?.code),
    },
    {
      title: "Role Description",
      dataIndex: "desc",
      ...filter("desc"),
      sorter: (a, b) => a?.desc?.localeCompare(b?.desc),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <div className="flex justify-center gap-2">
          <Tooltip title="View Role Detail">
            <Button
              type="primary"
              icon={<EyeFilled />}
              onClick={() => {
                setRowData(data);
                setShowViewRoleModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="New Role Detail">
            <Button
              type="primary"
              className="btn-success-primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRowData(data);
                setShowNewRoleDetailModal(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Manage Roles</h1>
        <Tooltip title="Add New Role" placement="left">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowNewRoleModal(true)}
          />
        </Tooltip>
      </div>

      <div>
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="role"
        />
      </div>

      <Modal
        title="Add New Role"
        open={showNewRoleModal}
        onCancel={() => setShowNewRoleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addNewRoleForm"
            loading={addRoleLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowNewRoleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddRole createRole={createRole} />
      </Modal>

      <Modal
        title="Add Role Detail"
        open={showNewRoleDetailModal}
        onCancel={() => setShowNewRoleDetailModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addNewRoleDetailForm"
            loading={addRoleDetailLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowNewRoleDetailModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddRoleDetail
          createRoleDetail={createRoleDetail}
          roleid={rowData?.role}
        />
      </Modal>

      <Modal
        title="View Role Detail"
        open={showViewRoleModal}
        onCancel={() => setShowViewRoleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="2" onClick={() => setShowViewRoleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <ViewRoleDetails roleid={rowData?.role} />
      </Modal>
    </section>
  );
};

export default Roles;
