import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const LineChartCard = ({ LineData }) => {
  // Check if LineData exists and has data
  const isDataEmpty = !LineData || !LineData.data || LineData.data.length === 0;

  // Format the monthdate and map data for the series
  const chartData = LineData?.data?.map(item => ({
    name: moment(item.monthdate).format('MMMM DD'), // Format to Month Day (e.g., November 01)
    value: Number(item.totalamount), // Ensure the value is a number
  })) || [];

  // Highcharts configuration options for the line chart
  const options = {
    chart: {
      type: 'line',
      height: 250, // Set the height for the chart
    },
    title: {
      text: 'Monthly Total Sales',
    },
    xAxis: {
      categories: chartData.map(item => item.name), // Categories are formatted dates
    },
    yAxis: {
      title: {
        text: 'Total Amount ($)', // Label for the y-axis
      },
    },
    credits: false,
    series: [
      {
        name: 'Sales',
        data: chartData.map(item => item.value), // The y-values (total amount)
        color: '#2CAFFE',
      },
    ],
  };

  return (
    <div className="border border-border shadow-lg mx-2 my-2">
      {/* Optional header */}
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full">
        Monthly Total Sales
      </h4>
      {/* Render the chart only if data is available */}
      {!isDataEmpty ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <p>No data available for this month.</p> // Show message if no data is present
      )}
    </div>
  );
};

export default LineChartCard;
