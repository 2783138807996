import { useState, useEffect } from "react";
import { Button, Table, Tooltip, Tag, Modal } from "antd";
import { PlusOutlined, EyeFilled, EditFilled } from "@ant-design/icons";
import {
  useCreateBussFuncMutation,
  useCreateModuleMutation,
  useGetSecModulesQuery,
  useUpdateModuleMutation,
} from "../securityService";
import AddNewModule from "../components/businessFunction/AddNewModule";
import ViewBusinessModule from "../components/businessFunction/ViewBusinessModule";
import useTableFilters from "../../../hooks/useTableFilter";
import AddBusinessModule from "../components/businessFunction/AddBusinessModule";
import UpdateModule from "../components/businessFunction/UpdateModule";

const BusinessFunction = () => {
  const { data, isLoading } = useGetSecModulesQuery();
  const [
    createModule,
    { isSuccess: addModuleSuccess, isLoading: addModuleLoading },
  ] = useCreateModuleMutation();
  const [
    createBussFunc,
    { isSuccess: addBussSuccess, isLoading: addBussLoading },
  ] = useCreateBussFuncMutation();
  const [
    updateModule,
    { isSuccess: updateModuleSuccess, isLoading: updateModuleLoading },
  ] = useUpdateModuleMutation();
  const [rowData, setRowData] = useState();
  const [showAddModuleModal, setShowAddModuleModal] = useState(false);
  const [showViewBusinessModuleModal, setShowViewBusinessModuleModal] =
    useState(false);
  const [showAddBusinessModuleModal, setShowAddBusinessModuleModal] =
    useState(false);
  const [showUpdateModuleModal, setShowUpdateModuleModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (addModuleSuccess) setShowAddModuleModal(false);
  }, [addModuleSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addBussSuccess) setShowAddBusinessModuleModal(false);
  }, [addBussSuccess]); // eslint-disable-line

  useEffect(() => {
    if (updateModuleSuccess) setShowUpdateModuleModal(false);
  }, [updateModuleSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Module",
      dataIndex: "modulecode",
      ...filter("modulecode"),
      sorter: (a, b) => a?.modulecode?.localeCompare(b?.modulecode),
    },
    {
      title: "Description",
      dataIndex: "descr",
      ...filter("descr"),
      sorter: (a, b) => a?.descr?.localeCompare(b?.descr),
    },
    {
      title: "Port",
      dataIndex: "port",
      ...filter("port"),
      sorter: (a, b) => a?.port?.localeCompare(b?.port),
    },
    {
      title: "IP Address",
      dataIndex: "ipaddress",
      ...filter("ipaddress"),
      sorter: (a, b) => a?.ipaddress?.localeCompare(b?.ipaddress),
    },
    {
      title: "Context",
      dataIndex: "context",
      ...filter("context"),
      sorter: (a, b) => a?.context?.localeCompare(b?.context),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <div className="flex justify-center gap-2">
          <Tooltip title="View Business Module">
            <Button
              type="primary"
              icon={<EyeFilled />}
              onClick={() => {
                setRowData(data);
                setShowViewBusinessModuleModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Add Business Module">
            <Button
              type="primary"
              className="btn-success-primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRowData(data);
                setShowAddBusinessModuleModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Update Business Module">
            <Button
              type="primary"
              className="btn-warning-primary"
              icon={<EditFilled />}
              onClick={() => {
                setRowData(data);
                setShowUpdateModuleModal(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <section className="">
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">
          Module & Business Function
        </h1>
        <Tooltip title="Add New Module">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModuleModal(true)}
          />
        </Tooltip>
      </div>

      <div>
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 10 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="modulecode"
          // className='dark-grey-border'
        />
      </div>

      <Modal
        title="Add New Module"
        open={showAddModuleModal}
        onCancel={() => setShowAddModuleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addNewModuleForm"
            loading={addModuleLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModuleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddNewModule createModule={createModule} />
      </Modal>

      <Modal
        title="Module Details"
        open={showViewBusinessModuleModal}
        onCancel={() => setShowViewBusinessModuleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="2" onClick={() => setShowViewBusinessModuleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <ViewBusinessModule moduleId={rowData?.moduleid} />
      </Modal>

      <Modal
        title="New Business Module"
        open={showAddBusinessModuleModal}
        onCancel={() => setShowAddBusinessModuleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addNewBusinessModuleForm"
            loading={addBussLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddBusinessModuleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddBusinessModule
          createBussFunc={createBussFunc}
          moduleid={rowData?.moduleid}
          moduleDescr={rowData?.descr}
        />
      </Modal>

      <Modal
        title="Update Module"
        open={showUpdateModuleModal}
        onCancel={() => setShowUpdateModuleModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateModuleForm"
            loading={updateModuleLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModuleModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateModule updateModule={updateModule} rowData={rowData} />
      </Modal>
    </section>
  );
};

export default BusinessFunction;
