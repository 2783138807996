import { Tabs } from 'antd'
import React from 'react'
import Manufacturer from '../components/Manufacturer'
import MedType from '../components/MedType'
import Strength from '../components/Strength'
import Rack from '../components/Rack'
import MaterialMaster from '../components/MaterialMaster'

function StoreConfiguration() {
  return (
    <section className='border border-gainsboro'>
    <div  className="flex items-center justify-between px-5 py-3"
    style={{  backgroundColor: "#00b29c" }}
    >
      <h1 className='font-semibold text-base sm:text-lg text-white'>
       Store Configurations
      </h1>
    </div>
    <div className='p-5'>
      <Tabs
       items={[
          
          {label:'Manufacturer',key:'1',children:<Manufacturer/>},
          {label:'Medicine Type',key:'2',children:<MedType/>},
          {label:'Medicine Strength',key:'3',children:<Strength/>},
          {label:'Medicine Rack',key:'4',children:<Rack/>},
          {label:'Material Master',key:'5',children:<MaterialMaster/>},
          
          
         
         
          
        ]}
      />
    </div>
  </section>
  )
}

export default StoreConfiguration