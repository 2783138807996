import { Form, Input, Select } from 'antd'
import { useLovEmployeeQuery, useLovRoleQuery, useLovUserPolicyQuery } from '../../securityService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddUser = ({ createUser }) => {
  const { data: lovEmployee } = useLovEmployeeQuery()
  const { data: lovRole } = useLovRoleQuery()
  const { data: lovUserPolicy } = useLovUserPolicyQuery()

  const onFinish = values =>
    createUser({
      ...values,
      policy: lovUserPolicy?.data?.find(policy => policy?.policyid === values?.policy),
      email: values?.email || '',
      role: lovRole?.data?.filter(item => values?.role.includes(item?.roleid)),
      middlename: values?.middlename || '',
      userid: values?.username,
      useridnew: values?.username,
      fullname: `${values.firstname} ${values.middlename || ''} ${values.lastname || ''}`,
    })

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      id='addUserForm'
      validateMessages={formValidationMessages}
      className='grid sm:grid-cols-3 grid-cols-1 gap-2'
    >
      <Form.Item
        name='firstname'
        label='First Name'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Enter First Name' autoFocus />
      </Form.Item>
      <Form.Item name='middlename' label='Middle Name'>
        <Input placeholder='Enter Middle Name' autoFocus />
      </Form.Item>
      <Form.Item
        name='lastname'
        label='Last Name'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Enter Last Name' autoFocus />
      </Form.Item>
      <Form.Item
        name='employeeid'
        label='Employee'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Employee'
          options={lovEmployee?.data?.map(item => ({
            value: item?.code,
            label: `${item?.code} - ${item?.descr}`,
          }))}
          showSearch
          filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
        />
      </Form.Item>
      <Form.Item
        name='username'
        label='User Name'
        autoComplete='off'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder='Enter User Name' />
      </Form.Item>
      <Form.Item
        name='passwordnew'
        label='Password'
        autoComplete='off'
        rules={[
          {
            required: true,
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
            message:
              'Password must be atleast 10 characters long & contain atleast one lowercase, one uppercase, one numeric & one special character',
          },
        ]}
      >
        <Input.Password placeholder='Enter Password' />
      </Form.Item>
      <Form.Item
        name='cpassword'
        label='Confirm Password'
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('passwordnew') === value) return Promise.resolve()
              return Promise.reject(new Error('Passwords do not match!'))
            },
          }),
        ]}
      >
        <Input.Password placeholder='Enter Confirm Password' />
      </Form.Item>
      <Form.Item
        name='policy'
        label='Policy'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Policy'
          options={lovUserPolicy?.data?.map(item => ({
            value: item?.policyid,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item
        name='role'
        label='Role'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Role'
          mode='multiple'
          options={lovRole?.data?.map(item => ({
            value: item?.roleid,
            label: item?.rolecode,
          }))}
        />
      </Form.Item>
      <Form.Item
        name='status'
        label='Status'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Status'
          options={[
            { value: 'Y', label: 'Active' },
            { value: 'N', label: 'In Active' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            type: 'email',
          },
        ]}
      >
        <Input placeholder='Enter Email' />
      </Form.Item>
    </Form>
  )
}

export default AddUser
