import { api } from '../api/api'

export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      query: body => ({
        url: 'security/login',
        method: 'POST',
        body,
      }),
    }),
    changePassword: build.mutation({
      query: body => ({
        url: 'auth/changePassword',
        method: 'POST',
        body,
      }),
    }),
    getRoles: build.query({
      query: () => 'security/lovUserRole',
    }),
    getRoleMenu: build.mutation({
      query: body => `security/getMenuAgainstRole/${body}`,
    }),
    getOrgUnit: build.query({
      query: () => 'security/getOrgUnit',
    }),
  }),
})

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useGetRolesQuery,
  useGetRoleMenuMutation,
  useGetOrgUnitQuery,
} = authApi
