import { Form, Select } from 'antd'
import {
  useGetBussFunctionsMMutation,
  useGetSecModulesQuery,
} from '../../securityService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddRoleDetail = ({ roleid, createRoleDetail }) => {
  const { data: secModules } = useGetSecModulesQuery()
  const [getBussFunction, { data: bussFunc }] = useGetBussFunctionsMMutation()
  const [form] = Form.useForm()

  const onFinish = values =>
    createRoleDetail({
      ...values,
      roleid,
      title: bussFunc.data.find(item => item?.bussfunc === values?.bussfunc)
        ?.title,
      message: 'B',
    })

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      id='addNewRoleDetailForm'
      form={form}
      validateMessages={formValidationMessages}
      className='grid sm:grid-cols-2 grid-cols-1 gap-2'>
      <Form.Item
        name='moduleid'
        label='Module'
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
          placeholder='Select Module'
          onChange={e => {
            getBussFunction(e)
            form.setFieldsValue({ bussfunc: undefined })
          }}
          options={secModules?.data?.map(item => ({
            value: item?.moduleid,
            label: item?.descr,
          }))}
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase()?.includes(input?.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name='bussfunc'
        label='Business Function'
        rules={[
          {
            required: true,
          },
        ]}>
        <Select
          placeholder='Select Business Function'
          options={bussFunc?.data?.map(item => ({
            value: item?.bussfunc,
            label: item?.title,
          }))}
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase()?.includes(input?.toLowerCase())
          }
        />
      </Form.Item>
    </Form>
  )
}

export default AddRoleDetail
