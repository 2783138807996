import { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import useTableFilters from "../../../../hooks/useTableFilter";
import {
  useCreateSectionMutation,
  useGetAllSectionsQuery,
  useUpdateSectionMutation,
} from "../../orgStructureService";
import AddSection from "./AddSection";
import UpdateSection from "./UpdateSection";

const Section = () => {
  const { data, isLoading } = useGetAllSectionsQuery();
  const [createSection, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateSectionMutation();
  const [
    updateSection,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateSectionMutation();
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "sectionid",
      ...filter("sectionid"),
      sorter: (a, b) => a?.sectionid - b?.sectionid,
      defaultSortOrder: "descend",
    },
    {
      title: "Section Abbreviation",
      dataIndex: "sectionabbrv",
      ...filter("sectionabbrv"),
      sorter: (a, b) => a?.sectionabbrv?.localeCompare(b?.sectionabbrv),
    },
    {
      title: "Section Name",
      dataIndex: "sectionname",
      ...filter("sectionname"),
      sorter: (a, b) => a?.sectionname?.localeCompare(b?.sectionname),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Section">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
        <Tooltip title="New Section">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="sectionid"
        />
      </div>

      <Modal
        title="Add Section"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addSectionForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddSection createSection={createSection} />
      </Modal>

      <Modal
        title="Update Section"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateSectionForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateSection rowData={rowData} updateSection={updateSection} />
      </Modal>
    </section>
  );
};

export default Section;
