import { Button, Form, Input, message, Select, Table } from 'antd';
import React, { useState } from 'react';
import formValidationMessages from '../../../utils/formValidationMessages';
import { useLovSrviceListQuery, useLovSrvicepriceforappointMutation, useLovSubSrviceListMutation, useLovSubSrvicepriceforappointMutation } from '../../employee/employeeServices';
const AddVisit = ({ createappoint, selectedPatient, handlerefetch }) => {

  const { data: lovSrviceList } = useLovSrviceListQuery();
  const [savesubservice] = useLovSrvicepriceforappointMutation();
  const [fetchSubServiceList] = useLovSubSrviceListMutation();
  const [fetchSubServiceappointList] = useLovSubSrvicepriceforappointMutation();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [subServiceOptions, setSubServiceOptions] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  
  const handleServiceChange = async (value) => {
    setSelectedService(value);
    try {
      const response = await savesubservice({ srvid: value }).unwrap();
      if (response && response.responsecode === 1) {
        const service = lovSrviceList?.data?.find(item => item.code === value);
        const newData = response.data.map(subService => ({
          srvid: service?.descr || '--',
          doctor: subService?.descr || '--',
          price: subService?.amount || '--',
          disc: '0', // Default discount to "0"
          payableAmount: subService?.amount || '--',
          key: `${service?.code}-${subService?.seqno}`
        }));
        setTableData(prevData => [...prevData, ...newData]);
        setSubServiceOptions([]);
      }
    } catch (error) {
      const secondaryResponse = await fetchSubServiceList({ srvid: value }).unwrap();
      if (secondaryResponse) {
        const options = secondaryResponse.data.map(subService => ({
          value: subService.code,
          label: subService.descr
        }));
        setSubServiceOptions(options);
      } else {
        console.error("Error handling service change:", error);
      }
    }
  };
  const handleSubServiceChange = async (value) => {
    const subService = subServiceOptions.find(item => item.value === value);
    const service = lovSrviceList?.data?.find(item => item.code === selectedService);
    if (subService) {
      const subsrvid = subService.value;
      
        const priceResponse = await fetchSubServiceappointList(subsrvid).unwrap();
        if (priceResponse && priceResponse.responsecode === 1) {
          const newRow = {
            srvid: service?.descr || '--',
            doctor: subService?.label || '--',
            price: priceResponse.data[0]?.amount || '--',
            disc: '0',
            payableAmount: priceResponse.data[0]?.amount || '--',
            key: `${service?.code}-${subService?.value}`
          };
          setTableData(prevData => [
            ...prevData,
            newRow
          ]);
        }
    }
  };
  const handleDelete = (key) => {
    setTableData(prevData => prevData.filter(row => row.key !== key));
  };
  const handleDiscountChange = (key, discount) => {
    setTableData(prevData =>
      prevData.map(row => {
        if (row.key === key) {
          const price = parseFloat(row.price) || 0;
          let disc = parseFloat(discount) || 0;
          if (disc > price) {
            disc = price;
          }
          const payableAmount = (price - disc).toFixed(2);
          return { ...row, disc: disc.toString(), payableAmount };
        }
        return row;
      })
    );
  };
  const getTotal = (key) => {
    return tableData.reduce((total, row) => {
      const value = parseFloat(row[key]) || 0;
      return total + value;
    }, 0).toFixed(2);
  };

    const onFinish = () => {
    const appointmentData = {
      seqno: selectedPatient?.seqno, 
      cellno: selectedPatient?.cellno,
      facilities: tableData.map(row => ({
        actamt: row.price,
        disc: row.disc,
        amt: row.payableAmount,
        catid: row.key.split('-')[1]
      }))
    };
    createappoint(appointmentData)
    .unwrap()
    .then(() => {
      message.success('Appointment created successfully');
      handlerefetch();
    })
    .catch((error) => {
      message.error('Error creating appointment');
      console.error("Error creating appointment:", error);
    });
  }

   

  const tableColumns = [
    {
      title: "Service",
      dataIndex: 'srvid',
    },
    {
      title: "Sub Service",
      dataIndex: "doctor",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Discount",
      dataIndex: "disc",
      render: (_, record) => (
        <Input
          value={record.disc}
          onChange={(e) => handleDiscountChange(record.key, e.target.value)}
        />
      )
    },
    {
      title: "Payable Amount",
      dataIndex: "payableAmount",
    },
    {
      title: "Action(s)",
      render: (_, record) => (
        <Button
          danger
          onClick={() => handleDelete(record.key)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="flex gap-2">
        <Form
          layout="vertical"
          form={form}
          validateMessages={formValidationMessages}
          className="border border-gainsboro p-2 flex-[4]"
        >
          <div className="grid grid-cols-1 gap-2">
            <p>Patient Name: {selectedPatient?.patientname}</p>
            <p>Cell No: {selectedPatient?.cellno}</p>
            <Form.Item
              name="srvid"
              label="Service List"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Service"
                options={lovSrviceList?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                onChange={handleServiceChange}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>
            {subServiceOptions.length > 0 && (
              <Form.Item
                name="subsrvid"
                label="Sub Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Sub Service"
                  options={subServiceOptions}
                  onChange={handleSubServiceChange}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            )}
          </div>
        </Form>
        <Form
          id="addVisit"
          onFinish={onFinish}
          className="border border-gainsboro p-2 flex-[6]"
        >
          <Table
            size="small"
            columns={tableColumns}
            dataSource={tableData}
            rowKey="key"
            pagination={false}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell />
                <Table.Summary.Cell>{getTotal("price")}</Table.Summary.Cell>
                <Table.Summary.Cell>{getTotal("disc")}</Table.Summary.Cell>
                <Table.Summary.Cell>{getTotal("payableAmount")}</Table.Summary.Cell>
                <Table.Summary.Cell />
              </Table.Summary.Row>
            )}
          />
       
        </Form>
      </div>
  
    </>
  );
};

export default AddVisit;
