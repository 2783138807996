import { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import useTableFilters from "../../../../hooks/useTableFilter";
import {
  useCreateSubDepartmentMutation,
  useGetAllSubDepartmentsQuery,
  useUpdateSubDepartmentMutation,
} from "../../orgStructureService";
import AddSubDepartment from "./AddSubDepartment";
import UpdateSubDepartment from "./UpdateSubDepartment";

const SubDepartment = () => {
  const { data, isLoading } = useGetAllSubDepartmentsQuery();
  const [
    createSubDepartment,
    { isSuccess: addSuccess, isLoading: addLoading },
  ] = useCreateSubDepartmentMutation();
  const [
    updateSubDepartment,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateSubDepartmentMutation();

  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "sdeptid",
      ...filter("sdeptid"),
      sorter: (a, b) => a?.sdeptid - b?.sdeptid,
      defaultSortOrder: "descend",
    },
    {
      title: "Sub Department Abbreviation",
      dataIndex: "sdeptabbrv",
      ...filter("sdeptabbrv"),
      sorter: (a, b) => a?.sdeptabbrv?.localeCompare(b?.sdeptabbrv),
    },
    {
      title: "Sub Department Name",
      dataIndex: "sdeptname",
      ...filter("sdeptname"),
      sorter: (a, b) => a?.sdeptname?.localeCompare(b?.sdeptname),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Sub Department">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
        <Tooltip title="New Sub Department">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="sdeptid"
        />
      </div>

      <Modal
        title="Add Sub Department"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addSubDepartmentForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddSubDepartment createSubDepartment={createSubDepartment} />
      </Modal>

      <Modal
        title="Update Sub Department"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateSubDepartmentForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateSubDepartment
          rowData={rowData}
          updateSubDepartment={updateSubDepartment}
        />
      </Modal>
    </section>
  );
};

export default SubDepartment;
