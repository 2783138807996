import { DatePicker, Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddEmployee=({createemployee})=> {
    const onFinish = (values) =>{
        createemployee({
            ...values,
            email: values.email || "",
            emptype: values.emptype || "",
            message: 'B',
          })
        }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addEmployeeForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >
    <Form.Item
      name='empname'
      label='Employee Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Employee Name'/>
    </Form.Item>
    <Form.Item name='cellno' label='Phone No'>
      <Input placeholder='Enter Phone No.' 
      rules={[
        {
          required: true,
        },
      ]} />
    </Form.Item>
    <Form.Item
      name='email'
      label='Email'
    >
      <Input placeholder='Enter Email' />
    </Form.Item>
  
    <Form.Item
                  name='gender'
                  label='Gender'
                >
                  <Select
                    placeholder='Select Gender'
                    options={[
                      { value: 'M', label: 'Male' },
                      { value: 'F', label: 'Female' },
                    ]}
                  />
                </Form.Item>
   
    <Form.Item
      name='emptype'
      label='Employee Type'
      
    >
      <Input placeholder="Enter Employee Type"></Input>
    
    </Form.Item>
 
    <Form.Item
      name='status'
      label='Status'
    >
      <Select
        placeholder='Select Status'
        options={[
          { value: 'Y', label: 'Active' },
          { value: 'N', label: 'In Active' },
        ]}
      />
    </Form.Item>
    <Form.Item 
      name='age' label='Age' rules={[
                {
                  required: true,
                },
                
              ]}>
      <Input placeholder='Enter Age'></Input>
      </Form.Item>

  </Form>
    </div>
  )
}

export default AddEmployee