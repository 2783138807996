import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const UpdateInventoryStrength = ({rowData,updatestrengthData,updatestrength}) => {
  const [form] = Form.useForm()
  const onFinish = (values) =>
    updatestrength({
      ...values,
      genericname:values?.genericname,
      status: values?.status,
      cid:values?.strid,
   
      message: 'B',
    })
  return (

    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='updateStrengthForm'
    form={form}
    initialValues={{
      stname:rowData?.stname,
      strid:rowData?.strid,
     
      status:rowData?.status,
      
      
     
    }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >

<Form.Item
      name='strid'
      label='Strength ID'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled='true' />
    </Form.Item>
    <Form.Item
      name='stname'
      label='Strength'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Strength'  />
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
     
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}

export default UpdateInventoryStrength