import React, { useEffect } from 'react';
import { Modal, Spin, Alert, Table } from 'antd';
import { useGetMedExpiryDetailQuery } from '../storeServices';

const DashboardExpireyDetail = ({ isVisible, onClose }) => {
  const { data, error, isLoading, refetch } = useGetMedExpiryDetailQuery();

  // Fetch data when the modal opens
  useEffect(() => {
    if (isVisible) {
      refetch();
    }
  }, [isVisible, refetch]);

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <Alert message="Error" description="Failed to fetch expiry details." type="error" showIcon />;
  }

  // Access the data field from the response object
  const expiryData = data?.data || [];

  // Define table columns
  const columns = [
    {
        title: 'Sr.No',
        key: 'index',
        render: (text, record, index) => index + 1, // Serial number
        width: 60, // Adjust width as needed
      },
    {
      title: 'Medicine Name',
      dataIndex: 'medname',
      key: 'medname',
    },
    {
      title: 'Type',
      dataIndex: 'medtype',
      key: 'medtype',
    },
    {
      title: 'Strength',
      dataIndex: 'strength',
      key: 'strength',
    },
    {
      title: 'Rack No',
      dataIndex: 'rackno',
      key: 'rackno',
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Company Name',
      dataIndex: 'companyname',
      key: 'companyname',
    },

    {
        title: 'Expirey Date',
        dataIndex: 'expdate',
        key: 'expdate',
      },
  ];

  return (
    <Modal
      title="Near Expiry Medicines Details"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
        dataSource={expiryData}
        columns={columns}
        rowKey="id" // Use a unique key from your data; adjust if needed
        pagination={false} // Disable pagination if not needed
      />
    </Modal>
  );
};

export default DashboardExpireyDetail;
