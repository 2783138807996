import { api } from '../api/api'

const StoreApi = api.injectEndpoints({
    
	endpoints: build => ({
  
        getAllMedlist: build.query({
            query: () => `security/getAllMedlist`,
            
          }), 

        getAllstats: build.query({
            query: () => `security/getAllstats`,
            
          }),
          
        getMedExpiryDetail: build.query({
            query: () => `security/getMedExpiryDetail`,
            
          }),

      
       
      CreateSopRecord: build.mutation({
      query: (body) => ({
        url: "security/CreateSopRecord",
        method: "POST",
        body,
       
      }),
    }),   
  
       
 
 
 
    
  }),

 
})

export const {

    useGetAllMedlistQuery,
    useGetAllstatsQuery,
    useGetMedExpiryDetailQuery,
    useCreateSopRecordMutation,
        
} = StoreApi
