import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useGetPatientAppointStatQuery } from '../DashboardService'

const PatientBarChart = () => {
  const { data: patientStat } = useGetPatientAppointStatQuery()

  // Transform the API data to match the required format for the Highcharts bar chart
  const transformedData = patientStat?.data?.map(item => ({
    name: item.month,
    beni: parseInt(item.beni),
    others: parseInt(item.others),
    cne: parseInt(item.cne),
    empl: parseInt(item.empl),
  }))

  const options = {
    credits: false,
    chart: {
      type: 'column',
    },
    title: {
      text: [],
    },

    xAxis: {
      categories: transformedData?.map(item => item.name) || [],
      title: {
        text: [],
      },
      gridLineWidth: 1,
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      title: [],
      labels: {
        overflow: 'justify',
      },
      gridLineWidth: 0,
    },
    tooltip: {
      valueSuffix: '',
    },
    plotOptions: {
      bar: {
        borderRadius: '100%',
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1,
      },
      column: {
        stacking: 'normal',
      },
    },
    // legend: {
    //   layout: 'horizontal',
    //   align: 'right',
    //   verticalAlign: 'top',
    //   x: -40,
    //   y: 80,
    //   floating: true,
    //   borderWidth: 1,
    //   backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    //   shadow: true,
    // },

    series: [
      {
        name: 'Beni',
        data: transformedData?.map(item => item.beni) || [],
      },
      {
        name: 'Others',
        data: transformedData?.map(item => item.others) || [],
      },
      {
        name: 'CNE',
        data: transformedData?.map(item => item.cne) || [],
      },
      {
        name: 'Empl',
        data: transformedData?.map(item => item.empl) || [],
      },
    ],
  }

  return (
    <div className='border border-border rounded-xl shadow-lg mx-2 my-2'>
      <h4 className='font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white'>
        Patient Type Wise Visit (Monthly)
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default PatientBarChart
