import { api } from '../api/api'

export const financeApi = api.injectEndpoints({
  tagTypes: [
    'NonFin',
    'VoucherTemplate',
    'VoucherTemplateDetails',
    'Voucher',
    'Cost Center',
    'VoucherType',
    'COA',
    'UserBatch',
    'AccountGroup',
    'Responsibility',
    'RespCostCenter',
    'RespVoucherType',
    'RespUser',
  ],
  endpoints: build => ({
    lovLoggedUserRespCompany: build.query({
      query: () => 'finance/lovLoggedUserRespCompany',
    }),
    lovFisYear: build.query({
      query: () => 'finance/lovFisYear',
    }),
    lovFisPeriod: build.mutation({
      query: body => `finance/lovFisPeriod/${body}`,
    }),
    getCashBook: build.mutation({
      query: body =>
        `finance/getCashBook/${body?.project}/${body?.year}/${body.periodFrom}/${body?.periodTo}/${body?.approvalStatus}`,
    }),
    lovLoggedUserResponsiblity: build.query({
      query: () => `finance/lovLoggedUserResponsiblity`,
    }),
    lovCoaNum: build.query({
      query: () => `finance/lovCoaNum`,
    }),
    lovRespCostCenter: build.mutation({
      query: body => `finance/lovRespCostCenter/${body}`,
    }),
    getTrialBalance: build.mutation({
      query: body => ({
        url: 'finance/getTrialBalance',
        method: 'POST',
        body,
      }),
    }),
    getAllNonFinancialDataForSubmission: build.query({
      query: () => 'finance/getAllNonFinancialDataForSubmission',
      providesTags: ['NonFin'],
    }),
    updateNonFinancialData: build.mutation({
      query: body => ({
        url: 'finance/updateNonFinancialData',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NonFin'],
    }),
    saveNonFinancialData: build.mutation({
      query: body => ({
        url: 'finance/saveNonFinancialData',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NonFin'],
    }),
    perfomActionOnNonFinVouchers: build.mutation({
      query: body => ({
        url: 'finance/perfomActionOnNonFinVouchers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NonFin'],
    }),
    getAllBatchWiseVoucher: build.query({
      query: body => `finance/getAllBatchWiseVoucher/${body}`,
      providesTags: ['Voucher'],
    }),
    getVoucherReciept: build.mutation({
      query: body => `finance/voucherReciept/${body}`,
    }),
    viewVoucher: build.query({
      query: body => `finance/viewVoucher/${body}`,
    }),
    lovCurrency: build.query({
      query: () => 'finance/lovCurrency',
    }),
    lovVoucherType: build.query({
      query: () => 'finance/lovVoucherType',
    }),
    getAllNonFinancialDataForApproval: build.query({
      query: () => 'finance/getAllNonFinancialDataForApproval',
      providesTags: ['NonFin'],
    }),
    getAllApprovedNonFinancialData: build.query({
      query: () => 'finance/getAllApprovedNonFinancialData',
      providesTags: ['NonFin'],
    }),
    lovChartOfAccountProjectWise: build.mutation({
      query: body => `finance/lovChartOfAccountProjectWise/${body}`,
    }),
    lovCostCenter: build.query({
      query: () => 'finance/lovCostCenter',
    }),
    getAllPeriodsYearWise: build.mutation({
      query: body => `finance/getAllPeriodsYearWise/${body}`,
    }),
    updateFiscPer: build.mutation({
      query: body => ({
        url: 'finance/updateFiscPer',
        method: 'POST',
        body,
      }),
    }),
    getNonFinDataByMonth: build.mutation({
      query: body => `finance/getNonFinDataByMonth/${body}`,
    }),
    getNonFinDataByDate: build.mutation({
      query: body => `finance/getNonFinDataByDate/${body}`,
    }),
    searchOB: build.mutation({
      query: body => ({
        url: 'finance/searchOB',
        method: 'POST',
        body,
      }),
    }),
    lovChartOfAccountNumbersVoucherTemplate: build.query({
      query: () => 'finance/lovChartOfAccountNumbersVoucherTemplate/1',
    }),
    saveAcctOpeningBalance: build.mutation({
      query: body => ({
        url: 'finance/saveAcctOpeningBalance',
        method: 'POST',
        body,
      }),
    }),
    getVoucherStats: build.query({
      query: () => 'finance/getVoucherStats',
      providesTags: ['Voucher'],
    }),
    getAllVoucherTemplates: build.query({
      query: () => 'finance/getAllVoucherTemplates',
      providesTags: ['VoucherTemplate'],
    }),
    createVoucherTemplate: build.mutation({
      query: body => ({
        url: 'finance/createVoucherTemplate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherTemplate'],
    }),
    getTemplateVoucherDetail: build.query({
      query: body => `finance/getTemplateVoucherDetail/${body}`,
      providesTags: ['VoucherTemplateDetails'],
    }),
    updateVoucherTemplateDetail: build.mutation({
      query: body => ({
        url: 'finance/updateVoucherTemplateDetail',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherTemplateDetails'],
    }),
    createVoucherTemplateDetail: build.mutation({
      query: body => ({
        url: 'finance/createVoucherTemplateDetail',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherTemplateDetails'],
    }),
    getAllVoucherProjectAndStatusWise: build.query({
      query: body =>
        `finance/getAllVoucherProjectAndStatusWise/${body?.id}/${body?.status}`,
      providesTags: ['Voucher'],
    }),
    perfomActionOnVouchers: build.mutation({
      query: body => ({
        url: 'finance/perfomActionOnVouchers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    postToOF: build.mutation({
      query: body => ({
        url: 'finance/postVoucherToOF',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    lovRespCompany: build.mutation({
      query: body => `finance/lovRespCompany/${body}`,
    }),
    lovBatchName: build.mutation({
      query: body => `finance/lovBatchName/${body.project}`,
    }),
    lovBatchType: build.mutation({
      query: body => `finance/lovBatchType/${body}`,
    }),
    updateVoucherBatch: build.mutation({
      query: body => ({
        url: 'finance/updateVoucherBatch',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    sendBackFromHo: build.mutation({
      query: body => ({
        url: 'finance/sendBackFromHo',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    getAllActiveVoucherTemplates: build.query({
      query: () => 'finance/getAllActiveVoucherTemplates/',
    }),
    getTemplateVoucherTemplateDetails: build.mutation({
      query: body => `finance/getTemplateVoucherDetail/${body}`,
    }),
    createBatch: build.mutation({
      query: body => ({
        url: 'finance/saveBatch',
        method: 'POST',
        body,
      }),
    }),
    saveVoucherTemplate: build.mutation({
      query: body => ({
        url: 'finance/saveVoucherFromTemplateFromTemplate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    updateVoucher: build.mutation({
      query: body => ({
        url: 'finance/updateVoucher',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    updateApprovedVoucher: build.mutation({
      query: body => ({
        url: 'finance/updateApprovedVoucher',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Voucher'],
    }),
    updateVoucherTemplate: build.mutation({
      query: body => ({
        url: 'finance/updateVoucherTemplate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherTemplate'],
    }),
    getAllCostCenterTree: build.query({
      query: () => 'finance/getAllCostCenterTree/',
      providesTags: ['CostCenter'],
    }),
    addCostCenter: build.mutation({
      query: body => ({
        url: 'finance/enterCostCenter',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CostCenter'],
    }),
    getAllVoucherType: build.query({
      query: () => 'finance/getAllVoucherType',
      providesTags: ['VoucherType'],
    }),
    addVoucherType: build.mutation({
      query: body => ({
        url: 'finance/saveVoucherType',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherType'],
    }),
    updateVoucherType: build.mutation({
      query: body => ({
        url: 'finance/updateVoucherType',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VoucherType'],
    }),
    getAllCoaTitle: build.query({
      query: () => 'finance/getAllCoaTitle',
      providesTags: ['COA'],
    }),
    addCoaTitle: build.mutation({
      query: body => ({
        url: 'finance/saveCoaTitle',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['COA'],
    }),
    updateCoaTitle: build.mutation({
      query: body => ({
        url: 'finance/updateCoaTitle',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['COA'],
    }),
    getAllUserBatchTypes: build.query({
      query: () => 'finance/getALLUserBatchTypes',
      providesTags: ['UserBatch'],
    }),
    addUserBatchType: build.mutation({
      query: body => ({
        url: 'finance/createBatchType',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserBatch'],
    }),
    updateUserBatchType: build.mutation({
      query: body => ({
        url: 'finance/updateUserBatch',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserBatch'],
    }),
    getAllAccountGroup: build.query({
      query: () => 'finance/getAllAccountGroup',
      providesTags: ['AccountGroup'],
    }),
    saveAccountGroup: build.mutation({
      query: body => ({
        url: 'finance/saveAccountGroup',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AccountGroup'],
    }),
    getAllResponsibility: build.query({
      query: () => 'finance/getAllResponsibility',
      providesTags: ['Responsibility'],
    }),
    lovProject: build.query({
      query: () => 'finance/lovProject',
    }),
    saveResponsibility: build.mutation({
      query: body => ({
        url: 'finance/saveResponsibility',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Responsibility'],
    }),
    getAllRespCompany: build.query({
      query: body => `finance/getAllRespCompany/${body}`,
      providesTags: ['RespCompany'],
    }),
    getAllRespCostCenter: build.query({
      query: body => `finance/getAllRespCostCenter/${body}`,
      providesTags: ['RespCostCenter'],
    }),
    getAllRespVoucherType: build.query({
      query: body => `finance/getAllRespVoucherType/${body}`,
      providesTags: ['RespVoucherType'],
    }),
    getRespUsers: build.query({
      query: body => `finance/getRespUsers/${body}`,
      providesTags: ['RespUser'],
    }),
    saveRespCompany: build.mutation({
      query: body => ({
        url: 'finance/saveRespCompany',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RespCompany'],
    }),
    saveRespCostCenter: build.mutation({
      query: body => ({
        url: 'finance/saveRespCostCenter',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RespCostCenter'],
    }),
    saveRespVoucherType: build.mutation({
      query: body => ({
        url: 'finance/saveRespVoucherType',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RespVoucherType'],
    }),
    saveRespUser: build.mutation({
      query: body => ({
        url: 'finance/saveRespUser',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RespUser'],
    }),
  }),
})

export const {
  useLovLoggedUserRespCompanyQuery,
  useLovFisYearQuery,
  useLovFisPeriodMutation,
  useGetCashBookMutation,
  useLovLoggedUserResponsiblityQuery,
  useLovCoaNumQuery,
  useLovRespCostCenterMutation,
  useGetTrialBalanceMutation,
  useGetAllNonFinancialDataForSubmissionQuery,
  useUpdateNonFinancialDataMutation,
  useSaveNonFinancialDataMutation,
  usePerfomActionOnNonFinVouchersMutation,
  useGetAllBatchWiseVoucherQuery,
  useGetVoucherRecieptMutation,
  useViewVoucherQuery,
  useLovCurrencyQuery,
  useLovVoucherTypeQuery,
  useGetAllNonFinancialDataForApprovalQuery,
  useGetAllApprovedNonFinancialDataQuery,
  useLovChartOfAccountProjectWiseMutation,
  useLovCostCenterQuery,
  useGetAllPeriodsYearWiseMutation,
  useUpdateFiscPerMutation,
  useGetNonFinDataByMonthMutation,
  useGetNonFinDataByDateMutation,
  useSearchOBMutation,
  useLovChartOfAccountNumbersVoucherTemplateQuery,
  useSaveAcctOpeningBalanceMutation,
  useGetVoucherStatsQuery,
  useGetAllVoucherTemplatesQuery,
  useCreateVoucherTemplateMutation,
  useGetTemplateVoucherDetailQuery,
  useUpdateVoucherTemplateDetailMutation,
  useCreateVoucherTemplateDetailMutation,
  useGetAllVoucherProjectAndStatusWiseQuery,
  usePerfomActionOnVouchersMutation,
  useLovRespCompanyMutation,
  useLovBatchNameMutation,
  useLovBatchTypeMutation,
  useUpdateVoucherBatchMutation,
  useSendBackFromHoMutation,
  useGetAllActiveVoucherTemplatesQuery,
  useGetTemplateVoucherTemplateDetailsMutation,
  useCreateBatchMutation,
  useSaveVoucherTemplateMutation,
  useUpdateVoucherMutation,
  useUpdateApprovedVoucherMutation,
  useUpdateVoucherTemplateMutation,
  useGetAllCostCenterTreeQuery,
  useAddCostCenterMutation,
  useGetAllVoucherTypeQuery,
  useAddVoucherTypeMutation,
  useUpdateVoucherTypeMutation,
  useGetAllCoaTitleQuery,
  useAddCoaTitleMutation,
  useUpdateCoaTitleMutation,
  useGetAllUserBatchTypesQuery,
  useAddUserBatchTypeMutation,
  useUpdateUserBatchTypeMutation,
  useGetAllAccountGroupQuery,
  useSaveAccountGroupMutation,
  usePostToOFMutation,
  useGetAllResponsibilityQuery,
  useLovProjectQuery,
  useSaveResponsibilityMutation,
  useGetAllRespCompanyQuery,
  useGetAllRespCostCenterQuery,
  useGetAllRespVoucherTypeQuery,
  useGetRespUsersQuery,
  useSaveRespCompanyMutation,
  useSaveRespCostCenterMutation,
  useSaveRespVoucherTypeMutation,
  useSaveRespUserMutation,
} = financeApi
