import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovSrviceListQuery } from '../employeeServices';


const AddSubService=({createsubservice,service}) =>{
    const onFinish = (values) =>{
        createsubservice({
            ...values,
            message: 'B',
          })
        }
    const { data: lovSrviceList } = useLovSrviceListQuery();

  return (
    <div>
    <Form
    layout='vertical'
onFinish={onFinish}
    id='addSubServiceCategoryForm'
    initialValues={{
      srvid:service,
    }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-2 gap-2'
  >
     <Form.Item
                name="srvid"
                label="Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                    disabled
                  placeholder="Select Service"
                  options={lovSrviceList?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
                <Form.Item
      name='subcatname'
      label='Sub Category Name'
    >
      <Input placeholder='Enter Sub Category Name '
      />
    </Form.Item>


  </Form>
    </div>)
}

export default AddSubService