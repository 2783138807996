import { api } from "../api/api";

export const DialysisApi = api.injectEndpoints({
  endpoints: (build) => ({
    lovmachineNumber: build.query({
      query: () => "dialysis/machineNumber",
    }),

    getReceptionData: build.query({
      query: (body) => `dialysis/getReceptionData/${body}`,
    }),

    getOpdDocListOnUnit: build.query({
      query: () => "opd/getOpdDocListOnUnit/100/DIALSYSOPD",
    }),

    lovMedicineAll: build.query({
      query: (body) => `opd/lovMedicineAll/100/RETISSSTR1`,
    }),
    createDialysisReception: build.mutation({
      query: (body) => ({
        url: "dialysis/createDialysisReception",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetReceptionDataQuery,
  useLovmachineNumberQuery,
  useGetOpdDocListOnUnitQuery,
  useLovMedicineAllQuery,
  useCreateDialysisReceptionMutation,
} = DialysisApi;
