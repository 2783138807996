import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddBedsMaintenanceDetails from './AddBedsMaintenanceDetails';
import {  useCreateBedFacilitiesMutation, useCreateBedInfoMutation, useGetWardBedAccessoriesMutation, useLovbedinfoMutation, useUpdateBedFacilityMutation, useUpdateBedInfoMutation, useWardbednumListQuery } from '../../wardConfiguratorsService';
import AddFacilites from './AddFacilites';
import UpdateBedFacility from './UpdateBedFacility';
import UpdateBedInfo from './UpdateBedInfo';



const BedsMaintenance=()=> {
  // const filter = useTableFilters();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDetailModal, setDetailModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdatebedinfoModal, setShowUpdatebedinfoModal] = useState(false);
  const [bedinfo, setbedinfo] = useState([]);
  const [bedaccessories, setBedaccessories] = useState([]);
  const [rowData, setRowData] = useState(null);

  const { data: WardbednumList } = useWardbednumListQuery(); 
  const [getlovbedinfo,  { data: lovbedinfo, isLoading: biLoading }] = useLovbedinfoMutation();
  const [createbedinfo, { data:beddata,isSuccess: addSuccess, isLoading: addLoading }] = useCreateBedInfoMutation();
  const [getwardaccessories, { data: wardaccessories, isLoading: woLoading }] = useGetWardBedAccessoriesMutation();
  const [ updatebedfacility, {data:updatebedfacilitesData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateBedFacilityMutation();
  const [ updatebedinfo, {data:updatebedinfoData, isSuccess: updatebedinfoSuccess, isLoading: updatebedinfoLoading }, ] = useUpdateBedInfoMutation();
const [tablebedlov,setTablebedlov]=useState([])
const [bedinfoTable,setBedinfoTable]=useState([])

useEffect(()=>{
  if(lovbedinfo)
  {
    setBedinfoTable(lovbedinfo?.data)
  }
},[lovbedinfo])
useEffect(()=>{
  if(updatebedinfoData)
  {
    console.log("value",bedinfo?.unit)
    getlovbedinfo({
      unit: bedinfo?.unit,

      message: "B",
    });
  }
},[updatebedinfoData])//eslint-disable-line
useEffect(()=>{
  if(wardaccessories){
    setTablebedlov(wardaccessories?.data)
  }
},[wardaccessories])
useEffect(()=>{
  if(beddata)

  {
    console.log("data",beddata)
    getlovbedinfo({
      unit: beddata?.data[0]?.unit,

      message: "B",
    });
  }
},[beddata])//eslint-disable-line
useEffect(()=>{
  if(updatebedfacilitesData){
handleEdit(updatebedfacilitesData?.data)
  }
},[updatebedfacilitesData]) //eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line
  

  
  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
    // handlerefetch();
  }, [updateSuccess]); // eslint-disable-line


  useEffect(() => {
    if (updatebedinfoSuccess) {setShowUpdatebedinfoModal(false);
      
    }
    // handlerefetch();
  }, [updatebedinfoSuccess]); // eslint-disable-line

  const columns=[

    {
        title:"Ward Name",
        dataIndex:"wardname",
      //   ...filter("wardname"),
      // sorter: (a, b) => a?.wardname - b?.wardname,
      // defaultSortOrder: "descend",
    },

    {
      title:"Ward Type",
      dataIndex:"wardtype",
    //   ...filter("wardname"),
    // sorter: (a, b) => a?.wardname - b?.wardname,
    // defaultSortOrder: "descend",
  },
    {
        title:"Total Beds",
        dataIndex:"bednum",
      //   ...filter("tbeds"),
      // sorter: (a, b) => a?.tbeds - b?.tbeds,
      // defaultSortOrder: "descend",
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Add Bed Details">
          <Button
            type="primary"
            // className="btn-success-primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setRowData(data);
              setShowAddModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ]
    const data = [
      {
        title: "Bed Num",
        dataIndex: "bednum",
      },
  
      {
        title: "Description",
        dataIndex: "descr",
      },
  
      {
        title: "Bed Type",
        dataIndex: "type",
      },
      {
        title: "Extra Bed",
        dataIndex: "extbed",
      },

      {
        title: "Status",
        render: (_, { status }) =>
          status === "Y" ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">In Active</Tag>
          ),
        sorter: (a, b) => a?.status?.localeCompare(b?.status),
      },
      {
        title: "Action(s)",
        render: (data) => (
          <div className='flex justify-center gap-2'>
          <Tooltip title="Add facilites for bed ">
            <Button
              type="primary"
              // className="btn-success-primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRowData(data);
                setDetailModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Update Bed info">
            <Button
              type="primary"
              className="btn-warning-primary"
              icon={<EditFilled />}
              onClick={() => {
                setRowData(data);
                setShowUpdatebedinfoModal(true);
              }}
            />
          </Tooltip>
          </div>
          
        ),
      },
      
];
const handleEdit =(updateData)=>{
  const updatetable=tablebedlov.map((item)=>
  {
    if(item.facilitycode===updateData.facilitycode)
    return {
      ...item,
      status:updateData?.status
    }
    return item
  })
  setTablebedlov(updatetable)
}
const Columns = [
  {
    title: "Facility",
    dataIndex: "facilitytype",
    
  },
  {
    title: "Description",
    dataIndex: "descr",

  },

  {
    title: "Status",
    render: (_, { status }) =>
      status === "Y" ? (
        <Tag color="success">Active</Tag>
      ) : (
        <Tag color="error">In Active</Tag>
      ),
    sorter: (a, b) => a?.status?.localeCompare(b?.status),
  },
  {
    title: "Action(s)",
    render: (data) => (
      <Tooltip title="Update Bed Facility">
        <Button
          type="primary"
          className="btn-warning-primary"
          icon={<EditFilled />}
          onClick={() => {
            setRowData(data);
            setShowUpdateModal(true);
          }}
        />
      </Tooltip>
    ),
  },
  
];

  return (
    <div>
    <div>
     <Table
        columns={columns}
        dataSource={WardbednumList?.data}
        pagination={{ defaultPageSize: 3 }}
        
        rowKey='type'
          onRow={(record) => {
            return {
              onClick: () => {
                setbedinfo(record);

                getlovbedinfo({
                  unit: record?.unit,

                  message: "B",
                });
              },
            };
          }}
               
          rowClassName={(record) =>
            record === bedinfo
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === bedinfo
              ? { backgroundColor: "gray", color: "white" }
              : {}
          }       
        ></Table>

    </div>   
{lovbedinfo &&
<div>
      <Modal
        title='Add Bed'
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key='1'
            htmlType='submit'
            form='addBedForm'
            loading={addLoading}
            type='primary'
          >
            Add
          </Button>,
          <Button key='2' onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddBedsMaintenanceDetails  createbedinfo={createbedinfo} bedinfo={bedinfo}  />
      </Modal>
      <Modal
      title="Update Bed info"
      open={showUpdatebedinfoModal}
      onCancel={() => setShowUpdatebedinfoModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updatebedinfoform"
          loading={updatebedinfoLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdatebedinfoModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateBedInfo
        rowData={rowData}
        updatebedinfo={updatebedinfo}
      />
    </Modal>
    <div>
        <Table
          dataSource={bedinfoTable}
          columns={data}
          // onFinish={onFinish}
          id='savedata'
          pagination={{ defaultPageSize: 3 }}
          loading={biLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='facilitycode'
          key='facilitycode'
          onRow={(record) => {
            return {
              onClick: () => {
                setBedaccessories(record);
                getwardaccessories({
                  unit: record?.unit,
                  bednum:record?.bednum,
                });
              },
            };
          }}
          rowClassName={(record) =>
            record === bedaccessories
              ? "selected-row cursor-pointer"
              : "cursor-pointer"
          }
          rowStyle={(record) =>
            record === bedaccessories
              ? { backgroundColor: "gray", color: "white" }
              : {}
          }
        />
      </div>
      </div>
}

{ wardaccessories &&

  <div>
   <Modal
        title='Add Facilites For Bed '
        className='hideFooter'
        open={showDetailModal}
        onCancel={() => setDetailModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
        ]}
      >
        <AddFacilites  bedaccessories={bedaccessories}  handlecancel={()=>setDetailModal(false)}    
         onCancel={() => setDetailModal(false)} />
      </Modal>
      <Modal
      title="Update Bed Facility"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updatebedfacilityform"
          loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateBedFacility
        rowData={rowData}
        updatebedfacilitesData={updatebedfacilitesData}
        updatebedfacility={updatebedfacility}   
      />
    </Modal>
      <Table
       dataSource={tablebedlov}
        columns={Columns}
        pagination={{ defaultPageSize: 5 }}
        loading={woLoading}
        bordered
        size="small"
        rowKey="facilitycode"
      />
</div>
}      </div>
  )
}

export default BedsMaintenance