import { Button, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useGetPatientByMrNumNameMobileNoCNICMutation, useLovCurrentDocListQuery, useLovCurrentOPDListMutation, useLovOPDListMutation, useLovProjectListQuery} from '../referralServices';
import moment from "moment";

const AddProjectReferral=({createrefer})=> {
  
  const [ PatientByMrNumNameMobileNoCNIC, { data: getPatientByMrNumNameMobileNoCNIC },] = useGetPatientByMrNumNameMobileNoCNICMutation();
  const { data: lovCurrentDoctor } = useLovCurrentDocListQuery();
  const { data: lovProjectList } = useLovProjectListQuery();
  const [currentopd, { data: getCurrentOPD }] = useLovCurrentOPDListMutation();
  const [opdlist, { data: getOPDList }] = useLovOPDListMutation();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  
  useEffect(() => {
    if (getPatientByMrNumNameMobileNoCNIC) {
      form1.setFieldsValue({
        firstname: getPatientByMrNumNameMobileNoCNIC?.data[0]?.firstname,
        fhname: getPatientByMrNumNameMobileNoCNIC?.data[0]?.fhname,
        gender: getPatientByMrNumNameMobileNoCNIC?.data[0]?.gender==="M"?"Male":getPatientByMrNumNameMobileNoCNIC?.data[0]?.gender==="F"?"Female":"Others",
        patienttype: getPatientByMrNumNameMobileNoCNIC?.data[0]?.patienttype,
        unit: getPatientByMrNumNameMobileNoCNIC?.data[0]?.unit,
        nicnum: getPatientByMrNumNameMobileNoCNIC?.data[0]?.nicnum,
      });
      currentopd({gender:getPatientByMrNumNameMobileNoCNIC?.data[0]?.gender})
    }
  }, [getPatientByMrNumNameMobileNoCNIC, form1]); // eslint-disable-line
  
  
  const onFinish = (values) =>{
    console.log("value",values)
    createrefer({
        ...values,
        gender:values.gender==="Male"?"M":values.gender==="Female"?"F":"",
        regnum:getPatientByMrNumNameMobileNoCNIC?.data[0]?.regnum,
        fhname:values.firstname,
        middlename:getPatientByMrNumNameMobileNoCNIC?.data[0]?.middlename ===null?"":getPatientByMrNumNameMobileNoCNIC?.data[0]?.middlename,
        lastname:getPatientByMrNumNameMobileNoCNIC?.data[0]?.lastname===null?"":getPatientByMrNumNameMobileNoCNIC?.data[0]?.lastname,
        cnic:values.nicnum,
        dob:moment(getPatientByMrNumNameMobileNoCNIC?.data[0]?.dob)?.format("YYYY-MM-DD"),
        ptype:getPatientByMrNumNameMobileNoCNIC?.data[0]?.patienttype,

        message: 'B',
      })
  
    }
  
  const onFinish1 = (values) => {
    PatientByMrNumNameMobileNoCNIC({
      patient: values?.regnum,
      message:"B"
    });
  };
  return (
    <div>
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish1}
    form={form}
    autoComplete='off'
    id='addprojectreferral'
    validateMessages={formValidationMessages}
  >
 <div className="grid grid-cols-3 p-2 gap-1 ">
            <Form.Item  
            name="regnum" rules={[
                {
                  required: true,
                }
              ]}
            className=" col-span-1 ">
              <Input 
              
              placeholder="Search by MR"
              ></Input>
            </Form.Item>
            <Form.Item className="mb-0 col-span-2">
              <Button className="mr-2" type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
          </Form>
          </div>

       <div> 
       <Form
       layout='vertical'
    onFinish={onFinish}
    form={form1}
    autoComplete='off'
    id='addprojectreferral1'
   >
          
    <div className='grid sm:grid-cols-4 grid-cols-4 gap-2 p-2 '>
    <Form.Item name="firstname" label="Patient Name">
    <Input disabled
    placeholder='Enter Patient Name'></Input>
    </Form.Item>
    <Form.Item name="gender" label="Gender">
    <Input disabled
    placeholder='Enter Gender'></Input>
    </Form.Item>
    <Form.Item 
    name="nicnum" label="CNIC"
    type="number"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[0-9]{13}$/,
                
                },
              ]}
    
    >
    <Input placeholder='Enter CNIC'></Input>
    </Form.Item>
    <Form.Item required
    name="disease" label="Disease">
    <Input placeholder='Enter Disease'></Input>
    </Form.Item>
      <Form.Item required
      name='tohospital' label='Referral Hospital'>
      <Select
          placeholder='Select Hospital'
          allowClear
          showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          
          onChange={(value)=>{
          
          opdlist({co:value,gender:getPatientByMrNumNameMobileNoCNIC?.data[0]?.gender})

        }}
          
          options={lovProjectList?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item required
      name='toopd' label='To OPD'>
      <Select
          placeholder='Select To OPD'

          allowClear
          showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          
          options={getOPDList?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item required
      name='fromdoctor' label='Current Doctor'>
      <Select
          placeholder='Select Current Doctor'
          allowClear
          showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          options={lovCurrentDoctor?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item required
       name='fromopd' label='From OPD'>
      <Select
          placeholder='Select From OPD'
          allowClear
          showSearch
          filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
        
          
          options={getCurrentOPD?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      
      <Form.Item required
      name='referdate' label='Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item  required
         name='refreason' label='Referral Reason'>
        <Input placeholder='Enter Referral Reason' />
      </Form.Item>


      </div> 
      </Form> 
    </div>

  </div>

  )
}


export default AddProjectReferral