import ProjectReferralList from "../features/Referral/pages/ProjectRerralList";
import ReferralList from "../features/Referral/pages/ReferralList";



export const referralRoutes = [
	{
		path: 'Referrallist',
		element: <ReferralList/>,
	},
	
    {
		path: 'ProjectReferrallist',
		element: <ProjectReferralList/>,
	},
	
	
]
