import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddSupplier = ({ createvendordetail }) => {
  const onFinish = (values) => {
    createvendordetail({
      ...values,
      email: "",
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          weburl: " ",
          fax: " ",
          tel2: " ",
          saletaxnum: " ",
          addr2: " ",
          ntnnum: " ",
          tel1: " ",
        }}
        id="addvendordetailsForm"
        validateMessages={formValidationMessages}
        className="grid sm:grid-cols-3 gap-2"
      >
        <Form.Item
          name="vendorname"
          label="Supplier Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Vendor Name" />
        </Form.Item>
        <Form.Item
          name="addr1"
          label="Address 1"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Address 1" />
        </Form.Item>
        <Form.Item
          name="city"
          label="City "
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter City" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
          className=" hidden"
        >
          <Input placeholder="Enter Email" />
        </Form.Item>

        <Form.Item name="addr2" label="Address 2" className=" hidden">
          <Input placeholder="Enter Address 2" />
        </Form.Item>

        <Form.Item name="tel1" label="Telephone 1" className=" hidden">
          <Input placeholder="Enter Telephone 1" />
        </Form.Item>

        <Form.Item name="tel2" label="Telephone 2" className=" hidden">
          <Input placeholder="Enter Telephone 2" />
        </Form.Item>
        <Form.Item name="ntnnum" label="ntnnum" className=" hidden">
          <Input placeholder="Enter ntnnum" />
        </Form.Item>

        <Form.Item
          name="saletaxnum"
          label="Sale&nbsp;Tax&nbsp;Number"
          className=" hidden"
        >
          <Input placeholder="EnterSales Tax Number" />
        </Form.Item>

        <Form.Item
          name="vendorcurrentstatus"
          label="Vendor&nbsp;Current&nbsp;Status"
          initialValue="Y"
          className=" hidden"
        >
          <Select
            placeholder="Select Vendor Current Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>

        <Form.Item name="fax" label="Fax" className=" hidden">
          <Input placeholder="Enter Fax" />
        </Form.Item>
        <Form.Item name="weburl" label="Web URL" className=" hidden">
          <Input placeholder="Enter Web URL" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSupplier;
