import { api } from "../api/api";

export const ReportsMedixApi = api.injectEndpoints({
 
  endpoints: (build) => ({

    getRefferedbyDate: build.mutation({
      query: (body) => ({
        url: `reffer/getPatientRefferedbyDate/${body.fromdate}/${body.todate}`,
      }),
    }),
    
  }),
});

export const {
   
  useGetRefferedbyDateMutation,

} = ReportsMedixApi;
