import React, { useEffect, useState } from 'react'
import AddSubServicePrice from '../components/AddSubServicePrice'
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useCreateSubServiceCategoryPriceMutation, useGetSubSrviceAmountQuery } from '../employeeServices'

const SubServicePrice=() =>{
  const { data, isLoading,refetch} = useGetSubSrviceAmountQuery();
  const [createsubserviceprice, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateSubServiceCategoryPriceMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([]);
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  const tableColumns = [
    {
      title:"Sub Service ID",
      dataIndex: "subsrvid",
   
    },

    {
      title:"Service Name",
      dataIndex: "servicename",
     
   
    },
    
    {
      title:"Sub Service Name",
      dataIndex: "subservicename",
     
   
    },
    {
      title:"Sub Service Amount",
      dataIndex: "amount",
      
   
    },
    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      
    },
    
    
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
     <h1 className="font-semibold text-base sm:text-lg">Sub Service Category Price</h1>
     <Tooltip title="Add Sub Service Price">
       <Button
         type="primary"
         icon={<PlusOutlined />}
         onClick={() => setShowAddModal(true)}
       />
     </Tooltip>
   </div>
   <div className='p-2'>
     <Table
      columns={tableColumns}
      dataSource={tableDatainfo}
       pagination={{ defaultPageSize: 5 }}
       loading={isLoading}
       bordered
       size='small'
       scroll={{ x: "fit-content" }}
       rowKey='type'

       
     />
   </div>
   <Modal
   title="Add Sub Service Category Price"
   open={showAddModal}
   onCancel={() => setShowAddModal(false)}
   centered
   width={1000}
   destroyOnClose
   footer={[
     <Button
       key="1"
       htmlType="submit"
       form="addSubServiceCategoryPriceForm"
      loading={addLoading}
       type="primary"
     >
       Add
     </Button>,
   
   ]}
 >
<AddSubServicePrice
 createsubserviceprice={createsubserviceprice} 
 handlerefetch={handlerefetch}
 />
 </Modal>
</section>
  )
}

export default SubServicePrice