import React from 'react'
import { Form, Input, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateWardBedTypeMaintenance=({rowData, updatebedtype,updatebedData }) =>{
  const [form] = Form.useForm()
  const onFinish = (values) =>
  updatebedtype({
      ...values,
      status1: values?.status,
      message: 'B',
    })
  return (
    <Form
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='updateWardBedTypeMaintenanceForm'
    form={form}
    initialValues={{
      type:rowData?.type,
      descr:rowData?.descr,
      status:updatebedData?updatebedData?.data.status:rowData?.status,
      
     
    }}
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>
      <Form.Item name='type' label='Type'>
        <Input disabled
        placeholder='Enter Type'></Input>
      </Form.Item>
      <Form.Item name='descr' label='Description'>
        <Input disabled
        placeholder='Enter Description' />
      </Form.Item>
      <Form.Item name='status' label='Status'>
        <Select 
          placeholder='Select Status'
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
        />
      </Form.Item>
    </div>
  </Form>
  )
}

export default UpdateWardBedTypeMaintenance