import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const UpdateInventoryBrand=({rowData,updatebrandData,updatebrand}) =>{
  const [form] = Form.useForm()
  const onFinish = (values) =>
    updatebrand({
      ...values,
      brandname:values?.brandname,
      status: values?.status,
      bid:values?.bid,
   
      message: 'B',
    })
  return (

    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='updatebrandForm'
    form={form}
    initialValues={{
      brandname:rowData?.brandname,
      bid:rowData?.bid,
     
      status:rowData?.status,
      
      
     
    }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >

<Form.Item
      name='bid'
      label='Brand ID'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled='true' />
    </Form.Item>
    <Form.Item
      name='brandname'
      label='Brand Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Brand Name'  />
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
     
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}

export default UpdateInventoryBrand