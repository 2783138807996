/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddProjectReferral from './AddProjectReferral';

import moment from "moment";
import { useCreateReferMutation, useGetPatientRefferDateMutation, useGetPatientRefferQuery } from "../referralServices";
import useFetchPDF from '../../../hooks/useFetchPDF';

const ProjectReferralList=()=> {

  const { data, isLoading,refetch } = useGetPatientRefferQuery();
  const [ PatientRefferDate, { data: getPatientRefferDate },] = useGetPatientRefferDateMutation();
  const [createrefer, { isSuccess: addSuccess, isLoading: addLoading , data:referredData}] = useCreateReferMutation();
  const [showProjectReferral, setShowProjectReferral] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([])
  const [form] = Form.useForm();
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [reportData, getReport] = useFetchPDF();
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  
useEffect(() => {
 if(referredData){
  getReport(
    `http://172.16.70.90:8081/reffer/RefferReport/${referredData?.data.co}/${referredData?.data.regnum}`
  );
  setShowPdfModal(true);
 }
}, [referredData])


  useEffect(() => {
    if (addSuccess) setShowProjectReferral(false);
    handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  

  const tableColumns = [
    {
      title: "Regnum",
      dataIndex: "regnum",
    },
    {
      title: "Patient Name",
      dataIndex: "patientname",
    },
    {
      title: "Patient Type",
      dataIndex: "patienttype",
    },
    {
      title: "Hospital Name",
      dataIndex: "tohospitalname",
    },
    {
      title: "Disease",
      dataIndex: "disease",
    },
    {
      title: "Referral Reason",
      dataIndex: "refreason",
       
    },
    {
      title: "From OPD",
      dataIndex: "fromopdname",
    },
  
    {
      title: "From Doctor",
      dataIndex: "fromdocname",
    },
    {
        title: "Refer Date",
        dataIndex: "referdate",
        render:(data)=>(
          <div>
        {  moment(data?.demanddate)?.format("DD-MM-YYYY") }</div>
        )
    },
    {
      title: "Referral Status",
      render: (_, { refferedstatus }) =>
      refferedstatus === "Y" ? (
          <Tag color="success">Reffered</Tag>
        ) : (
          <Tag color="error">Reffer</Tag>
        ),
      // sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
  ];
  
  const handlerefetch=()=>{
    refetch()
  }
  const onFinish = (values) => {
    PatientRefferDate({
      fromdate: moment(values?.fromdate)?.format("DD-MMM-YYYY"),
      todate:moment(values?.todate)?.format("DD-MMM-YYYY"),
      message:"B"
    });
  };
  
  return (
    <section>
        <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        Project Referral List 
        </h1>
</div>
<Form
layout='vertical'
 onFinish={onFinish}
  form={form}
    >
  <div className='p-2 grid sm:grid-cols-3 gap-2'>
     <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
    <Form.Item name='todate' label='To Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item  className='flex justify-end'
      label=' '>
      <Button className='w-40 '
      type="primary" htmlType="submit" >
               Search
              </Button> </Form.Item>
    </div>
    
</Form>
<div className="flex items-center justify-end px-5 py-3">
      <Tooltip title="Add Project Referral List ">
        <Button
          type="primary"
          icon={<PlusOutlined />}
        onClick={() => setShowProjectReferral(true)}
        />
      </Tooltip>
    </div>
<div className='p-2'>
        <Table
 
          columns={tableColumns}
        dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
        //   onRow={(record) => {
        //     return {
        //       onClick: () => {
        //         setdemanddetailinfo(record);

        //         getdemanddetail({
                  
        //           demandid: record?.demandid,
               
        //           message: "B",
        //         });
        //       },
        //     };
        //   }}
        //   rowClassName={(record) =>
        //     record === demanddetailinfo
        //       ? "selected-row cursor-pointer"
        //       : "cursor-pointer"
        //   }
        //   rowStyle={(record) =>
        //     record === demanddetailinfo
        //       ? { backgroundColor: "gray", color: "white" }
        //       : {}
        //   }
          
        />
      </div>

      <Modal
      title="Add Project Referral"
      open={showProjectReferral}
      onCancel={() => setShowProjectReferral(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form='addprojectreferral1'
        loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
        <Button key="2" onClick={() => setShowProjectReferral(false)}>
          Cancel
        </Button>,
      ]}
    >
    
      <AddProjectReferral  createrefer={createrefer} handlerefetch={handlerefetch} />
    </Modal>
    <Modal
        title="Patient Reffered Report"
        open={showPdfModal}
        onCancel={() => {
          setShowPdfModal(false);
          // handleLaboratory();
        }}
        centered
        width={1000}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button
            key="1"
            onClick={() => {
              setShowPdfModal(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Patient Reffered Report "
        ></iframe>
      </Modal>
    </section>
  )
}

export default ProjectReferralList