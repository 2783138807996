import { Button, DatePicker, Form } from 'antd'
import React from 'react'
import { useGetRefferedbyDateMutation } from '../repmedixServices';

import moment from "moment";

export const ReportsMedix = () => {
  
  const [form] = Form.useForm();
  const [ PatientRefferDate, { data: getPatientRefferDate },] = useGetRefferedbyDateMutation();
  const onFinish = (values) => {
    PatientRefferDate({
      fromdate: moment(values?.fromdate)?.format("DD-MMM-YYYY"),
      todate:moment(values?.todate)?.format("DD-MMM-YYYY"),
      message:"B"
    });
  };
  return (
    <section>
      <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
       Reports
        </h1>
        <Form
layout='vertical'
  onFinish={onFinish}
  form={form}
    >
    <div className='p-2 grid sm:grid-cols-3 gap-2'>
     <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
    <Form.Item name='todate' label='To Date'>
          <DatePicker
            // disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item  className='flex justify-end'
      label=' '>
      <Button className='w-48 '
      type="primary" htmlType="submit" >
               Search
              </Button> </Form.Item>
    </div>

</Form>


      </div>
      
    </section>
  )
}
