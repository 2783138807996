import { Form, Input } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddMstatus = ({ saveMstatus }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    saveMstatus({
      description: values?.description,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        id="addmstatus"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item name="description" label="Description">
          {/* <Select
            placeholder="Select Description"
            options={getlovmstatus?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default AddMstatus;
