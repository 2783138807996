import { Table, Input, Form, Button, Typography, message, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCreateSopRecordMutation, useGetAllMedlistQuery } from '../storeServices';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useFetchPDF from '../../../hooks/useFetchPDF';
import ReturnMed from '../components/ReturnMed';

const MainStore = () => {
  const { data, isLoading } = useGetAllMedlistQuery();
  const [createappoint] = useCreateSopRecordMutation();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [reportData, getReport] = useFetchPDF();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (data) {
      setTableDatainfo(data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (reportData) {
      setShowPdfModal(true);
    }
  }, [reportData]);

  const handleQuantityChange = (value, record, index) => {
    const newQty = value ? parseFloat(value) : 0;
    const newPayablePrice = (newQty * (record.sellprice || 0)) - (record.discount || 0);

    const updatedRows = [...selectedRows];
    updatedRows[index] = { ...record, qty: newQty, payableprice: newPayablePrice };
    setSelectedRows(updatedRows);
  };

  const handleDiscountChange = (value, record, index) => {
    const newDiscount = value ? parseFloat(value) : 0;
    const newPayablePrice = (record.qty * (record.sellprice || 0)) - newDiscount;

    const updatedRows = [...selectedRows];
    updatedRows[index] = { ...record, discount: newDiscount, payableprice: newPayablePrice.toFixed(2) };
    setSelectedRows(updatedRows);
  };

  const deleteRecord = (index) => {
    const updatedRows = selectedRows.filter((_, i) => i !== index);
    setSelectedRows(updatedRows);
  };

  const renderEditableCell = (value, record, dataIndex, handleChange, index) => {
    return (
      <Input
        value={value}
        onChange={e => handleChange(e.target.value, record, index)}
        style={{ fontSize: '12px', padding: '4px 8px' }} // Adjusted font size and padding
      />
    );
  };

  const addNewRecord = (record) => {
    setSelectedRows([...selectedRows, { ...record, qty: 0, discount: 0, payableprice: 0 }]);
  };

  const getTotal = (key) => {
    return selectedRows.reduce((total, row) => total + (parseFloat(row[key]) || 0), 0).toFixed(2);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredData = tableDatainfo.filter(item => 
    item.medname.toLowerCase().includes(searchText)
  );

  const tableColumns = [
    {
      title: "Medicine Name",
      dataIndex: "medname",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Strength",
      dataIndex: "strenght",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Rack No.",
      dataIndex: "rackid",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Manufacture Name",
      dataIndex: "companyname",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Sell Price",
      dataIndex: "sellprice",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
  ];

  const detailColumns = [
    {
      title: "Medicine Name",
      dataIndex: "medname",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Strength",
      dataIndex: "strenght",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Rack No.",
      dataIndex: "rackid",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      render: (text, record, index) => renderEditableCell(text, record, "qty", handleQuantityChange, index),
    },
    {
      title: "Actual Cost",
      dataIndex: "actualpercost",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Sell Price",
      dataIndex: "sellprice",
      render: (text) => <Text style={{ fontSize: '12px' }}>{text}</Text>, // Adjusted font size
    },
    {
      title: "Discount",
      dataIndex: "discount",
      render: (text, record, index) => renderEditableCell(text, record, "discount", handleDiscountChange, index),
    },
    {
      title: "Payable Price",
      dataIndex: "payableprice",
      render: (text, record) => <Text style={{ fontSize: '12px' }}>{(record.qty * record.sellprice - record.discount).toFixed(2)}</Text>, // Adjusted font size
    },
    {
      title: "Actions",
      render: (text, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => deleteRecord(index)}
          size="small" // Smaller button
        />
      ),
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    const saveData = {
      qty: getTotal("qty"),
      actcost: getTotal("actualpercost"),
      sellcost: getTotal("sellprice"),
      disc: getTotal("discount"),
      invdetail: selectedRows.map(row => ({
        eachqty: row.qty,
        eachactualprice: row.actualpercost,
        eachsellprice: row.sellprice,
        medid: row.mmseq,
        strengthid: row.strengthid,
        typeid: row.typeid,
        manufactureid: row.manufactureid,
        rackid: row.rackid,
      })),
    };

    try {
      const response = await createappoint(saveData).unwrap();
      if (response && response.responsecode === 1) {
        message.success("Medicine Prescribed successfully!");

        const invid = response.data[0].invid;
        const reportURL = `http://192.168.0.200:8080/security/MedReciept/${invid}`;

        await getReport(reportURL);

        setLoading(false);
      } else {
        throw new Error("Failed to Prescribe Medicine");
      }
    } catch (error) {
      message.error("Failed to Prescribe Medicine");
      setLoading(false);
    }
  };

  return (
    <section >
     <div>
        <h1 className=' col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0'>
         Point of Sale
        </h1>
      </div>
      <div className='flex items-center justify-end px-5 py-3 pt-0 mt-0 gap-2'>
      <Input
          placeholder="Search Medicine"
          value={searchText}
          onChange={handleSearchChange}
          style={{ width: 200 }}
        />
        <Tooltip title='Return'>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
        
       
      
     
      </div>
      <div className='flex items-center justify-between px-5 py-3 p-1'>
       
      </div>

      <div className='p-2' style={{ maxHeight: '200px', overflowY: 'auto' }}>
        <Table
          columns={tableColumns}
          dataSource={filteredData}
          rowKey='mmseq'
          loading={isLoading}
          pagination={false}
          size="small"
          scroll={{ y: 200 }}
          onRow={(record) => ({
            onClick: () => addNewRecord(record),
          })}
        />
      </div> {selectedRows.length > 0 && (
        <>
          <fieldset className='p-2'>
            <legend>Details</legend>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <Form form={form} component={false}>
                <Table
                  dataSource={selectedRows}
                  columns={detailColumns}
                  bordered
                  size='small'
                  scroll={{ x: "fit-content" }}
                  rowKey={(record, index) => `${record.type}-${index}`}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>Total</Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell>{getTotal("qty")}</Table.Summary.Cell>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell>{getTotal("discount")}</Table.Summary.Cell>
                      <Table.Summary.Cell>{getTotal("payableprice")}</Table.Summary.Cell>
                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  )}
                />
              </Form>
            </div>
          </fieldset>
          <div className="flex justify-end p-2">
            <Button
              type="primary"
              onClick={onFinish}
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </>
      )}
      <Modal
        title="Medicine Prescription Receipt"
        visible={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        footer={null}
        width="100%"
        style={{ top: 0 }}
        bodyStyle={{ height: '100vh', padding: 0 }}
      >
        <iframe
          src={reportData}
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </Modal>
      <Modal
      title="Return Medicine "
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="returnMedForm"
          // loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
        <Button key="2" onClick={() => setShowAddModal(false)}>
          Cancel
        </Button>,
      ]}
    >
    
      <ReturnMed  />
    </Modal>

    </section>
  );
};

export default MainStore;
