import { useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import {
  useLovDepartmentQuery,
  useLovOrganizationQuery,
  useLovSubDepartmentMutation,
} from '../../orgStructureService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateSection = ({ rowData, updateSection }) => {
  const { data: lovOrganization } = useLovOrganizationQuery()
  const { data: lovDepartment } = useLovDepartmentQuery()
  const [getLovSubDepartment, { data: lovSubDepartment }] =
    useLovSubDepartmentMutation()
  const [form] = Form.useForm()

  const onFinish = values =>
    updateSection({
      ...values,
      sectionid: rowData?.sectionid,
      message: 'B',
    })

  useEffect(() => {
    getLovSubDepartment({ dept: rowData?.orgDepartment?.deptid })
  }, []) // eslint-disable-line

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='updateSectionForm'
      form={form}
      initialValues={{
        ...rowData,
        orgId: rowData?.orgOrganization?.orgid,
        deptid: rowData?.orgDepartment?.deptid,
        sdeptid: rowData?.orgSubDepartment?.sdeptid,
      }}
      validateMessages={formValidationMessages}>
      <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
        <Form.Item name='orgId' label='Organization'>
          <Select
            placeholder='Select Organization'
            options={lovOrganization?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='deptid' label='Department'>
          <Select
            placeholder='Select Department'
            onChange={e => {
              getLovSubDepartment({
                dept: e,
                message: 'B',
              })
              form.setFieldsValue({ sdeptid: undefined })
            }}
            options={lovDepartment?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='sdeptid' label='Sub Department'>
          <Select
            placeholder='Select Sub Department'
            options={lovSubDepartment?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='sectionname' label='Section Name'>
          <Input placeholder='Enter Section Name' />
        </Form.Item>
        <Form.Item name='sectionabbrv' label='Section Abbreviation'>
          <Input placeholder='Enter Section Abbreviation' />
        </Form.Item>
        <Form.Item name='sectionstatus' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default UpdateSection
