import { useEffect } from 'react'
import { Checkbox, DatePicker, Form, Input, Select } from 'antd'
import {
  useLovDepartmentQuery,
  useLovOrganizationQuery,
  useLovProjectsMutation,
  useLovProjectsCatMutation,
  useLovSectionMutation,
  useLovSubDepartmentMutation,
  useLovSubOrganizationMutation,
  useLovZoneMutation,
} from '../../../../features/orgStructure/orgStructureService'
import { formateDate, futureDates } from '../../../../utils/dateUtils'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateOrgStructure = ({ rowData, updateOrgUnit }) => {
  const { data: lovOrganization } = useLovOrganizationQuery()
  const { data: lovDepartment } = useLovDepartmentQuery()
  const [getLovSubOrganization, { data: lovSubOrganization }] = useLovSubOrganizationMutation()
  const [getLovProjectsCat, { data: lovProjectsCat }] = useLovProjectsCatMutation()
  const [getLovProject, { data: lovProject }] = useLovProjectsMutation()
  const [getLovSubDepartment, { data: lovSubDepartment }] = useLovSubDepartmentMutation()
  const [getLovSection, { data: lovSection }] = useLovSectionMutation()
  const [getLovZone, { data: lovZone }] = useLovZoneMutation()
  const [form] = Form.useForm()

  const onFinish = values =>
    updateOrgUnit({
      ...values,
      orgunitid: rowData?.orgunitid,
      isStore: values.isStore ? 'Y' : 'N',
      isReception: values.isReception ? 'Y' : 'N',
      isOpd: values.isOpd ? 'Y' : 'N',
      isOt: values.isOt ? 'Y' : 'N',
      isEr: values.isEr ? 'Y' : 'N',
      isWard: values.isWard ? 'Y' : 'N',
      isDayCare: values.isDayCare ? 'Y' : 'N',
      isLab: values.isLab ? 'Y' : 'N',
      isRadiology: values.isRadiology ? 'Y' : 'N',
      startDate: formateDate(values.startDate),
      message: 'B',
    })

  useEffect(() => {
    getLovSubOrganization({ org: rowData?.orgid })
    getLovProjectsCat({
      org: rowData?.orgid,
      subOrg: rowData?.suborgid,
    })
    getLovProject({
      projCat: rowData?.pcatid,
    })
    getLovSubDepartment({
      dept: rowData?.deptid,
    })
    getLovSection({
      dept: rowData?.deptid,
      subDept: rowData?.sdeptid,
    })
    getLovZone({
      org: rowData?.orgid,
      subOrg: rowData?.suborgid,
    })
  }, []) // eslint-disable-line

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='updateOrgStructureForm'
      form={form}
      initialValues={{
        orgId: rowData?.orgid,
        subOrgId: rowData?.suborgid,
        zoneId: rowData?.zoneid,
        pCatId: rowData?.pcatid,
        projectId: rowData?.projectid,
        deptId: rowData?.deptid,
        sDeptId: rowData?.sdeptid,
        sectionId: rowData?.sectionid,
        unitName: rowData?.name,
        status: rowData?.status,
        isStore: rowData?.isStore ? 'isStore' : undefined,
        isReception: rowData?.isReception ? 'isReception' : undefined,
        isOpd: rowData?.isOpd ? 'isOpd' : undefined,
        isOt: rowData?.isOt ? 'isOt' : undefined,
        isEr: rowData?.isEr ? 'isEr' : undefined,
        isWard: rowData?.isWard ? 'isWard' : undefined,
        isDayCare: rowData?.isDayCare ? 'isDayCare' : undefined,
        isLab: rowData?.isLab ? 'isLab' : undefined,
        isRadiology: rowData?.isRadiology ? 'isRadiology' : undefined,
      }}
      validateMessages={formValidationMessages}>
      <div className='grid lg:grid-cols-4 md:grid-cols-3  grid-cols-1 gap-2'>
        <Form.Item
          name='orgId'
          label='Organization'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Organization'
            onChange={e => {
              getLovSubOrganization({ org: e, message: 'B' })
              form.setFieldsValue({ subOrgId: undefined })
            }}
            options={lovOrganization?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          name='subOrgId'
          label='Sub Organization'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Sub Organization'
            onChange={e => {
              getLovProjectsCat({
                org: form.getFieldValue('orgId'),
                subOrg: e,
                message: 'B',
              })
              getLovZone({
                org: form.getFieldValue('orgId'),
                subOrg: e,
                message: 'B',
              })
              form.setFieldsValue({ pCatId: undefined, orgZoneId: undefined })
            }}
            options={lovSubOrganization?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          name='zoneId'
          label='Zone'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Zone'
            options={lovZone?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          name='pCatId'
          label='Project Category'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Project Category'
            onChange={e => {
              getLovProject({
                projCat: e,
                message: 'B',
              })
              form.setFieldsValue({ projectId: undefined })
            }}
            options={lovProjectsCat?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          name='projectId'
          label='Project'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Project'
            options={lovProject?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          name='deptId'
          label='Department'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Department'
            onChange={e => {
              getLovSubDepartment({
                dept: e,
                message: 'B',
              })
              form.setFieldsValue({ sDeptId: undefined })
            }}
            options={lovDepartment?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item
          name='sDeptId'
          label='Sub Department'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            onChange={e => {
              getLovSection({
                dept: form.getFieldValue('deptId'),
                subDept: e,
                message: 'B',
              })
              form.setFieldsValue({ sectionId: undefined })
            }}
            placeholder='Select Sub Department'
            options={lovSubDepartment?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item
          name='sectionId'
          label='Section'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Section'
            options={lovSection?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item
          name='unitName'
          label='Name'
          rules={[
            {
              required: true,
            },
          ]}>
          <Input placeholder='Enter Name' />
        </Form.Item>
        <Form.Item
          name='startDate'
          label='Start Date'
          rules={[
            {
              required: true,
            },
          ]}>
          <DatePicker disabledDate={futureDates} placeholder='Enter Start Date' format='DD-MM-YYYY' />
        </Form.Item>
        <Form.Item
          name='status'
          label='Status'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Active', value: 'Y' },
              { label: 'In Active', value: 'N' },
            ]}
          />
        </Form.Item>
      </div>
      <div className='flex justify-evenly flex-wrap'>
        <Form.Item name='isStore' label=' ' valuePropName='checked'>
          <Checkbox>Is Store</Checkbox>
        </Form.Item>
        <Form.Item name='isReception' label=' ' valuePropName='checked'>
          <Checkbox>Is Reception</Checkbox>
        </Form.Item>
        <Form.Item name='isOpd' label=' ' valuePropName='checked'>
          <Checkbox>Is OPD</Checkbox>
        </Form.Item>
        <Form.Item name='isOt' label=' ' valuePropName='checked'>
          <Checkbox>IS OT</Checkbox>
        </Form.Item>
        <Form.Item name='isEr' label=' ' valuePropName='checked'>
          <Checkbox>Is ET</Checkbox>
        </Form.Item>
        <Form.Item name='isWard' label=' ' valuePropName='checked'>
          <Checkbox>Is Ward</Checkbox>
        </Form.Item>
        <Form.Item name='isDayCare' label=' ' valuePropName='checked'>
          <Checkbox>Is DayCare</Checkbox>
        </Form.Item>
        <Form.Item name='isLab' label=' ' valuePropName='checked'>
          <Checkbox>Is Lab</Checkbox>
        </Form.Item>
        <Form.Item name='isRadiology' label=' ' valuePropName='checked'>
          <Checkbox>Is Radiology</Checkbox>
        </Form.Item>
      </div>
    </Form>
  )
}

export default UpdateOrgStructure
