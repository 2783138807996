import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip } from 'antd'
import React, { useState } from 'react'
import UpdateNomenclature from './UpdateNomenclature';
import { useGetConfiguredNomncltreQuery } from '../NomenclatureService';

function Nomenclature() 
{
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const { data: getConfiguredNomncltre } = useGetConfiguredNomncltreQuery();

// const dataSource = [
//   {
    
//     pridesc: 'Abc',
    

    
//   },
// ];



const column = [
  {
    title: "Class",
    dataIndex: "class",
    
  },
  
  {
    title: "Pre Description",
    dataIndex: "pridesc",
    
  },
  {
    title: "Price List",
    dataIndex: "pricelist",
    
  },
  {
    title: "Description",
    dataIndex: "desc",
    
  },
  {
    title: "Nomenclature",
    dataIndex: "nomenclature",
    
  },
  
  {
    title: "Amount",
    dataIndex: "amount",
    
  },
  {
    title: "Action(s)",
    render: (data) => (
      <Tooltip title="Edit Nomenclature">
        <Button
          type="primary"
          className="btn-warning-primary"
          icon={<EditFilled />}
          onClick={() => {
            setRowData(data);
            setShowUpdateModal(true);
          }}
        />
      </Tooltip>
    ),
  },

];
  return (
   <section>
   <div>
   <h1 className=" col-span-2 flex justify-center items-center font-semibold h-10 text-2xl mt-0">
        Configured Nomenclature
        </h1>
   </div>
   
     <div className="flex items-center justify-end px-5 py-3 ">
     
      <Tooltip title="Add Nomenclature ">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          // onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>

    <div>
      <Table
        dataSource={getConfiguredNomncltre?.data}
        // dataSource={dataSource}
        columns={column}

        pagination={{ defaultPageSize: 20 }}
        // loading={isLoading}
        bordered
        size="small"
        scroll={{ x: 'fit-content' }}
        rowKey="type"
      />
</div> 


<Modal
        title="Update Nomenclature"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateNomenclatureform"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateNomenclature
          rowData={rowData}
          // updateDepartment={updateDepartment}
        />
      </Modal>
   </section>
  )
}

export default Nomenclature