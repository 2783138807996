import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu, Button, Modal } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { logoutUser } from "../features/auth/authSlice";

import { useChangePasswordMutation } from "../features/auth/authService";
import { api } from "../features/api/api";
import UserProfile from "./components/UserProfile";
import ChangePassword from "./components/ChangePassword";
const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const [
    changePassword,
    { isSuccess: changePasswordSuccess, isLoading: changePasswordLoading },
  ] = useChangePasswordMutation();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handleClick = ({ key }) => {
    if (key === "1") setShowProfileModal(true);
    if (key === "2") setShowPasswordModal(true);
    if (key === "3") {
      dispatch(logoutUser());
      dispatch(api.util.resetApiState());
    }
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      setShowPasswordModal(false);
      dispatch(logoutUser());
      dispatch(api.util.resetApiState());
    }
  }, [changePasswordSuccess]); // eslint-disable-line

  const menu = (
    <Menu
      className=""
      onClick={handleClick}
      items={[
        {
          key: "1",
          label: <button>Profile</button>,
          icon: <UserOutlined />,
        },
        {
          key: "2",
          label: <button className="">Change Password</button>,
          icon: <SettingOutlined />,
        },
        {
          key: "3",
          label: <button className="">Logout</button>,
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );
  return (
    <header className="flex items-center justify-between pr-2 sticky top-0 h-[5rem] z-10 bg-[#e8ece7]">
      <div className="flex items-center justify-center sticky">
        {/* <img src={logo} alt='' className='w-[220px] h-[220px] mr-4 mt-6'></img> */}
        <h1 className="p-[20px] text-[#00b29c] text-4xl font-semi bold">
          Awan Store
        </h1>

        <div className=" flex items-center justify-center">
          {/* <button onClick={() => setHidden(hidden => !hidden)}>
            <MenuOutlined />
          </button> */}
        </div>
      </div>

      <div className="flex gap-2">
        <Dropdown overlay={menu}>
          <Button className="flex items-center px-3 py-2 space-x-2">
            <UserOutlined className="" />
            <span className="">{user?.fullname}</span>
          </Button>
        </Dropdown>
      </div>
      <Modal
        title="User Profile"
        open={showProfileModal}
        onCancel={() => setShowProfileModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="2" onClick={() => setShowProfileModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UserProfile />
      </Modal>

      <Modal
        title="Change Password"
        open={showPasswordModal}
        onCancel={() => setShowPasswordModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="passwordChangeForm"
            loading={changePasswordLoading}
            type="primary"
          >
            Change Password
          </Button>,
          <Button key="2" onClick={() => setShowPasswordModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <ChangePassword changePassword={changePassword} />
      </Modal>
    </header>
  );
};

export default Header;
