import React, { useEffect, useState } from 'react'
import AddSubService from '../components/AddSubService'
import { Button, Form, Modal, Select, Table, Tag, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { useCreateSubServiceCategoryMutation, useGetSubServiceCategoryMutation, useLovSrviceListQuery } from '../employeeServices';



const SubService=()=> {
 
  const { data: lovSrviceList, refetch } = useLovSrviceListQuery();
  const [subservice, { data: getSubService }] = useGetSubServiceCategoryMutation();
  const [createsubservice, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateSubServiceCategoryMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [service,setService]=useState(null)
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  

  const tableColumns = [
    {
      title:"Sub Service ID",
      dataIndex: "subsrvid",
   
    },

    {
      title:" Sub Service Name",
      dataIndex: "subservicename",
     
   
    },

    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      
    },
    
    
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Sub Service Category</h1>
        <Tooltip title="Add Sub Service">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
  <div >
    <Form className='w-80 '
    layout='vertical'>
     
     <Form.Item
                name="srvid"
                label="Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Service"
                  onChange={(value)=>{
                    setService(value)
            subservice({
                  srvid: value,
                  message: "B",
                });
          }}
                  options={lovSrviceList?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
    </Form>
    </div>
    <div className=''>
      <Table
       columns={tableColumns}
       dataSource={getSubService?.data}
      pagination={{ defaultPageSize: 5 }}
      // loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
        
      />
    </div>
    <Modal
    title="Add Sub Service"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addSubServiceCategoryForm"
       loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddSubService
  createsubservice={createsubservice} service={service}
  handlerefetch={handlerefetch}
  />
  </Modal>
 </section>
  )
}

export default SubService