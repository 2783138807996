import React, { useEffect, useRef, useState } from 'react'
import { getAge,getAgeInYears} from "../../../utils/dateUtils";
import avatar from "../../../assets/images/avatar.jpg";
import { Table,Image, Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import openNotification from "../../../utils/notification";

import AddPatientVisit from './AddPatientVisit';
import { useCreateVisitForPateintMutation, useEnterAppointmentMutation, useGetPatientByMrNumNameMobileNoQuery, useGetPatientListMutation } from '../referralServices';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const AddPatientAppointment=({rowData})=> {
  const co = useSelector((state) => state.auth.user.co);
console.log("rowData",rowData)
const location = useLocation();
 
  const { data, isLoading } = useGetPatientByMrNumNameMobileNoQuery({regnum:rowData.regnum});
  const [getPatientList, { data: patientGenderList }] =
  useGetPatientListMutation();
  const [
    enterAppointment,
    { data: enterAppointmentData, isLoading: appointmentLoading },
  ] = useEnterAppointmentMutation();
  const [
    createVisitForPatient,
    {
      data: entercreateVisitForPatientData,
      isLoading: createVisitForPatientLoading,
    },
  ] = useCreateVisitForPateintMutation();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showAddVisitModal, setShowAddVisitModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const[tableDatabedinfo,setTableDatabedinfo]=useState([])
  const buttonRef = useRef(null); // Create a ref for the button
  useEffect(() => {
    if (location.state !== null) {
      // Perform actions when location.state is not null
      buttonRef.current.click();
      setSelectedDoctor(location.state);
    }
  }, [location.state]);
  const initialValues = {
    patient: location.state === null ? "" : location.state.cnic,
  };
 
  useEffect(()=>{
    if(data){
      setTableDatabedinfo(data?.data)
          }
  },[data])
  const getRelToApplicant = (reltoapplicant) => {
    switch (reltoapplicant) {
      case "S":
        return "Self";
      case "D":
        return "Daughter";
      case "W":
        return "Wife";
      default:
        return "";
    }
  };
  const columns = [
    {
      title: "MR #",
      dataIndex: "regnum",
      key: "regnum",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => {
        const firstName = record.firstname || "";
        const lastName = record.lastname || "";
        const middleName = record.middlename || "";
        return `${firstName}  ${middleName} ${lastName}`;
      },
    },
    {
      title: "Age",
      render: (data) => <div>{getAge(data?.dob)}</div>,
      key: "dob",
    },
    {
      title: "Type",
      dataIndex: "patienttype",
      key: "patienttype",
    },
    {
      title: "CNIC",
      dataIndex: "nicnum",
      key: "nicnum",
    },
  ];
  const handlevisit = () => {
    setShowAddVisitModal(false);
  };
  
  return (
    
   <section className='flex flex-col lg:flex-row md:flex-col'>

<div className="border border-gainsboro flex-1 m-2">
        <div className="flex items-center justify-between p-3 bg-ghost-white border-b border-gainsboro">
          <h1 className="font-semibold text-base sm:text-lg">Referral List</h1>
          
        </div>
        <div className="p-1">
    

          <Table
            dataSource={tableDatabedinfo}
            columns={columns}
            pagination={{ defaultPageSize: 5 }}
            loading={isLoading}
            bordered
            size="small"
            // scroll={{ x: 'fit-content' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  getPatientList({ co: co, gender: record.gender });
                  setSelectedPatient(record);
                },
              };
            }}
            rowClassName={(record) =>
              record === selectedPatient
                ? "ant-table-row-selected cursor-pointer"
                : "cursor-pointer"
            }
            // ! for Production add row key
          />
        </div>
      </div>

          <div className="border border-gainsboro flex-1 m-2">
            <div className="flex items-center justify-between py-3.5 px- border-b border-gainsboro p-2">
              <h1 className="font-semibold text-base sm:text-lg">
                Patient Detail
              </h1>
            </div>
            <div className="flex gap-2 p-2">
              <div className="flex-1">
                <div className="grid grid-cols-2 w-max gap-2">
                  <h2 className="font-semibold">MR #:</h2>
                  <p>{selectedPatient?.regnum}</p>
                  <h2 className="font-semibold">Name:</h2>
                  <p>
                    {selectedPatient?.firstname}&nbsp;
                    {selectedPatient?.middlename}&nbsp;{" "}
                    {selectedPatient?.lastname}
                  </p>
                  <h2 className="font-semibold">Relation To Applicant:</h2>
                  <p>{getRelToApplicant(selectedPatient?.reltoapplicant)}</p>
                  <h2 className="font-semibold">Gender:</h2>
                  <p>
                    {selectedPatient?.gender === "M" && "Male"}
                    {selectedPatient?.gender === "F" && "Female"}
                  </p>
                  <h2 className="font-semibold">Age:</h2>
                  <p>
                  {getAge(selectedPatient?.dob)}
                  </p>
                  <h2 className="font-semibold">Patient Type:</h2>
                  <p>{selectedPatient?.patienttype}</p>
                  <h2 className="font-semibold">Unit Name:</h2>
                  <p>{selectedPatient?.unit}</p>
                  <h2 className="font-semibold">Marital Status:</h2>
                  <p>
                    {selectedPatient?.marstat === "M" && "Married"}
                    {selectedPatient?.marstat === "S" && "Single"}
                    {selectedPatient?.marstat === "O" && "Orphan"}
                    {selectedPatient?.marstat === "W" && "Widow"}
                  </p>
                  <h2 className="font-semibold">Father/Husband's Name:</h2>
                  <p>{selectedPatient?.fhname}</p>
                  <h2 className="font-semibold">Phone:</h2>
                  <p>{selectedPatient?.cellno}</p>
                  <h2 className="font-semibold">CNIC:</h2>
                  <p>{selectedPatient?.nicnum}</p>
                  <h2 className="font-semibold">Billing Group:</h2>
                  <p>{selectedPatient?.billto}</p>
                </div>
              </div>
              <div className="flex-1 flex justify-center mt-5">
                <Image
                  height={150}
                  src={
                    selectedPatient?.pictureid
                      ? selectedPatient?.pictureid
                      : avatar
                  }
                />
              </div>
            </div>
            {/* actions */}
            <div className="mt-4 mx-2 mb-2">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-1 mb-1">
                <Button
                  type="primary"
                  className="btn-success-primary"
                  onClick={() => {
                    if (
                      selectedPatient.reltoapplicant === "N" &&
                      getAgeInYears(selectedPatient.dob) > 18 &&
                      !selectedPatient.disable
                    )
                      openNotification(
                        "error",
                        "Son must be younger than 18 or disable!"
                      );
                    else if (
                      selectedPatient.reltoapplicant === "D" &&
                      selectedPatient.marstat === "M" &&
                      selectedPatient.patienttype === "BEN"
                    )
                      openNotification("error", "Daughter must be unmarried!");
                    else setShowAddVisitModal(true);
                  }}
                  icon={<PlusOutlined />}
                >
                  Add Visit
                </Button>
                
              </div>
            </div>
          </div>
       
            <Modal
        title="Add Visit"
        open={showAddVisitModal}
        onCancel={() => setShowAddVisitModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            key="1"
            form="addPatientVisit"
            // loading={appointmentLoading}
          >
            Add Visit
          </Button>,
          <Button key="2" onClick={() => setShowAddVisitModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddPatientVisit
        rowData={rowData}
          selectedPatient={selectedPatient}
          patientGenderList={patientGenderList}
          enterAppointment={enterAppointment}
          selectedDoctor={selectedDoctor}
          createVisitForPatient={createVisitForPatient}
          handlevisit={handlevisit}
        />
      </Modal>
{/* // </div> */}
   </section>
  )
}

export default AddPatientAppointment