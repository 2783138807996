import { Checkbox, Form, Input, Select } from 'antd'

import { useCreateWardNomncltreMutation, useGenNomncltreMutation, useLovWardListQuery, useLovbedtypeQuery } from '../../wardConfiguratorsService'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import formValidationMessages from '../../../../utils/formValidationMessages';
import openNotification from '../../../../utils/notification';

const AddNomncltreControl=({getBedNomncltre, onCancel,unitname,bednomncltre }) => {
  
  const [form] = Form.useForm();
  const { data: lovWardList } = useLovWardListQuery();
  const { data: lovbedtype } = useLovbedtypeQuery();
  const [savenomncltre, { data: AllGenNomncltre }] = useGenNomncltreMutation();
  const [savewardnomncltre, { data: CreateWardNomncltre,isSuccess }] = useCreateWardNomncltreMutation();
  const pco = useSelector((state) => state.auth.user.co);
  const puserid = useSelector((state) => state.auth.user.userid);
useEffect(()=>{
  if(isSuccess){
    openNotification("success","Added Successfully")
    bednomncltre({
      unit: unitname,
      message: "B",
    });
  }
},[isSuccess])//eslint-disable-line
const [nomncltrevalues,setNomncltrevalues]=useState(null)
useEffect(()=>{
if(AllGenNomncltre){
  console.log("AllGenNomncltre",AllGenNomncltre)
  savewardnomncltre({
    unit: nomncltrevalues?.wardunit,
    descr: nomncltrevalues?.pdescr,
    type:nomncltrevalues?.type,
    nomncltre:AllGenNomncltre?.data,
    ncchg:nomncltrevalues?.ncchg===true?"Y":"N",
    mochg:nomncltrevalues?.mochg===true?"Y":"N",
    adchg:nomncltrevalues?.adchg===true?"Y":"N",
    bedchg:nomncltrevalues?.bedchg===true?"Y":"N",
    foodchg:nomncltrevalues?.foodchg===true?"Y":"N",


  })
}
},[AllGenNomncltre])
  const onFinish = (values) => {
    console.log("values",values)
    if(values)
    {
      savenomncltre({
      
      pco: pco,
      puserid: puserid,
      ptablename: 'WMS$Ctrl',
      psingle: 'Y',
      pdescr: values?.pdescr,
      pcategoryid: 'CategoryId',
      pnature : 'Nature',
      ptype :'Type',
      pclass :'Class',
      pmaingrp :'MainGrp',
      psubgrp : 'SubGrp',
      pgengrp : 'GenGrp'
    
    });
setNomncltrevalues(values)

    if (savenomncltre){
      onCancel()
    }}
  }

  return (
    <Form
    form={form}
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='addNomncltreForm'
    initialValues={{
      wardunit:unitname,
    }}
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-4 grid-cols-1 gap-2'>
    <Form.Item name='wardunit' label='Ward Type'>
        <Select
        disabled
          placeholder='Select Ward'
          options={lovWardList?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
      </Form.Item>

      <Form.Item name='type' label='Bed Type'>
        <Select
          placeholder='Select Bed Type'
          options={lovbedtype?.data?.map((item) => ({
            value: item?.type,
            label: item?.descr,
          }))}
        />
      </Form.Item>

      <Form.Item name='pdescr' label='Description of Nomncltre'>
        <Input placeholder='Enter Description' />
      </Form.Item>
      <Form.Item
				name='status'
				label='Status'>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
    </div>
    <div className='grid grid-cols-5 md:grid-cols-5 sm:grid-cols-1 gap-2 p-2 py-1'   >
              <Form.Item name="ncchg" valuePropName='checked' initialValue={false}>
                <Checkbox >NC Charges</Checkbox>
              </Form.Item>
              <Form.Item name="mochg" valuePropName='checked' initialValue={false}>
                <Checkbox>MO Charges</Checkbox>
              </Form.Item>
              <Form.Item name="bedchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Room/Bed Charges</Checkbox>
              </Form.Item>
              <Form.Item name="foodchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Food Charges</Checkbox>
              </Form.Item>
              <Form.Item name="adchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Admission Charges</Checkbox>
              </Form.Item>
              
              

              
              
              
              
</div>       
  </Form>
  )
}

export default AddNomncltreControl