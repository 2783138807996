import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import productsimg from "../../../assets/images/defaultimg.png";
import { useLocation } from "react-router-dom";
import {
  useCreateCustomerorderMutation,
  useSendEmailCustomerMutation,
} from "../productsServices";
import openNotification from "../../../utils/notification";
const OrderToCard = () => {
  const location = useLocation();
  const [patientData] = useState(location?.state);
  const [form] = Form.useForm();
  const [tabledata, setTabledata] = useState([]);
  const [saveuserproduct, { data: getproduct, isSuccess: productsuccess }] =
    useCreateCustomerorderMutation();
  const [saveOrderCustomer] = useSendEmailCustomerMutation();
  const [selectedrow, setSelectedrow] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  useEffect(() => {
    if (patientData) {
      const updatedTableData = patientData?.data.map((item) => ({
        mainco: item.mainco,
        pcategory: item.pcategory,
        pdescr: item.pdescr,
        id: item.id,
        pname: item.prodname,
        pnewprice: item.pnewprice,
        pprice: item.pprice,
        ppic: item.ppic,
        status: item.status,
        quantity: 1,
      }));
      setTabledata(updatedTableData);
    }
  }, [patientData]); //eslint-disable-line
  useEffect(() => {
    if (productsuccess) {
      const customeremail = form.getFieldValue("customeremail");
      console.log("getproduct", getproduct);
      saveOrderCustomer({
        senderEmail: "yaseengul8@gmail.com",
        receiverEmail: customeremail,
        subject: "abc Proposal",
        message:
          "Product Name : " +
          getproduct?.data[0].odprdname +
          ", Qauntity : " +
          getproduct?.data[0].odsamedetailqty +
          ", Price : " +
          getproduct?.data[0].sameprodtotprice,
      });
      setSelectedrow([]);
      setShowDrawer(false);
      openNotification(
        "success",
        "Order Successfully Please Check Your Email",
        10
      );
    }
  }, [productsuccess]); //eslint-disable-line
  // useEffect(() => {
  //   if (ordesuccess) {
  //     openNotification(
  //       "success",
  //       "Order Successfully Please Check Your Email",
  //       10
  //     );
  //   }
  // }, [ordesuccess]);
  const incrementCount = (id) => {
    setTabledata((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decrementCount = (id) => {
    setTabledata((prevData) =>
      prevData.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };
  const columns = [
    {
      title: "Items",
      render: (data) => {
        return (
          <Card className="grid justify-center items-start gap-1">
            <div className="pb-1">
              <img
                src={data?.ppic ? data?.ppic : productsimg}
                alt="product"
                className="w-[6rem] h-[6rem] object-cover rounded-md"
              />
            </div>
            <div className=" justify-between items-center gap-2 mb-2">
              <div className="pr-2">
                <div className=" text-left font-bold">{data?.pname}</div>
              </div>
              <div className=" flex-row justify-end  gap-1">
                <div className=" text-left font-bold">
                  <h1 className="text-[#33a69b]">PKR&nbsp;{data?.pnewprice}</h1>
                </div>
              </div>
            </div>
            <div className=" flex justify-start items-start">
              <Button
                onClick={() => decrementCount(data.id)} // Pass product key
                size="small"
                className=" btncustomize"
              >
                <FiMinus className=" text-lg" />
              </Button>
              <h1>&nbsp;&nbsp;{data.quantity || 1}&nbsp;&nbsp;</h1>

              <Button
                onClick={() => incrementCount(data.id)} // Pass product key
                size="small"
                className="btncustomize"
              >
                <GoPlus className=" text-lg" />
              </Button>
            </div>
          </Card>
        );
      },
    },
  ];
  const onFinish = (values) => {
    const sv1 = selectedrow?.map((item) => ({
      odprdname: item?.pname,
      omtsngleprodprice: item?.pnewprice,
      odsamedetailqty: item.quantity,
    }));
    const sv2 = selectedrow?.map((item) => ({
      totalprice: item.pnewprice * item.quantity || item.pnewprice,
    }));
    const totalSum = sv2.reduce((acc, item) => acc + item.totalprice, 0);
    saveuserproduct({
      omcount: selectedrow?.length || 1,
      omtotprice: totalSum,
      customername: values?.customername,
      cellno: values?.cellno,
      address: values?.customername,
      dilvraddress: values?.deliveryaddress,
      custemail: values?.customeremail,
      sv1: sv1,
    });
  };
  const rowSelection = {
    getCheckboxProps: () => ({
      style: { display: "none" }, // Hide the individual checkboxes
    }),
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedrow(selectedRows);
      if (selected) {
        setShowDrawer(true);
      }
    },
  };
  return (
    <section>
      <div className="">
        <div>
          <Checkbox
            onChange={(e) => {
              const value = e.target.checked;
              if (value) {
                setSelectedrow(tabledata);
                setShowDrawer(true);
              } else {
                setSelectedrow([]);
                setShowDrawer(false);
              }
            }}
          >
            Select All Items
          </Checkbox>
        </div>
        <Row gutter={[9, 9]}>
          {tabledata.map((product) => (
            <Col className="col-span-24 sm:col-span-4" key={product.id}>
              <Card>
                <Table
                  columns={columns}
                  dataSource={[product]}
                  pagination={false}
                  rowKey="id"
                  scroll={{ x: "fit-content" }}
                  rowSelection={rowSelection}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <Drawer
        title="Purchase Product"
        placement="right"
        closable={true}
        onClose={() => {
          setShowDrawer(false);
          setSelectedrow([]); // Clear selected rows when drawer closes
        }}
        open={showDrawer}
        width={400}
        className="transition-transform duration-300 ease-in-out overflow-hidden"
        destroyOnClose
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-1 p-2">
            <Form.Item
              name="customername"
              label="Customer Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cellno"
              label="Mobile Number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item
              name="deliveryaddress"
              label="Delivery Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="customeremail"
              label="Customer Email"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label=" ">
              <Button className="btncustomize" htmlType="submit">
                Purchase
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </section>
  );
};
export default OrderToCard;
