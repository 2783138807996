import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { AddStrength } from './AddStrength';
import useTableFilters from '../../../hooks/useTableFilter';
import { useCreateMedStrengthMutation, useGetAllMedStrengthQuery } from '../storeConfiguratorsService';

const Strength = () => {
  const { data, isLoading,refetch} = useGetAllMedStrengthQuery();
  const [createmedstrength, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateMedStrengthMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([]);
  const filter = useTableFilters();
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  const tableColumns = [

    {
      title:"Medicine Strength",
      dataIndex: "strenght",
      ...filter("strenght"),
   
    },
    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      // sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
   
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Medicine Strength</h1>
      <Tooltip  title="Add Med Strength">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
      dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add Med Strength"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addstrengthForm"
      loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddStrength
  createmedstrength={createmedstrength} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  </section>
  )
}

export default Strength