import { Form,Input,Radio } from 'antd'
import React, { useState } from 'react';

export default function Vitals() {
    const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (

    <div>
    <h1 className='col-span-2 flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-10 '>Vitals</h1>
    <div className='border border-gainsboro'>
    <Form 
    layout='vertical'>
    <Form.Item>
    <div className='p-2'>          
    <Radio.Group onChange={onChange} value={value}>
    <Radio value={1}>Acute</Radio>
    <Radio value={2}>Chronic</Radio>
    </Radio.Group>
    </div>
    </Form.Item>
    <div className='grid sm:grid-cols-7 grid-cols-1 gap-1 p-2 py-0 m-0'>
        <Form.Item 
        name='time'
        label='Time'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='pumpspeed'
        label='Pump Speed'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='conductivity'
        label='Conductivity'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='ufr'
        label='UFR'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='tmp'
        label='T.M.P'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='heparin'
        label='Haparin'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item 
        name='uf'
        label='Total UF'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='ap'
        label='AP'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='vp'
        label='VP'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='pulse'
        label='Pulse'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='bp'
        label='BP'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='temp'
        label='Temp'
        >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name='lvfluid'
        label='Lufluid'
        >
        <Input autoFocus />
      </Form.Item>

    <Form.Item 
        name='dialysisnum'
        label='Dialysis Num'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='timeon'
        label='Time On'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='timeoff'
        label='Time Off'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='pre'
        label='Pre Dial Wght'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='post'
        label='Post Dial Wght'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='dry'
        label='Dry Weight'
        >
        <Input autoFocus />
      </Form.Item>
    
      <Form.Item
        name='priming'
        label='Priming'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='sol'
        label='Dial Solution'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='dialyzer'
        label='Dialyzer'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        name='fistule'
        label='Fistule Needles'
        >
        <Input autoFocus />
      </Form.Item>
      </div>
</Form>
</div>
    
    </div>

  )
}
