import { Form, Input, Select } from 'antd'
import { useLovRoleQuery, useLovUserPolicyQuery } from '../../securityService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateUser = ({ updateUserFunc, rowData }) => {
	const { data: lovRole } = useLovRoleQuery()
	const { data: lovUserPolicy } = useLovUserPolicyQuery()
	
	const onFinish = values =>
		updateUserFunc({
			...rowData,
			fullname: values.fullname.toUpperCase(),
			policy: lovUserPolicy.data.find(item => item.policyid === values.policy),
			role: lovRole.data.filter(item => values.role.includes(item.roleid)),
			message: 'B',
		})

	return (
		<Form
			layout='vertical'
			onFinish={onFinish}
			id='updateUserForm'
			validateMessages={formValidationMessages}
			initialValues={{
				...rowData,
				policy: rowData?.policyResponse?.policyid,
				role: rowData?.roleResponses?.map(item => item?.role),
			}}
			className='grid sm:grid-cols-2 grid-cols-1 gap-2'>
			<Form.Item
				name='fullname'
				label='Full Name'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Full Name' autoFocus />
			</Form.Item>
			<Form.Item
				name='policy'
				label='Policy'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Policy'
					options={lovUserPolicy?.data?.map(item => ({
						value: item?.policyid,
						label: item?.descr,
					}))}
				/>
			</Form.Item>
			<Form.Item
				name='role'
				label='Role'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Role'
					mode='multiple'
					options={lovRole?.data?.map(item => ({
						value: item?.roleid,
						label: item?.rolecode,
					}))}
				/>
			</Form.Item>
			<Form.Item
				name='status'
				label='Status'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
		</Form>
	)
}

export default UpdateUser
