import React from "react";
import pagebackground from "../../assets/images/bgdesign2.png";
import pagebackground2 from "../../assets/images/bgdesign.png";
import { Carousel } from "antd";
export const Awanstorbgpage = () => {
  return (
    <Carousel autoplay>
      <div className=" pt-10">
        <div className="  flex bg-[#00b29c] justify-center item-center text-[white] rounded text-xl font-medium col-span-4 p-1.5 mb-4"></div>
        <div>
          <img
            alt=""
            src={pagebackground}
            className="bg-no-repeat bg-center bg-cover"
          />
        </div>
        <div className="flex bg-[#00b29c] justify-center item-center text-[white] rounded text-xl font-medium col-span-4 p-1.5 mt-4"></div>
      </div>
      <div className=" pt-10">
        <div className="flex bg-[#00b29c] justify-center item-center text-[white] rounded text-xl font-medium col-span-4 p-1.5 mb-4"></div>
        <div className=" ">
          <img alt="" src={pagebackground2} />
        </div>
        <div className="flex bg-[#00b29c] justify-center item-center text-[white] rounded text-xl font-medium col-span-4 p-1.5 mt-4"></div>
      </div>
    </Carousel>
  );
};
