import { useState, useEffect } from "react";
import { Input, Button, Form, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../authService";
import { loginSuccess } from "../authSlice";
import formValidationMessages from "../../../utils/formValidationMessages";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import bgimg from "../../../assets/images/bglogin.png";
import bgBlank from "../../../assets/images/bgblank.png";
const LoginAwan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser, { data, isLoading }] = useLoginMutation();

  const onFinish = (values) => {
    loginUser({ ...values, message: "E" });
  };

  useEffect(() => {
    if (data) {
      dispatch(loginSuccess(data));
      navigate("/");
    }
  }, [data]); // eslint-disable-line

  const imageUrl = useWindowWidth() >= 650 ? bgimg : bgBlank;
  return (
    <div className="flex justify-center items-center h-screen bg-[#96dcc4] ">
      <div
        className="flex justify-center items-center h-[600px] w-[600px]   bg-no-repeat bg-center bg-cover "
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className=" sm:w-[284px] sm:ml-[7px] sm:mt-[49px]">
          <Form
            layout="vertical"
            onFinish={onFinish}
            validateMessages={formValidationMessages}
          >
            <Form.Item
              name="username"
              autoComplete="off"
              rules={[{ required: true }]}
              className="mb-0 border-none"
            >
              <Input
                placeholder="Enter username"
                size="large"
                prefix={<UserOutlined className=" text-[#47a686]" />}
                className=" border-none  "
              />
            </Form.Item>
            <Divider className="border-[#c0d5d6] mt-1 mb-1 bg-white my-4 border-t-2" />
            <Form.Item
              name="password"
              autoComplete="on"
              rules={[{ required: true }]}
              className="mb-2 border-none "
            >
              <Input.Password
                placeholder="Enter password"
                size="large"
                prefix={<LockOutlined className=" text-[#47a686]" />}
                className=" border-none "
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
                className="bg-[#47a686] w-full sm:pt-[12px] sm:h-[47px]"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return windowWidth;
};
export default LoginAwan;
