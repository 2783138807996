import { api } from '../api/api'

const EttApi = api.injectEndpoints({
    
	endpoints: build => ({
    
  getPatientInfo: build.query({
  query: (body) =>`http://172.16.70.99:8083/echoett/GetPatientInfo/${body.co}`,
    }),
  getInfoByDate: build.mutation({
        query: (body) => ({
          url: `http://172.16.70.99:8083/echoett/GetInfoByDate/${body.co}/${body.fromdate}/${body.todate}`,
        }),
      }),
      updateStatus: build.mutation({
        query: body => ({
          url: `http://172.16.70.99:8083/echoett/updatestatus`,
          method: 'POST',
          body,
        }),
      }),  
      saveett: build.mutation({
        query: body => ({
          url: `http://172.16.70.99:8083/echoett/saveett/${body.co}/${body.regnum}/${body.reqnum}/${body.patienttype}/${body.test}/${body.adviseddate}`,
          // method: 'POST',
          // body,
        }),
      }), 
      
      getPatientCurrentVitals: build.mutation({
        query: (body) => ({
          url: "opd/getPatientCurrentVitals",
          method: "POST",
          body,
        }),
      }),
      patientVitals: build.mutation({
      query: (body) => ({
        url: "opd/addPatientVitals",
        method: "POST",
        body,
      }),
    }),

  }),

 
})


export const {

useGetPatientInfoQuery,
useSaveettMutation,
usePatientVitalsMutation,
useGetPatientCurrentVitalsMutation,
useGetInfoByDateMutation,
useUpdateStatusMutation,
        
} = EttApi
