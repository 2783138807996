import { Form, Input, Select } from "antd";
import React from "react";
import { useLovHmsDesignationQuery } from "../employeeServices";

export const UpdateEmpreg = ({ rowData, updateregempdata }) => {
  const [form] = Form.useForm();
  const { data: getlovdesignation } = useLovHmsDesignationQuery();
  const onFinish = (values) => {
    updateregempdata({
      empid: values?.empid,
      fathername: values?.fathername,
      desigid: values?.desigid,
      empstatus: values?.empstatus,
      salary: values?.salary,
      mobile: values?.mobile,
      leavingreason: values?.leavingreason,
      remarks: values?.remarks || "",
      status: values?.status,
    });
  };
  return (
    <div>
      <Form
        id="updateempreg"
        initialValues={{
          empid: rowData?.empid,
          fathername: rowData?.fathername,
          desigid: rowData?.desigid,
          empstatus: rowData?.emptype,
          salary: rowData?.salary,
          mobile: rowData?.mobile,
          status: rowData?.status,
        }}
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1 p-1">
          <Form.Item name="empid" label="Employee ID">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="fathername"
            label="Father Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="desigid"
            label="desigid"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Designation"
              showSearch
              filterOption={(input, option) =>
                option?.label?.toLowerCase()?.includes(input?.toLowerCase())
              }
              options={getlovdesignation?.data?.map((item) => ({
                value: item?.code,
                label: item?.descr,
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="empstatus"
            label="Employee Status"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="salary" label="Salary" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mobile" label="Mobile" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="leavingreason"
            label="Leaving Reason"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select
              placeholder="Select Status"
              options={[
                { label: "Active", value: "Y" },
                { label: "In Active", value: "N" },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
