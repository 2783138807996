import { api } from "../api/api";

export const ReferralApi = api.injectEndpoints({
  tagTypes: [
    "enterAppointment",
    "CreateVisitForPateint",

  ],
  endpoints: (build) => ({

    lovCurrentDocList: build.query({
        query: () => "reffer/lovCurrentDocList",
      }),

      lovProjectList: build.query({
        query: () => "reffer/lovProjectList",
      }),
      getPatientReffer: build.query({
        query: () => `reffer/getPatientReffer`,
        
      }),  

      getPatientReffered: build.query({
        query: () => `reffer/getPatientReffered`,
        
      }),  
      getPatMedHistory: build.mutation({
        query: (body) =>({
         url: `reffer/getPatMedHistory/${body.regnum}`,

        }),  
      }), 
      
      updateRefferedStatus: build.mutation({
        query: body => ({
          url: `reffer/updateRefferedStatus`,
          method: 'POST',
          body,
        }),
  
      }), 

      lovCurrentOPDList: build.mutation({
        query: (body) => ({
          url: `reffer/lovCurrentOPDList/${body.gender}`,
        }),
      }),
      lovOPDList: build.mutation({
        query: (body) => ({
          url: `registration/lovOPDList/${body.co}/${body.gender}`,
        }),
      }),
// http://172.16.70.90:8081/
      getPatientRefferDate: build.mutation({
        query: (body) => ({
          url: `reffer/getPatientRefferDate/${body.fromdate}/${body.todate}`,
        }),
      }),

      getPatientRefferedbyDate: build.mutation({
        query: (body) => ({
          url: `reffer/getPatientRefferedbyDate/${body.fromdate}/${body.todate}`,
        }),
      }),


      CreateRefer: build.mutation({
        query: body => ({
          url: `reffer/CreateRefer`,
          method: 'POST',
          body,
        }),
        
      }),  

    
    getPatientByMrNumNameMobileNoCNIC: build.mutation({
        query: (body) => ({
          url: `registration/getPatientByMrNumNameMobileNoCNIC/${body.regnum}`,
        }),
      }),

      getPatientByMrNumNameMobileNo: build.query({
        query: (body) => ({
          url: `registration/getPatientByMrNumNameMobileNoCNIC/${body.regnum}`,
        }),
      }),

      enterAppointment: build.mutation({
        query: (body) => ({
          // export const BASE_URL = 'http://172.16.70.90:8090/'       ///Appointment
          url: "/registration/SaveRegAppointment",
          method: "POST",
          body,
        }),
        invalidatesTags: ["enterAppointment"],
      }), 
      getPrice: build.mutation({
        query: (body) =>
          `registration/getPrice/${body.suborgid}/${body.nomnclhtr}/${body.pattype}`,
      }),
      lovDocList: build.query({
        query: (body) => `/registration/lovDocList/${body}`,
      }),

      lovOPD: build.query({
        query: (body) =>
          `/registration/lovOPDList/${body.suborgid}/${body.gender}`,
      }),
      CreateVisitForPateint: build.mutation({
        query: (body) => ({
          url: "/registration/CreateVisitForPateint",
          method: "POST",
          body,
        }),
        invalidatesTags: ["CreateVisitForPateint"],
      }),
      changEntertaineStatus: build.mutation({
        query: (body) => ({
          url: "/registration/changentertainestatus",
          method: "POST",
          body,
        }),
      }),
      getPatientList: build.mutation({
        query: (body) => `/registration/getpatientlist/${body.co}/${body.gender}`,
      }),
      BillInsertion: build.mutation({
        query: (body) => ({
          url: "/registration/BillInsertion",
          method: "POST",
          body,
        }),
      }),
      
  }),
});

export const {
  
  useLovCurrentOPDListMutation,
  useEnterAppointmentMutation,
  useGetPatientByMrNumNameMobileNoCNICMutation,
  useGetPatientByMrNumNameMobileNoQuery, 
  useLovCurrentDocListQuery,
  useGetPatientRefferQuery,
  useLovProjectListQuery,
  useLovOPDListMutation,
  useLovOPDQuery,
  useGetPatientRefferDateMutation,
  useGetPatientRefferedQuery,
  useGetPatientRefferedbyDateMutation,
  useCreateReferMutation,
  useGetPriceMutation,
  useLovDocListQuery,
  useLovOPDListQuery,
  useCreateVisitForPateintMutation,
  useChangEntertaineStatusMutation,
  useBillInsertionMutation,
  useGetPatientListMutation,
  useUpdateRefferedStatusMutation,
  useGetPatMedHistoryMutation,
 
} = ReferralApi;
