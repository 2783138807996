import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import {
  useLovInvBrandsQuery,
  useLovInvClassQuery,
  useLovInvManufactureQuery,
  useLovInvStrengthQuery,
  useLovInvTypeQuery,
  useLovInvUomQuery,
  // useLovNatQuery,
} from "../inventoryConfiguratorsService";

const AddInventoryCode = ({ createinvcode }) => {
  const onFinish = (values) => {
    createinvcode({
      ...values,
      gid: "1",
      mid: "1",
      natid: "1",
      message: "B",
    });
  };
  const { data: lovInvBrands } = useLovInvBrandsQuery();
  const { data: lovInvClass } = useLovInvClassQuery();
  // const { data: lovNature } = useLovNatQuery();
  // const { data: lovInvMainGrp} = useLovInvMainGrpQuery();
  const { data: lovInvStrength } = useLovInvStrengthQuery();
  const { data: lovInvManufacture } = useLovInvManufactureQuery();
  const { data: lovInvUom } = useLovInvUomQuery();
  const { data: lovInvType } = useLovInvTypeQuery();
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          imported: "N",
          inventory: "Y",
          price: "1",
          prvItemnomcltre: " ",
        }}
        id="addInvcodeForm"
        validateMessages={formValidationMessages}
        className="grid grid-cols-5 gap-2"
      >
        <Form.Item name="itemname" label="Item Name ">
          <Input placeholder="Enter Item Name " />
        </Form.Item>

        <Form.Item
          name="bid"
          label="Brand Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Brand Name"
            options={lovInvBrands?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        {/* <Form.Item
          name="natid"
          label="Nature Class"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Nature Class"
            options={lovNature?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item> */}

        <Form.Item
          name="cid"
          label="Inventory Class"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Inventory Class"
            options={lovInvClass?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="strid"
          label="Inventory Strength"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Strength"
            options={lovInvStrength?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="mafcid"
          label="Inventory Manufacturer"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Manufacturer"
            options={lovInvManufacture?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="uomid"
          label="Inventory UOM"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select UOM"
            options={lovInvUom?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="tid"
          label="Inventory Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Inventory Type"
            options={lovInvType?.data?.map((item) => ({
              value: item?.code,
              label: `${item?.descr}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item name="price" label="Price">
          <Input placeholder="Enter Price" />
        </Form.Item>
        <Form.Item name="imported" label="Imported">
          <Select
            placeholder="Select Imported"
            options={[
              { label: "Yes", value: "Y" },
              { label: "Not", value: "N" },
            ]}
          />
        </Form.Item>
        {/* <Form.Item
              name="gid"
              label="Generic Group"
             
            >
               <Input disabled />
            </Form.Item>
            <Form.Item
              name="mid"
              label="Inventory Main Group"
              
            >
              <Input disabled/>
            </Form.Item> */}

        <Form.Item name="inventory" label="Inventory">
          <Select
            placeholder="Select Inventory"
            options={[
              { label: "Yes", value: "Y" },
              { label: "Not", value: "N" },
            ]}
          />
        </Form.Item>
        {/* <Form.Item name="itemnomncltre" label="Itemnomncltre ">
          <Input placeholder="Enter Itemnomncltre" />
        </Form.Item> */}
        <Form.Item name="prvItemnomcltre" label="PrvItemnomcltre ">
          <Input placeholder="Enter PrvItemnomcltre" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddInventoryCode;
