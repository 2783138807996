import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateProvince = ({ rowData, updateprovincedata }) => {
  console.log("row", rowData);
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updateprovincedata({
      provid: values?.provid,
      cntryid: values?.cntryid ? values?.cntryid : rowData?.cntryid,
      provcode: values?.provcode,
      description: values?.description,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updateprovince"
        form={form}
        initialValues={{
          description: rowData?.description,
          provid: rowData?.provid,
          cntryid: rowData?.cntryid,
          provcode: rowData?.provcode,
          status: rowData?.status,
          countryid: rowData?.cntryid,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="provid"
          label="Province ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled="true" />
        </Form.Item>
        <Form.Item
          name="provcode"
          label="Province Code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          {/* <Select
            placeholder="Select Organization"
            defaultValue={rowData?.description}
            onChange={(value) => {
              const selectedOption = lovhmsCountry?.data?.find(
                (item) => item?.code === value
              );
              form.setFieldsValue({
                description: selectedOption?.descr,
                countryid: selectedOption?.code,
              });
            }}
            options={lovhmsCountry?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item name="cntryid" label="Country ID">
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateProvince;
