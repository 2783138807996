import { useState, useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Space, Button } from 'antd'

const useTableFilters = () => {
	const [searchText, setSearchText] = useState('')
	const [searchedColumn, setSearchedColumn] = useState('')
	const searchInput = useRef(null)
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm()
		setSearchText(selectedKeys[0])
		setSearchedColumn(dataIndex)
	}

	const handleReset = (clearFilters, confirm) => {
		clearFilters()
		setSearchText('')
		confirm()
	}

	const getColumnSearchProps = (dataIndex, dataIndex2) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div
				style={{
					padding: 8,
				}}>
				<Input
					ref={searchInput}
					placeholder='Search...'
					value={selectedKeys[0]}
					onChange={e =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size='small'
						style={{
							width: 90,
						}}>
						Search
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters, confirm)}
						size='small'
						style={{
							width: 90,
						}}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => (
			<SearchOutlined
				style={{
					color: filtered ? '#00b29c' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			dataIndex2
				? record[dataIndex][dataIndex2]
						?.toString()
						?.toLowerCase()
						.includes(value.toLowerCase())
				: record[dataIndex]
						?.toString()
						?.toLowerCase()
						.includes(value.toLowerCase()),

		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100)
			}
		},
		render: text =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	})

	return getColumnSearchProps
}

export default useTableFilters
