import { Form, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddBloodGroup = ({ saveBloodGroup }) => {
  const onFinish = (values) => {
    saveBloodGroup({
      description: values?.description,

      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="addbloodgroup"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item name="description" label="Blood Group">
          <Select
            placeholder="Select Blood Group"
            options={[
              { value: "A+", label: "A+" },
              { value: "A-", label: "A-" },
              { value: "B+", label: "B+" },
              { value: "B-", label: "B-" },
              { value: "AB+", label: "AB+" },
              { value: "AB-", label: "AB-" },
              { value: "O+", label: "O+" },
              { value: "O-", label: "O-" },
            ]}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default AddBloodGroup;
