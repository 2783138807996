import { Button, Form, Input, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreatePOSMutation,
  useGetInvoicedetailinfoMutation,
  useGetInvoiceinfoQuery,
  // useDeleteposdetrecordMutation,
  useGetposmasterMutation,
  useGetProfitSaleTodayQuery,
  useGetQutationdetailMutation,
  useLovCustomerBalanceMutation,
  useLovCustomerQuery,
  useLovItemListforPOSQuery,
  // useUpdatePosDetailRecordMutation,
  // useUpdPosmasterMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import useFetchPDF from "../../../hooks/useFetchPDF";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import openNotification from "../../../utils/notification";

export const PointofSale = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [buttonid, setButtonid] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reportData, getReport] = useFetchPDF();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();
  const { data: lovCustomer } = useLovCustomerQuery();
  const [
    detaildata,
    { data: getdetaildata, isError: getError, isLoading: detailLoading },
  ] = useGetQutationdetailMutation();
  useEffect(() => {
    if (getdetaildata) {
      const updatedTableData = getdetaildata?.data.map((item) => ({
        itemid: item.itemid,
        item: item.itemname,
        price: item.quotedrate,
        qty: 1,
        discountprice: 0,
      }));
      setTableData(updatedTableData);
      // setTableData(getdetaildata?.data);
      // const totalactualprice = getdetaildata?.data.reduce(
      //   (a, v) => (a = a + v.actualprice),
      //   0
      // );
      // const totalfinalprice = getdetaildata?.data.reduce(
      //   (a, v) => (a = a + v.finalprice),
      //   0
      // );
      // setTotalactual(totalactualprice);
      // setTotalfinal(totalfinalprice);
    }
  }, [getdetaildata]);
  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      total: item.price * (item.qty || 1),
    }));
    const totalValue = updatedTableData.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    form.setFieldsValue({ totalamount: totalValue }); //, finalamount: totalValue
    setTableData(updatedTableData);
  }, [tableData, form]);
  useEffect(() => {
    if (getError) {
      setTableData([]);
    }
  }, [getError]);
  const [createpos, { data: masterdata, isSuccess: masterSuccess }] =
    useCreatePOSMutation();
  // const [updatecreatepos] = useUpdPosmasterMutation();
  // const [createposdetail] = useUpdatePosDetailRecordMutation();
  // const [deletepos] = useDeleteposdetrecordMutation();
  const [savebalance, { data: getbalance }] = useLovCustomerBalanceMutation();
  const [saveinvnum, { data: getinvnum }] = useGetposmasterMutation();
  const { data: getpostoday, refetch } = useGetProfitSaleTodayQuery();
  const { data: getinvinfo } = useGetInvoiceinfoQuery();
  const [savetransactionid, { data: gettransdata }] =
    useGetInvoicedetailinfoMutation();
  useEffect(() => {
    if (getpostoday) {
      form.setFieldsValue({ invbarcode: getpostoday?.data[0]?.profit || "" });
    }
  }, [getpostoday, form]);
  const handlerefetch = () => {
    refetch();
  };
  const filter = useTableFilters();
  // useEffect(() => {
  //   if (getinvnum) {
  //     form.setFieldsValue({
  //       // customername: getinvnum?.data[0].customerid || 0,
  //       // totalamount: getinvnum?.data[0].totalamt || 0,
  //       previousbalance: getinvnum?.data[0].discamt || 0,
  //       // discount: getinvnum?.data[0].totalpaid || 0,
  //       // finalamount: getinvnum?.data[0].totalpaid || 0,
  //     });
  //   }
  // }, [getinvnum, form]);
  useEffect(() => {
    if (getbalance) {
      form.setFieldsValue({
        previousbalance: getbalance?.data[0].balance || 0,
      });
    }
  }, [getbalance, form]);
  useEffect(() => {
    if (masterSuccess) {
      getReport(`pos/GetPOSRPT/${masterdata?.data.invnum}`);
      setShowPdfModal(true);
      setTableData([]);
      form.resetFields();
      handlerefetch();
    }
  }, [masterSuccess]); //eslint-disable-line
  const columns = [
    {
      title: "Itemname",
      dataIndex: "item",
      ...filter("item"),
      sorter: (a, b) => a?.item?.localeCompare(b?.item),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },

    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        <Input
          ref={itemSelectRefqty}
          placeholder="Enter Qty"
          defaultValue={1}
          value={record.qty}
          onChange={(e) =>
            handleInputChange(record.itemid, "qty", e.target.value)
          }
          // className=""
        />
      ),
    },
    {
      title: "Price  F5",
      dataIndex: "price",
      render: (text, record) => (
        <Input
          ref={priceInputRef}
          placeholder="Enter Price"
          defaultValue={1}
          value={record.price}
          onChange={(e) =>
            handleInputChange(record.itemid, "price", e.target.value)
          }
          className=" w-1/2"
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (_, record) => {
        const total = record.price * (record.qty || 1);
        return <span>{total.toFixed(2)}</span>;
      },
    },
    // {
    //   title: "Discount Price",
    //   dataIndex: "discountprice",
    // },
    {
      title: "Action(s)",
      key: "actions",
      render: (record) => (
        <div className="space-x-0.5">
          <Tooltip title="Delete" placement="left">
            <Button
              danger
              icon={<i className="fa fa-trash"></i>}
              onClick={() => handleDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  //add feature
  const itemSelectRef = useRef();
  const itemSelectRefqty = useRef();
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const itemSelectRefcustomer = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  const [showchallanModal, setShowchallanModal] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const discountInputRef = useRef(null);
  const carriageInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const payableamountInputRef = useRef(null);
  const [discountsis, setDiscountsis] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if F1 key is pressed
      if (e.key === "F1") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (discountInputRef.current) {
          discountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F2") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (carriageInputRef.current) {
          carriageInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F4") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (payableamountInputRef.current) {
          payableamountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F5") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (priceInputRef.current) {
          priceInputRef.current.focus(); // Focus on the discount input
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tableData.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTableData(newData);
  };

  const onFinish = (values) => {
    if (buttonid === "savepos") {
      const updateData = tableData.map((row) => ({
        itemid: row.itemid,
        itemname: row.item,
        quantity: row.qty || 1,
        price: row.price,
        totalprice: row.price * (row.qty || 1),
        taxpct: "0",
        discountamount: "0",
      }));
      createpos({
        invtype:
          values?.paymode === "Cash"
            ? "C"
            : values?.paymode === "Credit"
            ? "Cr"
            : "",
        totalamt: values?.totalamount,
        discamt: values?.discount || 0,
        taxamt: 0,
        totalpaid: values?.payableamount,
        balance: values?.previousbalance || 0,
        customerid: values?.customername,
        paymode: values?.paymode,
        carriageamt: values?.carriageamt,
        data: updateData,
        message: "B",
      });
    } else if (buttonid === "reprint") {
      getReport(`pos/GetPOSRPT/${values.invnum.toUpperCase()}`);
      setShowPdfModal(true);
    }
  };
  const handleDelete = (record) => {
    setTableData((prevTabledata) =>
      prevTabledata.filter((row) => row.itemid !== record.itemid)
    );
    setRowsToDelete((prevRowsToDelete) => [...prevRowsToDelete, record.itemid]);
  };
  useEffect(() => {
    if (rowsToDelete.length > 0) {
      const updatedData = tableData.filter(
        (row) => !rowsToDelete.includes(row.key)
      );
      setTableData(updatedData);
      setRowsToDelete([]);
    }
  }, [rowsToDelete, tableData]);
  //handle pre and next

  const dataLength = getinvinfo?.data.length || 0;
  const handlePrevious = () => {
    setCurrentIndex((prevCount) => Math.max(prevCount - 1, 0));
    console.log("currentIndex", currentIndex);
    savetransactionid({
      transactionid: getinvinfo?.data[currentIndex]?.transactionid,
    });
  };
  const handleNext = () => {
    setCurrentIndex((prevCount) => prevCount + 1);
    savetransactionid({
      transactionid: getinvinfo?.data[currentIndex + 1]?.transactionid,
    });
  };
  //
  const [disprevious, setDisprevious] = useState(false);
  const cus = form.getFieldValue("customername");
  useEffect(() => {
    if (cus === 1) {
      setDisprevious(true);
    }
  }, [cus]);
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Point of Sale
      </h1>
      <div>
        <div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              customername: 1,
              discount: 0,
              previousbalance: 0,
              discountper: 0,
              paymode: "Cash",
              carriageamt: 0,
            }}
          >
            <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
              <Form.Item
                name="customername"
                label="Customer Alt + C"
                className=" mb-0"
                rules={[{ required: buttonid === "savepos" }]}
              >
                <Select
                  ref={itemSelectRefcustomer}
                  open={isItemSelectOpencustomer}
                  onDropdownVisibleChange={(open) =>
                    setIsItemSelectOpencustomer(open)
                  }
                  placeholder="Select Customer Name"
                  options={lovCustomer?.data?.map((item) => ({
                    value: item?.customerid,
                    label: `${item?.name}`,
                  }))}
                  onChange={(value) => {
                    setIsItemSelectOpencustomer(false);
                    if (value) {
                      savebalance({ customerid: value });
                    }
                    if (value !== 1) {
                      setDisprevious(false);
                    }
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name="itemid"
                label="Item Name Alt + L"
                className=" sm:col-span-3"
              >
                <Select
                  ref={itemSelectRef}
                  open={isItemSelectOpen}
                  onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
                  placeholder="Select Item Name"
                  options={LovItemListforPOS?.data?.map((item) => ({
                    searchvalue: item.item_name,
                    value: item?.itemid,
                    label: (
                      <span className=" grid sm:grid-cols-6 justify-center items-center">
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.itemid}
                        </span>
                        <span
                          className="sm:col-span-2"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item?.item_name}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item.quantity}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item.price}
                        </span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {item.purchaseprice}
                        </span>
                      </span>
                    ),
                  }))}
                  dropdownRender={(menu) => (
                    <div>
                      {/* Header */}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr",
                          padding: "8px",
                          backgroundColor: "#47a686",
                          fontWeight: "bold",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <span>Barcode</span>
                        <span>Item Name</span>
                        <span>Qauntity</span>
                        <span>Price</span>
                        <span>Purchase Price</span>
                      </div>
                      {/* Dropdown Menu */}
                      {menu}
                    </div>
                  )}
                  onChange={(value) => {
                    setIsItemSelectOpen(false); // Close the dropdown after selecting
                    const updatedTableData = LovItemListforPOS?.data.find(
                      (item) => item.itemid === value
                    );
                    if (updatedTableData) {
                      const duplicate = tableData?.find(
                        (item) => item.itemid === updatedTableData?.itemid
                      );
                      if (!duplicate) {
                        setTableData((prevTableData) => [
                          ...prevTableData,
                          ...[updatedTableData],
                        ]);
                        setTimeout(() => {
                          if (itemSelectRefqty.current) {
                            itemSelectRefqty.current.focus();
                            itemSelectRefqty.current.select();
                          }
                        }, 100);
                      } else
                        openNotification(
                          "error",
                          "Item is Already in The Table",
                          10
                        );
                    }
                  }}
                  showSearch
                  filterOption={(input, option) => {
                    const inputLower = input.toLowerCase();
                    return (
                      option?.searchvalue
                        ?.toLowerCase()
                        ?.includes(inputLower) ||
                      option?.value
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(inputLower)
                    );
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item className=" sm:col-span-3 mb-0">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  loading={detailLoading}
                  size="small"
                  scroll={{ x: "fit-content" }}
                  rowKey="itemid"
                />
              </Form.Item>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-1 mb-0">
                <Form.Item
                  name="totalamount"
                  label="Total Amount"
                  className=" mb-0 sm:col-span-2"
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="discount"
                  label="Discount F1"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input
                    type="number"
                    ref={discountInputRef}
                    onBlur={(e) => {
                      const total = form.getFieldValue("totalamount");
                      console.log("total", total);

                      const final = parseInt(total) - parseInt(e.target.value);
                      const dis =
                        (parseInt(e.target.value) / parseInt(total)) * 100;
                      form.setFieldsValue({
                        finalamount: final,
                        discountper: dis,
                        payableamount: final,
                      });
                    }}
                    onChange={() => {
                      setDiscountsis(true);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="discountper"
                  label="Discount Percentage"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input
                    type="number"
                    ref={discountInputRef}
                    readOnlydisabled={discountsis}
                    onBlur={(e) => {
                      const total = form.getFieldValue("totalamount");

                      const final =
                        parseInt(total) -
                        (parseInt(e.target.value) / 100) * parseInt(total);
                      form.setFieldsValue({
                        finalamount: final,
                        payableamount: final,
                        discount:
                          (parseInt(e.target.value) / 100) * parseInt(total),
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="finalamount"
                  label="Final Amount"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="previousbalance"
                  label="Previous Balance"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input readOnly={disprevious} />
                </Form.Item>
                <Form.Item
                  name="payableamount"
                  label="Payable Amount F4"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input ref={payableamountInputRef} />
                </Form.Item>
                <Form.Item
                  name="carriageamt"
                  label="Carriage F2"
                  className=" mb-0"
                  rules={[{ required: buttonid === "savepos" }]}
                >
                  <Input ref={carriageInputRef} />
                </Form.Item>
                <Form.Item
                  label="Mode"
                  name="paymode"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Mode"
                    options={[
                      { label: "Cash", value: "Cash" },
                      { label: "Credit", value: "Credit" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="invbarcode"
                  label="Inv Bar Code"
                  className=" mb-0"
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setButtonid("savepos")}
                    className=" w-full"
                  >
                    Create Point Of Sale
                  </Button>
                </Form.Item>
              </div>
              <Form.Item
                name="invnum"
                label="Invoice Nmuber"
                className=" mb-0 sm:col-span-2"
                rules={[
                  {
                    required: buttonid === "reprint",
                  },
                ]}
              >
                <Input
                  onBlur={(e) => {
                    if (e.target.value !== "" || e.target.value !== undefined) {
                      saveinvnum({
                        invnum: e.target.value.toUpperCase(),
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="Quotation ID"
                label="quotationid"
                className=" mb-0"
              >
                <Input
                  onBlur={(e) => {
                    if (e.target.value !== "" || e.target.value !== undefined) {
                      detaildata({
                        quotationno: e.target.value,
                      });
                    }
                  }}
                />
              </Form.Item>
              <div className="flex justify-end items-center sm:col-span-4">
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setButtonid("reprint")}
                    className=" w-full"
                  >
                    Reprint
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
          <Button
            type="primary"
            onClick={() => {
              navigate("/quotation");
            }}
          >
            Quotation
          </Button>
          <Button
            type="primary"
            onClick={() => {
              navigate("/posreturn");
            }}
          >
            POS Return
          </Button>
          <Button
            type="primary"
            onClick={() => {
              getReport(`pos/DeliveryChalanRPT/${getinvinfo?.data[0].invnum}`);
              setShowchallanModal(true);
            }}
          >
            Delivery Challan Report
          </Button>
          <div className=" flex gap-1">
            <Tooltip title="Previous">
              <Button
                type="primary"
                onClick={handlePrevious}
                // readOnly={currentIndex < 0}
              >
                <FaArrowLeft />
              </Button>
            </Tooltip>

            <Tooltip title="Next">
              <Button
                type="primary"
                onClick={handleNext}
                // readOnly={currentIndex === dataLength - 1}
              >
                <FaArrowRight />
              </Button>
            </Tooltip>
          </div>
        </div>
        <Modal
          title="Print Reciept"
          open={showPdfModal}
          onCancel={() => setShowPdfModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowPdfModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Finance Report"
          ></iframe>
        </Modal>
        <Modal
          title="Delivery Challan Report"
          open={showchallanModal}
          onCancel={() => setShowchallanModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowchallanModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Finance Report"
          ></iframe>
        </Modal>
      </div>
    </section>
  );
};
