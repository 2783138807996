import { Button, DatePicker, Form, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import useTableFilters from '../../../hooks/useTableFilter';
import {  useGetInfoByDateMutation, useGetPatientInfoQuery, useSaveettMutation, useUpdateStatusMutation } from '../ettServices';
import { futureDates } from '../../../utils/dateUtils';
import { useSelector } from 'react-redux';
import moment from "moment";
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import UpdateStatus from '../components/UpdateStatus';
import PerformEtt from '../components/PerformEtt';


const ETT=() =>{
  const co = useSelector((state) => state.auth.user.co);
  const { data, isLoading,refetch } = useGetPatientInfoQuery({co:co});

  const [ PatientInfoDate, { data: getPatientInfoDate },] = useGetInfoByDateMutation();
  const [ updatestatus, {data:updateStatusData, isSuccess: updateSuccess, isLoading: updateLoading },] = useUpdateStatusMutation();
  const [saveett, { isSuccess: addSuccess, isLoading: addLoading }] = useSaveettMutation();
  
  const filter = useTableFilters();
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState(null);
  const [showETTList, setettList] = useState(false);
  const [showupdateModal, setUpdateModal] = useState(false);
  const[tableDatabedinfo,setTableDatabedinfo]=useState([])
  useEffect(() => {
    if (addSuccess) setettList(false);
    handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  useEffect(() => {
    if (updateSuccess) setUpdateModal(false);
    handlerefetch();
  }, [updateSuccess]); // eslint-disable-line
    useEffect(()=>{
      if(data){
        setTableDatabedinfo(data?.data)
      }
    },[data])
   
    const tableColumns = [
      {
        title : 'MR. No', 
        dataIndex:'regnum',
        ...filter ('regnum'),
      sorter: (a, b) => a?.regnum?.localeCompare(b?.regnum),
    },
    
      {
        title : 'Patient Name', 
        dataIndex:'patientname',
        ...filter ('patientname'),
        sorter: (a, b) => a?.patientname?.localeCompare(b?.patientname),
      },
      {
        title : 'Patient Type', 
        dataIndex:'patienttype',
        ...filter ('patienttype'),
        sorter: (a, b) => a?.patienttype?.localeCompare(b?.patienttype),
      },
      {
       title : 'Advised Date', 
       dataIndex:'adviseddate',
      ...filter ('adviseddate'),
      sorter: (a, b) => a?.adviseddate?.localeCompare(b?.adviseddate),
      render:(_,data)=>(
      <div>
      {  moment(data?.adviseddate)?.format("DD-MM-YYYY") }</div>
      )
      },
      {
        title : 'From Unit', 
        dataIndex:'fromunit',
        ...filter ('fromunit'),
        sorter: (a, b) => a?.fromunit?.localeCompare(b?.fromunit),
  },
  {
    title : 'To Unit', 
    dataIndex:'tounit',
    ...filter ('tounit'),
    sorter: (a, b) => a?.tounit?.localeCompare(b?.tounit),
},     
{
 title: "Status",
 render: (_,record) =>
 record.status === "V" ? (
<Tag color="success">Performed</Tag>
 ) :record.status === "N" ?(
  <Tag color="error">Not Performed</Tag>
):(<Tag color="error">Referal</Tag>)
        
 },
 {
          title: "Action(s)",
          render: (data) => (
            <div className='flex justify-center gap-2'>
         
         <Tooltip title="Perform ETT">
              <Button 
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => {
                  setRowData(data);
                  setettList(true)
                }}
              ></Button>
            </Tooltip>

            <Tooltip title="Update Status">
              <Button
                type="primary"
                className="btn-warning-primary"
                icon={<EditFilled />}
                onClick={() => {
                setRowData(data);
                  setUpdateModal(true);
                  // updatestatus({seqno:data.seqno})
                }}
              />
            </Tooltip>
            </div>
          ),
        },

      ];
const onFinish = (values) => {
        PatientInfoDate({
          fromdate: moment(values?.fromdate)?.format("DD-MMM-YYYY"),
          todate:moment(values?.todate)?.format("DD-MMM-YYYY"),
          co:co,
          message:"B"
        });
      };
 const handlerefetch=()=>{
        refetch()
      }
    
  return (
   <section>
                <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        Refered Patients 
        </h1>
</div>
      <Form
layout='vertical'
   onFinish={onFinish}
  form={form}
    >
    <div className='p-2 grid sm:grid-cols-3 gap-2'>
     <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
    <Form.Item name='todate' label='To Date'>
          <DatePicker
            disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item  className='flex justify-end'
      label=' '>
      <Button className='w-48 '
      type="primary" htmlType="submit" >
               Search
              </Button> </Form.Item>
    </div>

</Form>
      <div className="p-2">
      <Table 
        dataSource={tableDatabedinfo}
        columns={tableColumns}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size="small"
      />
    </div>
    <Modal
        title='Perform ETT'
        open={showETTList}
        onCancel={() => setettList(false)}
        centered
        width={1400}
        form='performETT'
        loading={addLoading}
        destroyOnClose
        maskClosable={false}
        footer={[
        ]}
      >
        <PerformEtt rowData={rowData}  handlerefetch={handlerefetch} saveett={saveett} />
      </Modal>
    <Modal   
    title="Update Status"
      open={showupdateModal}
      onCancel={() => setUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="UpdateStatus"
          loading={updateLoading}
          type="primary">
          Update
        </Button>,
        <Button key="2" onClick={() => setUpdateModal(false)}>
          Cancel
        </Button>,
      ]}>
      <UpdateStatus
           rowData={rowData} 
           updateStatusData={updateStatusData}
           updatestatus={updatestatus}
           />
    </Modal>
    
    
   </section>
  )
}

export default ETT