import React from 'react'
import { TbLoader } from "react-icons/tb";
import { CiTempHigh } from "react-icons/ci";
import { IoMdPulse } from "react-icons/io";
import { BsHeartPulseFill } from "react-icons/bs";
import { GiWeightScale } from "react-icons/gi";
import { FaAllergies, FaProcedures } from "react-icons/fa";
import { MdOutlineBloodtype, MdOutlineTireRepair } from "react-icons/md";
import { SiOxygen } from "react-icons/si";

const PatientVitals=({vital,VitalLoading}) =>{
  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-0.5 text-center">
      {vital ? (
        <>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1 ">
              Temp (°F)&nbsp;&nbsp;
              <CiTempHigh className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color: vital?.data?.temperature >= 100 ? "#fe8d59" : "white",
                fontWeight: vital?.data?.temperature >= 100 ? "bold" : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.temperature}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5 ">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Systolic BP (mm Hg)&nbsp;&nbsp;
              <MdOutlineTireRepair className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.bloodPressuressystolic > 120
                    ? "#fe8d59"
                    : "white",
                fontWeight:
                  vital?.data?.bloodPressuressystolic > 120 ? "bold" : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.bloodPressuressystolic}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5 ">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Diastolic BP (mm Hg)&nbsp;&nbsp;
              <MdOutlineTireRepair className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.bloodPressuredistolic < 80 ? "#fe8d59" : "white",
                fontWeight:
                  vital?.data?.bloodPressuredistolic < 80 ? "bold" : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.bloodPressuredistolic}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Pulse (bpm)&nbsp;&nbsp;
              <IoMdPulse className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.pulse > 100 || vital?.data?.pulse < 60
                    ? "#fe8d59"
                    : "white",
                fontWeight:
                  vital?.data?.pulse > 100 || vital?.data?.pulse < 60
                    ? "bold"
                    : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.pulse}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Heart Rate&nbsp;&nbsp;
              <BsHeartPulseFill className="text-white text-2xl pr-1" />
            </h4>
            {/*Oxygen */}
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.oxygen > 100 || vital?.data?.oxygen < 60
                    ? "#fe8d59"
                    : "white",
                fontWeight:
                  vital?.data?.oxygen > 100 || vital?.data?.oxygen < 60
                    ? "bold"
                    : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center ">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.oxygen !== 0 ? vital?.data?.oxygen : ""}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white  flex justify-center items-center pt-1">
              Allergy &nbsp;&nbsp;
              <FaAllergies className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white">
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.allergy !== null ? vital?.data?.allergy : ""}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Weight (Kg) &nbsp;&nbsp;
              <GiWeightScale className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white">
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.weight !== 0 ? vital?.data?.weight : ""}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Blood Group &nbsp;&nbsp;
              <MdOutlineBloodtype className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white">
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.bloodGroup}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Resp Rate (bpm) &nbsp;&nbsp;
              <FaProcedures className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.respirationRate > 100 ||
                  vital?.data?.respirationRate < 60
                    ? "#fe8d59"
                    : "white",
                fontWeight:
                  vital?.data?.respirationRate > 100 ||
                  vital?.data?.respirationRate < 60
                    ? "bold"
                    : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.respirationRate !== 0
                ? vital?.data?.respirationRate
                : ""}
            </div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Saturation &nbsp;&nbsp;
              <SiOxygen className="text-white text-2xl pr-1" />
            </h4>
            <div
              className="text-white"
              style={{
                color:
                  vital?.data?.saturation > 90 || vital?.data?.saturation < 50
                    ? "#fe8d59"
                    : "white",
                fontWeight:
                  vital?.data?.saturation > 90 || vital?.data?.saturation < 50
                    ? "bold"
                    : "normal",
              }}
            >
              {VitalLoading && (
                <div className="flex justify-center items-center">
                  <TbLoader style={{ fontSize: "2rem" }} className="pr-1" />
                </div>
              )}
              {vital?.data?.saturation !== 0 ? vital?.data?.saturation : ""}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Temp (°F)&nbsp;&nbsp;
              <CiTempHigh className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Systolic BP (mm Hg)&nbsp;&nbsp;
              <MdOutlineTireRepair className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Diastolic BP (mm Hg)&nbsp;&nbsp;
              <MdOutlineTireRepair className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Pulse (bpm)&nbsp;&nbsp;
              <IoMdPulse className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Heart Rate&nbsp;&nbsp;
              <BsHeartPulseFill className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Allergy&nbsp;&nbsp;
              <FaAllergies className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Weight (Kg)&nbsp;&nbsp;
              <GiWeightScale className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Blood Group&nbsp;&nbsp;
              <MdOutlineBloodtype className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Resp Rate (bpm)&nbsp;&nbsp;
              <FaProcedures className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro py-0.5">
            <h4 className="font-semibold text-white flex justify-center items-center pt-1">
              Saturation&nbsp;&nbsp;
              <SiOxygen className="text-white text-2xl pr-1" />
            </h4>
            <div className="text-white"></div>
          </div>
        </>
      )}
    </div>
  )
}

export default PatientVitals