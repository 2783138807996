import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateBloodGroup = ({ rowData, updateBloodGroup }) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updateBloodGroup({
      bid: values?.bid,
      description: values?.description,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatebloodgroup"
        form={form}
        initialValues={{
          description: rowData?.description,
          bid: rowData?.bid,

          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="bid"
          label="Brand ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled="true" />
        </Form.Item>
        <Form.Item name="description" label="Blood Group">
          <Select
            placeholder="Select Blood Group"
            options={[
              { value: "A+", label: "A+" },
              { value: "A-", label: "A-" },
              { value: "B+", label: "B+" },
              { value: "B-", label: "B-" },
              { value: "AB+", label: "AB+" },
              { value: "AB-", label: "AB-" },
              { value: "O+", label: "O+" },
              { value: "O-", label: "O-" },
            ]}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateBloodGroup;
