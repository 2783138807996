import { Button, Form, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useCreatePatientMutation } from '../registrationServices'
import { useNavigate } from 'react-router-dom'

const PatientRegister=()=> {
  const [form] = Form.useForm()
  const navigate = useNavigate();
  const [registerPatient, { isSuccess, data, isLoading }] = useCreatePatientMutation();
  const onFinish = (values) =>{
    registerPatient({
        ...values,
        message: 'B',
      })
    }
    useEffect(() => {
      if (isSuccess) {
        navigate('/PatientSearch');
      }
    }, [isSuccess, navigate]);

  return (
   <section>
     <div className='flex items-center justify-between px-5 py-3 bg-lotion border border-gainsboro'>
          <h1 className='font-semibold text-base sm:text-lg'>Register Patient</h1>
        </div>
        <Form
        layout='vertical'
        form={form}
        validateMessages={formValidationMessages}
        onFinish={onFinish}
        >
            <div className='grid sm:grid-cols-3 grid-cols-3 gap-2 p-2 border border-gainsboro mt-5 '>
    <Form.Item name="patientname" label="Patient Name"
     rules={[
                {
                  required: true,
                },
                
              ]}>
    <Input
    placeholder='Enter Patient Name'></Input>
    </Form.Item>
 
    <Form.Item
                  name='cellno'
                  label='Cell / Mobile Phone'
                  type='number'
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^[0][3][0-9]{9}$/,
                    },
                  ]}
                >
                  <Input placeholder='033312345678' maxLength={11}  />
                </Form.Item>
    <Form.Item 
    name="fhname" label="Father Name" 
                
            >
    <Input placeholder='Enter Father Name'></Input>
    </Form.Item>
     
      <Form.Item 
      name='age' label='Age' rules={[
                {
                  required: true,
                },
                
              ]}>
      <Input placeholder='Enter Age'></Input>
      </Form.Item>
      <Form.Item
      name='gender'
      label='Gender'
      rules={[
                {
                  required: true,
                },
                
              ]}
    >
      <Select
        placeholder='Select Gender'
        options={[
          { value: 'M', label: 'Male' },
          { value: 'F', label: 'Female' },
        ]}
      />
    </Form.Item>
      
      <Form.Item 
      name='address' label='Address' rules={[
                {
                  required: true,
                },
                
              ]}>
         <Input placeholder='Enter Address' />  
        </Form.Item>
    
      </div> 


      <div className='flex justify-end mt-2 p-2'>
            <Button type='primary' className='btn-success-primary' 
            loading={isLoading} 
            htmlType='submit'>
              Register
            </Button>
          </div>


        </Form>

   </section>
  )
}

export default PatientRegister