import React from 'react'
import { Form, Input, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddWardBedTypeMaintenance=({createbedtype}) => {

  const onFinish = (values) =>{
  createbedtype({
      ...values,
      message: 'B',
    })
  
  }
  return (
    <Form
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='addWardBedTypeMaintenanceForm'
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>
      <Form.Item name='type' label='Type'>
      <Input placeholder='Enter Type'></Input>
      
      </Form.Item>
      <Form.Item name='descr' label='Description'>
        <Input placeholder='Enter Description' />
      </Form.Item>
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
        />
      </Form.Item>
    </div>
  </Form>
  )
}

export default AddWardBedTypeMaintenance