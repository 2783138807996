import { Form, Input, Select } from 'antd'
import React, { useEffect } from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovSrviceListQuery, useLovSubSrviceListMutation } from '../employeeServices';

const AddSubServicePrice=({createsubserviceprice})=> {
  const [form]=Form.useForm()
  const { data: lovSrviceList } = useLovSrviceListQuery();
  const [ savesubservice,{ data: lovSubService }] = useLovSubSrviceListMutation();
  const onFinish = (values) =>{
    createsubserviceprice({
        ...values,
        message: 'B',
      })
    }
  useEffect(()=>
    {
      if(lovSubService){
        form.setFieldsValue({srvid:lovSubService?.data[0].descr}, 
        )
      }
    },[lovSubService,form])
  return (
    <div>
    <Form
    layout='vertical'
     onFinish={onFinish}
    id='addSubServiceCategoryPriceForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >
    <Form.Item
                name="srvid"
                label="Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Service"
                  options={lovSrviceList?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  onChange={(value)=>{
                    savesubservice({
                srvid:value,
          
              })  
                             
          }}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name="subsrvid"
                label="Sub Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Sub Service"
                  options={lovSubService?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
                <Form.Item
      name='amount'
      label='Amount'
    >
      <Input placeholder='Enter Sub Category Amount '
      />
    </Form.Item>


  </Form>
    </div>
  )
}

export default AddSubServicePrice