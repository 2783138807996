import DialysisTreatment from "../features/dialysis/pages/DialysisTreatment";
import Dialysis from "../features/dialysis/pages/dialysis";
import Vitals from "../features/dialysis/pages/vitals";

export const dialysisRoutes = [
  {
    path: 'dialysis',
    element: <Dialysis/>
  },
  {
    path:'/dialysis/dialysistreatment',
    element:<DialysisTreatment/>
  },
  {
    path:'vitals',
    element:<Vitals/>
  }
]
