import { Menu } from "antd";
import logo from "../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { logoutUser } from "../features/auth/authSlice";
import { api } from "../features/api/api";
const Navbar = () => {
  const dispatch = useDispatch();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClick = () => {
    dispatch(logoutUser());
    dispatch(api.util.resetApiState());
  };
  return (
    <div className="flex fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-10" />
      </div>
      <div className="container mx-auto px-4">
        <Menu
          mode="horizontal"
          className="flex justify-between items-center"
          theme="light"
          defaultSelectedKeys={["home"]}
        >
          <Menu.Item key="home">
            <a href="#/userproducts" className="text-blue-500 font-semibold">
              Home
            </a>
          </Menu.Item>
          <Menu.Item key="products">
            <a
              href="#/userproducts"
              onClick={() => scrollToSection("tablecardid")}
              className="text-blue-500 font-semibold"
            >
              Products
            </a>
          </Menu.Item>
          <Menu.Item key="cartid">
            <a href="#/cardlogin" className="text-blue-500 font-semibold">
              Cart Login
            </a>
          </Menu.Item>
          <Menu.Item key="about">
            <a
              href="#/userproducts"
              onClick={() => scrollToSection("aboutid")}
              className="text-blue-500 font-semibold"
            >
              About Us
            </a>
          </Menu.Item>

          <Menu.Item key="contact">
            <a
              href="#/userproducts"
              onClick={() => scrollToSection("contactid")}
              className="text-blue-500 font-semibold"
            >
              Contact
            </a>
          </Menu.Item>
          <Menu.Item key="loginid">
            <a
              href="#/login"
              onClick={handleClick}
              className="text-blue-500 font-semibold"
            >
              Admin Login
            </a>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
