import { useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { securityRoutes } from "./securityRoutes";
import { dialysisRoutes } from "./dialysisRoutes";
import { orgStructureRoutes } from "./orgStructureRoutes";
import { nomenclatureRoutes } from "./nomenclatureRoutes";
import { wardConfigurationRoutes } from "./wardConfigurationRoutes";
import { referralRoutes } from "./referralRoutes";
import { ReportsMedixRoutes } from "./reportsMedixRoutes";
import { dashboardRoutes } from "./dashboardRoutes";
import { ettRoutes } from "./ettRoutes";
import { configurationRoutes } from "./configurationRoutes";
import { registrationRoutes } from "./registrationRoutes";
import { storeConfigurationRoutes } from "./storeConfiguratorsRoutes";
import { storeRoutes } from "./storeRoutes";
import { inventoryConfigurationRoutes } from "./inventoryConfiguratorsRoutes";
import { employeeRoutes } from "./employeeRoutes";
import { productRoutes } from "./productRoutes";
import { customerDetaiRoutes } from "./customerDetaiRoutes";
import { inventoryPurchaseRoutes } from "./inventoryPurchaseRoutes";
import { Awanstorbgpage } from "../layouts/components/PageLayoutPic";
import { salesDashboardRoutes } from "./salesDashboardRoutes";
import LoginUsers from "../features/auth/pages/LoginUsers";
import LoginAwan from "../features/auth/pages/LoginAwan";

const AppRouter = () => {
  const routes = useMemo(
    () => [
      ...securityRoutes,
      ...dialysisRoutes,
      ...orgStructureRoutes,
      // ...inventoryRoutes,
      ...nomenclatureRoutes,
      ...wardConfigurationRoutes,
      ...referralRoutes,
      ...ReportsMedixRoutes,
      ...dashboardRoutes,
      ...ettRoutes,
      ...configurationRoutes,
      ...registrationRoutes,
      ...storeConfigurationRoutes,
      ...storeRoutes,
      ...inventoryConfigurationRoutes,
      ...employeeRoutes,
      ...productRoutes,
      ...customerDetaiRoutes,
      ...inventoryPurchaseRoutes,
      ...salesDashboardRoutes,
    ],
    []
  );

  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/userlogin" element={<LoginUsers />} />
        <Route path="/login" element={<LoginAwan />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route index element={<Awanstorbgpage />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<Navigate to={-1} />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
