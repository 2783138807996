import React, { useEffect, useState } from "react";
import {
  useCreatePurchaseOrderDetMutation,
  useCreatePurchaseOrderMutation,
  useGetInvPurchaseDetailbyMasterMutation,
  useGetInvPurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
  useUpdPurchaseOrderDetMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import AddPurchaseOrder from "../components/AddPurchaseOrder";

import moment from "moment";
import UpdatePurchaseOrder from "../components/UpdatePurchaseOrder";
import AddInvPurchaseDetail from "../components/AddInvPurchaseDetail";
import UpdateInvPurchaseDetail from "../components/UpdateInvPurchaseDetail";

const PurchaseOrder = () => {
  const {
    data: getMasterData,
    isLoading,
    refetch,
  } = useGetInvPurchaseOrderQuery();
  const [createpo, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreatePurchaseOrderMutation();
  const [
    updatepo,
    { data: updatepoData, isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdatePurchaseOrderMutation();
  const [savePodetail, { data: getdatapoDetail, isLoading: poDetailLoading }] =
    useGetInvPurchaseDetailbyMasterMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const [tabledetail, setTabledetail] = useState([]);
  const [showAddModaldetail, setShowAddModaldetail] = useState(false);
  const [showUpdateModalDetail, setShowUpdateModalDetail] = useState(false);
  const [
    createpodetail,
    {
      data: getpodetdata,
      isSuccess: addSuccessdetail,
      isLoading: addLoadingdetail,
    },
  ] = useCreatePurchaseOrderDetMutation();
  const [
    updatepodetail,
    {
      data: getpodetupdate,
      isSuccess: updatedetailSuccess,
      isLoading: updatedetailLoading,
    },
  ] = useUpdPurchaseOrderDetMutation();
  useEffect(() => {
    if (getMasterData) {
      setTableDatainfo(getMasterData?.data);
    }
  }, [getMasterData]);
  useEffect(() => {
    if (getdatapoDetail) {
      setTabledetail(getdatapoDetail?.data);
    }
  }, [getdatapoDetail]);
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
    handlerefetch();
  }, [addSuccess]); // eslint-disable-line

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
    handlerefetch();
  }, [updateSuccess]); // eslint-disable-line
  useEffect(() => {
    if (addSuccessdetail && getpodetdata) {
      setShowAddModaldetail(false);
      savePodetail({
        purchaseseqnum: getpodetdata?.data[0].purchaseseqnum,
        purchasenum: getpodetdata?.data[0]?.purchasenum,
        message: "B",
      });
    }
  }, [addSuccessdetail]); // eslint-disable-line

  useEffect(() => {
    if (updatedetailSuccess && getpodetupdate) {
      setShowUpdateModalDetail(false);
      savePodetail({
        purchaseseqnum: getpodetupdate?.data.purchaseseqnum,
        purchasenum: getpodetupdate?.data?.purchasenum,
        message: "B",
      });
    }
  }, [updatedetailSuccess]); // eslint-disable-line
  const tableColumns = [
    {
      title: "Purchase No.",
      dataIndex: "purchasenum",
      ...filter("purchasenum"),
    },
    // {
    //   title: "itemname",
    //   dataIndex: "descr",
    //   ...filter("descr"),
    // },
    {
      title: "Purchase Date ",
      dataIndex: "purchasedate ",
      render: (_, record) => (
        <div>{moment(record?.purchasedate)?.format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Quotation No.",
      dataIndex: "quotnum",
      ...filter("quotnum"),
    },
    {
      title: "Quotation Date",
      dataIndex: "quotdate ",
      render: (_, record) => (
        <div>{moment(record?.quotdate)?.format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorname",
      ...filter("vendorname"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (_, record) => (
        <div className=" flex gap-1">
          <Tooltip title="Update PO">
            <Button
              type="primary"
              className="btn-warning-primary"
              icon={<EditFilled />}
              disabled={record?.status !== "Y"}
              onClick={() => {
                setRowData(record);
                setShowUpdateModalDetail(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Add PO Detail">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={record?.status !== "Y"}
              onClick={() => {
                setRowData(record);
                setShowAddModaldetail(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const tableColumnsDetail = [
    {
      title: "Iitem Name",
      dataIndex: "itemname",
      ...filter("itemname"),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
    },
    {
      title: "Last Quantity",
      dataIndex: "lastrcvqty",
    },
    {
      title: "currrcvqty",
      dataIndex: "currrcvqty",
    },
    {
      title: "Total Received Qty",
      dataIndex: "totalreceivedqty",
    },
    {
      title: "Rate",
      dataIndex: "rate",
    },
    // {
    //   title: "Retail Price",
    //   dataIndex: "retailprice",
    // },
    {
      title: "Discount Amount",
      dataIndex: "discountamount",
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update PO">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handlerefetch = () => {
    refetch();
  };
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Purchase Order</h1>
        <Tooltip title="Add New PO">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="purchasenum"
          onRow={(record) => ({
            onClick: () => {
              savePodetail({
                purchaseseqnum: record?.purchaseseqnum,
                purchasenum: record?.purchasenum,
                message: "B",
              });
            },
          })}
        />
      </div>
      <div className="p-2">
        <Table
          columns={tableColumnsDetail}
          dataSource={tabledetail}
          pagination={{ defaultPageSize: 5 }}
          loading={poDetailLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="itemid"
          onRow={(record) => ({
            onClick: () => {},
          })}
        />
      </div>
      <Modal
        title="Add PO"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={700}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addPOForm"
            loading={addLoadingdetail}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddPurchaseOrder createpo={createpo} handlerefetch={handlerefetch} />
      </Modal>

      <Modal
        title="Update PO"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatePOForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdatePurchaseOrder
          rowData={rowData}
          updatepoData={updatepoData}
          updatepo={updatepo}
        />
      </Modal>
      <Modal
        title="Add PO Detail"
        open={showAddModaldetail}
        onCancel={() => setShowAddModaldetail(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addpodetailForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddInvPurchaseDetail
          rowData={rowData}
          createpodetail={createpodetail}
          handlerefetch={handlerefetch}
        />
      </Modal>

      <Modal
        title="Update PO Detail"
        open={showUpdateModalDetail}
        onCancel={() => setShowUpdateModalDetail(false)}
        centered
        width={1200}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatepodetail"
            loading={updatedetailLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModalDetail(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateInvPurchaseDetail
          rowData={rowData}
          updatepodetail={updatepodetail}
        />
      </Modal>
    </section>
  );
};

export default PurchaseOrder;
