import EmployeeConfigurators from "../features/employee/components/EmployeeConfigurators";
import EmployeeRegistration from "../features/employee/pages/EmployeeRegistration";

export const employeeRoutes = [
  {
    path: "employeeconfigurators",
    element: <EmployeeConfigurators />,
  },
  {
    path: "employeeregistration",
    element: <EmployeeRegistration />,
  },
];
