import { Button, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { useCreateBedFacilitiesMutation, useGetBedRemainingAccessoriesMutation } from '../../wardConfiguratorsService';

const AddFacilites=({bedaccessories,handlecancel})=> {
  const [selectedRows, setSelectedRows] = useState([]);
  const [createbedfacilites, { isSuccess: addbedSuccess, isLoading: addbedLoading }] = useCreateBedFacilitiesMutation();

// console.log("bedaccessories",bedaccessories)
    const [getwardremaccessories, { data: wardremaccessories,isLoading: raLoading }] = useGetBedRemainingAccessoriesMutation();
console.log("selectedRows",selectedRows)
useEffect(()=>{
  if(bedaccessories)
  {
    getwardremaccessories({
      unit: bedaccessories?.unit,
      bednum:bedaccessories?.bednum,
    });
  }
},[bedaccessories])//eslint-disable-line
useEffect(() => {
  if (addbedSuccess) handlecancel();
}, [addbedSuccess]); // eslint-disable-line
    const Columns = [
        {
          title: "Bed Num",
          dataIndex: "bednum",
          render:()=>
          (bedaccessories?.bednum)
          
        },
    
        {
          title: "Description",
          dataIndex: "descr",
        },
    
        {
          title: "Bed Type",
          dataIndex: "type",
          render:()=>
          (bedaccessories?.type)
        },
      
  
        {
          title: "Status",
          render: (_, { status }) =>
            status === "Y" ? (
              <Tag color="success">Active</Tag>
            ) : (
              <Tag color="error">In Active</Tag>
            ),
        //   sorter: (a, b) => a?.status?.localeCompare(b?.status),
        },
        
  ];

  const onFinish = (values) =>{
    
    createbedfacilites(selectedRows?.map((item) => ({
      unit: bedaccessories?.unit,
      bednum: bedaccessories?.bednum,
      facilitycode: item?.facilitycode,
      facilitytype: item?.facilitytype,
      message: 'B',
    })));

      // handlerefetch()
    }
    const rowSelection={
      onChange: (selectedRowKeys, selectedRows) => setSelectedRows(selectedRows),
    }
  return (
   

<div>
        <Table
         dataSource={wardremaccessories?.data}
          columns={Columns}
          onFinish={onFinish}
          id='addFacilitesform'
          rowSelection={rowSelection}
         
          pagination={{ defaultPageSize: 3 }}
        loading={raLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='facilitycode'
          onRow={(record) => {
            return {
              onClick: () => { 

              },
            };
          }}
        />
        <div className='flex justify-end items-start'>
        <Button
            type='primary'
            onClick={onFinish}
          >
            Add
          </Button>,
          <Button key='3' onClick={() => handlecancel()}>
            Cancel
          </Button>
        </div>
      </div>
  )
}

export default AddFacilites