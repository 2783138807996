import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useTableFilters from "../../../hooks/useTableFilter";
import AddDept from "../modals/AddDept";
import UpdateDept from "../modals/UpdateDept";
import {
  useCreateHmsDepartmentMutation,
  useGetHmsdepartmentQuery,
  // useLovHmsDepartmentQuery,
  useUpdateHmsDepartmentMutation,
} from "../employeeServices";

const DeptPage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const { data: getHmsDept, refetch } = useGetHmsdepartmentQuery();
  // const { data: getlovdept } = useLovHmsDepartmentQuery();
  const [saveDept, { isSuccess: deptSuccess }] =
    useCreateHmsDepartmentMutation();
  const [updatedeptdata, { isSuccess: deptupdateSuccess }] =
    useUpdateHmsDepartmentMutation();
  useEffect(() => {
    if (getHmsDept) {
      setTableDatainfo(getHmsDept?.data);
    }
  }, [getHmsDept]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (deptSuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [deptSuccess]); //eslint-disable-line
  useEffect(() => {
    if (deptupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [deptupdateSuccess]); //eslint-disable-line
  const tableColumns = [
    {
      title: "Dept Code",
      dataIndex: "deptcode",
      ...filter("deptcode"),
    },
    {
      title: "Dept name",
      dataIndex: "deptname",
      ...filter("deptname"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Dept">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Dept</h1>
        <Tooltip title="Add Dept ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          //   loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="deptcode"
        />
      </div>
      <Modal
        title="Add Dept "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="adddept"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddDept
          saveDept={saveDept}
          // getlovdept={getlovdept}
        />
      </Modal>

      <Modal
        title="Update Dept"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatedept"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateDept
          rowData={rowData}
          updatedeptdata={updatedeptdata}
          // getlovdept={getlovdept}
        />
      </Modal>
    </section>
  );
};

export default DeptPage;
