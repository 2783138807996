import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const PublicRoutes = () => {
  const loginStatus = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();

  return !loginStatus ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PublicRoutes;
