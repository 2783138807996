import React, { useEffect, useState } from "react";
import {
  useCreateInvStockMutation,
  useGetInvStockQuery,
  useLovItemListForStockQuery,
  // useLovItemListQuery,
  useUpdateInvStockMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import AddInvStock from "../components/AddInvStock";
import UpdateInvStock from "../components/UpdateInvStock";

const InvStock = () => {
  const { data, isLoading, refetch } = useGetInvStockQuery();
  const { data: lovItemList } = useLovItemListForStockQuery();
  const [createinvstock, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateInvStockMutation();
  const [
    updatestock,
    {
      data: updatestockData,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
    },
  ] = useUpdateInvStockMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  useEffect(() => {
    if (data) {
      setTableDatainfo(data?.data);
    }
  }, [data]);
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
    handlerefetch();
  }, [addSuccess]); // eslint-disable-line

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
    handlerefetch();
  }, [updateSuccess]); // eslint-disable-line
  const tableColumns = [
    {
      title: "Item Name",
      dataIndex: "itemname",
      ...filter("itemname"),
    },
    {
      title: "Item Description",
      dataIndex: "item_name",
      ...filter("item_name"),
    },
    {
      title: "Current Quantity",
      dataIndex: "currentquantity",
      ...filter("currentquantity"),
    }, //purchaseprice
    {
      title: "Purchase Price",
      dataIndex: "purchaseprice",
      ...filter("purchaseprice"),
    },
    {
      title: "Sale Price",
      dataIndex: "currentprice",
      ...filter("currentprice"),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Inventory Stock">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handlerefetch = () => {
    refetch();
  };
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Inventory Stock</h1>
        <Tooltip title="Add New Stock ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="itemid"
        />
      </div>
      <Modal
        title="Add Stock"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addInvStockForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddInvStock
          createinvstock={createinvstock}
          handlerefetch={handlerefetch}
          lovItemList={lovItemList}
        />
      </Modal>

      <Modal
        title="Update Stock"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateInvStockForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateInvStock
          rowData={rowData}
          updatestockData={updatestockData}
          updatestock={updatestock}
        />
      </Modal>
    </section>
  );
};

export default InvStock;
