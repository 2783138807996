import { Table } from "antd";
import useTableFilters from "../../../hooks/useTableFilter";
import { useGetProjectEqptListQuery } from "../securityService";

const ProjectEqptList = () => {
  const { data, isLoading } = useGetProjectEqptListQuery();

  const filter = useTableFilters();

  const tableColumns = [
    {
      title: "Sr#",
      dataIndex: "srno",
      ...filter("srno"),
      sorter: (a, b) => a?.srno?.localeCompare(b?.srno),
    },
    {
      title: "Project",
      dataIndex: "project",
      ...filter("project"),
      sorter: (a, b) => a?.project?.localeCompare(b?.project),
    },
    {
      title: "Type",
      dataIndex: "type",
      ...filter("type"),
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
    },
    {
      title: "Model",
      dataIndex: "model",
      ...filter("model"),
      sorter: (a, b) => a?.model?.localeCompare(b?.model),
    },
    {
      title: "Make",
      dataIndex: "make",
      ...filter("make"),
      sorter: (a, b) => a?.make?.localeCompare(b?.make),
    },
    {
      title: "Held",
      dataIndex: "held",
      ...filter("held"),
      sorter: (a, b) => a?.held?.localeCompare(b?.held),
    },
    {
      title: "Firm Name",
      dataIndex: "firmname",
      ...filter("firmname"),
      sorter: (a, b) => a?.firmname?.localeCompare(b?.firmname),
    },

    {
      title: "Eqp Type",
      dataIndex: "eqptype",
      ...filter("eqptype"),
      sorter: (a, b) => a?.eqptype?.localeCompare(b?.eqptype),
    },
    {
      title: "Eqp Name",
      dataIndex: "eqpname",
      ...filter("eqpname"),
      sorter: (a, b) => a?.eqpname?.localeCompare(b?.eqpname),
    },

    {
      title: "Eqpfe",
      dataIndex: "eqpfe",
      ...filter("eqpfe"),
      sorter: (a, b) => a?.eqpfe?.localeCompare(b?.eqpfe),
    },
    {
      title: "Eqp Cost",
      dataIndex: "eqpcost",
      ...filter("eqpcost"),
      sorter: (a, b) => a?.eqpcost?.localeCompare(b?.eqpcost),
    },

    {
      title: "Deptt Held",
      dataIndex: "depttheld",
      ...filter("depttheld"),
      sorter: (a, b) => a?.depttheld?.localeCompare(b?.depttheld),
    },
    {
      title: "Date Receipt",
      dataIndex: "datereceipt",
      ...filter("datereceipt"),
      sorter: (a, b) => a?.datereceipt?.localeCompare(b?.datereceipt),
    },

    {
      title: "Date Install",
      dataIndex: "dateinstall",
      ...filter("dateinstall"),
      sorter: (a, b) => a?.dateinstall?.localeCompare(b?.dateinstall),
    },
    {
      title: "Crv #",
      dataIndex: "crvno",
      ...filter("crvno"),
      sorter: (a, b) => a?.crvno?.localeCompare(b?.crvno),
    },
    {
      title: "Country",
      dataIndex: "country",
      ...filter("country"),
      sorter: (a, b) => a?.country?.localeCompare(b?.country),
    },
    {
      title: "Contract Date",
      dataIndex: "contractdate",
      ...filter("contractdate"),
      sorter: (a, b) => a?.contractdate?.localeCompare(b?.contractdate),
    },
    {
      title: "Auth",
      dataIndex: "auth",
      ...filter("auth"),
      sorter: (a, b) => a?.auth?.localeCompare(b?.auth),
    },
    {
      title: "Accessories",
      dataIndex: "accessories",
      ...filter("accessories"),
      sorter: (a, b) => a?.accessories?.localeCompare(b?.accessories),
    },
    {
      title: "Status",
      dataIndex: "status",
      ...filter("status"),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    // {
    //   title: 'Status',
    //   render: (_, { status }) =>
    //     status === 'svcable' ? <Tag color='green'>svcable</Tag> : <Tag color='red'>In Active</Tag>,
    //   sorter: (a, b) => a?.status?.localeCompare(b?.status),
    // },
  ];

  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">
          Project Equipment List
        </h1>
      </div>

      <div>
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 10 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="role"
        />
      </div>
    </section>
  );
};

export default ProjectEqptList;
