import { useEffect, useState } from "react";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tooltip } from "antd";
import {
  useGetOrganizationStructureQuery,
  useSaveOrgUnitMutation,
  useUpdateOrgUnitMutation,
} from "../orgStructureService";
import useTableFilters from "../../../hooks/useTableFilter";
import AddOrgStructure from "../components/OrgStructure/AddOrgStructure";
import UpdateOrgStructure from "../components/OrgStructure/UpdateOrgStructure";

const OrgStructure = () => {
  const { data, isLoading } = useGetOrganizationStructureQuery();
  const [saveOrgUnit, { isSuccess: addSuccess, isLoading: addLoading }] =
    useSaveOrgUnitMutation();
  const [
    updateOrgUnit,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateOrgUnitMutation();
  const [rowData, setRowData] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Org Id",
      dataIndex: "orgunitid",
      ...filter("orgunitid"),
      sorter: (a, b) => a?.orgunitid - b?.orgunitid,
      defaultSortOrder: "descend",
    },
    {
      title: "Org Strcuture",
      dataIndex: "orgbreadcrum",
      ...filter("orgbreadcrum"),
      sorter: (a, b) => a?.orgbreadcrum?.localeCompare(b?.orgbreadcrum),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Org Structure">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-between px-5 py-3 bg-lotion border-b border-gainsboro">
        <h1 className="font-semibold text-base sm:text-lg">
          Organizational Structure
        </h1>
        <Tooltip title="Add New Org Structure">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="orgunitid"
        />
      </div>

      <Modal
        title="Add Organizational Structure"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1400}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addOrgStructureForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddOrgStructure saveOrgUnit={saveOrgUnit} />
      </Modal>

      <Modal
        title="Update Organizational Structure"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1400}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateOrgStructureForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateOrgStructure rowData={rowData} updateOrgUnit={updateOrgUnit} />
      </Modal>
    </section>
  );
};

export default OrgStructure;
