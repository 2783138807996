import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddManufacturer = ({createmanufacturer}) => {
  const onFinish = (values) =>{
    createmanufacturer({
        ...values,
       
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addManufacturerForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-2 gap-2'
  >
    <Form.Item
      name='genericname'
      label='Manufacturer Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter  Manufacturer Name '/>
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}

export default AddManufacturer