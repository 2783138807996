import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useTableFilters from "../../../hooks/useTableFilter";
import {
  useCreateHmsDesignationMutation,
  useGetHmsdesignationQuery,
  // useLovHmsDesignationQuery,
  useUpdateHmsDesignationMutation,
} from "../employeeServices";
import AddDesignation from "../modals/AddDesignation";
import UpdateDesignation from "../modals/UpdateDesignation";

const DesignationPage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const { data: getHmsDesignation, refetch } = useGetHmsdesignationQuery();
  // const { data: getlovdesignation } = useLovHmsDesignationQuery();
  const [saveDesignation, { isSuccess: designationSuccess }] =
    useCreateHmsDesignationMutation();
  const [updatedesignationdata, { isSuccess: designationupdateSuccess }] =
    useUpdateHmsDesignationMutation();
  useEffect(() => {
    if (getHmsDesignation) {
      setTableDatainfo(getHmsDesignation?.data);
    }
  }, [getHmsDesignation]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (designationSuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [designationSuccess]); //eslint-disable-line
  useEffect(() => {
    if (designationupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [designationupdateSuccess]); //eslint-disable-line
  const tableColumns = [
    {
      title: "Designation Code",
      dataIndex: "desigcode",
      ...filter("desigcode"),
    },
    {
      title: "Designation name",
      dataIndex: "designame",
      ...filter("designame"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Designation">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Designation</h1>
        <Tooltip title="Add Designation ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          //   loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="desigcode"
        />
      </div>
      <Modal
        title="Add Designation "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="adddesignation"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddDesignation
          saveDesignation={saveDesignation}
          // getlovdesignation={getlovdesignation}
        />
      </Modal>

      <Modal
        title="Update Designation"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatedesignation"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateDesignation
          rowData={rowData}
          updatedesignationdata={updatedesignationdata}
          // getlovdesignation={getlovdesignation}
        />
      </Modal>
    </section>
  );
};

export default DesignationPage;
