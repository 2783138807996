import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useTableFilters from "../../../hooks/useTableFilter";

import AddTitle from "../modals/AddTitle";
import UpdateTitle from "../modals/UpdateTitle";
import {
  useCreateHmsTitleMutation,
  useGetHmsTitleQuery,
  // useLovHmsTitleQuery,
  useUpdateHmsTitleMutation,
} from "../employeeServices";
const TitlePage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const { data: getHmsTitle, refetch } = useGetHmsTitleQuery();
  // const { data: getlovtitle } = useLovHmsTitleQuery();
  const [saveTitle, { isSuccess: titleSuccess }] = useCreateHmsTitleMutation();
  const [updatetitledata, { isSuccess: titleupdateSuccess }] =
    useUpdateHmsTitleMutation();
  useEffect(() => {
    if (getHmsTitle) {
      setTableDatainfo(getHmsTitle?.data);
    }
  }, [getHmsTitle]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (titleSuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [titleSuccess]); //eslint-disable-line
  useEffect(() => {
    if (titleupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [titleupdateSuccess]); //eslint-disable-line
  const tableColumns = [
    {
      title: "Title ID",
      dataIndex: "tid",
      ...filter("tid"),
    },
    {
      title: "Description",
      dataIndex: "description",
      ...filter("description"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Title">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Title</h1>
        <Tooltip title="Add Title ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          //   loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="tid"
        />
      </div>
      <Modal
        title="Add Title "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addtitle"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddTitle
          saveTitle={saveTitle}
          // getlovtitle={getlovtitle}
        />
      </Modal>

      <Modal
        title="Update Title"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatetitle"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateTitle
          rowData={rowData}
          updatetitledata={updatetitledata}
          // getlovtitle={getlovtitle}
        />
      </Modal>
    </section>
  );
};

export default TitlePage;
