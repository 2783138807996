import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { Button,  Form,  Modal,  Select,  Table, Tag, Tooltip } from 'antd'
import { useEffect, useState } from 'react';
import AddNomncltreControl from './AddNomncltreControl';
import AddNomncltreDetails from './AddNomncltreDetails';
import { useGetBedNomncltreMutation, useLovWardListQuery, useUpdateWardNomncltreStatusMutation } from '../../wardConfiguratorsService';


function NomncltreControl() {
    const [rowData, setRowData] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const { data: lovWardList } = useLovWardListQuery();
    const [bednomncltre, { data: getBedNomncltre }] = useGetBedNomncltreMutation();
    const [ updatenomncltrestatus, { isSuccess: updateSuccess, isLoading: updateLoading },
    ] = useUpdateWardNomncltreStatusMutation();
    const [unitname,setUnitname]=useState(null)
    useEffect(() => {
      if (updateSuccess) setShowUpdateModal(false);
    }, [updateSuccess]); // eslint-disable-line
    
    const tableColumns = [

      {
        title: "Nomncltre",
        dataIndex: "nomncltre",
        //  ...filter("type"),
        // sorter: (a, b) => a?.type - b?.type,
        // defaultSortOrder: "descend",
      },
      {
        title: "Description of Nomncltre",
        dataIndex: "descr",
        // ...filter("desc"),
        // sorter: (a, b) => a?.desc?.localeCompare(b?.desc),
      },
      {
        title: "Type",
        dataIndex: "type",
        //  ...filter("type"),
        // sorter: (a, b) => a?.type - b?.type,
        // defaultSortOrder: "descend",
      },
      {
        title: "NC Charges",
        dataIndex: "ncchg",
    
      },
      
      {
        title: "MO Charges",
        dataIndex: "mochg",
        
      },
      {
        title: "Bed/Room Charges",
        dataIndex: "bedchg",
       
      },
      {
        title: "Food Charges",
        dataIndex: "foodchg",
        
      },
      {
        title: "Addmission Charges",
        dataIndex: "adchg",
       
      },
      {
        title: "Status",
        render: (_, { status }) =>
          status === "Y" ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">In Active</Tag>
          ),
        // sorter: (a, b) => a?.status?.localeCompare(b?.status),
      },
      {
        title: "Action(s)",
        render: (data) => (
          <Tooltip title="Update Status">
            <Button
              type="primary"
              className="btn-warning-primary"
              icon={<EditFilled />}
              onClick={() => {
                setRowData(data);
                setShowUpdateModal(true);
              }}
            />
          </Tooltip>
        ),
      },
    ];
    
  return (
   <div>
    <div  className='border-gainsboro p-2 '>
    <Form className='w-80 '
    layout='vertical'>
      <Form.Item
                name="unit"
                label="Ward"
              >
                <Select
          placeholder='Select Ward'
          onChange={(value)=>{
            setUnitname(value)
            bednomncltre({
                  unit: value,
                  message: "B",
                });
          }}
          options={lovWardList?.data?.map((item) => ({
            value: item?.code,
            label: item?.descr,
          }))}
        />
        </Form.Item>
    </Form>
  
      <div className='flex items-center justify-end'
      >
      <Tooltip title="Add ">
        <Button
          type="primary"
          icon={<PlusOutlined />}
        onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
      </div>
    </div>
    
    <div className="p-2">
      <Table
         dataSource={getBedNomncltre?.data}
        columns={tableColumns}
        pagination={{ defaultPageSize: 5 }}
        // loading={isLoading}
        bordered
        size="small"
        rowKey='type'
          
      />
    </div>

    <Modal
      title="Add Nomncltre "
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addNomncltreForm"
          // loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
        <Button key="2" onClick={() => setShowAddModal(false)}>
          Cancel
        </Button>,
      ]}
    >
    
      <AddNomncltreControl onCancel={() => setShowAddModal(false)} unitname={unitname} bednomncltre={bednomncltre}/>
    </Modal>

    <Modal
      title="Update Nomncltre"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="UpdateNomncltreStatus"
          loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <AddNomncltreDetails
        rowData={rowData}
        updatenomncltrestatus={updatenomncltrestatus}
      />
    </Modal>
    </div>
 
  )
}

export default NomncltreControl