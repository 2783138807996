import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddServiceCategoryPrice from '../components/AddServiceCategoryPrice'
import { useCreateServicePriceCategoryMutation, useGetSrviceAmountQuery } from '../employeeServices'

const PriceConfig=()=> {
  const { data, isLoading,refetch} = useGetSrviceAmountQuery();
  const [createserviceprice, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateServicePriceCategoryMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([]);
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title:"Service ID",
      dataIndex: "srvid",
   
    },

    {
      title:"Service Name",
      dataIndex: "servicename",
     
   
    },
    {
      title:"Amount",
      dataIndex: "amount",
      
   
    },
    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      
    },
    
    
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
   <section>
       <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Service Category Price</h1>
        <Tooltip title="Add Service">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
         columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
   
          
        />
      </div>
      <Modal
      title="Add Service Category Price"
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addServiceCategoryPriceForm"
        loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
      
      ]}
    >
<AddServiceCategoryPrice
    createserviceprice={createserviceprice} 
    handlerefetch={handlerefetch}
    />
    </Modal>
   </section>
  )
}

export default PriceConfig