import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddInventoryType = ({createtype}) => {
  const onFinish = (values) =>{
    createtype({
        ...values,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addtypeForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-2 gap-2'
  >
    <Form.Item
      name='typename'
      label='Inventory Type '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Inventory Type'/>
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}

export default AddInventoryType