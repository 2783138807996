import { PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddManufacturer from './AddManufacturer'
import useTableFilters from '../../../hooks/useTableFilter'
import { useCreateSOManufecturerMutation, useGetAllManufecturerQuery } from '../storeConfiguratorsService'

const Manufacturer=() =>{
  const [showAddModal, setShowAddModal] = useState(false);
  const { data, isLoading,refetch} = useGetAllManufecturerQuery();
  const [createmanufecturer, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateSOManufecturerMutation();
  const[tableDatainfo,setTableDatainfo]=useState([]);
  const filter = useTableFilters();
  
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  const tableColumns = [

    {
      title:"Manufacturer Name",
      dataIndex: "name",
      ...filter("name"),
   
    },
    
      {
        title: "Company Name",
        dataIndex: "companyname",
         ...filter("companyname"),
    
      },
      
    {

      title: "Company Cell No.",
      dataIndex: "companycellno",
       ...filter("companycellno"),
     
    },
   
   
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Manage Manufacturer</h1>
        <Tooltip  title="Add New Manufacturer">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
         columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
   
          
        />
      </div>
      <Modal
      title="Add Manufacturer"
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addManufacturerForm"
          loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
      
      ]}
    >
<AddManufacturer
    createmanufecturer={createmanufecturer} 
    handlerefetch={handlerefetch}
    />
    </Modal>

    </section>
  )
}

export default Manufacturer