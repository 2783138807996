import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddInvStock = ({ lovItemList, createinvstock }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    createinvstock({
      ...values,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        id="addInvStockForm"
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item name="itemname" label="Item ID" className=" hidden">
          <Input readOnly />
        </Form.Item>
        <Form.Item
          name="itemid"
          label="Item Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Item Name"
            onChange={(value) => {
              const selectedOption = lovItemList?.data?.find(
                (item) => item?.itemid === value
              );
              form.setFieldsValue({
                itemname: selectedOption.item,
                currentprice: selectedOption.price,
              });
            }}
            options={lovItemList?.data?.map((item) => ({
              value: item?.itemid,
              label: `${item?.item_name}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="currentquantity"
          label="Current Quantity"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Current Quantity" />
        </Form.Item>
        <Form.Item
          name="purchaseprice"
          label="Purchase Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Purchase Price" />
        </Form.Item>
        <Form.Item
          name="currentprice"
          label="Sale Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly placeholder="Enter Sale Price" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddInvStock;
