import { Form, Input } from 'antd';
import formValidationMessages from '../../utils/formValidationMessages';

const ChangePasswordModal = ({ changePassword }) => {
  const onFinish = values => changePassword({ ...values, message: 'B' });

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      id='passwordChangeForm'
      validateMessages={formValidationMessages}
      className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
      <Form.Item
        name='oldPassword'
        label='Current Password'
        rules={[
          {
            required: true,
          },
        ]}>
        <Input.Password placeholder='Enter Current Password' autoComplete='off' />
      </Form.Item>

      <Form.Item
        name='password'
        label='New Password'
        rules={[
          {
            required: true,
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
            message:
              'Password must be atleast 10 characters long & contain atleast one lowercase, one uppercase, one numeric & one special character',
          },
        ]}
        required>
        <Input.Password placeholder='Enter New Password' autoComplete='off' />
      </Form.Item>

      <Form.Item
        name='confirm_pass'
        label='Confirm New Password'
        rules={[
          {
            required: true,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) return Promise.resolve();
              return Promise.reject(new Error('Passwords do not match!'));
            },
          }),
        ]}
        required>
        <Input.Password placeholder='Confirm New Password' autoComplete='off' />
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordModal;
