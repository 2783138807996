import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../authService";
import { loginSuccess, logoutUser } from "../authSlice";
import { useEffect } from "react";
import { api } from "../../api/api";

const LoginUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginUser, { data }] = useLoginMutation();
  //first time only
  const location = useLocation();
  console.log("location.pathname", location.pathname);
  useEffect(() => {
    if (location.pathname === "/login") {
      dispatch(logoutUser());
      dispatch(api.util.resetApiState());
    }
  }, [location.pathname, dispatch]);
  useEffect(() => {
    // First-time API call with static values
    loginUser({
      username: "userproducts",
      password: "Pakistan12@12",
      message: "E",
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (data) {
      dispatch(loginSuccess(data));
      navigate("/");
    }
  }, [data]); // eslint-disable-line

  return;
};

export default LoginUsers;
