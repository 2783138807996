import { Form, Input, Select } from 'antd'
import {
  useLovDepartmentQuery,
  useLovOrganizationQuery,
} from '../../orgStructureService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddSubDepartment = ({ createSubDepartment }) => {
  const { data: lovOrganization } = useLovOrganizationQuery()
  const { data: lovDepartment } = useLovDepartmentQuery()

  const onFinish = (values) =>
    createSubDepartment({
      ...values,
      message: 'B',
    })

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='addSubDepartmentForm'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
        <Form.Item name='orgId' label='Organization'>
          <Select
            placeholder='Select Organization'
            options={lovOrganization?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='deptid' label='Department'>
          <Select
            placeholder='Select Department'
            options={lovDepartment?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='sdeptname' label='Sub Department Name'>
          <Input placeholder='Enter Sub Department Name' />
        </Form.Item>
        <Form.Item name='sdeptabbrv' label='Sub Department Abbreviation'>
          <Input placeholder='Enter Sub Department Abbreviation' />
        </Form.Item>
        <Form.Item name='sdeptstatus' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default AddSubDepartment
