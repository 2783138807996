import { Form, Input } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

export const AddStrength = ({createmedstrength}) => {
  const onFinish = (values) =>{
    createmedstrength({
        ...values,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addstrengthForm'
    validateMessages={formValidationMessages}
    className=''
  >
    <Form.Item
      name='strength'
      label='Medicine Strength'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Medicine Strength'/>
    </Form.Item>
   
   
  </Form>
    </div>
  )
}
