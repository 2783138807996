import { Form, Input, Select } from 'antd';
import React from 'react'

const UpdateBedInfo=({rowData,updatebedinfo}) =>{
    const [form] = Form.useForm();
    const onFinish = (values) =>{
        updatebedinfo({
            ...values,
            status: values?.status,
           descr:values?.descr,
           available:values?.available,
            bednum:rowData?.bednum,
            unit:rowData?.unit,
            message: 'B',
          })
        }
  return (
    <Form
    form={form}
    initialValues={{
        bednum:rowData?.bednum,
      descr:rowData?.descr,
      status:rowData?.status,
      type:rowData?.type,
      extbed:rowData?.extbed,
    }}
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='updatebedinfoform'>
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>

    <Form.Item name='bednum' label='Bed Num'>
        <Input disabled></Input>
      </Form.Item>

      <Form.Item name='descr' label='Description'>
        <Input disabled></Input>
      </Form.Item>

      <Form.Item name='type' label='Bed Type'>
        <Select disabled
         
        //   options={lovbedtype?.data?.map((item) => ({
        //     value: item?.type,
        //     label: item?.descr,
        //   }))}
        />
      </Form.Item>
   
      <Form.Item name='extbed' label='Extra Bed'>
        <Select disabled
          placeholder='Select Extra Bed'
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
          
        />
      </Form.Item>
      <Form.Item name='available' label='Available'>
        <Select disabled
          placeholder='Select Availability'
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
          
        />
      </Form.Item>
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
        />
      </Form.Item>
    </div>
    
  </Form>
  )
}

export default UpdateBedInfo