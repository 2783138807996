import { Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../../utils/formValidationMessages';
import { useLovbedtypeQuery } from '../../wardConfiguratorsService';

const AddBedsMaintenanceDetails=({createbedinfo,bedinfo}) =>{

  const onFinish = (values) =>{
    createbedinfo([{     
       ...values,
       unit:bedinfo?.unit,
        message: 'B',

      }])
      // handlerefetch()
    }
    const [form] = Form.useForm();
    const { data: lovbedtype } = useLovbedtypeQuery(); 
  return (
    <Form
    form={form}
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='addBedForm'
    initialValues={{
      bednum:bedinfo?.bednum,
    }}
    validateMessages={formValidationMessages}>
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>

    <Form.Item name='bednum' label='Bed Num'>
        <Input></Input>
      </Form.Item>

      <Form.Item name='descr' label='Description'>
        <Input></Input>
      </Form.Item>

      <Form.Item name='type' label='Bed Type'>
        <Select
          placeholder='Select Bed Type'
          options={lovbedtype?.data?.map((item) => ({
            value: item?.type,
            label: item?.descr,
          }))}
        />
      </Form.Item>
   
      <Form.Item name='extbed' label='Extra Bed'>
        <Select
          placeholder='Select Extra Bed'
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
          
        />
      </Form.Item>
      <Form.Item name='available' label='Available'>
        <Select
          placeholder='Select Availability'
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
          
        />
      </Form.Item>
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
        />
      </Form.Item>



     
    </div>
    
  </Form>
  )
}

export default AddBedsMaintenanceDetails