import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateDept = ({ rowData, updatedeptdata, getlovdept }) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updatedeptdata({
      deptid: values?.deptid,
      deptcode: values?.deptcode,
      deptname: values?.deptname,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatedept"
        form={form}
        initialValues={{
          deptname: rowData?.deptname,
          deptid: rowData?.deptid,
          deptcode: rowData?.deptcode,
          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="deptid"
          label="Dept ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled="true" />
        </Form.Item>
        <Form.Item name="deptcode" label="Dept Code">
          <Input />
        </Form.Item>
        <Form.Item name="deptname" label="Dept Name">
          {/* <Select
            placeholder="Select City"
            onChange={(value) => {
              const selectedOption = getlovdept?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                deptcode: selectedOption?.code,
              });
            }}
            options={getlovdept?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateDept;
