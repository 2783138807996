import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovSrviceListQuery } from '../employeeServices';

const AddServiceCategoryPrice=({createserviceprice})=> {
    const onFinish = (values) =>{
        createserviceprice({
            ...values,
            message: 'B',
          })
        }
    const { data: lovSrviceList } = useLovSrviceListQuery();
  return (
    <div>
    <Form
    layout='vertical'
onFinish={onFinish}
    id='addServiceCategoryPriceForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-2 gap-2'
  >
    
  
   
    <Form.Item
                name="srvid"
                label="Service List"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Service"
                  options={lovSrviceList?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
                <Form.Item
      name='amount'
      label='Amount'
    >
      <Input placeholder='Enter Amount '
      />
    </Form.Item>


  </Form>
    </div>
  )
}

export default AddServiceCategoryPrice