import DashboardPage from "../features/store/pages/DashboardPage";
import MainStore from "../features/store/pages/MainStore";

export const storeRoutes = [
	{
		path: 'MainStore',
		element: <MainStore/>,
		
	},

	{
		path: 'DashboardPage',
		element: <DashboardPage/>,
		
	},
	
]
