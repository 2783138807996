import { DatePicker, Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovManufacturerQuery, useLovMedStrengthQuery, useLovMedtypeQuery, useLovrackQuery } from '../storeConfiguratorsService';

const AddMaterialMaster=({creatematerialmaster}) =>{
    const onFinish = (values) =>{
        creatematerialmaster({
            ...values,
            message: 'B',
          })
        }
    const { data: lovMedType } = useLovMedtypeQuery();
    const { data: lovMedStrength } = useLovMedStrengthQuery();
    const { data: lovMedRack } = useLovrackQuery();
    const { data: lovManufacturer} = useLovManufacturerQuery();
  return (
    <div>
    <Form
    layout='vertical'
     onFinish={onFinish}
    id='addmaterialmasterForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-4 gap-2'
  >
    <Form.Item
      name='medname'
      label='Medicine Name '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Medicine Name '/>
    </Form.Item>
   
    <Form.Item
                name="typeid"
                label="Medicine Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Medicine Type"
                  options={lovMedType?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                name="strengthid"
                label="Medicine Strength"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Medicine Strength"
                  options={lovMedStrength?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="rackid"
                label="Medicine Rack No."
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Medicine Rack No."
                  options={lovMedRack?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="manufactureid"
                label="Manufacture Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Manufacture Name"
                  options={lovManufacturer?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.descr}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
      name='qty'
      label='Quantity '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Quantity '/>
    </Form.Item>

    <Form.Item
      name='actualpercost'
      label='Actual Per Cost '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Actual Per Cost '/>
    </Form.Item>

    <Form.Item
      name='sellprice'
      label='Sell Price '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Sell Price'/>
    </Form.Item>

    <Form.Item
      name='mgdate'
      label='Manufacturer Date '
      rules={[
        {
          required: true,
        },
      ]}
    >
        <DatePicker placeholder='Enter Manufacturer Date' format='DD-MM-YYYY' />
    </Form.Item>

    <Form.Item
      name='expdate'
      label='Expiry Date '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <DatePicker placeholder='Enter Expiry Date' format='DD-MM-YYYY' />
    </Form.Item>
  </Form>
    </div>
  )
}

export default AddMaterialMaster