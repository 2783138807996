import { api } from "../api/api";

export const InventoryPurchaseApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvPurchaseOrder: build.query({
      query: () => `pos/getInvPurchaseOrder`,
    }),

    CreatePurchaseOrder: build.mutation({
      query: (body) => ({
        url: `pos/createPurchaseOrder`,
        method: "POST",
        body,
      }),
    }),

    UpdatePurchaseOrder: build.mutation({
      query: (body) => ({
        url: `pos/updatePurchaseOrder`,
        method: "POST",
        body,
      }),
    }),

    lovVendr: build.query({
      query: (body) => `pos/lovVendr`,
    }),

    getInvStock: build.query({
      query: () => `pos/getInvStock`,
    }),
    lovItemListForStock: build.query({
      query: () => `pos/lovItemListForStock`,
    }),
    createInvStock: build.mutation({
      query: (body) => ({
        url: `pos/createInvStock`,
        method: "POST",
        body,
      }),
    }),

    updateInvStock: build.mutation({
      query: (body) => ({
        url: `pos/updateInvStock`,
        method: "POST",
        body,
      }),
    }),

    getInvPurchaseDetail: build.query({
      query: () => `pos/getInvPurchaseDetail `,
    }),

    lovPurchaseOrder: build.query({
      query: (body) => `pos/lovPurchaseOrder`,
    }),

    lovItemList: build.query({
      query: (body) => `pos/lovItemList`,
    }),

    lovInvUom: build.query({
      query: (body) => `pos/lovInvUom `,
    }),

    CreatePurchaseOrderDet: build.mutation({
      query: (body) => ({
        url: `pos/createPurchaseorderinsert`,
        method: "POST",
        body,
      }),
    }),
    GetInvPurchaseDetailbyMaster: build.mutation({
      query: (body) =>
        `pos/getInvPurchaseDetailbyMaster/${body.purchaseseqnum}/${body.purchasenum}`, ///${body.itemid}
    }),
    UpdPurchaseOrderDet: build.mutation({
      query: (body) => ({
        url: `pos/updPurchaseOrderDet`,
        method: "POST",
        body,
      }),
    }),
    //purchase receiving
    LovPODetail: build.query({
      query: (body) => `pos/lovPODetail `,
    }),
    CreatePurchaseReceive: build.mutation({
      query: (body) => ({
        url: `pos/CreatePoRcv`,
        method: "POST",
        body,
      }),
    }),
    UpdateCurrentQty: build.mutation({
      query: (body) => ({
        url: `pos/UpdateCurrentQty`,
        method: "POST",
        body,
      }),
    }),
    //Quotation
    GetQutationinfo: build.query({
      query: (body) => `pos/getQutationinfo `,
    }), //
    LovCustomer: build.query({
      query: (body) => `pos/lovCustomer `,
    }),
    LovItemListForQutation: build.query({
      query: (body) => `pos/lovItemListForQutation `,
    }),
    GetQutationdetail: build.mutation({
      query: (body) => `pos/getQutationdetail/${body.quotationno} `,
    }),
    CreateQuotationMaster: build.mutation({
      query: (body) => ({
        url: `pos/createQuotationMaster`,
        method: "POST",
        body,
      }),
    }),
    UpdateQuotationnewrecord: build.mutation({
      query: (body) => ({
        url: `pos/updateQuotationnewrecord`,
        method: "POST",
        body,
      }),
    }),
    CreateQuotationnewrecord: build.mutation({
      query: (body) => ({
        url: `pos/createQuotationnewrecord`,
        method: "POST",
        body,
      }),
    }),
    GetQuotationReport: build.mutation({
      query: (body) => `pos/GetQuotationReport/${body.quotationno} `,
    }),
    //point of sale
    LovItemListforPOS: build.query({
      query: (body) => `pos/lovItemListforPOS `,
    }),
    LovCustomerBalance: build.mutation({
      query: (body) => `pos/lovCustomerBalance/${body.customerid} `,
    }),
    Getposmaster: build.mutation({
      query: (body) => `pos/getposmaster/${body.invnum} `,
    }),
    CreatePOS: build.mutation({
      query: (body) => ({
        url: `pos/createPOS`,
        method: "POST",
        body,
      }),
    }),
    UpdPosmaster: build.mutation({
      query: (body) => ({
        url: `pos/updPosmaster`,
        method: "POST",
        body,
      }),
    }),
    UpdatePosDetailRecord: build.mutation({
      query: (body) => ({
        url: `pos/updatePosDetailRecord`,
        method: "POST",
        body,
      }),
    }),
    Deleteposdetrecord: build.mutation({
      query: (body) => ({
        url: `pos/deleteposdetrecord`,
        method: "POST",
        body,
      }),
    }),
    GetItemSoldHistory: build.mutation({
      query: (body) =>
        `pos/getItemSoldHistory/${body.fromdate}/${body.todate}/${body.itemid}`, ///${body.itemid}
    }),
    GetProfitSaleToday: build.query({
      query: (body) => `pos/getProfitSaleToday `,
    }),
    GetInvoiceinfo: build.query({
      query: (body) => `pos/getInvoiceinfo`,
    }),
    GetInvoicedetailinfo: build.mutation({
      query: (body) => `pos/getInvoicedetailinfo/${body.transactionid}`, ///${body.itemid}
    }),
    //pos
    LovCustomerInvoiceList: build.mutation({
      query: (body) => `pos/lovCustomerInvoiceList/${body.customerid}`, ///${body.itemid}
    }),
    GetPosDetailRecord: build.mutation({
      query: (body) =>
        `pos/getPosDetailRecord/${body.transactionno}/${body.salnum}`, ///${body.itemid}
    }),
    ReturnPOS: build.mutation({
      query: (body) => ({
        url: `pos/returnPOS`,
        method: "POST",
        body,
      }),
    }),
    //inventory items

    GetItemsByID: build.mutation({
      query: (body) => `pos/getItemsByID/${body.itemid}`, ///${body.itemid}
    }),
    //purchase entry
    CreatePurchaseEntry: build.mutation({
      query: (body) => ({
        url: `pos/createPurchaseEntry`,
        method: "POST",
        body,
      }),
    }),
    //pending
    GetPendingPurchaseInvoices: build.mutation({
      query: (body) =>
        `pos/getPendingPurchaseInvoices/${body.fromdate}/${body.todate}`, ///${body.itemid}
    }),
    LovSupplierBalance: build.mutation({
      query: (body) => `pos/lovSupplierBalance/${body.supplierid}`, ///${body.itemid}
    }),
    Getpendinginvoicesdetail: build.mutation({
      query: (body) => `pos/getpendinginvoicesdetail/${body.purchaseid}`, ///${body.itemid}
    }),
    //entry finish
    GetFinishedPurchaseInvoices: build.mutation({
      query: (body) =>
        `pos/getFinishedPurchaseInvoices/${body.fromdate}/${body.todate}`, ///${body.itemid}
    }),
    Getfinishedinvoicesdetail: build.mutation({
      query: (body) => `pos/getfinishedinvoicesdetail/${body.purchaseid}`, ///${body.itemid}
    }),
    //return finished
    GetFinishedReturnInvoices: build.mutation({
      query: (body) =>
        `pos/getFinishedReturnInvoices/${body.fromdate}/${body.todate}`, ///${body.itemid}
    }),
    Getfinishedreturninvoicesdetail: build.mutation({
      query: (body) =>
        `pos/getfinishedreturninvoicesdetail/${body.transactionid}`, ///${body.itemid}
    }),
    //purcahse Return
    CreateReturnEntry: build.mutation({
      query: (body) => ({
        url: `pos/createReturnEntry`,
        method: "POST",
        body,
      }),
    }),
    //Payment
    GetBalance: build.mutation({
      query: (body) =>
        `pos/getBalance/${body.fromdate}/${body.todate}/${body.type}`, ///${body.itemid}
    }),
    GetBalanceCustSupplierBase: build.mutation({
      query: (body) =>
        `pos/getBalanceCustSupplierBase/${body.fromdate}/${body.todate}/${body.type}/${body.custvendorid}`, ///${body.itemid}
    }),
    CreateTrasactionhistory: build.mutation({
      query: (body) => ({
        url: `pos/createTrasactionhistory`,
        method: "POST",
        body,
      }),
    }),
    //Qoutation
    CreatenewQuotationMaster: build.mutation({
      query: (body) => ({
        url: `pos/createnewQuotationMaster`,
        method: "POST",
        body,
      }),
    }),
    GetPendingQuotation: build.mutation({
      query: (body) =>
        `pos/getPendingQuotation/${body.fromdate}/${body.todate}`, ///${body.itemid}
    }),
    GetQuotationData: build.mutation({
      query: (body) => `pos/getQuotationData/${body.quotationsrno}`, ///${body.itemid}
    }),
    GetFinshedQuotation: build.mutation({
      query: (body) =>
        `pos/getFinshedQuotation/${body.fromdate}/${body.todate}`, ///${body.itemid}
    }),
  }),
});

export const {
  useGetInvPurchaseOrderQuery,
  useUpdatePurchaseOrderMutation,
  useCreatePurchaseOrderMutation,
  useLovVendrQuery,
  useGetInvStockQuery,
  useLovItemListForStockQuery,
  useCreateInvStockMutation,
  useUpdateInvStockMutation,
  useGetInvPurchaseDetailQuery,
  useCreatePurchaseOrderDetMutation,
  useLovInvUomQuery,
  useLovItemListQuery,
  useLovPurchaseOrderQuery,
  useGetInvPurchaseDetailbyMasterMutation,
  useUpdPurchaseOrderDetMutation,
  //purchase Receiving
  useLovPODetailQuery,
  useCreatePurchaseReceiveMutation,
  useUpdateCurrentQtyMutation,
  //qoutation
  useGetQutationinfoQuery,
  useLovCustomerQuery,
  useLovItemListForQutationQuery,
  useGetQutationdetailMutation,
  useCreateQuotationMasterMutation,
  useCreateQuotationnewrecordMutation,
  useUpdateQuotationnewrecordMutation,
  useGetQuotationReportMutation,
  //point of sale
  useLovItemListforPOSQuery,
  useLovCustomerBalanceMutation,
  useGetposmasterMutation,
  useCreatePOSMutation,
  useUpdPosmasterMutation,
  useUpdatePosDetailRecordMutation,
  useDeleteposdetrecordMutation,
  useGetItemSoldHistoryMutation,
  useGetProfitSaleTodayQuery,
  useGetInvoiceinfoQuery,
  useGetInvoicedetailinfoMutation,
  //pos return
  useGetPosDetailRecordMutation,
  useLovCustomerInvoiceListMutation,
  useReturnPOSMutation,
  //item inventory
  useGetItemsByIDMutation,
  //purchase entry
  useCreatePurchaseEntryMutation,
  //pending
  useGetPendingPurchaseInvoicesMutation,
  useLovSupplierBalanceMutation,
  useGetpendinginvoicesdetailMutation,
  //entry finish
  useGetFinishedPurchaseInvoicesMutation,
  useGetfinishedinvoicesdetailMutation,
  //return finished
  useGetFinishedReturnInvoicesMutation,
  useGetfinishedreturninvoicesdetailMutation,
  //purchase return
  useCreateReturnEntryMutation,
  //payment
  useGetBalanceMutation,
  useGetBalanceCustSupplierBaseMutation,
  useCreateTrasactionhistoryMutation,
  //qoutation
  useCreatenewQuotationMasterMutation,
  useGetPendingQuotationMutation,
  useGetFinshedQuotationMutation,
  useGetQuotationDataMutation,
} = InventoryPurchaseApi;
