import { api } from "../api/api";


export const WardConfigurationApi = api.injectEndpoints({

  endpoints: (build) => ({
    lovWardList: build.query({
        query: (body) => `ipdnew/lovWardList`,
      }),

      lovbedtype: build.query({
        query: (body) => `ipdnew/lovbedtype`,
      }),
      WardbednumList: build.query({
        query: (body) =>
          `ipdnew/WardbednumList`,
      }),
      lovbedinfo: build.mutation({
        query: (body) =>
          `ipdnew/lovbedinfo/${body.unit}`,
      }),

      getBedNomncltre: build.mutation({
        query: (body) =>({
         url: `ipdnew/getBedNomncltre/${body.unit}`,

        }),
      }), 
      
      getWardBedAccessories: build.mutation({
        query: (body) =>({
         url: `ipdnew/getWardBedAccessories/${body.unit}/${body.bednum}`,

        }),  
      }), 

      getBedRemainingAccessories: build.mutation({
        query: (body) =>({
         url: `ipdnew/getBedRemainingAccessories/${body.unit}/${body.bednum}`,

        }),  
      }), 
      
      GenNomncltre: build.mutation({
        query: (body) => ({
          url: `ipdnew/GenNomncltre`,
          method: "POST",
          body,
        }),
   
      }),
      CreateWardNomncltre: build.mutation({
        query: (body) => ({
          url: `ipdnew/CreateWardNomncltre`,
          method: "POST",
          body,
        }),
     
      }),

      CreateBedFacilities: build.mutation({
        query: (body) => ({
          url: `ipdnew/CreateBedFacilities`,
          method: "POST",
          body,
        }),
      
      }),


      UpdateWardNomncltreStatus: build.mutation({
        query: body => ({
          url: `ipdnew/UpdateWardNomncltreStatus`,
          method: 'POST',
          body,
        }),
    
      }),

      UpdateBedFacility: build.mutation({
        query: body => ({
          url: `ipdnew/UpdateBedFacility`,
          method: 'POST',
          body,
        }),
        
      }),

      UpdateBedInfo: build.mutation({
        query: body => ({
          url: `ipdnew/UpdateBedInfo`,
          method: 'POST',
          body,
        }),
        
      }),

      getBedInformation: build.query({
        query: () => `ipdnew/getBedInformation`,
        
      }),  

      
      CreateBedType: build.mutation({
        query: body => ({
          url: `ipdnew/CreateBedType`,
          method: 'POST',
          body,
        }),
        
      }),  

      CreateBedInfo: build.mutation({
        query: body => ({
          url: `ipdnew/CreateBedInfo`,
          method: 'POST',
          body,
        }),
       
      }), 

      UpdateBedTypeStatus: build.mutation({
        query: body => ({
          url: `ipdnew/UpdateBedTypeStatus`,
          method: 'POST',
          body,
        }),
  
      }), 
  }),

});

export const {
    useLovWardListQuery,
    useLovbedtypeQuery,
    useWardbednumListQuery,
    useLovbedinfoMutation,
    useGetBedNomncltreMutation,
    useGenNomncltreMutation,
    useCreateWardNomncltreMutation,
    useUpdateWardNomncltreStatusMutation,
    useGetBedInformationQuery,
    useCreateBedTypeMutation,
    useUpdateBedTypeStatusMutation,
    useGetWardBedAccessoriesMutation,
    useCreateBedInfoMutation,
    useGetBedRemainingAccessoriesMutation,
    useUpdateBedFacilityMutation,
    useCreateBedFacilitiesMutation,
    useUpdateBedInfoMutation,
  
   
 
} = WardConfigurationApi;

