import { useEffect, useState } from "react";
import { Button, Table, Tooltip, Tag, Modal } from "antd";
import { EditFilled } from "@ant-design/icons";
import {
  useGetBussFunctionsQuery,
  useUpdateBussFuncMutation,
} from "../../securityService";
import useTableFilters from "../../../../hooks/useTableFilter";
import UpdateBusinessModule from "./UpdateBusinessModule";

const ViewBusinessModule = ({ moduleId }) => {
  const { data, isLoading } = useGetBussFunctionsQuery(moduleId);
  const [
    updateBussFunc,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateBussFuncMutation();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [rowData, setRowData] = useState();
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Module",
      dataIndex: "moduleid",
      ...filter("moduleid"),
      sorter: (a, b) => a?.moduleid?.localeCompare(b?.moduleid),
    },
    {
      title: "Business Function",
      dataIndex: "bussfunc",
      ...filter("bussfunc"),
      sorter: (a, b) => a?.bussfunc?.localeCompare(b?.bussfunc),
    },
    {
      title: "Path",
      dataIndex: "path",
      ...filter("path"),
      sorter: (a, b) => a?.path?.localeCompare(b?.path),
    },
    {
      title: "Business Function",
      dataIndex: "title",
      ...filter("title"),
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Module",
      dataIndex: "moduleid",
      ...filter("moduleid"),
      sorter: (a, b) => a?.moduleid?.localeCompare(b?.moduleid),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Business Module">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section>
      <Table
        dataSource={data?.data}
        columns={tableColumns}
        pagination={{ defaultPageSize: 8 }}
        loading={isLoading}
        bordered
        size="small"
        // scroll={{ x: 'fit-content' }}
        rowKey="title"
      />

      <Modal
        title="Update Business Module"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateBusinessModuleForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateBusinessModule
          updateBussFunc={updateBussFunc}
          rowData={rowData}
        />
      </Modal>
    </section>
  );
};

export default ViewBusinessModule;
