import { Form, Input } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddDesignation = ({ saveDesignation }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    saveDesignation({
      desigcode: values?.desigcode,
      designame: values?.designame,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        id="adddesignation"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item name="desigcode" label="Designation Code">
          <Input />
        </Form.Item>
        <Form.Item name="designame" label="Designation">
          {/* <Select
            placeholder="Select Designation"
            onChange={(value) => {
              const selectedOption = getlovdesignation?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                desigcode: selectedOption?.code,
              });
            }}
            options={getlovdesignation?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default AddDesignation;
