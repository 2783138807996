import { api } from "../api/api";

export const employeeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmployeeList: build.query({
      query: () => `security/getEmployeeList`,
    }),

    getServiceCategory: build.query({
      query: () => `security/getServiceCategory`,
    }),

    getSrviceAmount: build.query({
      query: () => `security/getSrviceAmount`,
    }),

    getSubSrviceAmount: build.query({
      query: () => `security/getSubSrviceAmount`,
    }),

    getSubServiceCategory: build.mutation({
      query: (body) => ({
        url: `security/getSubServiceCategory/${body.srvid}`,
      }),
    }),

    lovSrviceList: build.query({
      query: () => "security/lovSrviceList",
    }),

    lovSubSrviceList: build.mutation({
      query: (body) => `security/lovSubSrviceList/${body.srvid}`,
    }),
    lovSrvicepriceforappoint: build.mutation({
      query: (body) => `security/lovSrvicepriceforappoint/${body.srvid}`,
    }),

    CreateEmployee: build.mutation({
      query: (body) => ({
        url: `security/createEmployee`,
        method: "POST",
        body,
      }),
    }),
    lovSubSrvicepriceforappoint: build.mutation({
      query: (subsrvid) => ({
        url: `security/lovSubSrvicepriceforappoint/${subsrvid}`,
        method: "GET",
      }),
    }),

    CreateSubServiceCategory: build.mutation({
      query: (body) => ({
        url: `security/createSubServiceCategory`,
        method: "POST",
        body,
      }),
    }),

    CreateServiceCategory: build.mutation({
      query: (body) => ({
        url: `security/createServiceCategory`,
        method: "POST",
        body,
      }),
    }),

    CreateServicePriceCategory: build.mutation({
      query: (body) => ({
        url: `security/createServicePriceCategory`,
        method: "POST",
        body,
      }),
    }),

    createSubServiceCategoryPrice: build.mutation({
      query: (body) => ({
        url: `security/createSubServiceCategoryPrice`,
        method: "POST",
        body,
      }),
    }),

    UpdateEmployeeConfig: build.mutation({
      query: (body) => ({
        url: `security/updateEmployeeConfig`,
        method: "POST",
        body,
      }),
    }),
    //employee configurators
    GetHmsBloodgroup: build.query({
      query: () => "security/getHmsBloodgroup",
    }),
    LovHmsBloodGroup: build.query({
      query: () => "pos/lovHmsBloodGroup",
    }),
    CreateHmsBloodGroup: build.mutation({
      query: (body) => ({
        url: `security/createHmsbloodgroup`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsBloodgroup: build.mutation({
      query: (body) => ({
        url: `security/updateHmsBloodgroup`,
        method: "POST",
        body,
      }),
    }),
    //Country
    GetHmscountry: build.query({
      query: () => "security/getHmscountry",
    }),
    CreateHmscountry: build.mutation({
      query: (body) => ({
        url: `security/createHmscountry`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsCountry: build.mutation({
      query: (body) => ({
        url: `security/updateHmsCountry`,
        method: "POST",
        body,
      }),
    }),
    //province
    LovHmsCountry: build.query({
      query: () => "security/lovHmsCountry",
    }),
    GetHmsprovnce: build.query({
      query: () => "security/getHmsprovnce",
    }),
    CreateHmsprovnce: build.mutation({
      query: (body) => ({
        url: `security/createHmsprovnce`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsProvince: build.mutation({
      query: (body) => ({
        url: `security/updateHmsProvince`,
        method: "POST",
        body,
      }),
    }),
    //City
    LovHmsProvince: build.query({
      query: () => "security/lovHmsProvince",
    }),
    LovHmsCity: build.query({
      query: () => "pos/lovHmsCity",
    }),
    GetHmscity: build.query({
      query: () => "security/getHmscity",
    }),
    CreateHmsCity: build.mutation({
      query: (body) => ({
        url: `security/createHmsCity`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsCity: build.mutation({
      query: (body) => ({
        url: `security/updateHmsCity`,
        method: "POST",
        body,
      }),
    }),
    //dept
    GetHmsdepartment: build.query({
      query: () => "security/getHmsdepartment",
    }),
    lovHmsDepartment: build.query({
      query: () => "pos/lovHmsDepartment",
    }),
    CreateHmsDepartment: build.mutation({
      query: (body) => ({
        url: `security/createHmsDepartment`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsDepartment: build.mutation({
      query: (body) => ({
        url: `security/updateHmsDepartment`,
        method: "POST",
        body,
      }),
    }),
    //Designation
    GetHmsdesignation: build.query({
      query: () => "security/getHmsdesignation",
    }),
    lovHmsDesignation: build.query({
      query: () => "pos/lovHmsDesignation",
    }),
    CreateHmsDesignation: build.mutation({
      query: (body) => ({
        url: `security/createHmsDesignation`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsDesignation: build.mutation({
      query: (body) => ({
        url: `security/updateHmsDesignation`,
        method: "POST",
        body,
      }),
    }),
    //m status
    GetHmsMaritalStatus: build.query({
      query: () => "security/getHmsMaritalStatus",
    }),
    lovHmsMaritalStatus: build.query({
      query: () => "pos/lovHmsMaritalStatus",
    }),
    CreateHmsMaritalStatus: build.mutation({
      query: (body) => ({
        url: `security/createHmsMaritalStatus`,
        method: "POST",
        body,
      }),
    }),
    updateHmsMaritalStatus: build.mutation({
      query: (body) => ({
        url: `security/updateHmsMaritalStatus`,
        method: "POST",
        body,
      }),
    }),
    //Religion
    GetHmsReligion: build.query({
      query: () => "security/getHmsReligion",
    }),
    lovHmsReligion: build.query({
      query: () => "pos/lovHmsReligion",
    }),
    CreateHmsReligion: build.mutation({
      query: (body) => ({
        url: `security/createHmsReligion`,
        method: "POST",
        body,
      }),
    }),
    updateHmsRelgion: build.mutation({
      query: (body) => ({
        url: `security/updateHmsRelgion`,
        method: "POST",
        body,
      }),
    }),
    //title
    GetHmsTitle: build.query({
      query: () => "security/getHmsTitle",
    }),
    lovHmsTitle: build.query({
      query: () => "pos/lovHmsTitle",
    }),
    CreateHmsTitle: build.mutation({
      query: (body) => ({
        url: `security/createHmsTitle`,
        method: "POST",
        body,
      }),
    }),
    updateHmsTitle: build.mutation({
      query: (body) => ({
        url: `security/updateHmsTitle`,
        method: "POST",
        body,
      }),
    }),
    //emp Reg
    GetEmployeedata: build.query({
      query: () => "pos/getEmployeedata",
    }),
    CreateHmsEmployee: build.mutation({
      query: (body) => ({
        url: `security/createHmsEmployee`,
        method: "POST",
        body,
      }),
    }),
    UpdateHmsEmployee: build.mutation({
      query: (body) => ({
        url: `security/updateHmsEmployee`,
        method: "POST",
        body,
      }),
    }),
    //
  }),
});

export const {
  useLovSubSrvicepriceforappointMutation,
  useGetEmployeeListQuery,
  useGetSubServiceCategoryMutation,
  useGetSubSrviceAmountQuery,
  useCreateSubServiceCategoryMutation,
  useLovSrviceListQuery,
  useLovSubSrviceListMutation,
  useLovSrvicepriceforappointMutation,
  useCreateServiceCategoryMutation,
  useCreateServicePriceCategoryMutation,
  useGetServiceCategoryQuery,
  useCreateSubServiceCategoryPriceMutation,
  useGetSrviceAmountQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeConfigMutation,
  // employee con
  useGetHmsBloodgroupQuery,
  useLovHmsBloodGroupQuery,
  useCreateHmsBloodGroupMutation,
  useUpdateHmsBloodgroupMutation,
  //country
  useGetHmscountryQuery,
  useCreateHmscountryMutation,
  useUpdateHmsCountryMutation,
  //province
  useLovHmsCountryQuery,
  useGetHmsprovnceQuery,
  useCreateHmsprovnceMutation,
  useUpdateHmsProvinceMutation,
  //city
  useLovHmsProvinceQuery,
  useLovHmsCityQuery,
  useGetHmscityQuery,
  useCreateHmsCityMutation,
  useUpdateHmsCityMutation,
  //dept
  useGetHmsdepartmentQuery,
  useLovHmsDepartmentQuery,
  useCreateHmsDepartmentMutation,
  useUpdateHmsDepartmentMutation,
  //desig
  useGetHmsdesignationQuery,
  useLovHmsDesignationQuery,
  useCreateHmsDesignationMutation,
  useUpdateHmsDesignationMutation,
  //m status
  useGetHmsMaritalStatusQuery,
  useLovHmsMaritalStatusQuery,
  useCreateHmsMaritalStatusMutation,
  useUpdateHmsMaritalStatusMutation,
  //religion
  useGetHmsReligionQuery,
  useLovHmsReligionQuery,
  useCreateHmsReligionMutation,
  useUpdateHmsRelgionMutation,
  //title
  useGetHmsTitleQuery,
  useLovHmsTitleQuery,
  useCreateHmsTitleMutation,
  useUpdateHmsTitleMutation,
  //emp reg
  useGetEmployeedataQuery,
  useCreateHmsEmployeeMutation,
  useUpdateHmsEmployeeMutation,
  //
} = employeeApi;
