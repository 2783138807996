import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { Form, Input, Select } from 'antd'

const AddServiceCategory=({createservice})=> {
    const onFinish = (values) =>{
        createservice({
            ...values,
            message: 'B',
          })
        }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}

    id='addServiceCategoryForm'
    
    validateMessages={formValidationMessages}
    className='grid grid-cols-2 gap-2'
  >
    
  
    <Form.Item
                  name='srvname'
                  label='Service Name'
                >
                  <Input placeholder='Enter Service Name'></Input>
                </Form.Item>
                <Form.Item
      name='status'
      label='Status'
    >
      <Select
        placeholder='Select Status'
        options={[
          { value: 'Y', label: 'Active' },
          { value: 'N', label: 'In Active' },
        ]}
      />
    </Form.Item>


  </Form>
    </div>
  )
}

export default AddServiceCategory