import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const UpdateManufacturer = ({rowData,updatemanufacturer,updatemanufacturerData}) => {
  const [form] = Form.useForm()
  const onFinish = (values) =>
    updatemanufacturer({
      ...values,
      genericname:values?.mafcname,
      status: values?.status,
      cid:values?.mafcid,
   
      message: 'B',
    })
  return (

    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='updateManufacturerForm'
    form={form}
    initialValues={{
      mafcname:rowData?.mafcname,
      mafcid:rowData?.mafcid,
     
      status:rowData?.status,
    }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >

<Form.Item
      name='mafcid'
      label='Manufacturer ID'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled='true' />
    </Form.Item>
    <Form.Item
      name='mafcname'
      label='Manufacturer Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input
       placeholder='Enter Manufacturer Name'  />
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
     
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}
export default UpdateManufacturer