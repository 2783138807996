import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo.png";

const Sidebar = ({ collapsed, setCollapsed, hidden }) => {
  const menuItems = useSelector((state) => state?.auth?.menu);

  const [activeCategory, setActiveCategory] = useState(-1);
  const catagories = useMemo(
    () => menuItems.filter((item) => item?.level === 1),
    [menuItems]
  );
  const getChildren = (id) =>
    menuItems.filter((item) => item?.parent_id === id);

  return (
    <div className="top-0">
      <nav
        className={`${!collapsed ? "w-[3.175rem]" : "w-[13rem]"} ${
          hidden && !collapsed ? "-translate-x-[3.175rem]" : ""
        } ${
          hidden && collapsed ? "-translate-x-[13rem]" : ""
        } fixed h-[calc(100%-3rem)] bg-[#e8ece7] duration-300 z-10`}
      >
        <div className="flex justify-center items-center mt-6 ">
          <img
            src={logo}
            alt=""
            className={`${
              collapsed ? "w-[100px] h-[70px]" : "w-[55px] h-[35px]"
            }`}
          />
        </div>

        {/* <h1
          className={`${
            collapsed
              ? "p-8 text-black text-3xl"
              : "pb-8 pt-8 text-black text-3xl rotate-90"
          }`}
        >
          MinMed
        </h1> */}
        <ul className="flex flex-col mt-12">
          {catagories.map((catagory, index) => (
            <li
              key={catagory?.page_id}
              id={catagory?.page_id}
              className="w-full group relative"
            >
              <div
                className="h-10 grid grid-cols-[3.175rem,auto] items-center text-black group-hover:text-black cursor-pointer"
                onClick={() =>
                  setActiveCategory((activeCategory) =>
                    activeCategory === index ? -1 : index
                  )
                }
              >
                <span className="h-full flex items-center justify-center">
                  <i
                    className={`${
                      catagory?.icon !== "pi pi-sitemap"
                        ? catagory?.icon
                        : "fa fa-sitemap"
                      //#a36a6a#52c41a
                    }`}
                    // className={`${catagory.icon}`} ! ABOVE IS FOR DEV !
                    style={{ fontSize: "1.125rem", color: "#00b29c" }}
                  ></i>
                </span>
                <p
                  className={`${
                    !collapsed ? "hidden" : "block"
                  } text-[0.7rem] flex justify-between items-center`}
                >
                  <span>{catagory?.name}</span>
                  <i
                    className={`${
                      activeCategory === index ? "rotate-90" : ""
                    } fa fa-angle-right duration-300 p-[10px]`}
                    style={{ fontSize: "1.125rem", color: "#00b29c" }}
                  ></i>
                </p>
              </div>
              {/* collapsed */}
              <ul
                className={`${
                  !collapsed
                    ? "left-[3.175rem] hidden group-hover:block"
                    : "hidden"
                }  absolute top-0 bg-[#e8ece7] whitespace-nowrap overflow-y-auto `}
                style={{ maxHeight: `calc(100vh - ${index * 40}px)` }}
              >
                {getChildren(catagory?.page_id).map((item) => (
                  <li
                    key={item?.page_id}
                    className="text-[0.688rem]  min-w-[12rem]"
                  >
                    <Link
                      to={item?.url || "/"}
                      className="w-full h-10 px-2 grid items-center text-black"
                    >
                      <p>{item?.name}</p>
                    </Link>
                  </li>
                ))}
              </ul>
              {/* not collapsed */}
              <ul
                className={`${
                  activeCategory === index && collapsed ? "block" : "hidden"
                }  ml-[45px]`}
              >
                {getChildren(catagory.page_id).map((item) => (
                  <li
                    key={item?.page_id}
                    className="whitespace-nowrap text-[0.7rem] w-full "
                  >
                    <Link
                      to={item?.url || "/"}
                      className="w-full h-10 px-2 grid items-center text-black hover:text-black"
                    >
                      <p>{item?.name}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          <li
            className={`${
              !collapsed ? "w-[3.175rem]" : "w-[13rem]"
            } fixed bottom-0 text-white h-12 md:hidden lg:flex justify-center items-center bg-[#e8ece7] duration-300 `}
            onClick={() => setCollapsed((collapsed) => !collapsed)}
          >
            <i
              className={`${
                collapsed ? "rotate-180" : "rotate-0"
              } fa fa-angle-double-right duration-300`}
              style={{ fontSize: "1.5rem", color: "#00b29c" }}
            ></i>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
