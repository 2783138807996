import { DatePicker, Form, Input, Select } from "antd";
import React from "react";
import {
  useLovInvUomQuery,
  useLovItemListQuery,
} from "../inventorypurchaseService";
import moment from "moment";
const UpdateInvPurchaseDetail = ({ rowData, updatepodetail }) => {
  const [form] = Form.useForm();
  const { data: lovItemList } = useLovItemListQuery();
  const { data: lovUOM } = useLovInvUomQuery();

  const onFinish = (values) => {
    updatepodetail({
      purchaseseqnum: values?.purchaseseqnum,
      purchasenum: values?.purchasenum,
      descr: values?.descr,
      purchasedate: moment(values?.purchasedate).format("DD-MMM-YYYY"),
      itemid: values?.itemid,
      quantity: values?.quantity,
      rate: values?.rate,
      unitrate: values?.unitrate,
      uomid: values?.uomid,
      lastrcvqty: values?.lastrcvqty,
      currrcvqty: values?.currrcvqty,
      taxpct: values?.taxpct,
      discountpct: values?.discountpct,
      discountamount: values?.discountamount,
      packsize: values?.packsize,
      extraqty: values?.extraqty,
      retailprice: values?.retailprice,
      status: values?.status,
    });
  };
  return (
    <div>
      <Form
        form={form}
        initialValues={{
          purchaseseqnum: rowData?.purchaseseqnum,
          purchasenum: rowData?.purchasenum,
          descr: rowData?.descr,
          purchasedate: rowData?.purchasedate,
          itemid: rowData?.itemid,
          quantity: rowData?.quantity,
          rate: rowData?.rate,
          unitrate: rowData?.unitrate,
          uomid: rowData?.uomid,
          lastrcvqty: rowData?.lastrcvqty,
          currrcvqty: rowData?.currrcvqty,
          taxpct: rowData?.taxpct,
          discountpct: rowData?.discountpct,
          discountamount: rowData?.discountamount,
          packsize: rowData?.packsize,
          extraqty: rowData?.extraqty,
          retailprice: rowData?.retailprice,
          status: rowData?.status,
        }}
        id="updatepodetail"
        onFinish={onFinish}
        layout="vertical"
      >
        <div className=" grid sm:grid-cols-5 grid-cols-1 gap-1">
          <Form.Item
            name="purchaseseqnum"
            label="Purchase Seqnum"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="purchasenum"
            label="Purchase Number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="descr"
            label="Description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="purchasedate"
            label="Purchase Date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="itemid"
            label="Item ID"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select Item Name"
              options={lovItemList?.data?.map((item) => ({
                value: item?.code,
                label: `${item?.descr}`,
              }))}
              showSearch
              filterOption={(input, option) =>
                option?.label?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="rate"
            label="Rate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="unitrate"
            label="Unit Rate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="uomid"
            label="Uom ID"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select UOM"
              options={lovUOM?.data?.map((item) => ({
                value: item?.code,
                label: `${item?.descr}`,
              }))}
              showSearch
              filterOption={(input, option) =>
                option?.label?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="lastrcvqty"
            label="Last Rcv Qty"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="currrcvqty"
            label="Current Rcv Qty"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="taxpct"
            label="Tax Pct"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discountpct"
            label="Discount Pct"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discountamount"
            label="Discount Amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="packsize"
            label="Pack Size"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="extraqty"
            label="Extra Qty"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="retailprice"
            label="Retail Price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select  Status"
              options={[
                { label: "Active", value: "Y" },
                { label: "In Active", value: "N" },
              ]}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default UpdateInvPurchaseDetail;
