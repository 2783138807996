import {  Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useLovVendrQuery } from '../inventorypurchaseService';
import moment from 'moment'
const UpdatePurchaseOrder = ({rowData,updatepoData,updatepo}) => {
    const { data: lovVender } = useLovVendrQuery();
    const [form] = Form.useForm()
    const onFinish = (values) =>
        updatepo({
        ...values,
         purchaseseqnum:rowData?.purchaseseqnum,
        // status: values?.status,
        // cid:values?.mafcid,
     
        message: 'B',
      })
    return (
  
      <div>
      <Form
      layout='vertical'
      onFinish={onFinish}
      id='updatePOForm'
      form={form}
      initialValues={{
        vendorid:rowData?.vendorid,
        purchasenum:rowData?.purchasenum,
        descr:rowData?.descr,
        purchasedate:moment(rowData?.purchasedate).format("YYYY-MM-DD"),
        quotnum:rowData?.quotnum,
        quotdate:moment(rowData?.quotdate).format("YYYY-MM-DD"),
        status:rowData?.status,
        purchaseseqnum:rowData?.purchaseseqnum,
        
       
      }}
      validateMessages={formValidationMessages}
      className='grid grid-cols-4 gap-2'
    >
  
  <Form.Item
              name='vendorid'
              label="Vender Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Select Vender Name"
                options={lovVender?.data?.map((item) => ({
                  value: item?.code,
                  label: `${item?.descr}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>

    <Form.Item
      name='purchasenum'
      label='Purchase No.'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Purchase No. '/>
    </Form.Item>

    <Form.Item
      name='descr'
      label='Description'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Description'/>
    </Form.Item>

    <Form.Item 
      name='purchasedate' label='Purchase Date'>
          <Input/>
        </Form.Item>

    <Form.Item
      name='quotnum'
      label='Quotation No.'
      rules={[
        {
          required: true,
        },
      ]}
 >
      <Input placeholder='Enter Quotation No.'/>
    </Form.Item>

    <Form.Item 
      name='quotdate' label='Quotation Date'>
          {/* <DatePicker
          // disabledDate={futureDates}
            placeholder='Enter Quotation Date'
            format="YYYY-MM-DD"
          /> */}
          <Input/>
        </Form.Item>
    
    <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select  Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
     
    </Form>
      </div>
    )
  }

export default UpdatePurchaseOrder