import { useSelector } from "react-redux";
import { Form, Input } from "antd";

const UserProfileModal = () => {
  const user = useSelector((state) => state?.auth?.user);

  return (
    <Form
      layout="vertical"
      disabled
      initialValues={{
        ...user,
      }}
      className="grid sm:grid-cols-3 grid-cols-1 gap-2"
    >
      <Form.Item name="fullname" label="Full Name">
        <Input />
      </Form.Item>
      <Form.Item name="empno" label="Employee No">
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input />
      </Form.Item>
      <Form.Item name="policy" label="Policy">
        <Input />
      </Form.Item>
      <Form.Item name="role" label="Role">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default UserProfileModal;
