// import React, { PureComponent } from 'react'
// import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts'
// import { useGetcomparepatientQuery } from '../DashboardService'

// export default function PatientRadarChart() {
//   const { data: todayPat } = useGetcomparepatientQuery()
//   const data = [
//     {
//       subject: 'Math',
//       A: 120,
//       B: 110,
//       fullMark: 150,
//     },
//     {
//       subject: 'Chinese',
//       A: 98,
//       B: 130,
//       fullMark: 150,
//     },
//     {
//       subject: 'English',
//       A: 86,
//       B: 130,
//       fullMark: 150,
//     },
//     {
//       subject: 'Geography',
//       A: 99,
//       B: 100,
//       fullMark: 150,
//     },
//     {
//       subject: 'Physics',
//       A: 85,
//       B: 90,
//       fullMark: 150,
//     },
//     {
//       subject: 'History',
//       A: 65,
//       B: 85,
//       fullMark: 150,
//     },
//   ]
//   return (
//     <div className='border border-border rounded-xl shadow-lg mx-2 my-2'>
//       <h4 className='font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-[#3D92BC] text-white'>
//         Patient Area Chart
//       </h4>
//       <div className='grid place-content-center'>
//         <RadarChart width={400} height={400} outerRadius='80%' data={data}>
//           <PolarGrid />
//           <PolarAngleAxis dataKey='subject' />
//           <PolarRadiusAxis angle={30} domain={[0, 150]} />
//           <Radar name='Mike' dataKey='A' stroke='#8884d8' fill='#8884d8' fillOpacity={0.6} />
//           <Radar name='Lily' dataKey='B' stroke='#82ca9d' fill='#82ca9d' fillOpacity={0.6} />
//         </RadarChart>
//       </div>
//     </div>
//   )
// }
// import React from 'react'
// import Highcharts from 'highcharts'
// import HighchartsMore from 'highcharts/highcharts-more' // Import the highcharts-more module
// import HighchartsReact from 'highcharts-react-official'
// import { useGetcomparepatientQuery } from '../DashboardService'
// // Initialize the highcharts-more module
// HighchartsMore(Highcharts)

// const PatientRadarChart = () => {
//   const { data: todayPat } = useGetcomparepatientQuery()
//   const options = {
//     chart: {
//       polar: true,
//     },

//     title: {
//       text: 'Highcharts Polar Chart',
//     },

//     subtitle: {
//       text: 'Also known as Radar Chart',
//     },

//     pane: {
//       startAngle: 0,
//       endAngle: 360,
//     },

//     xAxis: {
//       tickInterval: 45,
//       min: 0,
//       max: 360,
//     },

//     yAxis: {
//       min: 0,
//     },

//     plotOptions: {
//       series: {
//         pointStart: 0,
//         pointInterval: 45,
//       },
//       column: {
//         pointPadding: 0,
//         groupPadding: 0,
//       },
//     },

//     series: [
//       {
//         type: 'column',
//         name: 'Column',
//         data: [1],
//         pointPlacement: 'between',
//       },
//       {
//         type: 'line',
//         name: 'Line',
//         data: [1],
//       },
//       {
//         type: 'area',
//         name: 'Area',
//         data: [1],
//       },
//     ],
//   }

//   return (
//     <div className='border border-border rounded-xl shadow-lg mx-2 my-2'>
//       <h4 className='font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white'>
//         Patient Monthly Count
//       </h4>
//       <HighchartsReact highcharts={Highcharts} options={options} />
//     </div>
//   )
// }

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetcomparepatientQuery } from "../DashboardService";

const PatientRadarChart = () => {
  const { data: todayPat } = useGetcomparepatientQuery();

  // Extract the "beni" values from each object in the response data
  const beniData = todayPat?.data.map((item) => parseInt(item.beni));
  const cneData = todayPat?.data.map((item) => parseInt(item.cne));
  const othersData = todayPat?.data.map((item) => parseInt(item.others));
  const emplData = todayPat?.data.map((item) => parseInt(item.others));
  const options = {
    credits: false,
    colors: ["#2CAFFE", "#00E272", "#544FC5", "#BDFFFF"],
    chart: {
      type: "column",
      inverted: true,
      polar: true,
    },
    title: {
      text: "",
      align: "left",
    },

    tooltip: {
      outside: true,
    },
    pane: {
      size: "85%",
      innerSize: "20%",
      endAngle: 270,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: "right",
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: "13px",
        },
      },
      lineWidth: 0,
      gridLineWidth: 0,
      categories: [
        ' Visited<span class="f16"><span id="flag" class="flag no">' +
          "</span></span>",

        'Registered <span class="f16"><span id="flag" class="flag at">' +
          "</span></span>",
      ],
    },
    yAxis: {
      lineWidth: 0,
      tickInterval: 25,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
        borderRadius: "50%",
      },
    },
    series: [
      {
        name: "Benificiary",
        data: beniData,
      },
      {
        name: "Civ not entitled",
        data: cneData,
      },
      {
        name: "Employee",
        data: emplData,
      },
      {
        name: "Others",
        data: othersData,
      },
    ],
  };

  return (
    <div className="border border-border rounded-xl shadow-lg mx-2 my-2">
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white">
        Patient Monthly Count
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PatientRadarChart;
