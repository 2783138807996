import { Form, Input } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddRack = ({createmedrack}) => {
  const onFinish = (values) =>{
    createmedrack({
        ...values,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addRackForm'
    validateMessages={formValidationMessages}
    className=''
  >
    <Form.Item
      name='rackno'
      label='Medicine Rack No. '
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Medicine Rack No. '/>
    </Form.Item>
   
   
  </Form>
    </div>
  )
}

export default AddRack