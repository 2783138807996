import { Form, Input } from "antd";
import React from "react";
import { LockFilled } from "@ant-design/icons";
import formValidationMessages from "../../../utils/formValidationMessages";
const AddtoCard = ({ savetocard, rowData }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    savetocard([
      {
        prodid: values?.prodid,
        prodname: values?.prodname,
        email: values?.email,
        pasword: values?.pasword,
        message: "B",
      },
    ]);
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        id="addtocard"
        initialValues={{
          prodid: rowData?.pid,
          prodname: rowData?.pname,
        }}
        validateMessages={formValidationMessages}
      >
        <div className=" grid sm:grid-cols-2 grid-cols-1 gap-1 w-full">
          <Form.Item
            name="prodid"
            label="Product ID"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="prodname"
            label="Product Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            type="email"
            label="Email"
            rules={[
              {
                type: "email",
              },
            ]}
            className=" sm:col-span-2 col-span-1"
          >
            <Input placeholder="Email..." />
          </Form.Item>
          <Form.Item
            name="pasword"
            rules={[
              {
                required: true,
              },
            ]}
            className=" sm:col-span-2 col-span-1"
          >
            <Input.Password
              addonBefore={<LockFilled />}
              placeholder="Password"
              size="large"
              autoComplete="on"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default AddtoCard;
