import React from "react";
import { DatePicker, Select, Radio, Button, Form, Table, Image } from "antd";
import { useState } from "react";
import avatar from "../../../assets/images/avatar.jpg";
// import useTableFilters from "../../../hooks/useTableFilter";
import {
  useCreateDialysisReceptionMutation,
  useLovmachineNumberQuery,
} from "../dialysisService";
import { useGetReceptionDataQuery } from "../dialysisService";
import { useSelector } from "react-redux";
import { formateDate } from "../../../utils/dateUtils";

const Dialysis=()=> {
  const co = useSelector((state) => state.auth.user.co);

  const [dialysisDetail, setdialysisDetail] = useState([]);
  const { data: lovmachineNumber } = useLovmachineNumberQuery();
  const { data: getReceptionData } = useGetReceptionDataQuery(co);
  const [saveDialysisReception, { isLoading: addLoading }] =
    useCreateDialysisReceptionMutation();

  const columns = [
    {
      title: "Request No.",
      dataIndex: "reqnum",
      // ...filter("reqnum"),
      // sorter: (a, b) => a?.reqnum?.localeCompare(b?.reqnum),
    },
    {
      title: "MR Number",
      dataIndex: "regnum",
      // ...filter("regnum"),
      // sorter: (a, b) => a?.regnum?.localeCompare(b?.regnum),
    },

    {
      title: "Patient Name",
      dataIndex: "patientname",
      // ...filter("patientname"),
      // sorter: (a, b) => a?.patientname?.localeCompare(b?.patientname),
    },
    {
      title: "Patient Type",
      dataIndex: "unit",
      // ...filter("unit"),
      // sorter: (a, b) => a?.unit?.localeCompare(b?.unit),
    },
  ];
  const onFinish = (values) => {
    console.log(dialysisDetail);
    console.log(values);
    saveDialysisReception({
      ...values,
      regnum: dialysisDetail?.reqnum,
      patienttype: dialysisDetail?.patienttype,
      visitnum: dialysisDetail?.visitnum,
      presdate: formateDate(values?.presdate),
      unit: dialysisDetail?.unit,
      advcnum: dialysisDetail?.advcnum,
      advctype: dialysisDetail?.advctype,
    });
  };

  return (
    <div className="">
      <div>
        <h1 className=" col-span-2 flex justify-center items-center font-bold h-12 text-2xl mt-0">
          Dialysis Reception
        </h1>
        <div className="grid grid-cols-3 gap-1 px-2">
          <div className="bg-[#3d92bc] border border-gainsboro">
            <h4 className="font-semibold text-white">Temp (°C)</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro">
            <h4 className="font-semibold text-white">BP (mm Hg)</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Pulse (bpm)</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Allergy</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Saturation</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Weight (Kg)</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Blood Group</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Resp Rate (bpm)</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white">Oxygen</h4>
            <div className="text-white"></div>
          </div>
          <div className="bg-[#3d92bc] border border-gainsboro ">
            <h4 className="font-semibold text-white"> </h4>
            <div className="text-white"> </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="grid sm:grid-cols-1">
          <div
            className="border border-gainsboro shadow-lg  flex-1 m-2 "
            style={{ height: "266px", overflow: "auto" }}
          >
            <Table
              columns={columns}
              dataSource={getReceptionData?.data}
              pagination={false}
              rowKey="MR"
              onRow={(record) => ({
                onClick: () => {
                  setdialysisDetail(record);
                },
              })}
            />
          </div>
        </div>
        {dialysisDetail ? (
          <div className="border border-gainsboro shadow-lg m-2">
            <h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-12">
              Patient Detail
            </h1>
            <div className="">
              <div className="flex  p-1">
                <div className="flex-1">
                  <div className=" leading-8 p-2 mr-3 m-0 ">
                    <div className="flex ">
                      {" "}
                      <h1 className=" font-semibold text-md">Request No. : </h1>
                      <p className="ml-6">{dialysisDetail?.reqnum}</p>
                    </div>
                    <div className="flex">
                      {" "}
                      <h1 className=" font-semibold text-md">MR : </h1>
                      <p className="ml-6">{dialysisDetail?.regnum}</p>
                    </div>
                    <div className="flex">
                      {" "}
                      <h1 className=" font-semibold text-md">
                        Patient Name :{" "}
                      </h1>
                      <p className="ml-6">{dialysisDetail?.patientname}</p>
                    </div>
                    <div className="flex">
                      <h1 className=" font-semibold text-md">
                        Patient Type :{" "}
                      </h1>
                      <p className="ml-6">{dialysisDetail?.unit}</p>
                    </div>
                  </div>
                </div>
                <div className="flex  mt-3 ">
                  <Image height={150} src={avatar} alt="patient" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Dashboard</div>
        )}
      </div>

      <div className=" m-2 border border-gainsboro shadow-lg mt-0">
        <h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-8">
          Dialysis Detail
        </h1>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            acute: "Y",
          }}
        >
          <div className="">
            <div className="grid grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-2 p-2 py-1">
         

              <Form.Item
                name="machinenum"
                label="Machine Number "
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select Machine Number"
                  options={lovmachineNumber?.data?.map((item) => ({
                    value: item?.code,
                    label: `${item?.code}`,
                  }))}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                name="hbs"
                label="HBS As"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { value: "P", label: "Positive" },
                    { value: "N", label: "Negative" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="hcv"
                label="HCV"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { value: "P", label: "Positive" },
                    { value: "N", label: "Negative" },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-6 md:grid-cols-6 sm:grid-cols-1 gap-2 p-2 py-0.5">
              {/* <Form.Item name="BloodGroup" label="Blood Group">
                <Select
                  placeholder="Select"
                  options={[
                    { value: "A", label: "A+" },
                    { value: "A-", label: "A-" },
                    { value: "B+", label: "B+" },
                    { value: "B-", label: "B-" },
                    { value: "AB+", label: "AB+" },
                    { value: "AB-", label: "AB-" },
                    { value: "O+", label: "0+" },
                    { value: "O-", label: "0-" },
                  ]}
                />
              </Form.Item> */}
              <Form.Item name="presdate" label="Date" className="col-span-2">
                <DatePicker />
              </Form.Item>
              <Form.Item className="mt-7 col-span-2 px-4" name="acute">
                <Radio.Group>
                  <Radio value="Y">Acute</Radio>
                  <Radio value="N">Chronic</Radio>
                </Radio.Group>
              </Form.Item>

              <div className="col-span-1 flex items-center mt-8 justify-end">
                <Form.Item>
                  <Button className="mr-3" type="primary">
                    History
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Recieved & Referred
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Dialysis