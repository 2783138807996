import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Select, Table, Modal } from "antd";
import {
  useEnterAppointmentMutation,
  useGetPriceMutation,
  useLovDocListQuery,
  useLovOPDQuery,
  useCreateVisitForPateintMutation,
  useChangEntertaineStatusMutation,
  useBillInsertionMutation,
} from "../referralServices";

import moment from "moment";
import useFetchPDF from "../../../hooks/useFetchPDF";
import formValidationMessages from "../../../utils/formValidationMessages";
const AddPatientVisit = ({ selectedPatient, selectedDoctor, handlevisit,rowData }) => {
  const suborgid = useSelector((state) => state.auth.user.co);
  const employeeid = useSelector((state) => state.auth.user.username);
  const { data: lovDocList } = useLovDocListQuery(suborgid);
  const { data: lovOPDList } = useLovOPDQuery({
    suborgid,
    gender: selectedPatient?.gender,
  });
  const [getPriceData, { data: price }] = useGetPriceMutation();
  const [selectedOption, setSelectedOption] = useState();
  const [commonVariableCode, setCommonVariableCode] = useState();
  const [commonVariableNomn, setCommonVariableNomn] = useState();
  const [selectedOpdOption, setSelectedOpdOption] = useState();
  const [form] = Form.useForm();
  // const radioValue = Form.useWatch('radio', form)
  const nomnopd=lovOPDList?.data.find((item)=>item.code===rowData?.toopd)
  console.log("nomnopd",nomnopd)
  const [tableData, setTableData] = useState(null);
  const [enterAppointment, { data: enterAppointmentData }] =
    useEnterAppointmentMutation();
  const [createVisitForPatient, { data: entercreateVisitForPatientData }] =
    useCreateVisitForPateintMutation();
  const [changeEntStatus, { data: changeEntStatusData }] =
    useChangEntertaineStatusMutation();
  const [billInsertion] = useBillInsertionMutation();
  const [showPdfModal, setShowPdfModal] = useState();
  const [reportData, getReport] = useFetchPDF();
  const tableColumns = [
    {
      title: "OPD",
      // dataIndex: 'opd',
      render: () => "opd" || "--",
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      // render: () => 'doctor' || '--',
    },
    {
      title: "Price",
      render: () => price?.data[0]?.code || "--",
    },
    {
      title: "Action(s)",
      render: () => (
        <Button
          danger
          icon={
            <i className="fa fa-trash" onClick={() => setTableData(null)}></i>
          }
        />
      ),
    },
  ];
  //  const commonVariable=
  const addVisit = () => {
    // console.log('already selected', selectedDoctor)
    // console.log('selected doctor', selectedOption)
    // console.log('selected opd', selectedOpdOption)
    // console.log('common', commonVariable)
    // console.log(selectedDoctor?.doctorname ? selectedDoctor?.nomn : commonVariableNomn)

    const formValues = form.getFieldValue();

    setTableData([formValues]);
    if (selectedPatient?.patienttype === "CNE") {
      console.log("selectedDoctor",selectedDoctor)
      console.log("selectedOption",selectedOption)
      getPriceData({
        suborgid,
        pattype: selectedPatient?.patienttype,
        nomnclhtr: selectedDoctor?.doctorname
          ? selectedDoctor?.nomn
          : selectedOption? selectedOption.nomnclhtr:nomnopd?.nomncltr,
        // nomncltr: selectedOption?.nomnclhtr,
      });
    } else {
      console.log("selectedOption",selectedOption)
      console.log("toopd",rowData?.toopd)
      getPriceData({
        suborgid,
        pattype: selectedPatient?.patienttype,
        nomnclhtr: selectedOpdOption!==undefined? selectedOpdOption.nomncltr:nomnopd?.nomncltr,
        // nomncltr: selectedOption?.nomnclhtr,
      });
    }
  };

  const submitVisits = () => {
    if (tableData) {
      enterAppointment({
        co: suborgid,
        regnum: selectedPatient?.regnum,
        patienttype: selectedPatient?.patienttype,
        chngamount: price?.data[0]?.code,
        dob: selectedPatient?.dob || "",
        gender: selectedPatient?.gender || "",
        firstname: selectedPatient?.firstname || "",
        middlename: selectedPatient?.middlename || "",
        lastname: selectedPatient?.lastname || "",
        fhname: selectedPatient?.fhname || "",
        marstat: selectedPatient?.marstat,
        bloodgrp: selectedPatient?.bloodgrp || "",
        tempaddr1: selectedPatient?.tempaddr1,
        tempcity: selectedPatient?.tempcity || "",
        tempteh: selectedPatient?.tempteh || "",
        tempdistrict: selectedPatient?.tempdistrict || "",
        reltoapplicant: selectedPatient?.reltoapplicant || "",
        llocation: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        refunit: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        nomncltre: selectedDoctor?.doctorname
          ? selectedDoctor?.nomn
          : commonVariableNomn,
        refdoctorid: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        treatingopd: selectedOpdOption?.descr || "",
        unit: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        // selectedOpdOption?.descr || selectedDoctor?.doctorname? selectedDoctor?.doctor_id: selectedOption?.code && selectedOpdOption?.descr,
        // entertained: null,
        // visitdate: moment(new Date()).format('DD/MM/YYYY'),
        message: "B",
      });
    }
  };

  useEffect(() => {
    if (enterAppointmentData) {
      createVisitForPatient({
        co: suborgid,
        pattype: selectedPatient?.patienttype,
        regnum: selectedPatient?.regnum,
        unit: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        tounit: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        invtype: "P",
        invnum: enterAppointmentData?.data?.advcnum,
        pl: price?.data[0]?.descr,
        nomn: selectedDoctor?.doctorname
          ? selectedDoctor?.nomn
          : commonVariableNomn,
        billto: selectedPatient?.patienttype,
        message: "B",
      });
    }
  }, [enterAppointmentData]); // eslint-disable-line
  useEffect(() => {
    if (enterAppointmentData) {
      changeEntStatus({
        co: suborgid,
        visitnum: enterAppointmentData?.data?.visitnum,
        regnum: selectedPatient?.regnum,
        message: "B",
      });
    }
  }, [enterAppointmentData]); // eslint-disable-line

  useEffect(() => {
    if (changeEntStatusData) {
      billInsertion({
        co: suborgid,
        regnum: selectedPatient?.regnum,
        reqnum: enterAppointmentData?.data?.visitnum,
        admnum: "",
        date: moment(new Date()).format("DD-MMM-YY"),
        ito: "REG",
        itodet: "CONSULTANCY",
        docid: selectedDoctor?.doctorname
          ? selectedDoctor?.doctor_id
          : commonVariableCode,
        multi: "1",
        message: "B",
      });
    }
  }, [changeEntStatusData]); // eslint-disable-line
  useEffect(() => {
    if (enterAppointmentData && entercreateVisitForPatientData) {
      getReport(
        `registration/AppointmentReciept/${enterAppointmentData?.data?.co}/${enterAppointmentData?.data?.visitnum}/${employeeid}`
      );
      setShowPdfModal(true);

      // setShowAddVisitModal(false)
    }
  }, [enterAppointmentData, entercreateVisitForPatientData]); // eslint-disable-line
  return (
    <>
      <div className="flex gap-2">
        <Form
          layout="vertical"
          form={form}
          onFinish={addVisit}
          disabled={tableData}
          initialValues={{ doctor: selectedDoctor?.doctorname, radio: "O",opd: rowData?.toopd, }}
          validateMessages={formValidationMessages}
          className="border border-gainsboro p-2 flex-[4]"
        >
          {/* <Form.Item name='radio' label='Visit OPD or Doctor'>
            <Radio.Group>
              <Radio value='O'>OPD</Radio>
              <Radio value='D'>Doctor</Radio>
            </Radio.Group>
          </Form.Item> */}
          <div className="grid grid-cols-1 gap-2">
            <Form.Item
              name="opd"
              label="OPD"
              
              // rules={[
              //   {
              //     required: radioValue === 'O',
              //   },
              // ]}
            >
              <Select
                placeholder="Select OPD"

                allowClear
                showSearch
                onChange={async (value) => {
                  const Option = lovOPDList?.data?.find(
                    (item) => item.code === value
                  );

                  setSelectedOpdOption(Option);
                  setCommonVariableNomn(Option?.nomncltr);
                  setCommonVariableCode(Option?.code);
                }}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
                options={lovOPDList?.data?.map((item) => ({
                  value: item?.code,
                  label: item?.descr,
                }))}
                disabled={
                  selectedDoctor?.doctorname ||
                  selectedPatient?.patienttype === "CNE"||
                  rowData?.toopd
                }
                // disabled={selectedPatient?.patienttype === 'CNE'}
              />
            </Form.Item>
            <Form.Item name="doctor" label="Doctor" shouldUpdate>
              <Select
                placeholder="Select Doctor"
                allowClear
                showSearch
                onChange={async (value) => {
                  const Option = lovDocList?.data?.find(
                    (item) => item.code === value
                  );

                  setSelectedOption(Option);
                  setCommonVariableNomn(Option?.nomnclhtr);
                  setCommonVariableCode(Option?.code);
                }}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
                options={lovDocList?.data?.map((item) => ({
                  value: item?.code,
                  label: item?.descr,
                }))}
                disabled={
                  selectedDoctor?.doctorname ||
                  selectedPatient?.patienttype !== "CNE"
                }
              />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" className="w-full">
            Get Appointment
          </Button>
        </Form>
        <Form
          id="addVisit"
          className="border border-gainsboro p-2 flex-[6]"
          onFinish={submitVisits}
        >
          <Table
            size="small"
            columns={tableColumns}
            dataSource={tableData}
            rowKey="doctor"
            pagination={false}
          />
        </Form>
      </div>
      <Modal
        title="Print Reciept"
        open={showPdfModal}
        onCancel={() => {
          setShowPdfModal(false);
          handlevisit();
        }}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Finance Report"
        ></iframe>
      </Modal>
    </>
  );
};

export default AddPatientVisit;
