import { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import useTableFilters from "../../../../hooks/useTableFilter";
import {
  useCreateDepartmentMutation,
  useGetAllDepartmentsQuery,
  useUpdateDepartmentMutation,
} from "../../orgStructureService";
import AddDepartment from "./AddDepartment";
import UpdateDepartment from "./UpdateDepartment";

const Department = () => {
  const { data, isLoading } = useGetAllDepartmentsQuery();
  const [createDepartment, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateDepartmentMutation();
  const [
    updateDepartment,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateDepartmentMutation();
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "deptid",
      ...filter("deptid"),
      sorter: (a, b) => a?.deptid - b?.deptid,
      defaultSortOrder: "descend",
    },
    {
      title: "Department Abbreviation",
      dataIndex: "deptabbrv",
      ...filter("deptabbrv"),
      sorter: (a, b) => a?.deptabbrv?.localeCompare(b?.deptabbrv),
    },
    {
      title: "Department Name",
      dataIndex: "deptname",
      ...filter("deptname"),
      sorter: (a, b) => a?.deptname?.localeCompare(b?.deptname),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Department">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
        <Tooltip title="New Department">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="deptid"
        />
      </div>

      <Modal
        title="Add Department"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addDepartmentForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddDepartment createDepartment={createDepartment} />
      </Modal>

      <Modal
        title="Update Department"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateDepartmentForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateDepartment
          rowData={rowData}
          updateDepartment={updateDepartment}
        />
      </Modal>
    </section>
  );
};

export default Department;
