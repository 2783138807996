import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../features/api/api';
import { rtkQueryLogger } from '../middlewares/rtkQueryLogger';
import authSlice from '../features/auth/authSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(rtkQueryLogger, api.middleware),

  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);
