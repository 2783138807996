import React, { useEffect, useState } from "react";
import {
  useGetItemSoldHistoryMutation,
  useLovItemListforPOSQuery,
} from "../inventorypurchaseService";
import { Button, DatePicker, Form, Select, Table } from "antd";
import useTableFilters from "../../../hooks/useTableFilter";

const Historypos = () => {
  const [form] = Form.useForm();
  const filter = useTableFilters();
  const [tableData, setTableData] = useState([]);
  const [saveposhistory, { data: getposData, isError: getError }] =
    useGetItemSoldHistoryMutation();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();
  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customername",
      ...filter("customername"),
    },
    {
      title: "Inv Number",
      dataIndex: "invnum",
      ...filter("invnum"),
    },
    {
      title: "Description",
      dataIndex: "itemname",
      ...filter("itemname"),
    },
    {
      title: "Date",
      dataIndex: "itemdate",
    },
    {
      title: "Time",
      dataIndex: "itemtime",
    },
    {
      title: "Qauntity",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Discount",
      dataIndex: "discountamt",
    },
    {
      title: "Total",
      dataIndex: "totalprice",
    },
  ];
  useEffect(() => {
    if (getposData) {
      setTableData(getposData?.data);
    }
  }, [getposData]);
  useEffect(() => {
    if (getError) {
      setTableData([]);
    }
  }, [getError]);
  const onFinish = (values) => {
    saveposhistory({
      fromdate: values?.fromdate.format("DD-MMM-YYYY"),
      todate: values?.todate.format("DD-MMM-YYYY"),
      itemid: values.itemid,
    });
  };
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Point of Sale (History)
      </h1>
      <div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className=" grid sm:grid-cols-3 grid-cols-1 gap-1">
            <Form.Item name="fromdate" label="From Date">
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item name="todate" label="To Date">
              <DatePicker format={"DD-MMM-YYYY"} />
            </Form.Item>
            <Form.Item name="itemid" label="Item Name">
              <Select
                placeholder="Select Item Name"
                options={LovItemListforPOS?.data?.map((item) => ({
                  value: item?.itemid,
                  label: `${item?.item_name}  Qty:${item.quantity}`,
                }))}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div>
        <Table
          size="small"
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "fit-content" }}
        />
      </div>
    </section>
  );
};
export default Historypos;
