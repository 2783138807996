import React, { useState } from 'react';
import { Table, Select, Input, Form, Button, Modal } from "antd";
import Vitals from './vitals';
import { useGetOpdDocListOnUnitQuery, useLovMedicineAllQuery } from '../dialysisService';
export default function DialysisTreatment() {
  const [showvitalsmodal,setShowvitalsmodal]=useState(false)
  const { data: getOpdDocListOnUnit } = useGetOpdDocListOnUnitQuery();
  const { data: lovMedicineAll } = useLovMedicineAllQuery();
  const columns = [
    {
      title: 'MR No',
      dataIndex: 'MR',
      key: 'MR',
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientname',
      key: 'age',
    },
    {
      title: 'Father/Husband Name',
      dataIndex: 'fathername',
      key: 'fathername',
    },
    {
      title: 'Patient Type',
      dataIndex: 'patienttype',
      key: 'patienttype',
    },
  ];
  const data = [
    {
      key: '1',
      MR: '1',
      patientname: 'Ali',
      fathername: 'Ahmed',
      patienttype:'Beni',
      
    },
    {
      key: '2',
      MR: '2',
      patientname: 'Umar',
      fathername: 'Bilal',
      patienttype:'CNE',
    },
    
  ];
  
 
  return (
<div> 
    <div>
    
    <h1 className=' col-span-2 flex justify-center items-center font-bold h-12 text-2xl'>Dialysis Treatment</h1>

      </div>
  
    <Table className='border border-gainsboro p-1'
    columns={columns} dataSource={data}
    pagination={false}  />
 

<Form 
layout='vertical'>


<div className='border border-gainsboro  p-2 grid grid-cols-4 gap-2 mt-2'>
<Form.Item
        name='Reffered' 
        label='Reffered From'
        >
        <Input />
</Form.Item>

<Form.Item
        name='machineNo' 
        label='Machine No.'
        rules={[
          {
            required: true,
          },
        ]}
        >
        <Input />

</Form.Item>

<Form.Item
        
        name='prescribeDate' 
        label='Prescribe Date'
        >
        <Input />
</Form.Item>

<Form.Item
        
        name='adm'
        label='Adm Num'
        >
        <Input />
</Form.Item>
</div>
</Form>


<Form 
layout='vertical'>
<h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-11 mt-2">
            Dialysis Patient Details
          </h1>
<div className='grid grid-cols-2 gap-2 mt-2'>
<div className='border border-gainsboro p-2 grid grid-cols-4 gap-2 '>

<Form.Item
        className='mb-0'
        name='gen'
        label='Gen Condition'
        >
        <Select
       placeholder='Select'
          options={[
            { value: 'S', label: 'Stable' },
            { value: 'I', label: 'Sick' }
          ]}
        />
      </Form.Item>
      <Form.Item
      
        name='oedema'
        label='Oedema'
        >
        <Select

        placeholder='Select'
      
          options={[
            { value: 'P', label: 'Positive' },
            { value: 'N', label: 'Negative' }
          ]}
        />
      </Form.Item>  

         <Form.Item
      
        name='pallor'
        label='Pallor'
       
        >
        <Select
         placeholder='Select'
          
          options={[
            { value: 'P', label: 'Positive' },
            { value: 'N', label: 'Negative' }
          ]}
        />
      </Form.Item>
      <Form.Item
       
        name='cns'
        label='CNS'
        
        >
        <Input autoFocus
       
        />
      </Form.Item>

      <Form.Item
        
        name='bonus'
        label='Bonus'
        >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
    
        name='kt'
        label='Kt/V'
        >
        <Input autoFocus />
      </Form.Item>

       <Form.Item
       
        name='vascular'
        label='Vascular Acc'
        >
        <Input autoFocus />
      </Form.Item>
       
       <Form.Item
        
        name='hbs'
        label='HBS As'
        >
        <Input autoFocus />
      </Form.Item>

</div>

<div className='border border-gainsboro grid grid-cols-4 gap-2 mt-0 p-2'>
<Form.Item
       
        name='cvs'
        label='CVS'
        >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
       
        name='resp'
        label='Resp'
        >
        <Input autoFocus />
      </Form.Item>

        <Form.Item
        
        name='gi'
        label='GI'
        >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
       
        name='bloodflow'
        label='Blood Flow'
        >
        <Input autoFocus />
      </Form.Item>
       <Form.Item
     
        name='hrly'
        label='Hrly Rate'
        >
        <Input autoFocus />
      </Form.Item>
      <Form.Item
        
        name='dialysisflow'
        label='Dialysis Flow'
        >
        <Input autoFocus />
      </Form.Item>
       <Form.Item
       
        name='hcv'
        label='HCV'
        >
        <Input autoFocus />
      </Form.Item> 

      <Form.Item
      
        name='complications'
        label='Complications'
        >
        <Input autoFocus />
      </Form.Item> 
</div>
</div>
</Form> 

<div className='border border-gainsboro mt-2'> 
<Form 
layout='vertical'>
<h1 className="flex justify-center items-center bg-[#3d92bc] font-semibold text-white h-11 ">
            Medicines Prescription
          </h1>
<div className='p-2 grid grid-cols-6 gap-2 mt-2'>
<Form.Item
        name='Medicine'
        label='Medicine '
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Medicine'
          options={lovMedicineAll?.data?.map(item => ({
            value: item?.pridescr,
            label: `${item?.pridescr}`,
          }))}
          showSearch
          filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
        />
      </Form.Item>

      <Form.Item
       
       name='type' 
       label='Type'
       >
       <Input />
</Form.Item>

<Form.Item
       
        name='strength' 
        label='Strength'
        >
        <Input />
</Form.Item>

<Form.Item
     
        name='onhand'
        label='On Hand'
        >
        <Input />
</Form.Item>
<Form.Item
        
        name='QTY'
        label='QTY'
        >
        <Input />
</Form.Item>
<Form.Item 

name="Status" 
label="Status"
 >
  <Select
   placeholder="Select"
   options={[
   { value: "G", label: "Given" },
   { value: "N", label: "Not given" },
    ]}
                 
     />
    </Form.Item>
</div>
<div className=' p-2 grid grid-cols-5  gap-2' > 
    <Form.Item
        
        name='purchasedmedicine'
        label='Self Purchased Medicines'
        >
        <Input />
</Form.Item> 

      <Form.Item 

        
        name='dialysis technician'
        label='Dialysis Technician'
        >
        <Input />
</Form.Item>

<Form.Item
        name='dialysisSpecialist'
        label='Dialysis Specialist '
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder='Select Dialysis Specialist'
          options={getOpdDocListOnUnit?.data?.map(item => ({
            value: item?.employeename,
            label: `${item?.employeename}`,
          }))}
          showSearch
          filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
        />
      </Form.Item>

{/* <Form.Item className='w-80'
        name='dialysisSpecialist'
        label='Dialysis Specialist'
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Select

placeholder='Select Dialysis Specialist'
/>
      
      </Form.Item> */}

<div className="col-span-2 flex items-center mt-8 justify-end gap-2">
<Form.Item>
<Button onClick={()=>{setShowvitalsmodal(true)}}
type='primary'>
  Vitals
</Button></Form.Item>
<Form.Item>
<Button type='primary'>
Treatment Completed
</Button></Form.Item>

<Form.Item>

<Modal open={showvitalsmodal}onCancel={()=>{setShowvitalsmodal(false)}}   width={1000} >
<Vitals/>
</Modal></Form.Item></div>

</div>
</Form>
</div>

      


    </div>
  )
    }