import moment from 'moment'

export const futureDates = current => current && current > moment().endOf('day')
export const pastDates = current => current && current < moment().startOf('day')
export const getAge = dob => moment.duration(moment().diff(dob)).humanize()
export const formateDate = date => (moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : '')
export const formateDateWithTime = date => (moment(date).isValid() ? moment(date).format('DD-MMM-YYYY HH:mm:ss') : '')
export const formateWithTime = time => (moment(time).isValid() ? moment(time).format('HH:mm:ss') : '')
export const formateDateToMoment = date => (date ? moment(date) : date)
export const getAgeInYears = dob => moment.duration(moment().diff(dob)).years()
export const newformateDate = date => (moment(date).isValid() ? moment(date).format('DD-MMM-YYYY') : '')
export const pastTime = current => current && current < moment().startOf('time')
