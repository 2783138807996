import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useTableFilters from "../../../hooks/useTableFilter";

import AddEmpreg from "../modals/AddEmpreg";
// import UpdateEmpreg from "../modals/UpdateEmpreg";
import {
  useCreateHmsEmployeeMutation,
  useGetEmployeedataQuery,
  useUpdateHmsEmployeeMutation,
} from "../employeeServices";
import { UpdateEmpreg } from "../modals/UpdateEmpreg";
const EmployeeRegistration = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const { data: getHmsEmpreg, refetch } = useGetEmployeedataQuery();
  const [saveEmpreg, { isSuccess: empregSuccess }] =
    useCreateHmsEmployeeMutation();
  const [updateregempdata, { isSuccess: empregupdateSuccess }] =
    useUpdateHmsEmployeeMutation();
  useEffect(() => {
    if (getHmsEmpreg) {
      setTableDatainfo(getHmsEmpreg?.data);
    }
  }, [getHmsEmpreg]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (empregSuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [empregSuccess]); //eslint-disable-line
  useEffect(() => {
    if (empregupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [empregupdateSuccess]); //eslint-disable-line
  const tableColumns = [
    {
      title: "Title ID",
      dataIndex: "titleid",
      ...filter("titleid"),
    },
    {
      title: "Name",
      dataIndex: "fname",
      ...filter("fname"), //mname,lname
    },
    {
      title: "Father Name",
      dataIndex: "fathername",
    },
    {
      title: "DOB",
      dataIndex: "dob",
      ...filter("dob"), //formate (yyyy-mm-dd)
    },
    {
      title: "DOJ",
      dataIndex: "doj",
      ...filter("doj"),
    },
    {
      title: "CNIC",
      dataIndex: "cnic",
      ...filter("cnic"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...filter("gender"),
    },
    {
      title: "Emp Type",
      dataIndex: "emptype",
      ...filter("emptype"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Empreg">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Empreg</h1>
        <Tooltip empreg="Add Empreg ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          //   loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="type"
        />
      </div>
      <Modal
        title="Employee Registration "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addempreg"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddEmpreg saveEmpreg={saveEmpreg} empregSuccess={empregSuccess} />
      </Modal>

      <Modal
        title="Update Employee Registration"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateempreg"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateEmpreg rowData={rowData} updateregempdata={updateregempdata} />
      </Modal>
    </section>
  );
};

export default EmployeeRegistration;
