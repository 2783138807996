import { Tabs } from "antd";
import React from "react";
import Bloodgroup from "../pages/BloodGroup";
import Country from "../pages/Country";
import ProvincePage from "../pages/ProvincePage";
import CityPage from "../pages/CityPage";
import DeptPage from "../pages/DeptPage";
import DesignationPage from "../pages/DesignationPage";
import MStatusPage from "../pages/MStatusPage";
import ReligionPage from "../pages/ReligionPage";
import TitlePage from "../pages/TitlePage";

const EmployeeConfigurators = () => {
  return (
    <section className="border border-gainsboro">
      <div
        className="flex items-center justify-between px-5 py-3"
        style={{ backgroundColor: "#00b29c" }}
      >
        <h1 className="font-semibold text-base sm:text-lg text-white">
          Employee Configurators
        </h1>
      </div>
      <div className="p-5">
        <Tabs
          items={[
            { label: "Blood Group", key: "1", children: <Bloodgroup /> },
            { label: "Country", key: "2", children: <Country /> },
            { label: "Province", key: "3", children: <ProvincePage /> },
            { label: "City", key: "4", children: <CityPage /> },
            { label: "Department", key: "5", children: <DeptPage /> },
            { label: "Designation", key: "6", children: <DesignationPage /> },
            { label: "Marital Status", key: "7", children: <MStatusPage /> },
            { label: "Religion", key: "8", children: <ReligionPage /> },
            { label: "Title", key: "9", children: <TitlePage /> },
          ]}
        />
      </div>
    </section>
  );
};

export default EmployeeConfigurators;
