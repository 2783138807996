import { useState } from "react";
import openNotification from "../utils/notification";

const useBase64 = () => {
  const [fileData, setFileData] = useState(null);
  const getBase64 = (
    e,
    size = 300,
    isImage = true,
    clear = false,
    isPDF = false
  ) => {
    const file = e.target.files[0];
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng && isImage) {
      openNotification("error", "Please upload a JPG or PNG File!");
      document.getElementById(e.target.id).value = "";
      return;
    }
    if (isPDF && file.type !== "application/pdf") {
      openNotification("error", "Please upload a PDF file!");
      document.getElementById(e.target.id).value = "";
      return;
    }
    const isLt2M = file.size / 1024 < size;
    if (!isLt2M) {
      openNotification(
        "error",
        `${isImage ? "Image" : "File"} must smaller than ${size}KB!`
      );
      document.getElementById(e.target.id).value = "";
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileData({
        name: file.name.split(".")[0],
        ext: file.name.split(".")[1],
        data: reader.result,
      });
      if (clear) document.getElementById(e.target.id).value = "";
    };
    reader.onerror = (error) => {
      openNotification("error", error);
    };
  };
  const clearData = () => setFileData(null);
  return [fileData, getBase64, clearData];
};

export default useBase64;
