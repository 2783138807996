import InventoryCode from "../features/inventoryConfigurators/pages/InventoryCode";
import InventoryConfiguration from "../features/inventoryConfigurators/pages/InventoryConfiguration";
import VendorDetails from "../features/inventoryConfigurators/pages/VendorDetails";
export const inventoryConfigurationRoutes = [
	{
		path: 'InventoryConfiguration',
		element: <InventoryConfiguration/>,
	},

	{
		path: 'InventoryCode',
		element: <InventoryCode/>,
	},

	{
		path: 'VenderDetail',
		element: <VendorDetails/>,
	},
	
	
]
