import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'
import { useSelector } from 'react-redux';
import moment from 'moment'

const UpdateStatus=({rowData,updateStatusData,updatestatus}) =>{
  const co = useSelector((state) => state.auth.user.co);
  const [form] = Form.useForm()
  const onFinish = (values) =>{
    updatestatus({
      regnum:values?.regnum,
        status: values?.status,
        reqnum:rowData?.reqnum,
        co:co,
        test:rowData?.test,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='UpdateStatus'
    form={form}
    initialValues={{
      regnum:rowData?.regnum,
      patientname:rowData?.patientname,
      patienttype:rowData?.patienttype,
      adviseddate:moment(rowData?.adviseddate).format("DD-MMM-YYYY"),
      fromunit:rowData?.fromunit,
      status:updateStatusData?updateStatusData?.data.status:rowData?.status,
     
    }}
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>
      <Form.Item name='regnum' label='MR. No'>
        <Input disabled
        placeholder='Enter MR. No'></Input>
      </Form.Item>
      <Form.Item name='patientname' label='Patient Name'>
        <Input disabled
        placeholder='Enter Patient Name' />
      </Form.Item>
      <Form.Item name='patienttype' label='Patient Type'>
        <Input disabled
        placeholder='Enter Patient Type' />
      </Form.Item>
      <Form.Item name='adviseddate' label='Advised Date'>
        <Input disabled
        placeholder='Enter Advised Date' />
      </Form.Item>
      <Form.Item name='fromunit' label='From Unit'>
        <Input disabled
        placeholder='Enter From Unit' />
      </Form.Item>
      
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Referal', value: 'R' },
            { label: 'Not Performed', value: 'N' },
            { label: 'Performed', value: 'V' },
          ]}
        />
      </Form.Item>
    </div>
  </Form>
   </div>
  )
}

export default UpdateStatus