import { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import useTableFilters from "../../../../hooks/useTableFilter";
import {
  useCreateProjectMutation,
  useGetAllProjectsQuery,
  useUpdateProjectMutation,
} from "../../orgStructureService";
import AddProject from "./AddProject";
import UpdateProject from "./UpdateProject";

const Project = () => {
  const { data, isLoading } = useGetAllProjectsQuery();
  const [createProject, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateProjectMutation();
  const [
    updateProject,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateProjectMutation();
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "projectid",
      ...filter("projectid"),
      sorter: (a, b) => a?.projectid - b?.projectid,
      defaultSortOrder: "descend",
    },
    {
      title: "Project Name",
      dataIndex: "projectname",
      ...filter("projectname"),
      sorter: (a, b) => a?.projectname?.localeCompare(b?.projectname),
    },
    {
      title: "Project Description",
      dataIndex: "projectdescr",
      ...filter("projectdescr"),
      sorter: (a, b) => a?.projectdescr?.localeCompare(b?.projectdescr),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Project">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
        <Tooltip title="New Project">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="projectid"
        />
      </div>

      <Modal
        title="Add Project"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addProjectForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddProject createProject={createProject} />
      </Modal>

      <Modal
        title="Update Project"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateProjectForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateProject rowData={rowData} updateProject={updateProject} />
      </Modal>
    </section>
  );
};

export default Project;
