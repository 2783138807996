import { api } from '../api/api'

export const salesdashboardApi = api.injectEndpoints({
  endpoints: build => ({


 
    getMonthTotalSale: build.query({
        query: () => `pos/getMonthTotalSale`,
        
      }), 

      getMonthTotalCreditSale: build.query({
        query: () => `pos/getMonthTotalCreditSale`,
        
      }), 


      getDashboardSummary: build.mutation({
        query: (body) => ({
          url: `pos/getDashboardSummary/${body.fromdate}/${body.todate}`,
        }),
      }),



  }),
})

export const {
  useGetMonthTotalSaleQuery,
  useGetMonthTotalCreditSaleQuery,
  useGetDashboardSummaryMutation,

 
} = salesdashboardApi
