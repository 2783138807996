import { Tabs } from 'antd'
import React from 'react'
import WardBedTypeMaintenance from '../components/wardConfiguration/WardBedTypeMaintenance'


import NomncltreControl from '../components/wardConfiguration/NomncltreControl'
import BedsMaintenance from '../components/wardConfiguration/BedsMaintenance'



function WardConfiguration() {
  return (
    <section className='border border-gainsboro'>
      <div className='flex items-center justify-between px-5 py-3 bg-lotion border-b border-gainsboro'>
        <h1 className='font-semibold text-base sm:text-lg'>
          Ward Configuration
        </h1>
      </div>
      <div className='p-5'>
        <Tabs
         items={[
            // {
            //   label: 'Ward Control',
            //   key: '1',
            //   children: <WardControl />,
            // },
            // {label:'Procedure Maintenance',key:'2',children: <ProcedureMaintenance/>},
            {label:'Ward Bed Type Maintenance',key:'3',children:<WardBedTypeMaintenance/>},
            // {label:'Ward Bed Accessories',key:'4',children:<WardBedAccessories/>},
            // {label:'Manage Ward Bed Configuration',key:'5',children:<ManageWardBedConfiguration/>},
            // {label:'Ward Slot Maintenance',key:'6',children:<WardSlotMaintenance/>},
            {label:'Nomncltre Control',key:'7',children:<NomncltreControl/>},
            {label:'Beds Maintenance',key:'8',children:<BedsMaintenance/>},
            
          ]}
        />
      </div>
    </section>
  )
}

export default WardConfiguration