import { Button, DatePicker, Form, Input, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useGetPendingQuotationMutation,
  useGetQuotationDataMutation,
} from "../inventorypurchaseService";
import moment from "moment";
import openNotification from "../../../utils/notification";
export const QuotationPending = ({
  tableDataMaster,
  setTableDataMaster,
  itemSelectRefqty,
  handlecancel,
}) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [rowdata, setrowdata] = useState(null);
  const [savepurchasepending, { data: getpurchasepending, isError: pperror }] =
    useGetPendingQuotationMutation();
  const [saveppdetail, { data: getpurchasedetail }] =
    useGetQuotationDataMutation();
  useEffect(() => {
    savepurchasepending({
      fromdate: moment().format("DD-MMM-YYYY"),
      todate: moment().format("DD-MMM-YYYY"),
      message: "B",
    });
  }, []); //eslint-disable-line
  useEffect(() => {
    if (getpurchasepending) {
      setTableData(getpurchasepending?.data);
    }
  }, [getpurchasepending]);
  useEffect(() => {
    if (pperror) {
      setTableData([]);
    }
  }, [pperror]);
  useEffect(() => {
    if (getpurchasedetail) {
      const updatedTableData = getpurchasedetail?.data.map((item) => {
        if (item.purchaseid === rowdata.purchaseid)
          return {
            ...item,
            item: item.itemname,
            item_name: item.itemname,
            itemid: item.itemid,
            price: item.purchaseprice,
            purchaseprice: item.saleprice,
            quantity: item.quantity,
          };
        return item;
      });
      if (updatedTableData) {
        const duplicate = tableDataMaster?.find(
          (item) => item.itemid === tableDataMaster?.item
        );
        if (!duplicate) {
          setTableDataMaster((prevTableData) => [
            ...prevTableData,
            ...updatedTableData,
          ]);
          setTimeout(() => {
            if (itemSelectRefqty.current) {
              itemSelectRefqty.current.focus();
            }
          }, 100);
          handlecancel();
        } else openNotification("error", "Item is Already in The Table", 10);
      }
    }
  }, [getpurchasedetail]); //eslint-disable-line
  const columns = [
    {
      title: "Quotation No",
      dataIndex: "quotationsrno",
    },
    {
      title: "Invoice Date",
      dataIndex: "invdate",
      render: (_, { invdate }) => moment(invdate).format("DD-MMM-YYYY"),
    },
    // {
    //   title: "Ref No",
    //   dataIndex: "refno",
    // },
    {
      title: "Supplier",
      dataIndex: "customername",
    },
    {
      title: "Net Total",
      dataIndex: "totalamt",
    },
    // {
    //   title: "Computer",
    //   dataIndex: "computer",
    // },
    {
      title: "Payment",
      dataIndex: "totalpaid",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Time",
      dataIndex: "invdate",
      render: (_, { invdate }) => moment(invdate).format("HH:mm:ss"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
  ];
  const onFinish = (values) => {
    savepurchasepending({
      fromdate: moment(values.fromdate).format("DD-MMM-YYYY"),
      todate: moment(values.todate).format("DD-MMM-YYYY"),
      message: "B",
    });
  };
  return (
    <section>
      <div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className=" grid sm:grid-cols-3 grid-cols-1 gap-1">
            <Form.Item name="fromdate" label="From Date" className=" mb-0">
              <DatePicker format={"DD-MMM-YYYY"} defaultValue={moment()} />
            </Form.Item>

            <Form.Item name="todate" label="To Date" className=" mb-0">
              <DatePicker format={"DD-MMM-YYYY"} defaultValue={moment()} />
            </Form.Item>
            <Form.Item name="user" label="User" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="pos" label="Pos">
              <Input />
            </Form.Item>
            <Form.Item label=" ">
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div>
        <Table
          size="small"
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "fit-content" }}
          onRow={(record) => {
            return {
              onClick: () => {
                setrowdata(record);
                saveppdetail({
                  quotationsrno: record?.quotationsrno,

                  message: "B",
                });
              },
            };
          }}
        />
      </div>
    </section>
  );
};
