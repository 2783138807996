import { Form, Input, InputNumber, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateModule = ({ updateModule, rowData }) => {
	const onFinish = values =>
		updateModule({
			...values,
			moduleid: values.modulecode.toUpperCase(),
			descr: values.descr.toUpperCase(),
			context: values.context.toLowerCase(),
			message: 'B',
		})

	return (
		<Form
			layout='vertical'
			onFinish={onFinish}
			id='updateModuleForm'
			validateMessages={formValidationMessages}
			initialValues={{ ...rowData }}
			className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
			<Form.Item
				name='modulecode'
				label='Module Code'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Module Code' disabled />
			</Form.Item>
			<Form.Item
				name='descr'
				label='Description'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Description' autoFocus />
			</Form.Item>
			<Form.Item
				name='icon'
				label='Icon'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Icon' />
			</Form.Item>
			<Form.Item
				name='port'
				label='Port'
				rules={[
					{
						required: true,
					},
				]}>
				<InputNumber controls={false} placeholder='Enter Port' />
			</Form.Item>
			<Form.Item
				name='ipaddress'
				label='IP Address'
				rules={[
					{
						required: true,
					},
					{
						pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
					},
				]}>
				<Input placeholder='Enter IP Address' />
			</Form.Item>
			<Form.Item
				name='context'
				label='Context'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Context' />
			</Form.Item>
			<Form.Item
				name='status'
				label='Status'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
		</Form>
	)
}

export default UpdateModule
