import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddClass = ({ createclass }) => {
  const onFinish = (values) => {
    createclass({
      ...values,
      status: values?.status || "Y",
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="addclassForm"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item
          name="classname"
          label="Class Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Class Name" />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            defaultValue={"Y"}
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddClass;
