import WardConfiguration from "../features/wardConfigurators/pages/WardConfiguration";


export const wardConfigurationRoutes = [
	{
		path: 'WardConfiguration',
		element: <WardConfiguration/>,
	},
	
]
