import { Tabs } from 'antd'
import Department from '../components/orgConfiguration/Department'
import OpdList from '../components/orgConfiguration/OpdList'
import Project from '../components/orgConfiguration/Project'
import ProjectCatagory from '../components/orgConfiguration/ProjectCatagory'
import Section from '../components/orgConfiguration/Section'
import SubDepartment from '../components/orgConfiguration/SubDepartment'



const OrgConfigutration = () => {
  return (
    <section className='border border-gainsboro'>
      <div className='flex items-center justify-between px-5 py-3 bg-lotion border-b border-gainsboro'>
        <h1 className='font-semibold text-base sm:text-lg'>
          Organizational Configuration
        </h1>
      </div>
      <div className='p-5'>
        <Tabs
          items={[
            {
              label: 'Project Catagory',
              key: '1',
              children: <ProjectCatagory />,
            },
            { label: 'Project', key: '2', children: <Project /> },
            { label: 'Department', key: '3', children: <Department /> },
            { label: 'Sub Department', key: '4', children: <SubDepartment /> },
            { label: 'Section', key: '5', children: <Section /> },
            { label: 'Opd List', key: '6', children: <OpdList /> },
            // {label:'Ward Control', key:'7', children: <WardControl/>},
            // {label:'Procedure Maintenance',key:'8',children: <ProcedureMaintenance/>},
            // {label:'Ward Bed Type Maintenance',key:'9',children:<WardBedTypeMaintenance/>},
            // {label:'Ward Bed Accessories',key:'10',children:<WardBedAccessories/>},
            // {label:'Manage Ward Bed Configuration',key:'11',children:<ManageWardBedConfiguration/>},
            // {label:'Ward Slot Maintenance',key:'12',children:<WardSlotMaintenance/>}
      

          ]}
        />
      </div>
    </section>
  )
}

export default OrgConfigutration
