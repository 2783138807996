import { useEffect } from 'react'
import { Button, Form, Input, Select } from 'antd'
import {
  useGetUserMutation,
  useLovAllUsersQuery,
  useLovRoleProjectWiseMutation,
  useTransferUserMutation,
} from '../securityService'
import { useGetAllResponsibilityQuery, useLovProjectQuery } from '../../finance/financeService'
import formValidationMessages from '../../../utils/formValidationMessages'

const TransferUser = () => {
  const { data: users } = useLovAllUsersQuery()
  const { data: responsibilities } = useGetAllResponsibilityQuery()
  const { data: projects } = useLovProjectQuery()
  const [getUser, { data: user }] = useGetUserMutation()
  const [lovRoleProjectWise, { data: roles }] = useLovRoleProjectWiseMutation()
  const [transferUserFunc] = useTransferUserMutation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (user?.data)
      form.setFieldsValue({
        username: user?.data?.username,
        currentProject: user?.data?.projectName,
      })
  }, [user]) //eslint-disable-line

  const onFinish = values => transferUserFunc({ ...values, message: 'B' })

  return (
    <section>
      <div className='flex items-center justify-between px-5 py-3 '>
        <h1 className='font-semibold text-base sm:text-lg'>Transfer Of Employees</h1>
      </div>
      <div className='p-5'>
        <Form layout='vertical' onFinish={onFinish} validateMessages={formValidationMessages} form={form}>
          <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
            <Form.Item
              name='userId'
              label='User'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder='Select User'
                onChange={e => getUser(e)}
                options={users?.data?.map(item => ({
                  value: item?.code,
                  label: item?.descr,
                }))}
                showSearch
              />
            </Form.Item>
            <Form.Item
              name='username'
              label='User Name'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='currentProject'
              label='Current Project'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='respId'
              label='Responsibility'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder='Select Responsibility'
                options={responsibilities?.data?.map(item => ({
                  value: item?.respid,
                  label: item?.respname,
                }))}
                showSearch
                filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
              />
            </Form.Item>
            <Form.Item
              name='projectId'
              label='Project'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder='Select Project'
                onChange={e => {
                  lovRoleProjectWise(e)
                  form.setFieldsValue({ roleId: undefined })
                }}
                options={projects?.data?.map(item => ({
                  value: item?.code,
                  label: item?.descr,
                }))}
                showSearch
                filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
              />
            </Form.Item>
            <Form.Item
              name='roleId'
              label='Role'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder='Select Role'
                options={roles?.data?.map(item => ({
                  value: item?.code,
                  label: item?.descr,
                }))}
                showSearch
                filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
              />
            </Form.Item>
          </div>
          <div className='grid place-content-end'>
            <Button type='primary'>Transfer</Button>
          </div>
        </Form>
      </div>
    </section>
  )
}

export default TransferUser
