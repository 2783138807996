import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const LineChart = ({ LineData }) => {
  // Check if LineData exists and has data
  const isDataEmpty = !LineData || !LineData.data || LineData.data.length === 0;

  // Map the data for the chart series
  const chartData = LineData?.data?.map(item => ({
    name: moment(item.monthdate).format('MMMM DD'), // Format to Month Day (e.g., November 07)
    value: Number(item.totalamount), // Convert total amount to a number
  })) || [];

  // Highcharts configuration options for the line chart
  const options = {
    chart: {
      type: 'line',
      height: 250, // Chart height
    },
    title: {
      text: 'Monthly Total Credit Sales',
    },
    xAxis: {
      categories: chartData.map(item => item.name), // Use formatted dates as x-axis labels
      title: {
        text: 'Date',
      },
    },
    yAxis: {
      title: {
        text: 'Total Amount ($)', // Label for y-axis
      },
      min: 0, // Start y-axis from 0
    },
    credits: {
      enabled: false, // Disable Highcharts credits
    },
    series: [
      {
        name: 'Sales',
        data: chartData.map(item => item.value), // Use total amount as y-values
        color: '#2CAFFE',
      },
    ],
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br/>Total Credit Sales: $${this.y}`;
      },
    },
  };

  return (
    <div className="border border-border shadow-lg mx-2 my-2">
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full">
        Monthly Total Credit Sales
      </h4>
      {!isDataEmpty ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <p className="p-2 text-center text-gray-500">No data available for this month.</p>
      )}
    </div>
  );
};

export default LineChart;
