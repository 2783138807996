import { api } from '../api/api'

const StoreConfigurationApi = api.injectEndpoints({
    
	endpoints: build => ({
  
        getAllManufecturer: build.query({
            query: () => `security/getAllManufecturer`,
            
          }), 
      
          CreateSOManufecturer: build.mutation({
            query: (body) => ({
              url: "security/createSOManufecturer",
              method: "POST",
              body,
             
            }),
          }),  
  
        getAllMedTypes: build.query({
            query: () => `security/getAllMedTypes`,
            
          }),
        
          CreateMedTypes: build.mutation({
            query: (body) => ({
              url: "security/createMedTypes",
              method: "POST",
              body,
             
            }),
          }),  

          getAllMedStrength: build.query({
            query: () => `security/getAllMedStrength`,
            
          }),
        
          CreateMedStrength: build.mutation({
            query: (body) => ({
              url: "security/createMedStrength",
              method: "POST",
              body,
             
            }),
          }),  

          getAllRacks: build.query({
            query: () => `security/getAllRacks`,
            
          }),
        
          CreateMedRacks: build.mutation({
            query: (body) => ({
              url: "security/createMedRacks",
              method: "POST",
              body,
             
            }),
          }),  

          getAllMaterials: build.query({
            query: () => `security/getAllMaterials`,
            
          }),
        
          CreateMaterialMaster: build.mutation({
            query: (body) => ({
              url: "security/createMaterialMaster",
              method: "POST",
              body,
             
            }),
          }),  

          lovManufacturer: build.query({
            query: () => `security/lovManufacturer`,
            
          }), 
          lovMedtype: build.query({
            query: () => `security/lovMedtype`,
            
          }), 
          lovMedStrength: build.query({
            query: () => `security/lovMedStrength`,
            
          }), 
          lovrack: build.query({
            query: () => `security/lovrack`,
            
          }), 
 
 
 
    
  }),

 
})

export const {
useGetAllManufecturerQuery,
useCreateSOManufecturerMutation,
useGetAllMedTypesQuery,
useCreateMedTypesMutation,
useGetAllMedStrengthQuery,
useCreateMedStrengthMutation,
useGetAllRacksQuery,
useCreateMedRacksMutation,
useGetAllMaterialsQuery,
useCreateMaterialMasterMutation,
useLovManufacturerQuery,
useLovMedtypeQuery,
useLovMedStrengthQuery,
useLovrackQuery,
        
} = StoreConfigurationApi
