import { Form, Input, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddBusinessModule = ({ createBussFunc, moduleid, moduleDescr }) => {
	const onFinish = values =>
		createBussFunc({
			...values,
			moduleid,
			bussfunc: values.bussfunc.toUpperCase(),
			title: values.title.toUpperCase(),
			icon: values.icon.toUpperCase(),
			path: values.path.toLocaleLowerCase(),
			message: 'B',
		})

	return (
		<Form
			layout='vertical'
			onFinish={onFinish}
			id='addNewBusinessModuleForm'
			validateMessages={formValidationMessages}
			className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
			<Form.Item name='moduleid' label='System Module'>
				<Input placeholder={`${moduleid} - ${moduleDescr}`} disabled />
			</Form.Item>
			<Form.Item
				name='bussfunc'
				label='Business Function'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Business Function' autoFocus />
			</Form.Item>
			<Form.Item
				name='title'
				label='Title'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Title' />
			</Form.Item>
			<Form.Item
				name='icon'
				label='Icon'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Icon' />
			</Form.Item>
			<Form.Item
				name='path'
				label='Path'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Path' />
			</Form.Item>
			<Form.Item
				name='status'
				label='Status'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
		</Form>
	)
}

export default AddBusinessModule
