import { api } from '../api/api'

const RegistrationApi = api.injectEndpoints({
    
	endpoints: build => ({
  

        getPatientByDateWise: build.mutation({
            query: (body) => ({
              url: `security/getPatientByDateWise/${body.fromdate}/${body.todate}`,
            }),
          }),  
          
      getPatientByMobileNo: build.mutation({
      query: (body) => ({
        url: `security/getPatientByMobileNo/${body.cellno}`
      }),
    }),

    CreatePatient: build.mutation({
      query: (body) => ({
        url: "security/createPatient",
        method: "POST",
        body,
       
      }),
    }),

    CreateAppoint: build.mutation({
      query: (body) => ({
        url: "security/CreateAppoint",
        method: "POST",
        body,
       
      }),
    }),
 
    
  }),

 
})

export const {
 useGetPatientByDateWiseMutation,
 useGetPatientByMobileNoMutation,
 useCreatePatientMutation,
 useCreateAppointMutation,
        
} = RegistrationApi
