import { Button, DatePicker, Form, Input, Modal, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { futureDates } from '../../../utils/dateUtils';
import { useCreateAppointMutation, useGetPatientByDateWiseMutation, useGetPatientByMobileNoMutation } from '../registrationServices';
import moment from "moment";
import { PlusOutlined } from '@ant-design/icons';
import AddVisit from '../components/AddVisit';
import useFetchPDF from '../../../hooks/useFetchPDF';

const PatientSearch = () => {
  const [
    getPatientByDate,
    { data: patientDate, isLoading: patientsdateLoading },
  ] = useGetPatientByDateWiseMutation();

  const [getPatient, { data: patients, isLoading: patientsLoading }] = useGetPatientByMobileNoMutation();
  const [createappoint, { isSuccess: addSuccess, isLoading: appointmentLoading ,data: saveappoint }] = useCreateAppointMutation();
  const [tableData, setTableData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showAddVisitModal, setShowAddVisitModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [reportData, getReport] = useFetchPDF();

  useEffect(() => {
    if (patients) setTableData(patients?.data);
  }, [patients]);

  useEffect(() => {
    if (patientDate) setTableData(patientDate?.data);
    console.log(setTableData);
  }, [patientDate]);

  useEffect(() => {
    if (addSuccess) setShowAddVisitModal(false);
    handlerefetch()
  }, [addSuccess]); // eslint-disable-line

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientname",
      key: "patientname",
    },
    {
      title: 'Cell No',
      dataIndex: 'cellno',
      key: 'cellno',
    },
    {
      title: "Father Name",
      dataIndex: "fhname",
      key: "fhname",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender) => (gender === "M" ? "Male" : gender === "F" ? "Female" : gender),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  const onFinish = (values) =>
    getPatient({
      cellno: values?.cellno.replace(/\s/g, ""),
    });

  const onFinish1 = (values) => {
    getPatientByDate({
      fromdate: moment(values?.fromdate)?.format("DD-MMM-YYYY"),
      todate: moment(values?.todate)?.format("DD-MMM-YYYY"),
      message: "B"
    });
  };

  const handlerefetch=()=>{
    // refetch()
  }
  useEffect(() => {
  
    if (saveappoint) {
      getReport(
        `http://localhost:8080/security/AppointmentReciept/${saveappoint?.data.cellno}/${saveappoint?.data.seqno}/${saveappoint?.data.visitnum}`
      );
      setShowPdfModal(true);
    }
  }, [saveappoint]); //eslint-disable-line

  return (
    <section className="">
      <div className="flex-1 m-2">
        <div className="flex items-center justify-between p-3 bg-lotion border-b border-gainsboro">
          <h1 className="font-semibold text-base sm:text-lg">Patient Search</h1>
          <Tooltip title="Register Patient" placement="left">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate('/PatientRegister')}
            >
              Register Patient
            </Button>
          </Tooltip>
        </div>
        <div className="p-2 border border-gainsboro mt-5">
          <Form layout="vertical"
            onFinish={onFinish1}
            form={form1}>
            <div className="flex items-center gap-2">
              <Form.Item name="fromdate" label="From Date" className="flex-1">
                <DatePicker
                  disabledDate={futureDates}
                  placeholder="Enter From Date"
                  format="DD-MM-YYYY"
                />
              </Form.Item>
              <Form.Item name="todate" label="To Date" className="flex-1">
                <DatePicker
                  disabledDate={futureDates}
                  placeholder="Enter To Date"
                  format="DD-MM-YYYY"
                />
              </Form.Item>

              <Form.Item className='gap=2' label=" ">
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button"
                  onClick={() => {
                    setTableData([]);
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
            </div>
          </Form>
          <div>
            <Form onFinish={onFinish}
              form={form}
              className="flex items-start gap-2">
              <Form.Item name="cellno" className="flex-1">
                <Input
                  label="Patient"
                  placeholder="Search Patient by Mobile No. or  Patient Name"
                  allowClear
                  autoFocus
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={patientsLoading}>
                Search
              </Button>

              <Button
                htmlType="button"
                onClick={() => {
                  setTableData([]);
                }}
              >
                Clear
              </Button>
            </Form>
          </div>
        </div>
        <div className='mt-5'>
          <Table
            dataSource={tableData}
            columns={columns}
            pagination={{ defaultPageSize: 5 }}
            loading={patientsLoading}
            bordered
            size="small"
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedPatient(record);
                },
              };
            }}
            rowClassName={(record) =>
              record === selectedPatient
                ? "ant-table-row-selected cursor-pointer"
                : "cursor-pointer"
            }

          />
        </div>
      </div>
      <div className="flex justify-end">
        {selectedPatient && (
          <Tooltip title="Add Visit" placement="right">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowAddVisitModal(true)}
            >
              Add Visit
            </Button>
          </Tooltip>
        )}
      </div>


      <Modal
        title="Add Visit"
        open={showAddVisitModal}
        onCancel={() => setShowAddVisitModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            type="primary"
            htmlType="submit"
            key="1"
            form="addVisit"
            loading={appointmentLoading}
          >
          Add Visit
          </Button>,
          <Button key="2" onClick={() => setShowAddVisitModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddVisit
          selectedPatient={selectedPatient}
          
          saveappoint={saveappoint}
          createappoint={createappoint}
          handlerefetch={handlerefetch}
         
        />
      </Modal>
      <Modal
        title="Print Reciept"
        open={showPdfModal}
        onCancel={() => {
          setShowPdfModal(false);
        }}
        centered
        width={1000}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button
            key="1"
            onClick={() => {
              setShowPdfModal(false);
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Appointment Slip"
        ></iframe>
      </Modal>
    </section>
  );
};

export default PatientSearch;
