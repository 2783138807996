import { Form, Input, Select } from 'antd'
import { useLovOrganizationQuery } from '../../orgStructureService'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddProject = ({ createProject }) => {
  const { data: lovOrganization } = useLovOrganizationQuery()

  const onFinish = (values) =>
    createProject({
      ...values,
      message: 'B',
    })

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='addProjectForm'
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-2'>
        <Form.Item name='orgId' label='Organization'>
          <Select
            placeholder='Select Organization'
            options={lovOrganization?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='projectname' label='Project Name'>
          <Input placeholder='Enter Project Name' />
        </Form.Item>
        <Form.Item name='projectdescr' label='Project Decription'>
          <Input placeholder='Enter Project Decription' />
        </Form.Item>
        <Form.Item name='projectstatus' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default AddProject
