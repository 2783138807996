import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useGetDashboardDataQuery } from '../../security/securityService'
import GenderWiseReport from '../components/GenderWiseReport'
import AgeWiseReport from '../components/AgeWiseReport'
import PatientWiseReport from '../components/PatientWiseReport'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const Dashboard = () => {
  const co = useSelector((state) => state.auth.user.suborgid)
  const { data: dashboardData } = useGetDashboardDataQuery(co)

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      labels: {
        font: {
          size: 15,
        },
      },
      title: {
        display: true,
      },
    },
  }

  const labels = useMemo(
    () =>
      dashboardData?.data?.dailyVisits?.map((dailyVisit) => dailyVisit?.label),
    [dashboardData]
  )
  const values = useMemo(
    () =>
      dashboardData?.data?.dailyVisits?.map((dailyVisit) => dailyVisit?.value),
    [dashboardData]
  )
  const VisitValues = useMemo(
    () =>
      dashboardData?.data?.dailyTreated?.map((dailyVisit) => dailyVisit?.value),
    [dashboardData]
  )

  const data = {
    labels,
    datasets: [
      {
        label: 'Patient Visit',
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        data: values,
      },
      {
        label: 'Patient Treated',
        borderColor: 'rgb(25, 94, 35)',
        backgroundColor: 'rgba(25, 94, 35, 0.5)',
        data: VisitValues,
      },
    ],
  }

  return (
    <section className='border border-gainsboro'>
      <div className='flex items-center justify-between px-5 py-3 bg-lotion border-b border-gainsboro'>
        <h1 className='font-semibold text-base sm:text-lg'>Dashboard</h1>
      </div>
      <div className='p-5'>
        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'>
          <div className='bg-[#27C0EF] p-4'>
            <p className='font-semibold text-3xl text-white'>
              {dashboardData?.data?.todayReg}
            </p>
            <h3 className='text-xl text-white'>New Registration(Today)</h3>
          </div>
          <div className='bg-[#1FA3CB] p-4'>
            <p className='font-semibold text-3xl text-white'>
              {dashboardData?.data?.todayVisit}
            </p>
            <h3 className='text-xl text-white'>Today Visit</h3>
          </div>
          <div className='bg-[#03629C] p-4'>
            <p className='font-semibold text-3xl text-white'>
              {dashboardData?.data?.todayTreated}
            </p>
            <h3 className='text-xl text-white'>Total Treated</h3>
          </div>
          <div className='bg-[#03629C] p-4'>
            <p className='font-semibold text-3xl text-white'>
              {dashboardData?.data?.todayWaiting}
            </p>
            <h3 className='text-xl text-white'>Waiting</h3>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div className='h-[22rem]'>
            <Line options={options} data={data} />
          </div>
          <div>
            <GenderWiseReport />
          </div>
          <div>
            <AgeWiseReport />
          </div>
          <div>
            <PatientWiseReport />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Dashboard
