import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const AddDept = ({ saveDept, getlovdept }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    saveDept({
      deptcode: values?.deptcode,
      deptname: values?.deptname,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        id="adddept"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item name="deptcode" label="Dept Code">
          <Input />
        </Form.Item>
        <Form.Item name="deptname" label="Dept Name">
          {/* <Select
            placeholder="Select City"
            onChange={(value) => {
              const selectedOption = getlovdept?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                deptcode: selectedOption?.code,
              });
            }}
            options={getlovdept?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item> */}
      </Form>
    </div>
  );
};

export default AddDept;
