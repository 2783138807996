import { Form, Input } from 'antd'
import React from 'react'

const EditVitals=({ vital, addPatientVitals}) =>{
  const [form] = Form.useForm();
  const validateMessages = {
    required: "Please Enter ${label}!", // eslint-disable-line
  };
  return (
    <div>
<Form
        layout="vertical"
        id="editVital"
        form={form}
        // initialValues={initialvalues}
        // onFinish={onFinish}
        validateMessages={validateMessages}
        className="grid sm:grid-cols-4 grid-cols-1 gap-2"
      >
        <Form.Item
          name="temperature"
          label="Temp (°F)"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[1-9]\d{0,5}(\.\d{0,5})?$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={5} />
        </Form.Item>

        <Form.Item
          name="bloodpressuresystolic"
          label="Systolic Blood Pressure (mmHg)"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>
        <Form.Item
          name="bloodpressuredistolic"
          label="Diastolic Blood Pressure (mmHg)"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>
        <Form.Item
          name="pulse"
          label="Pulse (bpm)"
          rules={[
            {
              required: true,
            },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>

        <Form.Item
          name="allergy"
          label="Allergy"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[a-zA-Z,]+$/,
              message: "Please Enter a valid string containing letters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="saturation"
          label="Saturation"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>

        <Form.Item
          name="weight"
          label="Weight (Kg)"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>

        <Form.Item
          name="bloodgroup"
          label="Blood Group"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[a-zA-Z+-]+$/,
              message: "Please Enter a valid String Except Numbers.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>

        <Form.Item
          name="respirationrate"
          label="Respiration Rate"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>
        <Form.Item
          name="oxygen"
          label="Heart Rate"
          rules={[
            // {
            //   required: true,
            // },
            {
              pattern: /^[1-9]\d{0,2}$/,
              message: "Please Enter a Valid Number.",
            },
          ]}
        >
          <Input maxLength={3} />
        </Form.Item>
      </Form>





    </div>
  )
}

export default EditVitals