import Historypos from "../features/inventorypurchase/modal/Historypos";
import InvPurchaseDetail from "../features/inventorypurchase/pages/InvPurchaseDetail";
import InvStock from "../features/inventorypurchase/pages/InvStock";
import ItemRegistration from "../features/inventorypurchase/pages/ItemRegistration";
import { PaymentAccount } from "../features/inventorypurchase/pages/PaymentAccount";
import { PointofSale } from "../features/inventorypurchase/pages/PointofSale";
import { PosReturn } from "../features/inventorypurchase/pages/PosReturn";
import { PurchaseEntry } from "../features/inventorypurchase/pages/PurchaseEntry";
import PurchaseOrder from "../features/inventorypurchase/pages/PurchaseOrder";
import PurchaseReceivingFinal from "../features/inventorypurchase/pages/PurchaseReceivingFinal";
import { PurchaseReturn } from "../features/inventorypurchase/pages/PurchaseReturn";
// import Quotation from "../features/inventorypurchase/pages/Quotation";
import { Quotationfinal } from "../features/inventorypurchase/pages/Quotationfinal";

export const inventoryPurchaseRoutes = [
  {
    path: "purchaseorders",
    element: <PurchaseOrder />,
  },

  {
    path: "InventoryStock",
    element: <InvStock />,
  },

  {
    path: "PODetail",
    element: <InvPurchaseDetail />,
  },
  {
    path: "purchasereceiving",
    element: <PurchaseReceivingFinal />,
  },
  // {
  //   path: "quotation",
  //   element: <Quotation />,
  // },
  {
    path: "pointofsale",
    element: <PointofSale />,
  },
  {
    path: "historypos",
    element: <Historypos />,
  },
  {
    path: "posreturn",
    element: <PosReturn />,
  },
  {
    path: "itemregistration",
    element: <ItemRegistration />,
  },
  {
    path: "purchaseentry",
    element: <PurchaseEntry />,
  },
  {
    path: "purchasereturn",
    element: <PurchaseReturn />,
  },
  {
    path: "payment",
    element: <PaymentAccount />,
  },
  // {
  //   path: "finalquotation",
  //   element: <Quotationfinal />,
  // },
  {
    path: "quotation",
    element: <Quotationfinal />,
  },
];
