import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateCity = ({
  rowData,
  updatecitydata,
  getlovCity,
  lovhmsProvince,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updatecitydata({
      provid: values?.provid,
      cityid: rowData?.cityid,
      citycode: values?.citycode,
      description: values?.description,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatecity"
        form={form}
        initialValues={{
          description: rowData?.description,
          provid: rowData?.provid,
          cityid: rowData?.cityid,
          citycode: rowData?.citycode,
          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="provid"
          label="Province ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          {/* <Select
            placeholder="Select Province ID"
            disabled
            options={lovhmsProvince?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item
          name="citycode"
          label="City Code"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="cityid" label="City ID">
          <Input disabled />
        </Form.Item>
        <Form.Item name="description" label="Description">
          {/* <Select
            placeholder="Select City ID"
            defaultValue={rowData?.description}
            onChange={(value) => {
              const selectedOption = getlovCity?.data?.find(
                (item) => item?.code === value
              );
              form.setFieldsValue({
                citycode: selectedOption?.code,
              });
            }}
            options={getlovCity?.data?.map((item) => ({
              value: item?.code,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateCity;
