import React, { useEffect, useState } from 'react'
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import useTableFilters from '../../../hooks/useTableFilter';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { useCreateInvCustomerInfoMutation, useGetCustomerInfoQuery, useUpdateInvCustomerInfoMutation } from '../customerInfoService';
import AddCustomerInfo from '../components/AddCustomerInfo';
import UpdateCustomerInfo from '../components/UpdateCustomerInfo';

const CustomerDetails = () => {
    const { data, isLoading,refetch} = useGetCustomerInfoQuery();
     const [createcustomerdetail, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateInvCustomerInfoMutation();
     const [ updatecustdetail, {data:updatecustdetailData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvCustomerInfoMutation();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rowData, setRowData] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const filter = useTableFilters();
    const[tableDatainfo,setTableDatainfo]=useState([]);
    useEffect(()=>{
        if(data){
          setTableDatainfo(data?.data)
        }
      },[data])
      useEffect(() => {
        if (addSuccess) setShowAddModal(false);
         handlerefetch()
      }, [addSuccess]); // eslint-disable-line
  
      useEffect(() => {
        if (updateSuccess) setShowUpdateModal(false);
        handlerefetch();
      }, [updateSuccess]); // eslint-disable-line
    const tableColumns = [
        
      {
        title:"Customer Name",
        dataIndex: "customername",
        ...filter("customername"),
     
      },
      {
        title:" Cell No",
        dataIndex: "customercellno",
        ...filter("customercellno"),
     
      },
      {
        title:"Email",
        dataIndex: "email",
        ...filter("email"),
     
      },
      {
        title:"Address",
        dataIndex: "customeraddress",
        ...filter("customeraddress"),
     
      },
      {
        title: "Status",
        render: (_, { status }) =>
            status === "Y" ? (
            <Tag  color="green">Active</Tag>
          ) : (
            <Tag color="red">In Active</Tag>
          ),
        sorter: (a, b) => a?.status?.localeCompare(b?.status),
      },
          {
            title: "Action(s)",
            render: (data) => (
              <Tooltip title="Update Vendor Details">
                <Button
                  type="primary"
                  className="btn-warning-primary"
                  icon={<EditFilled />}
                  onClick={() => {
                    setRowData(data);
                    setShowUpdateModal(true);
                  }}
                />
              </Tooltip>
            ),
          },
       
       
      ];
      const handlerefetch=()=>{
        refetch()
      }
    return (
      <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Customer Details</h1>
        <Tooltip  title="Add New Customer Details ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
         columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
   
          
        />
      </div>
      <Modal
      title="Add Customer Details"
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addcustomerdetailForm"
           loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
      
      ]}
    >
  <AddCustomerInfo
     createcustomerdetail={createcustomerdetail} 
    handlerefetch={handlerefetch}
    />
    </Modal>
  
    <Modal
        title="Update Customer Details"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatcustinfoForm"
          loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateCustomerInfo
          rowData={rowData}
          updatecustdetailData={updatecustdetailData}
          updatecustdetail={updatecustdetail}
        />
      </Modal>
  
    </section>
    )
  }
export default CustomerDetails