// import React from 'react'
// import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts'
// import { useGetPatientAppointStatQuery } from '../DashboardService'

// export default function PatientStackedChart() {
//   const { data: patientStat, isLoading } = useGetPatientAppointStatQuery()

//   // Transform the patientStat data to match the structure of the 'data' array in the chart
//   const data = patientStat?.data?.map(item => ({
//     name: item?.month,
//     beni: parseInt(item?.beni),
//     others: parseInt(item?.others),
//     cne: parseInt(item?.cne),
//     empl: parseInt(item?.empl),
//   }))
//   //   const maxCategoryValue = Math.max(...data.map(item => Math.max(item.beni, item.others, item.cne, item.empl)))

//   return (
//     <div className='border border-border rounded mx-2 my-2'>
//       <h4 className='font-semibold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl rounded-tr bg-cardGrey'>
//         Patient Visit
//       </h4>
//       <div style={{ width: '100%', height: 300 }}>
//         <ResponsiveContainer width='100%' height='100%'>
//           <BarChart
//             data={data}
//             margin={{
//               top: 20,
//               right: 30,
//               left: 20,
//               bottom: 5,
//             }}
//           >
//             <XAxis dataKey='name' />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             <Bar dataKey='beni' stackId='stack' fill='#2DC6D8' />
//             <Bar dataKey='others' stackId='stack' fill='#55D988' />
//             <Bar dataKey='cne' stackId='stack' fill='#4071D9' />
//             <Bar dataKey='empl' stackId='stack' fill='#5AD989' />
//           </BarChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   )
// }
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetPatientVisitRegComparQuery } from "../DashboardService";

const PatientAreaChart = () => {
  const { data: PatientData } = useGetPatientVisitRegComparQuery();

  // Highcharts configuration options for the area chart
  const options = {
    credits: false,
    chart: {
      type: "area", // Change chart type to 'area'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: PatientData?.data.map((item) => item.day),
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    plotOptions: {
      area: {
        // Specific options for the area chart
        stacking: "normal", // Change stacking to 'normal' if you want to stack the areas
        // lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: [
      {
        name: "Visit",
        data: PatientData?.data.map((item) => parseInt(item.visit)),
        // Specify the area specific options for this series
        // (optional, only needed if you want to configure the individual series)
        // For example, if you want to change the color for this series, you can add:
        // color: '#ff0000' (replace with your desired color)
      },
      {
        name: "Registered",
        data: PatientData?.data.map((item) => parseInt(item.registered)),
        // Specify the area specific options for this series
        // (optional, only needed if you want to configure the individual series)
        // For example, if you want to change the color for this series, you can add:
        // color: '#00ff00' (replace with your desired color)
      },
    ],
  };

  return (
    <div className="border border-border rounded-xl shadow-lg mx-2 my-2">
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white">
        Patient Area Chart
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PatientAreaChart;
