import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import useTableFilters from '../../../hooks/useTableFilter';
import UpdateInventoyClass from './UpdateInventoyClass';
import AddInventoryClass from './AddInventoryClass';
import { useCreateInvClassMutation, useGetInvClassQuery, useUpdateInvClassMutation } from '../inventoryConfiguratorsService';

const InventoryClass=()=> {
    const { data, isLoading,refetch} = useGetInvClassQuery();
    const [createclass, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateInvClassMutation();
    const [ updateclass, {data:updateclassData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvClassMutation();
    const filter = useTableFilters();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rowData, setRowData] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const[tableDatainfo,setTableDatainfo]=useState([]);
    useEffect(()=>{
        if(data){
          setTableDatainfo(data?.data)
        }
      },[data])
      useEffect(() => {
        if (addSuccess) setShowAddModal(false);
         handlerefetch()
      }, [addSuccess]); // eslint-disable-line
      useEffect(() => {
        if (updateSuccess) setShowUpdateModal(false);
        handlerefetch();
      }, [updateSuccess]); // eslint-disable-line

    const tableColumns = [
        {
            title:"Class ID",
            dataIndex: "cid",
            ...filter("name"),
         
          },
        {
          title:"Class Name",
          dataIndex: "classname",
          ...filter("name"),
       
        },

    
          {
            title: "Status",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">In Active</Tag>
              ),
            sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: "Action(s)",
            render: (data) => (
              <Tooltip title="Update Class Name">
                <Button
                  type="primary"
                  className="btn-warning-primary"
                  icon={<EditFilled />}
                  onClick={() => {
                    setRowData(data);
                    setShowUpdateModal(true);
                  }}
                />
              </Tooltip>
            ),
          },
       
       
      ];
      const handlerefetch=()=>{
        refetch()
      }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Inventory Class</h1>
      <Tooltip  title="Add New Inventory Class">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
       dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add Inventory Class"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addclassForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddInventoryClass
  createclass={createclass} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  <Modal
      title="Update Inventory Class"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateclassForm"
         loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateInventoyClass
        rowData={rowData}
        updateclassData={updateclassData}
        updateclass={updateclass}
      />
    </Modal>

  </section>
  )
}

export default InventoryClass