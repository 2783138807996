import { ReportsMedix } from "../features/reports/pages/ReportsMedix";


export const ReportsMedixRoutes = [
	{
		path: 'reports',
		element: <ReportsMedix/>,
	},
	
    
	
	
]