import { DatePicker, Form, Input, Select } from 'antd'
import {
  useLovOrganizationQuery,
  useLovSubOrganizationMutation,
} from '../../orgStructureService'
import { futureDates, formateDate } from '../../../../utils/dateUtils'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddProjectCatagory = ({ createProjectCat }) => {
  const { data: lovOrganization } = useLovOrganizationQuery()
  const [getLovSubOrganization, { data: lovSubOrganization }] =
    useLovSubOrganizationMutation()
  const [form] = Form.useForm()

  const onFinish = values =>
    createProjectCat({
      ...values,
      orgstartdate: formateDate(values?.orgstartdate),
      message: 'B',
    })
  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='addProjectCatagoryForm'
      form={form}
      validateMessages={formValidationMessages}
    >
      <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
        <Form.Item name='orgId' label='Organization'>
          <Select
            placeholder='Select Organization'
            onChange={e => {
              getLovSubOrganization({ org: e, message: 'B' })
              form.setFieldsValue({ suborgId: undefined })
            }}
            options={lovOrganization?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='suborgId' label='Sub Organization'>
          <Select
            placeholder='Select Sub Organization'
            options={lovSubOrganization?.data?.map(item => ({
              value: item?.code,
              label: item?.descr,
            }))}
          />
        </Form.Item>
        <Form.Item name='pcatname' label='Category Name'>
          <Input placeholder='Enter Category Name' />
        </Form.Item>
        <Form.Item name='pcatabbrv' label='Category Abbrv'>
          <Input placeholder='Enter Category Abbrv' />
        </Form.Item>
        <Form.Item name='orgstartdate' label='Start Date'>
          <DatePicker
            disabledDate={futureDates}
            placeholder='Enter Start Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item name='orgstatus' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default AddProjectCatagory
