import { LoginCard } from "../features/products/components/cardlogin.jsx/LoginCard";
import ProductList from "../features/products/pages/CreateProducts";
import OrderToCard from "../features/products/pages/OrderToCard";
import UserProduct from "../features/products/pages/UserProducts";

export const productRoutes = [
  {
    path: "products",
    element: <ProductList />,
  },
  {
    path: "userproducts",
    element: <UserProduct />,
  },
  {
    path: "cardlogin",
    element: <LoginCard />,
  },
  {
    path: "addtocard",
    element: <OrderToCard />,
  },
];
