import { Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';

const UpdateCustomerInfo = ({rowData,updatecustdetail,updatecustdetailData}) => {
    const [form] = Form.useForm()
    const onFinish = (values) =>
        updatecustdetail({
        ...values,
        customerid:rowData?.customerid,
       status: values?.status,
        // cid:values?.gid,
     
        message: 'B',
      })
  
  return (
    <div>
    <Form
    layout='vertical'
     onFinish={onFinish}
    id='updatcustinfoForm'
    form={form}
    initialValues={{
        customerid:rowData?.customerid,
        customername:rowData?.customername,
        email:rowData?.email,
        customeraddress:rowData?.customeraddress,
        customercellno:rowData?.customercellno,
       status:rowData?.status, 
       
      }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-5 gap-2'
  >
     <Form.Item
        name='customername'
        label='Customer Name'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Customer Name'/>
      </Form.Item>

    

      <Form.Item
        name='customeraddress'
        label='Customer Address'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Customer Address'/>
      </Form.Item>

      <Form.Item
        name='customercellno'
        label='Customer Cell No'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Customer Cell No'/>
      </Form.Item>
      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Email'/>
      </Form.Item>
     
      <Form.Item
  name='status'
  label='Customer Current Status'
  rules={[{ required: true, message: 'Please select the Customer current status!' }]}
>
  <Select
    placeholder='Select Customer Current Status'
    options={[
      { label: 'Active', value: 'Y' },
      { label: 'In Active', value: 'N' },
    ]}
  />
</Form.Item>
   
  </Form>
    </div>
  )
  }

export default UpdateCustomerInfo