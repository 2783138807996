import { Form, Input, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const AddRole = ({ createRole }) => {
	const onFinish = values => createRole({ ...values, message: 'B' })

	return (
		<Form
			layout='vertical'
			onFinish={onFinish}
			autoComplete='off'
			id='addNewRoleForm'
			validateMessages={formValidationMessages}
			className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
			<Form.Item
				name='code'
				label='Role Code'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Role Code' autoFocus />
			</Form.Item>
			<Form.Item
				name='descr'
				label='Role Description'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Role Description' />
			</Form.Item>
			<Form.Item
				name='status'
				label='Status'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
		</Form>
	)
}

export default AddRole
