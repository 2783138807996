import { Table, Tag } from "antd";
import { useGetRoleWiseBussFuncQuery } from "../../securityService";

const ViewRoleDetails = ({ roleid }) => {
  const { data, isLoading } = useGetRoleWiseBussFuncQuery(roleid);

  const tableColumns = [
    {
      title: "Name",
      render: (_, { data }) => data?.displaytitle,
    },
    {
      title: "Code",
      render: (_, { data }) => data?.bussfunc,
    },
    {
      title: "Status",
      render: (_, { data }) =>
        data?.status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
    },
  ];

  return (
    <Table
      dataSource={data?.data}
      columns={tableColumns}
      pagination={{ defaultPageSize: 20 }}
      loading={isLoading}
      bordered
      size="small"
      // scroll={{ x: 'fit-content' }}
    />
  );
};

export default ViewRoleDetails;
