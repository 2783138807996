import React, { useState } from 'react';
import { Row, Col, Card, Statistic, Spin, Alert } from 'antd';
import { useGetAllstatsQuery } from '../storeServices';
import { ExclamationCircleOutlined, MedicineBoxOutlined, ShoppingCartOutlined, DollarOutlined } from '@ant-design/icons';
import DashboardExpireyDetail from '../components/DashboardExpireyDetail';

const DashboardPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: response, error, isLoading } = useGetAllstatsQuery();

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (error) {
    return <Alert message="Error" description="Failed to fetch statistics." type="error" showIcon />;
  }

  // Extract statistics data
  const stats = response?.data?.length > 0 ? response.data[0] : {};

  const handleNearExpiryClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '24px' }}>
      {/* Enhanced Heading */}
      <div
        style={{
          backgroundColor: '#E6F9FF', // Light Blue Background
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '24px'
        }}
      >
        <h1 style={{ 
          fontSize: '36px', // Increase Font Size
          fontWeight: 'bold', // Make Text Bold
          color: '#2E86C1', // Dark Blue Color
          textAlign: 'center', // Center Align Text
          margin: 0 // Remove Margin
        }}>
          Dashboard
        </h1>
      </div>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #D1F2EB, #A9DFBF)', // Light Green to Mint Green Gradient
              color: '#1D8348', // Dark Green Text
            }}
            onClick={handleNearExpiryClick} // Open modal on click
          >
            <Statistic
              title="Near Expiry Medicines"
              value={stats.nearexpirymed || 0}
              prefix={<ExclamationCircleOutlined />}
              valueStyle={{ color: '#1D8348' }} // Dark Green Text
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #FADBD8, #F5B7B1)', // Light Pink to Coral Gradient
              color: '#C0392B', // Dark Coral Text
            }}
          >
            <Statistic
              title="Medicines Sold Today"
              value={stats.sellmedtoday || 0}
              prefix={<MedicineBoxOutlined />}
              valueStyle={{ color: '#C0392B' }} // Dark Coral Text
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #D6EAF8, #AED6F1)', // Light Blue to Soft Blue Gradient
              color: '#154360', // Dark Blue Text
            }}
          >
            <Statistic
              title="Total Medicines"
              value={stats.totalmed || 0}
              prefix={<MedicineBoxOutlined />}
              valueStyle={{ color: '#154360' }} // Dark Blue Text
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #F9E79F, #F7DC6F)', // Light Yellow to Pastel Yellow Gradient
              color: '#F39C12', // Dark Yellow Text
            }}
          >
            <Statistic
              title="Total Sales Today"
              value = {`${stats.totalselltoday ? `Rs ${stats.totalselltoday}` : 'Rs 0'}`}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#F39C12' }} // Dark Yellow Text
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #F5CBA7, #FAD7A0)', // Light Coral to Light Peach Gradient
              color: '#D35400', // Dark Orange Text
            }}
          >
            <Statistic
              title="Total Stock Cost"
              value = {`${stats.totalstockcost ? `Rs ${stats.totalstockcost}` : 'Rs 0'}`}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#D35400' }} // Dark Orange Text
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              background: 'linear-gradient(135deg, #E8DAEF, #D2B4DE)', // Light Lavender to Light Purple Gradient
              color: '#8E44AD', // Dark Purple Text
            }}
          >
            <Statistic
              title="Total Sales Current Month"
              value={`${stats.totalsellcurrentmonth ? `Rs ${stats.totalsellcurrentmonth}` : 'Rs 0'}`} // Format value with "Rs"
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#8E44AD' }} // Dark Purple Text
            />
          </Card>
        </Col>
      </Row>

      {/* Dashboard Expiry Detail Modal */}
      <DashboardExpireyDetail
        isVisible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default DashboardPage;
