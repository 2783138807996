import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreateInvClassMutation,
  useCreateInventoryCodeMutation,
  useCreateInvManufactureMutation,
  useCreateInvTypeMutation,
  useCreateInvVendorMutation,
  useGetInvcodedetailQuery,
  useLovInvClassQuery,
  useLovInvManufactureQuery,
  useLovInvTypeQuery,
} from "../../inventoryConfigurators/inventoryConfiguratorsService";
import {
  useGetItemsByIDMutation,
  useLovVendrQuery,
} from "../inventorypurchaseService";
import openNotification from "../../../utils/notification";
import { MdFormatListBulletedAdd, MdOutlineAddHomeWork } from "react-icons/md";
import AddComapnyMAFC from "../modal/addCompany";
import AddClass from "../modal/AddClass";
import AddType from "../modal/AddType";
import AddSupplier from "../modal/AddSupplier";

const ItemRegistration = () => {
  const [form] = Form.useForm();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const { data: getitems } = useGetInvcodedetailQuery();
  const { data: lovInvClass } = useLovInvClassQuery();
  const { data: lovInvManufacture } = useLovInvManufactureQuery();
  const { data: lovInvType } = useLovInvTypeQuery();
  const { data: lovVender } = useLovVendrQuery();
  const [searchItem, { data: getsearchData, isError: searchError }] =
    useGetItemsByIDMutation();
  const [
    createinvcode,
    { isSuccess: createSuccess, isLoading: createLoading },
  ] = useCreateInventoryCodeMutation();
  const [
    createmanufacturer,
    { data: getcomapnyadd, isLoading: addLoadingcompany },
  ] = useCreateInvManufactureMutation();
  const [createclass, { data: getClass, isLoading: addLoadingclass }] =
    useCreateInvClassMutation();
  const [createtype, { data: gettype, isLoading: addLoadingtype }] =
    useCreateInvTypeMutation();
  const [createvendordetail, { data: getsupplier, isLoading: addLoading }] =
    useCreateInvVendorMutation();
  useEffect(() => {
    if (getsupplier) {
      setShowAddSupplierModal(false);
      form.setFieldsValue({
        vendorid: getsupplier?.data.vendorid || "",
        supplier: getsupplier?.data.vendorname || "",
      });
    }
  }, [getsupplier, form]);
  useEffect(() => {
    if (gettype) {
      setShowAddCategoryModal(false);
      form.setFieldsValue({
        tid: gettype?.data.tid || "",
        category: gettype?.data.typename || "",
      });
    }
  }, [gettype, form]);
  useEffect(() => {
    if (searchError) {
      form.resetFields();
    }
  }, [searchError, form]);
  useEffect(() => {
    if (createSuccess) {
      form.resetFields(["itemname"]);
    }
  }, [createSuccess, form]);
  useEffect(() => {
    if (getcomapnyadd) {
      setShowAddCompanyModal(false);
      form.setFieldsValue({
        mafcid: getcomapnyadd?.data.mafcid || "",
        company: getcomapnyadd?.data.mafcname || "",
      });
    }
  }, [getcomapnyadd, form]);
  useEffect(() => {
    if (getClass) {
      setShowAddClassModal(false);
      form.setFieldsValue({
        cid: getClass?.data.cid || "",
        class: getClass?.data.classname || "",
      });
    }
  }, [getClass, form]);
  useEffect(() => {
    if (getsearchData) {
      form.setFieldsValue({
        itemid: getsearchData?.data[0]?.itemid,
        itemname: getsearchData?.data[0]?.itemname,
        code: getsearchData?.data[0]?.itemnomcltre,
        barcode: getsearchData?.data[0]?.itemid,
        costprice: getsearchData?.data[0]?.price,
        saleprice: getsearchData?.data[0]?.saleprice,
        company: getsearchData?.data[0]?.mafcname,
        category: getsearchData?.data[0]?.typename,
        class: getsearchData?.data[0]?.classname,
        supplier: getsearchData?.data[0]?.vendorname,
        cid: getsearchData?.data[0]?.cid, //clasename
        mafcid: getsearchData?.data[0]?.mafcid, //mafcname
        tid: getsearchData?.data[0]?.tid, //typename
        vendorid: getsearchData?.data[0]?.vendorid, //verdorname
      });
    }
  }, [getsearchData, form]);
  const handleSearch = () => {
    const itemid = form.getFieldValue("itemsearch");
    searchItem({ itemid: itemid, message: "B" });
  };

  //Alt L
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const itemSelectRef = useRef();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        e.preventDefault();
        setShowAddModal(true); // Open Modal first
        setTimeout(() => {
          setIsItemSelectOpen(true); // Then open Select dropdown
          itemSelectRef.current?.focus(); // Focus on Select input
        }, 100); // Delay to ensure Modal has rendered
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //F12
  const priceInputRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F12") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (priceInputRef.current) {
          // Focus on the discount input
          const itemid = form.getFieldValue("itemsearch");
          if (itemid !== null && itemid !== "" && itemid !== undefined) {
            searchItem({ itemid: itemid, message: "B" });
          } else {
            openNotification(
              "success",
              "Please Enter Item ID for Search Item",
              10
            );
            priceInputRef.current.focus();
          }
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); //eslint-disable-line
  //
  //F1 company
  // const [companylov, setCompanylov] = useState(false);
  // const companyRef = useRef();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F1") {
        e.preventDefault();
        // companyRef.current.focus();
        // setCompanylov(true);
        setShowAddCompanyModal(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  //F2 category
  // const [categorylov, setCategorylov] = useState(false);
  // const categoryRef = useRef();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F2") {
        e.preventDefault();
        setShowAddCategoryModal(true);
        // categoryRef.current.focus();
        // setCategorylov(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  //F3 class
  // const [classlov, setClasslov] = useState(false);
  // const classRef = useRef();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F3") {
        e.preventDefault();
        setShowAddClassModal(true);
        // classRef.current.focus();
        // setClasslov(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  //F6 supplier
  // const [supplierlov, setSupplierlov] = useState(false);
  // const supplierRef = useRef();
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F6") {
        e.preventDefault();
        setShowAddSupplierModal(true);
        // supplierRef.current.focus();
        // setSupplierlov(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //
  const onFinish = (values) => {
    createinvcode({
      itemname: values?.itemname,
      cid: values?.cid,
      mafcid: values?.mafcid,
      tid: values?.tid,
      vendorid: values?.vendorid,
      price: values?.costprice,
      saleprice: values?.saleprice,
      message: "B",
    });
  };
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Item Registration
      </h1>
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ active: true, applydiscount: true }}
          layout="vertical"
        >
          <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
            <div className="grid sm:col-span-4 justify-center items-center">
              <Form.Item name="itemsearch" className="mb-0" label="Search Item">
                <Input ref={priceInputRef} onBlur={handleSearch} />
              </Form.Item>
            </div>
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-1 sm:col-span-3">
              <Form.Item name="itemid" className="mb-0" label="Item ID">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                className=" sm:col-span-2 mb-0"
                name="itemname"
                label="Item Description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="code" className="mb-0" label="Article/Code/Size">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="barcode" className="mb-0" label="Bar Code">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="costprice" className="mb-0" label="Cost Price">
                <Input />
              </Form.Item>
              {/* <Form.Item name="costprice" className="mb-0" label=" " valuePropName="checked">
                <Checkbox>Cost Price</Checkbox>
              </Form.Item> */}
              <Form.Item name="saleprice" className="mb-0" label="Sale Price">
                <Input />
              </Form.Item>
              <Form.Item
                name="active"
                className="mb-0"
                label=" "
                valuePropName="checked"
              >
                <Checkbox>Active</Checkbox>
              </Form.Item>
              <Form.Item
                name="applydiscount"
                className="mb-0"
                label=" "
                valuePropName="checked"
              >
                <Checkbox checked>Apply Discount</Checkbox>
              </Form.Item>
            </div>
            <div>
              <div className=" flex gap-1">
                <Form.Item name="company" className="sm:mb-1" label="Company">
                  <Select
                    // ref={companyRef}
                    // open={companylov}
                    // onDropdownVisibleChange={(open) => setCompanylov(open)}
                    placeholder="Select Manufacturer"
                    options={lovInvManufacture?.data?.map((item) => ({
                      value: item?.code,
                      label: `${item?.descr}`,
                    }))}
                    onChange={(value) => {
                      // setCompanylov(false);
                      const selectedoption = lovInvManufacture?.data.find(
                        (item) => item.code === value
                      );
                      form.setFieldsValue({
                        mafcid: selectedoption?.code,
                      });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item label=" ">
                  <Tooltip title="Add Company " placement="top">
                    <Button
                      type="primary"
                      onClick={() => setShowAddCompanyModal(true)}
                    >
                      <MdOutlineAddHomeWork className=" text-2xl text-center" />
                    </Button>
                    F1
                  </Tooltip>
                </Form.Item>
              </div>
              <div className=" flex gap-1">
                <Form.Item name="category" className="sm:mb-1" label="Category">
                  <Select
                    // ref={categoryRef}
                    // open={categorylov}
                    // onDropdownVisibleChange={(open) => setCategorylov(open)}
                    placeholder="Select Inventory Type"
                    options={lovInvType?.data?.map((item) => ({
                      value: item?.code,
                      label: `${item?.descr}`,
                    }))}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    onChange={(value) => {
                      // setCategorylov(false);
                      const selectedoption = lovInvType?.data.find(
                        (item) => item.code === value
                      );
                      form.setFieldsValue({
                        tid: selectedoption?.code,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label=" ">
                  <Tooltip title="Add Category " placement="top">
                    <Button
                      type="primary"
                      onClick={() => setShowAddCategoryModal(true)}
                    >
                      <MdOutlineAddHomeWork className=" text-2xl text-center" />
                    </Button>
                    F2
                  </Tooltip>
                </Form.Item>
              </div>
              <div className=" flex gap-1">
                <Form.Item name="class" className="sm:mb-1" label="Class">
                  <Select
                    // ref={classRef}
                    // open={classlov}
                    // onDropdownVisibleChange={(open) => setClasslov(open)}
                    placeholder="Select Inventory Class"
                    options={lovInvClass?.data?.map((item) => ({
                      value: item?.code,
                      label: `${item?.descr}`,
                    }))}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    onChange={(value) => {
                      // setClasslov(false);
                      const selectedoption = lovInvClass?.data.find(
                        (item) => item.code === value
                      );
                      form.setFieldsValue({
                        cid: selectedoption?.code,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label=" ">
                  <Tooltip title="Add Class " placement="top">
                    <Button
                      type="primary"
                      onClick={() => setShowAddClassModal(true)}
                    >
                      <MdOutlineAddHomeWork className=" text-2xl text-center" />
                    </Button>
                    F3
                  </Tooltip>
                </Form.Item>
              </div>
              <div className=" flex gap-1">
                <Form.Item name="supplier" className="sm:mb-1" label="Supplier">
                  <Select
                    // ref={supplierRef}
                    // open={supplierlov}
                    // onDropdownVisibleChange={(open) => setSupplierlov(open)}
                    placeholder="Select Vender Name"
                    options={lovVender?.data?.map((item) => ({
                      value: item?.code,
                      label: `${item?.descr}`,
                    }))}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                    onChange={(value) => {
                      // setSupplierlov(false);
                      const selectedoption = lovVender?.data.find(
                        (item) => item.code === value
                      );
                      form.setFieldsValue({
                        vendorid: selectedoption?.code,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item label=" ">
                  <Tooltip title="Add Supplier " placement="top">
                    <Button
                      type="primary"
                      onClick={() => setShowAddSupplierModal(true)}
                    >
                      <MdOutlineAddHomeWork className=" text-2xl text-center" />
                    </Button>
                    F6
                  </Tooltip>
                </Form.Item>
              </div>
              <Form.Item name="cid" className="mb-0 hidden">
                <Input />
              </Form.Item>
              <Form.Item name="mafcid" className="mb-0 hidden">
                <Input />
              </Form.Item>
              <Form.Item name="tid" className="mb-0 hidden">
                <Input />
              </Form.Item>
              <Form.Item name="vendorid" className="mb-0 hidden">
                <Input />
              </Form.Item>
            </div>
            <div className="flex sm:col-span-4 justify-between">
              <div className="flex gap-1">
                <Tooltip title="ADD ITEMS" placement="top">
                  <Button
                    type="primary"
                    onClick={() => setShowAddModal(true)}
                    className=" w-full mt-2"
                  >
                    <MdFormatListBulletedAdd className=" text-2xl text-center" />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className=" w-full"
                  loading={createLoading}
                >
                  Register Item
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Modal
        title="Items"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1200}
        destroyOnClose
        footer={[]}
      >
        <Select
          className="w-full"
          ref={itemSelectRef}
          open={isItemSelectOpen}
          onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
          placeholder="Select Item Name"
          options={getitems?.data?.map((item) => ({
            searchvalue: item.itemname,
            value: item?.itemid,
            label: (
              // <Row gutter={[16, 16]}>
              //   <Col span={24 / 7}>{item?.itemname}</Col>
              //   <Col span={24 / 7}>Cost: {item?.price}</Col>
              //   <Col span={24 / 7}>Price: {item?.saleprice}</Col>
              //   <Col span={24 / 7}>Company: {item?.mafcname}</Col>
              //   <Col span={24 / 7}>Category: {item?.typename}</Col>
              //   <Col span={24 / 7}>Class: {item?.classname}</Col>
              //   <Col span={24 / 7}>Supplier: {item?.vendorname}</Col>
              // </Row>
              <span className=" grid sm:grid-cols-9 justify-center items-center">
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item?.itemid}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                  className=" sm:col-span-2"
                >
                  {item?.itemname}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.price}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.saleprice}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.mafcname}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.typename}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.classname}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {item.vendorname}
                </span>
              </span>
            ),
          }))}
          dropdownRender={(menu) => (
            <div>
              {/* Header */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  padding: "8px",
                  backgroundColor: "#47a686",
                  fontWeight: "bold",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <span>BarCode</span>
                <span>Item Name</span>
                <span>Cost</span>
                <span>Price</span>
                <span>Company</span>
                <span>Category</span>
                <span>Class</span>
                <span>Supplier</span>
              </div>
              {/* Dropdown Menu */}
              {menu}
            </div>
          )}
          allowClear
          onChange={(value) => {
            setIsItemSelectOpen(false);
            const selectedoption = getitems?.data.find(
              (item) => item.itemid === value
            );
            form.setFieldsValue({
              itemid: selectedoption?.itemid,
              itemname: selectedoption?.itemname,
              code: selectedoption?.itemnomcltre,
              barcode: selectedoption?.itemid,
              costprice: selectedoption?.price,
              saleprice: selectedoption?.saleprice,
              company: selectedoption?.mafcname,
              category: selectedoption?.typename,
              class: selectedoption?.classname,
              supplier: selectedoption?.vendorname,
              cid: selectedoption?.cid, //clasename
              mafcid: selectedoption?.mafcid, //mafcname
              tid: selectedoption?.tid, //typename
              vendorid: selectedoption?.vendorid, //verdorname
            });
            setShowAddModal(false);
          }}
          showSearch
          filterOption={(input, option) =>
            option?.searchvalue?.toLowerCase()?.includes(input?.toLowerCase())
          }
        />
      </Modal>
      <Modal
        title="Add Manufacturer "
        open={showAddCompanyModal}
        onCancel={() => setShowAddCompanyModal(false)}
        centered
        width={400}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addManufacturerForm"
            loading={addLoadingcompany}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddComapnyMAFC createmanufacturer={createmanufacturer} />
      </Modal>
      <Modal
        title="Add Type"
        open={showAddCategoryModal}
        onCancel={() => setShowAddCategoryModal(false)}
        centered
        width={400}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addtypeForm"
            loading={addLoadingtype}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddType createtype={createtype} />
      </Modal>
      <Modal
        title="Add Class"
        open={showAddClassModal}
        onCancel={() => setShowAddClassModal(false)}
        centered
        width={400}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addclassForm"
            loading={addLoadingclass}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddClass createclass={createclass} />
      </Modal>
      <Modal
        title="Add Supplier"
        open={showAddSupplierModal}
        onCancel={() => setShowAddSupplierModal(false)}
        centered
        width={500}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addvendordetailsForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddSupplier createvendordetail={createvendordetail} />
      </Modal>
    </section>
  );
};
export default ItemRegistration;
