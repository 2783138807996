import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateDesignation = ({
  rowData,
  updatedesignationdata,
  getlovdesignation,
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updatedesignationdata({
      desigid: values?.desigid,
      desigcode: values?.desigcode,
      designame: values?.designame,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatedesignation"
        form={form}
        initialValues={{
          designame: rowData?.designame,
          desigid: rowData?.desigid,
          desigcode: rowData?.desigcode,
          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="desigid"
          label="Designation ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="desigcode" label="Designation Code">
          <Input />
        </Form.Item>
        <Form.Item name="designame" label="Designation Name">
          {/* <Select
            placeholder="Select Designation"
            onChange={(value) => {
              const selectedOption = getlovdesignation?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                desigcode: selectedOption?.code,
              });
            }}
            options={getlovdesignation?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateDesignation;
