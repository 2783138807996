import React, { useEffect, useState } from "react";
import UpdateVendorDetails from "../components/UpdateVendorDetails";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import {
  useCreateInvVendorMutation,
  useGetInvvendorQuery,
  useUpdateInvVendorMutation,
} from "../inventoryConfiguratorsService";
import useTableFilters from "../../../hooks/useTableFilter";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import AddVendorDetails from "../components/AddVendorDetails";

const VendorDetails = () => {
  const { data, isLoading, refetch } = useGetInvvendorQuery();
  const [createvendordetail, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateInvVendorMutation();
  const [
    updatevendordetail,
    {
      data: updatevendordetailData,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
    },
  ] = useUpdateInvVendorMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  useEffect(() => {
    if (data) {
      setTableDatainfo(data?.data);
    }
  }, [data]);
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
    handlerefetch();
  }, [addSuccess]); // eslint-disable-line

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
    handlerefetch();
  }, [updateSuccess]); // eslint-disable-line
  const tableColumns = [
    {
      title: "Supplier Name",
      dataIndex: "vendorname",
      ...filter("vendorname"),
    },
    {
      title: " Web URL",
      dataIndex: "weburl",
      ...filter("weburl"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...filter("email"),
    },
    {
      title: "Fax",
      dataIndex: "fax",
      ...filter("fax"),
    },
    {
      title: "ntnnum",
      dataIndex: "ntnnum",
      ...filter("ntnnum"),
    },
    {
      title: "Sales Tax Number",
      dataIndex: "saletaxnum",
      ...filter("saletaxnum"),
    },
    {
      title: "Address 1",
      dataIndex: "addr1",
      ...filter("addr1"),
    },
    {
      title: "Address 2",
      dataIndex: "addr2",
      ...filter("addr2"),
    },

    {
      title: "city",
      dataIndex: "city",
      ...filter("city"),
    },

    {
      title: "Telephone 1",
      dataIndex: "tel1",
      ...filter("tel1"),
    },

    {
      title: "Telephone 2",
      dataIndex: "tel2",
      ...filter("tel2"),
    },

    {
      title: "Vendor Current Status",
      render: (_, { vendorcurrentstatus }) =>
        vendorcurrentstatus === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) =>
        a?.vendorcurrentstatus?.localeCompare(b?.vendorcurrentstatus),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Vendor Details">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handlerefetch = () => {
    refetch();
  };
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Vendor Details</h1>
        <Tooltip title="Add New Vendor Details ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="type"
        />
      </div>
      <Modal
        title="Add Vendor Details"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addvendordetailsForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddVendorDetails
          createvendordetail={createvendordetail}
          handlerefetch={handlerefetch}
        />
      </Modal>

      <Modal
        title="Update vendor Details"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatevendordetailForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateVendorDetails
          rowData={rowData}
          updatevendordetailData={updatevendordetailData}
          updatevendordetail={updatevendordetail}
        />
      </Modal>
    </section>
  );
};
export default VendorDetails;
