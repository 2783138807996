import Dashboard from "../features/Dashboard/pages/Dashboard";
import PatientDashboard from "../features/Dashboard/pages/PatientDashboard";

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: <Dashboard/>
  },
  {
    path: 'patientdashboard',
    element: <PatientDashboard/>
  },

]
