import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tag, Tooltip } from 'antd'
import React, {useEffect, useState } from 'react'

import useTableFilters from '../../../hooks/useTableFilter'
import AddEmployee from '../components/AddEmployee'
import moment from "moment";
import { useCreateEmployeeMutation, useGetEmployeeListQuery, useUpdateEmployeeConfigMutation } from '../employeeServices'


const Employee=()=> {
  const { data, isLoading,refetch} = useGetEmployeeListQuery();
  const [createemployee, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateEmployeeMutation();
  const [ updatestatus, {data:updateemployeeData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateEmployeeConfigMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  // const [showUpdateModal, setShowUpdateModal] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([]);
  const [rowData, setRowData] = useState(false);
 
  const filter = useTableFilters();
  //  console.log("rowdata",data)

  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line

 
  
  const tableColumns = [

    {
      title:"Employee Name",
      dataIndex: "empname",
      ...filter("empname"),
   
    },
    {
        title: "Gender",
        dataIndex: "gender",
         ...filter("gender"),
         render: (gender) => (gender === "M" ? "Male" : gender === "F" ? "Female" : gender),
    
      },
      {
        title: "Email",
        dataIndex: "email",
         ...filter("email"),
    
      },
      {
        title: "Age",
        dataIndex: "age",
        key: "age",
      },
  
      {
        title: "Employee Type",
        dataIndex: "emptype",
         ...filter("emptype"),
    
      },
      
    {

      title: "Phone No.",
      dataIndex: "cellno",
       ...filter("cellno"),
     
    },
    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      // sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Employee">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
             setRowData(data);
             updatestatus({empid:data.empid})
           
            

            }}
          />
        </Tooltip>
      ),
    },
  ];
const handlerefetch=()=>{
  refetch()
}
  
  return (
    <section>
  <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Manage Employee</h1>
        <Tooltip title="Add New Employee">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className='p-2'>
        <Table
         columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
   
          
        />
      </div>

    <Modal
      title="Add Employee"
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addEmployeeForm"
        loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
        // <Button key="2" onClick={() => setShowAddModal(false)}>
        //   Cancel
        // </Button>,
      ]}
    >
<AddEmployee
     createemployee={createemployee} 
     handlerefetch={handlerefetch}
    />
    </Modal>
{/* 
    <Modal
      title="Update Employee"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateEmployeeForm"
        loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateEmployee
         rowData={rowData}
         updateemployee={updateemployee}
         updateemployeeData={updateemployeeData}
      />
    </Modal> */}
  </section>
  )
}
export default Employee