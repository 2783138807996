/* eslint-disable no-template-curly-in-string */
const typeTemplate = 'Please enter a valid ${type}'

const formValidationMessages = {
	default: 'Please enter a valid ${label}',
	required: 'Please enter ${label}',
	whitespace: 'Please enter ${label}',
	types: {
		string: typeTemplate,
		method: typeTemplate,
		array: typeTemplate,
		object: typeTemplate,
		number: typeTemplate,
		date: typeTemplate,
		boolean: typeTemplate,
		integer: typeTemplate,
		float: typeTemplate,
		regexp: typeTemplate,
		email: typeTemplate,
		url: typeTemplate,
		hex: typeTemplate,
	},
	string: {
		len: '${label} should be exactly ${len} characters',
		min: '${label} should be at least ${min} characters',
		max: "${label} can't be longer than ${max} characters",
		range: '${label} should be between ${min} and ${max} characters',
	},
	number: {
		len: '${label} should equal ${len}',
		min: "${label} can't be less than ${min}",
		max: "${label} can't be greater than ${max}",
		range: '${label} should be between ${min} and ${max}',
	},
	array: {
		len: '${label} should be exactly ${len} in length',
		min: "${label} can't be less than ${min} in length",
		max: "${label} can't be greater than ${max} in length",
		range: '${label} should be between ${min} and ${max} in length',
	},
	pattern: {
		mismatch: 'Please enter a valid ${label}',
	},
}
/* eslint-enable no-template-curly-in-string */

export default formValidationMessages
