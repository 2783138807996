import { Form, Select, TimePicker } from 'antd'
import { useGetOrganizationStructureQuery } from '../../orgStructureService'
import formValidationMessages from '../../../../utils/formValidationMessages'
import { formateWithTime } from '../../../../utils/dateUtils'

const AddOpdList = ({ createOpdList }) => {
    const { data: orgStructure } = useGetOrganizationStructureQuery()

  const [form] = Form.useForm()

  const onFinish = values =>
    createOpdList({
        // unit:'98',
        // sttime:"00:00:00",
        // entime:"23:59:59",
        // status:'Y',
      ...values,
      message: 'B',
      sttime: formateWithTime(values.sttime),
      entime: formateWithTime(values.entime),
    })

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      autoComplete='off'
      id='addOpdListForm'
      form={form}
      validateMessages={formValidationMessages}>
      <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
      <Form.Item
          name='unit'
          label='Unit'
          rules={[
            {
              required: true,
            },
          ]}>
          <Select
            placeholder='Select Unit'
            options={orgStructure?.data
              ?.filter(item => item.isopd === 'Y')
              ?.map(item => ({
                value: item?.orgunitid,
                label: item?.name,
              }))}
            showSearch
            filterOption={(input, option) => option?.label?.toLowerCase()?.includes(input?.toLowerCase())}
          />
        </Form.Item>
        <Form.Item
          label='Start Time'
          className='flex-1'
          name='sttime'
          rules={[
            {
              required: true,
            },
          ]}>
          <TimePicker />
        </Form.Item>
        <Form.Item
          label='End Time'
          className='flex-1'
          name='entime'
          rules={[
            {
              required: true,
            },
          ]}>
          <TimePicker />
        </Form.Item>
        <Form.Item name='status' label='Status'>
          <Select
            placeholder='Select Status'
            options={[
              { label: 'Yes', value: 'Y' },
              { label: 'No', value: 'N' },
            ]}
          />
        </Form.Item> 
      </div>
    </Form>
  )
}

export default AddOpdList
