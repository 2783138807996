import React, { useEffect, useState } from 'react'
import { useCreateInvUomMutation, useGetInvUomQuery, useUpdateInvUomMutation } from '../inventoryConfiguratorsService';
import useTableFilters from '../../../hooks/useTableFilter';
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import AddInventoryUOM from './AddInventoryUOM';
import UpdateInventoryUOM from './UpdateInventoryUOM';
import { EditFilled, PlusOutlined } from '@ant-design/icons';

const InventoryUOM = () => {
  const { data, isLoading,refetch} = useGetInvUomQuery();
  const [createuom, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateInvUomMutation();
  const [ updateuom, {data:updateuomData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvUomMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const[tableDatainfo,setTableDatainfo]=useState([]);
  useEffect(()=>{
      if(data){
        setTableDatainfo(data?.data)
      }
    },[data])
    useEffect(() => {
      if (addSuccess) setShowAddModal(false);
       handlerefetch()
    }, [addSuccess]); // eslint-disable-line

    useEffect(() => {
      if (updateSuccess) setShowUpdateModal(false);
      handlerefetch();
    }, [updateSuccess]); // eslint-disable-line
  const tableColumns = [
      {
          title:"UOM ID",
          dataIndex: "uomid",
          ...filter("name"),
       
        },
      {
        title:"UOM ",
        dataIndex: "uomname",
        ...filter("uomname"),
     
      },

  
        {
          title: "Status",
          render: (_, { status }) =>
            status === "Y" ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="red">In Active</Tag>
            ),
          sorter: (a, b) => a?.status?.localeCompare(b?.status),
        },
        {
          title: "Action(s)",
          render: (data) => (
            <Tooltip title="Update UOM">
              <Button
                type="primary"
                className="btn-warning-primary"
                icon={<EditFilled />}
                onClick={() => {
                  setRowData(data);
                  setShowUpdateModal(true);
                }}
              />
            </Tooltip>
          ),
        },
     
     
    ];
    const handlerefetch=()=>{
      refetch()
    }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Inventory UOM</h1>
      <Tooltip  title="Add New UOM ">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
       dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add UOM"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addUOMForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddInventoryUOM
  createuom={createuom} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  <Modal
      title="Update UOM"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateUOMForm"
        loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateInventoryUOM
        rowData={rowData}
        updateuomData={updateuomData}
        updateuom={updateuom}
      />
    </Modal>

  </section>
  )
}

export default InventoryUOM