import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const UpdateInventoryUOM = ({rowData,updateuom,updateuomData}) => {
  const [form] = Form.useForm()
  const onFinish = (values) =>
    updateuom({
      ...values,
      genericname:values?.genericname,
      status: values?.status,
      cid:values?.uomid,
   
      message: 'B',
    })
  return (

    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='updateUOMForm'
    form={form}
    initialValues={{
      uomname:rowData?.uomname,
      uomid:rowData?.uomid,
     
      status:rowData?.status,
      
      
     
    }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >

<Form.Item
      name='uomid'
      label='UOM ID'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input disabled='true' />
    </Form.Item>
    <Form.Item
      name='uomname'
      label='Inventory UOM'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter UOM'  />
    </Form.Item>
    <Form.Item name='status' label='Status'>
        <Select
     
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
          
        />
      </Form.Item>
   
  </Form>
    </div>
  )
}
export default UpdateInventoryUOM