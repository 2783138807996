import { api } from '../api/api'

export const orgStructure = api.injectEndpoints({
  tagTypes: ['OrgStructure', 'ProjectCatagory', 'Project', 'Department', 'SubDepartment', 'OpdLists','WardControl'],
  endpoints: build => ({
    getOrganizationStructure: build.query({
      query: () => 'security/getOrganizationStructure',
      providesTags: ['OrgStructure'],
    }),
    lovOrganization: build.query({
      query: () => 'security/lovOrganization',
    }),

        lovDepartment: build.query({
      query: () => 'security/lovDepartment',
    }),
    lovSubOrganization: build.mutation({
      query: body => `security/lovSubOrganization/${body.org}`,
    }),
    lovProjectsCat: build.mutation({
      query: body => `security/lovProjectsCat/${body.org}/${body.subOrg}`,
    }),
    lovProjects: build.mutation({
      query: body => `security/lovProject/${body.projCat}`,
    }),
    lovSubDepartment: build.mutation({
      query: body => `security/lovSubDepartment/${body.dept}`,
    }),
    lovSection: build.mutation({
      query: body => `security/lovSection/${body.dept}/${body.subDept}`,
    }),
    saveOrgUnit: build.mutation({
      query: body => ({
        url: 'security/saveOrgOrgUnit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OrgStructure'],
    }),
    updateOrgUnit: build.mutation({
      query: body => ({
        url: 'security/updateOrgOrgUnit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OrgStructure'],
    }),
    getAllProjectCats: build.query({
      query: () => 'security/getAllProjectCats',
      providesTags: ['ProjectCatagory'],
    }),
    createProjectCat: build.mutation({
      query: body => ({
        url: 'security/createProjectCat',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectCatagory'],
    }),
    updateProjectCat: build.mutation({
      query: body => ({
        url: 'security/updateProjectCat',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectCatagory'],
    }),
    getAllProjects: build.query({
      query: () => 'security/getAllProjects',
      providesTags: ['Project'],
    }),
    createProject: build.mutation({
      query: body => ({
        url: 'security/createProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    updateProject: build.mutation({
      query: body => ({
        url: 'security/updateProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    getAllDepartments: build.query({
      query: () => 'security/getAllDepartments',
      providesTags: ['Department'],
    }),
    createDepartment: build.mutation({
      query: body => ({
        url: 'security/createDepartment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Department'],
    }),
    updateDepartment: build.mutation({
      query: body => ({
        url: 'security/updateDepartment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Department'],
    }),
    getAllSubDepartments: build.query({
      query: () => 'security/getAllSubDepartments',
      providesTags: ['SubDepartment'],
    }),
    createSubDepartment: build.mutation({
      query: body => ({
        url: 'security/createSubDepartment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SubDepartment'],
    }),
    updateSubDepartment: build.mutation({
      query: body => ({
        url: 'security/updateSubDepartment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SubDepartment'],
    }),
    getAllSections: build.query({
      query: () => 'security/getAllSections',
      providesTags: ['Section'],
    }),
    createSection: build.mutation({
      query: body => ({
        url: 'security/createSection',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Section'],
    }),
    updateSection: build.mutation({
      query: body => ({
        url: 'security/updateSection',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Section'],
    }),
    lovZone: build.mutation({
      query: body => `security/lovZone/${body.org}/${body.subOrg}`,
    }),
    GetAllOpdLists: build.query({
      query: () => 'security/getAllOpdLists',
      providesTags: ['OpdList'],
    }),
    createOpdLists: build.mutation({
      query: body => ({
        url: 'security/saveOPDs',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OpdList'],
    }),
    updateOpdLists: build.mutation({
      query: body => ({
        url: 'security/updateOpdLists',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['OpdList'],
    }),
  }),
})

export const {
  useGetOrganizationStructureQuery,
  useLovOrganizationQuery,
  useLovDepartmentQuery,
  useLovSubOrganizationMutation,
  useLovProjectsCatMutation,
  useLovProjectsMutation,
  useLovSubDepartmentMutation,
  useLovSectionMutation,
  useUpdateOrgUnitMutation,
  useSaveOrgUnitMutation,
  useGetAllProjectCatsQuery,
  useCreateProjectCatMutation,
  useUpdateProjectCatMutation,
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetAllDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetAllSubDepartmentsQuery,
  useCreateSubDepartmentMutation,
  useUpdateSubDepartmentMutation,
  useGetAllSectionsQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useLovZoneMutation,
  useGetAllOpdListsQuery,
  useCreateOpdListsMutation,
  useUpdateOpdListsMutation,

} = orgStructure
