import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateMstatus = ({ rowData, updatemstatusdata, getlovmstatus }) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updatemstatusdata({
      mid: values?.mid,
      description: values?.description,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatemstatus"
        form={form}
        initialValues={{
          description: rowData?.description,
          mid: rowData?.mid,
          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item name="mid" label="Marital Code">
          <Input disabled />
        </Form.Item>
        <Form.Item name="description" label="Description">
          {/* <Select
            placeholder="Select Description"
            onChange={(value) => {
              const selectedOption = getlovmstatus?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                mid: selectedOption?.code,
              });
            }}
            options={getlovmstatus?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateMstatus;
