import { useState, useEffect } from "react";
import { Button, Modal, Table, Tooltip } from "antd";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import useTableFilters from "../../../../hooks/useTableFilter";
import {
  useCreateOpdListsMutation,
  useGetAllOpdListsQuery,
  useUpdateOpdListsMutation,
} from "../../orgStructureService";
import AddOpdList from "./AddOpdList";
import UpdateOpdList from "./UpdateOpdList";

const OpdList = () => {
  const { data, isLoading } = useGetAllOpdListsQuery();
  const [createOpdList, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateOpdListsMutation();
  const [
    updateOpdList,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateOpdListsMutation();
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Unit",
      dataIndex: "unit",
      ...filter("unit"),
      sorter: (a, b) => a?.unit - b?.unit,
      defaultSortOrder: "descend",
    },
    {
      title: "Service Id",
      dataIndex: "srvid",
      ...filter("srvid"),
      sorter: (a, b) => a?.srvid?.localeCompare(b?.srvid),
    },
    {
      title: "Start Time",
      dataIndex: "sttime",
      ...filter("sttime"),
      sorter: (a, b) => a?.sttime?.localeCompare(b?.sttime),
    },
    {
      title: "End Time",
      dataIndex: "entime",
      ...filter("entime"),
      sorter: (a, b) => a?.entime?.localeCompare(b?.entime),
    },
    // {
    // 	title: 'Action(s)',
    // 	render: data => (
    // 		<Tooltip title='Update OpdList'>
    // 			<Button
    // 				type='primary'
    // 				className='btn-warning-primary'
    // 				icon={<EditFilled />}
    // 				onClick={() => {
    // 					setRowData(data)
    // 					setShowUpdateModal(true)
    // 				}}
    // 			/>
    // 		</Tooltip>
    // 	),
    // },
  ];

  return (
    <section className="border border-gainsboro">
      <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
        <Tooltip title="New OpdList">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-5">
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 20 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="OpdListid"
        />
      </div>

      <Modal
        title="Add OpdList"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addOpdListForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddOpdList createOpdList={createOpdList} />
      </Modal>

      {/* <Modal
				title='Update OpdList'
				open={showUpdateModal}
				onCancel={() => setShowUpdateModal(false)}
				centered
				width={1000}
				destroyOnClose
				footer={[
					<Button
						key='1'
						htmlType='submit'
						form='updateOpdListForm'
						loading={updateLoading}
						type='primary'>
						Update
					</Button>,
					<Button key='2' onClick={() => setShowUpdateModal(false)}>
						Cancel
					</Button>,
				]}>
				<UpdateOpdList rowData={rowData} updateOpdList={updateOpdList} />
			</Modal> */}
    </section>
  );
};

export default OpdList;
