import OrgConfigutration from '../features/orgStructure/pages/OrgConfigutration'
import OrgStructure from '../features/orgStructure/pages/OrgStructure'

export const orgStructureRoutes = [
	{
		path: 'orgstructure',
		element: <OrgStructure />,
	},
	{
		path: 'orgconfiguration',
		element: <OrgConfigutration />,
	},
]
