import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useGetDashboardDataQuery } from '../../security/securityService'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const PatientWiseReport = () => {
  const co = useSelector((state) => state.auth.user.suborgid)
  const { data: dashboardData } = useGetDashboardDataQuery(co)
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      labels: {
        font: {
          size: 15,
        },
      },
      title: {
        display: true,
        text: 'Patient Type Wise Report',
      },
    },
  }

  const labels = useMemo(
    () =>
      dashboardData?.data?.patientTypeWise?.map(
        (patientTypeWise) => patientTypeWise?.label
      ),
    [dashboardData]
  )
  const values = useMemo(
    () =>
      dashboardData?.data?.patientTypeWise?.map(
        (patientTypeWise) => patientTypeWise?.value
      ),
    [dashboardData]
  )

  const data = {
    labels,
    datasets: [
      {
        label: '',
        borderColor: 'rgb(31,163,203)',
        backgroundColor: 'rgba(31,163,203, 0.8)',
        data: values,
      },
    ],
  }

  return (
    <div className='h-[22rem]'>
      <Bar options={options} data={data} />
    </div>
  )
}

export default PatientWiseReport
