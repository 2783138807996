import { api } from "../api/api";

export const NomenclatureApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConfiguredNomncltre: build.query({
      query: (body) => `http://172.16.70.90:8091/security/getConfiguredNomncltre`,
    }),       
  }),
});

export const {
    useGetConfiguredNomncltreQuery,
} = NomenclatureApi

