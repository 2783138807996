import { Button, DatePicker, Form, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { futureDates } from '../../../utils/dateUtils';
import LineChartCard from '../components/LineChartCard';
import LineChart from '../components/LineChart';
import { useGetDashboardSummaryMutation, useGetMonthTotalCreditSaleQuery, useGetMonthTotalSaleQuery } from '../salesDashboardServices';
import moment from 'moment';

const SalesDashboard = () => {
  const { data: totalSale } = useGetMonthTotalSaleQuery();
  const { data: creditSale } = useGetMonthTotalCreditSaleQuery();
  const [form] = Form.useForm();
  
  // State to hold summary data with initial values set to 0
  const [summaryData, setSummaryData] = useState({
    cashsale: 0,
    creditsale: 0,
    purchaseitem: 0,
    returnitem: 0,
    saleitem: 0,
    totalsale: 0,
  });

  // Mutation hook for API call
  const [SummaryData] = useGetDashboardSummaryMutation();

  // Fetch summary data based on the current date
  const fetchSummaryData = async (fromdate, todate) => {
    try {
      message.loading('Fetching data...', 0);

      // API call with date parameters
      const response = await SummaryData({
        fromdate: moment(fromdate).format('DD-MMM-YYYY'),
        todate: moment(todate).format('DD-MMM-YYYY'),
        message: 'B',
      }).unwrap();

      console.log("API Response:", response);

      // Check if response code is 1 and data is available
      if (response?.responsecode === 1 && Array.isArray(response.data) && response.data.length > 0) {
        const summary = response.data[0]; // Extracting the first object from the data array

        // Update the summaryData state
        setSummaryData({
          cashsale: summary?.cashsale || 0,
          creditsale: summary?.creditsale || 0,
          purchaseitem: summary?.purchaseitem || 0,
          returnitem: summary?.returnitem || 0,
          saleitem: summary?.saleitem || 0,
          totalsale: summary?.totalsale || 0,
        });
        message.success('Data fetched successfully!');
      } else {
        message.warning('No data found or invalid response format.');
      }
    } catch (err) {
      console.error("API Error:", err);
      message.error('Failed to fetch sales summary data');
    } finally {
      message.destroy();
    }
  };

  // On component mount, fetch summary data for the current date
  useEffect(() => {
    const currentDate = moment(); // Current system date
    fetchSummaryData(currentDate, currentDate);

    // Set default dates in the form
    form.setFieldsValue({
      fromdate: currentDate,
      todate: currentDate,
    });
  }, [form]); // eslint-disable-line

  // Form submission handler
  const onFinish = async (values) => {
    await fetchSummaryData(values?.fromdate, values?.todate);
  };

  return (
    <section>
      <div>
        <h1 className="col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
          Sales Dashboard
        </h1>
      </div>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="p-2 grid sm:grid-cols-3 gap-2">
          <Form.Item name="fromdate" label="From Date">
            <DatePicker
              disabledDate={futureDates}
              placeholder="Enter From Date"
              format="DD-MMM-YYYY"
            />
          </Form.Item>
          <Form.Item name="todate" label="To Date">
            <DatePicker
              disabledDate={futureDates}
              placeholder="Enter To Date"
              format="DD-MMM-YYYY"
            />
          </Form.Item>
          <Form.Item className="flex justify-end" label=" ">
            <Button className="w-48" type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </div>
      </Form>

      {/* Summary Boxes with gradient colors */}
      <div className="grid grid-cols-3 gap-2 m-1 mb-3">
        <SummaryBox gradient="linear-gradient(to right, #47d4ed, #3db0e0)" title="Cash Sale" value={summaryData.cashsale} />
        <SummaryBox gradient="linear-gradient(to right, #9190e3, #6b67d8)" title="Credit Sale" value={summaryData.creditsale} />
        <SummaryBox gradient="linear-gradient(to right, #e67af0, #d05fdc)" title="Sale Item" value={summaryData.saleitem} />
        <SummaryBox gradient="linear-gradient(to right, #4773ed, #345bb8)" title="Total Sale" value={summaryData.totalsale} />
        <SummaryBox gradient="linear-gradient(to right, #9fd4a0, #7abc80)" title="Purchase Item" value={summaryData.purchaseitem} />
        <SummaryBox gradient="linear-gradient(to right, #f29060, #e06744)" title="Return Item" value={summaryData.returnitem} />
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
        <LineChartCard LineData={totalSale} />
        <LineChart LineData={creditSale} />
      </div>
    </section>
  );
};

// Updated SummaryBox Component with Gradient Background
const SummaryBox = ({ gradient, title, value }) => (
  <div
    style={{ backgroundImage: gradient, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    className="p-4 shadow-md hover:shadow-lg rounded transform transition-transform hover:scale-105"
  >
    <div className="flex justify-center items-center text-4xl font-medium text-white">
      {value}
    </div>
    <h3 className="flex justify-center items-center text-md font-semibold text-black">
      {title}
    </h3>
  </div>
);

export default SalesDashboard;
