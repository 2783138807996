import { Form, Input } from 'antd'
import React from 'react'

function UpdateNomenclature() {
  return (
    <Form
    layout='vertical'
    // onFinish={onFinish}
    autoComplete='off'
    id='updateNomenclatureform'
    // initialValues={{
    //   ...rowData,
    //   orgId: rowData?.orgOrganization?.orgid,
    // }}
    // validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
    <Form.Item name='class' label='Class'>
        <Input placeholder='Enter Class' />
      </Form.Item>
      <Form.Item name='Pridesc' label='Pre Desc'>
      <Input placeholder='Enter Pre desc' />
              </Form.Item>
      <Form.Item name='pricelist' label='Price List'>
        <Input placeholder='Enter Price list' />
      </Form.Item>
      <Form.Item name='desc' label='Description'>
        <Input placeholder='Enter Description' />
      </Form.Item>

      <Form.Item name='nomenclature' label='Nomenclature '>
        <Input placeholder='Enter Nomenclature' />
      </Form.Item>

      <Form.Item name='amount' label='Amount'>
        <Input placeholder='Enter Amount' />
      </Form.Item>
      

      
      
    </div>
  </Form>
  )
}

export default UpdateNomenclature