// import React from 'react'
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
// import { useGetCurrPatientRegQuery } from '../DashboardService'
// export default function PatientAreaChart() {
//   const { data: patientData } = useGetCurrPatientRegQuery()
//   console.log(patientData)
//   const data = [
//     {
//       name: 'Page A',
//       uv: 4000,
//       pv: 2400,
//       amt: 2400,
//     },
//     {
//       name: 'Page B',
//       uv: 3000,
//       pv: 1398,
//       amt: 2210,
//     },
//     {
//       name: 'Page C',
//       uv: 2000,
//       pv: 9800,
//       amt: 2290,
//     },
//     {
//       name: 'Page D',
//       uv: 2780,
//       pv: 3908,
//       amt: 2000,
//     },
//     {
//       name: 'Page E',
//       uv: 1890,
//       pv: 4800,
//       amt: 2181,
//     },
//     {
//       name: 'Page F',
//       uv: 2390,
//       pv: 3800,
//       amt: 2500,
//     },
//     {
//       name: 'Page G',
//       uv: 3490,
//       pv: 4300,
//       amt: 2100,
//     },
//   ]

//   return (
//     <div className='border border-border rounded mx-2 my-2'>
//       <h4 className='font-semibold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl rounded-tr bg-cardGrey'>
//         Patient Visit
//       </h4>
//       <div className='grid place-content-center py-4'>
//         <AreaChart
//           width={400}
//           height={220}
//           data={data}
//           margin={{
//             top: 10,
//             right: 30,
//             left: 0,
//             bottom: 0,
//           }}
//         >
//           <XAxis dataKey='name' />
//           <YAxis />
//           <Tooltip />
//           <Area type='monotone' dataKey='uv' stackId='1' stroke='#8884d8' fill='#8884d8' />
//           <Area type='monotone' dataKey='pv' stackId='1' stroke='#82ca9d' fill='#82ca9d' />
//           <Area type='monotone' dataKey='amt' stackId='1' stroke='#ffc658' fill='#ffc658' />
//         </AreaChart>
//       </div>
//     </div>
//   )
// }

// import React from 'react'
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import { useGetCurrPatientRegQuery } from '../DashboardService'

// const PatientAreaChart = () => {
//   const { data: patientData } = useGetCurrPatientRegQuery()
//   console.log(patientData)
//   // Extracting data for each series from the API response
//   const beniData = patientData?.data?.map(item => ({
//     name: item.month,
//     value: Number(item.beni),
//   }))
//   const othersData = patientData?.data?.map(item => ({
//     name: item.month,
//     value: Number(item.others),
//   }))
//   const cneData = patientData?.data?.map(item => ({
//     name: item.month,
//     value: Number(item.cne),
//   }))
//   const emplData = patientData?.data?.map(item => ({
//     name: item.month,
//     value: Number(item.empl),
//   }))

//   // Highcharts configuration options for the line chart
//   const options = {
//     chart: {
//       type: 'line',
//     },
//     title: {
//       text: 'Patient Area Chart',
//     },
//     xAxis: {
//       categories: patientData?.data?.map(item => item.month),
//     },
//     yAxis: {
//       title: {
//         text: 'Value',
//       },
//     },
//     series: [
//       {
//         name: 'Beni',
//         data: beniData.map(item => item.value),
//       },
//       {
//         name: 'Others',
//         data: othersData.map(item => item.value),
//       },
//       {
//         name: 'CNE',
//         data: cneData.map(item => item.value),
//       },
//       {
//         name: 'EMPL',
//         data: emplData.map(item => item.value),
//       },
//     ],
//   }

//   return (
//     <div className='border border-border rounded mx-2 my-2'>
//       <h4 className='font-semibold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl rounded-tr bg-cardGrey'>
//         Patient Area Chart
//       </h4>
//       <HighchartsReact highcharts={Highcharts} options={options} />
//     </div>
//   )
// }

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetCurrPatientRegQuery } from "../DashboardService";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsAccessibility from "highcharts/modules/accessibility";

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const PatientAreaChart = () => {
  const { data: patientData } = useGetCurrPatientRegQuery();

  // Check if patientData exists before accessing its data property
  const beniData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.beni),
    })) || [];
  const othersData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.others),
    })) || [];
  const cneData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.cne),
    })) || [];
  const emplData =
    patientData?.data?.map((item) => ({
      name: item.name,
      value: Number(item.empl),
    })) || [];

  // Highcharts configuration options for the line chart
  const options = {
    credits: false,
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: patientData?.data?.map((item) => item.month) || [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Beni",
        data: beniData.map((item) => item.value),
        color: "#2CAFFE",
      },
      {
        name: "Others",
        data: othersData.map((item) => item.value),
        color: "#004291",
      },
      {
        name: "CNE",
        data: cneData.map((item) => item.value),
        color: "#3D92BC",
      },
      {
        name: "EMPL",
        data: emplData.map((item) => item.value),
        color: "#5752C8",
      },
    ],
  };

  return (
    <div className="border border-border rounded-xl shadow-lg mx-2 my-2">
      <h4 className="font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white">
        Registered Patient (Current Month)
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PatientAreaChart;
