import ETT from "../features/ett/pages/ETT";



export const ettRoutes = [
  {
    path: 'ett',
    element: <ETT/>
  },
  // {
  //   path: 'ettreportentry',
  //   element: <ETTReportEntry/>
  // },
]
