import { Form, Input, Select } from 'antd';
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages';

const UpdateVendorDetails = ({rowData,updatevendordetail,updatevendordetailData}) => {
    const [form] = Form.useForm()
    const onFinish = (values) =>
        updatevendordetail({
        ...values,
        vendorid:rowData?.vendorid,
       status: values?.vendorcurrentstatus,
        // cid:values?.gid,
     
        message: 'B',
      })
  
  return (
    <div>
    <Form
    layout='vertical'
     onFinish={onFinish}
    id='updatevendordetailForm'
    form={form}
    initialValues={{
        vendorname:rowData?.vendorname,
        weburl:rowData?.weburl,
       email:rowData?.email,
        fax:rowData?.fax,
       ntnnum:rowData?.ntnnum,
        saletaxnum:rowData?.saletaxnum,
       addr1:rowData?.addr1,
        addr2:rowData?.addr2,
       city:rowData?.city,
        tel1:rowData?.tel1,
        tel2:rowData?.tel2,
   
       vendorcurrentstatus:rowData?.vendorcurrentstatus, 
       
      }}
    validateMessages={formValidationMessages}
    className='grid grid-cols-5 gap-2'
  >
     <Form.Item
        name='vendorname'
        label='Vendor Name'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Vendor Name'/>
      </Form.Item>

     

      <Form.Item
        name='email'
        label='Email'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Email'/>
      </Form.Item>

    

      <Form.Item
        name='addr1'
        label='Address 1'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Address 1'/>
      </Form.Item>

     

      <Form.Item
        name='city'
        label='City '
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter City'/>
      </Form.Item>

      <Form.Item
  name='vendorcurrentstatus'
  label='Vendor Current Status'
  rules={[{ required: true, message: 'Please select the vendor current status!' }]}
>
  <Select
    placeholder='Select Vendor Current Status'
    options={[
      { label: 'Active', value: 'Y' },
      { label: 'In Active', value: 'N' },
    ]}
  />
</Form.Item>
    <Form.Item
        name='remarks'
        label='Remarks'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Remarks'/>
      </Form.Item>

    

        <Form.Item
        name='weburl'
        label='Web URL'
   >
        <Input placeholder='Enter Web URL'/>
      </Form.Item>
        <Form.Item
        name='fax'
        label='Fax'
   >
        <Input placeholder='Enter Fax'/>
      </Form.Item>

      <Form.Item
        name='ntnnum'
        label='ntnnum'
   >
        <Input placeholder='Enter ntnnum'/>
      </Form.Item>

      <Form.Item
        name='saletaxnum'
        label='Sale Tax Number'
   >
        <Input placeholder='Enter Sales Tax Number'/>
      </Form.Item>
        <Form.Item
        name='addr2'
        label='Address 2'
   >
        <Input placeholder='Enter Address 2'/>
      </Form.Item>
      <Form.Item
        name='tel1'
        label='Telephone 1'
   >
        <Input placeholder='Enter Telephone 1'/>
      </Form.Item>

      <Form.Item
        name='tel2'
        label='Telephone 2'
   >
        <Input placeholder='Enter Telephone 2'/>
      </Form.Item>
    
  </Form>
    </div>
  )
  }

export default UpdateVendorDetails