import { api } from '../api/api'

export const dashboardApi = api.injectEndpoints({
  endpoints: build => ({
    getDashboardData: build.query({
      query: body => `security/getDashboardData/${body}`,
    }),
    getPatientVisit: build.query({
      query: () => `registration/getPatientVisit`,
    }),
    getPatientAppointStat: build.query({
      query: () => `registration/getPatientAppointStat`,
    }),
    getPatientComparison: build.query({
      query: () => `registration/getPatientComparison`,
    }),
    getCurrPatientReg: build.query({
      query: () => `registration/getCurrPatientReg`,
    }),
    getCurrPatientVisit: build.query({
      query: () => `registration/getCurrPatientVisit`,
    }),
    getcomparepatient: build.query({
      query: () => `registration/getcomparepatient`,
    }),
    getPatientVisitRegCompar: build.query({
      query: () => `registration/getPatientVisitRegCompar`,
    }),
    getPatientRegisteredCurrMonth: build.query({
      query: () => `registration/getPatientRegisteredCurrMonth`,
    }),
    getPatientVisitCurrMonthCount: build.query({
      query: () => `registration/getPatientVisitCurrMonthCount`,
    }),
    getPatientVisitCurrMonth: build.query({
      query: () => `registration/getPatientVisitCurrMonth`,
    }),
    // getcomparepatient: build.query({
    // http://172.16.70.90:8082/
    //   query: () => `registration/getcomparepatient`,
    // }),
  }),
})

export const {
  useGetDashboardDataQuery,
  useGetPatientVisitQuery,
  useGetPatientAppointStatQuery,
  useGetPatientComparisonQuery,
  useGetCurrPatientRegQuery,
  useGetCurrPatientVisitQuery,
  useGetcomparepatientQuery,
  useGetPatientVisitRegComparQuery,
  useGetPatientRegisteredCurrMonthQuery,
  useGetPatientVisitCurrMonthCountQuery,
  useGetPatientVisitCurrMonthQuery,
  // useGetcomparepatientQuery,
} = dashboardApi
