import React, { useEffect, useState } from "react";
import {
  useCreatePurchaseReceiveMutation,
  useGetInvPurchaseDetailbyMasterMutation,
  useGetInvPurchaseOrderQuery,
  useUpdateCurrentQtyMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import { Button, Input, Table, Tag } from "antd";

import moment from "moment";
import openNotification from "../../../utils/notification";

const PurchaseReceivingFinal = () => {
  const { data: getMasterData, isLoading } = useGetInvPurchaseOrderQuery();
  const [savePodetail, { data: getdatapoDetail, isLoading: poDetailLoading }] =
    useGetInvPurchaseDetailbyMasterMutation();
  const [saveporeceivedetail] = useCreatePurchaseReceiveMutation();
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const [tabledetail, setTabledetail] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [
    updatepodetail,
    {
      data: getpodetupdate,
      isSuccess: updatedetailSuccess,
      isLoading: updatedetailLoading,
    },
  ] = useUpdateCurrentQtyMutation();
  useEffect(() => {
    if (getMasterData) {
      setTableDatainfo(getMasterData?.data);
    }
  }, [getMasterData]);
  useEffect(() => {
    if (getdatapoDetail) {
      setTabledetail(getdatapoDetail?.data);
    }
  }, [getdatapoDetail]);
  useEffect(() => {
    if (updatedetailSuccess) {
      savePodetail({
        purchaseseqnum: getpodetupdate?.data.purchaseseqnum,
        purchasenum: getpodetupdate?.data?.purchasenum,
        message: "B",
      });
    }
  }, [updatedetailSuccess]); // eslint-disable-line
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tabledetail.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTabledetail(newData);
  };
  const tableColumns = [
    {
      title: "Purchase No.",
      dataIndex: "purchasenum",
      ...filter("purchasenum"),
    },
    // {
    //   title: "itemname",
    //   dataIndex: "descr",
    //   ...filter("descr"),
    // },
    {
      title: "Purchase Date ",
      dataIndex: "purchasedate ",
      render: (_, record) => (
        <div>{moment(record?.purchasedate)?.format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Quotation No.",
      dataIndex: "quotnum",
      ...filter("quotnum"),
    },
    {
      title: "Quotation Date",
      dataIndex: "quotdate ",
      render: (_, record) => (
        <div>{moment(record?.quotdate)?.format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorname",
      ...filter("vendorname"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="red">Pending</Tag>
        ) : (
          <Tag color="green">Delivered</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
  ];
  const tableColumnsDetail = [
    {
      title: "Iitem Name",
      dataIndex: "itemname",
      ...filter("itemname"),
    },
    {
      title: "Item ID",
      dataIndex: "itemid",
      ...filter("itemid"),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
    },
    {
      title: "Last Quantity",
      dataIndex: "lastrcvqty",
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
    },
    {
      title: "currrcvqty",
      dataIndex: "currrcvqty",
      key: "currrcvqty",
      render: (text, record) => (
        <Input
          placeholder="Enter Currrcvqty"
          value={record.currrcvqty}
          onChange={(e) =>
            handleInputChange(record.itemid, "currrcvqty", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    {
      title: "Total Received Qty",
      dataIndex: "totalreceivedqty",
    },
    {
      title: "Rate",
      dataIndex: "rate",
    },
    {
      title: "Retail Price",
      dataIndex: "retailprice",
    },
    // {
    //   title: "Discount Amount",
    //   dataIndex: "discountamount",
    // },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const onFinish = () => {
    if (selectedRows?.length !== 0) {
      updatepodetail({
        data: selectedRows?.map((item) => ({
          purchasenum: item?.purchasenum,
          purchaseseqnum: item?.purchaseseqnum,
          // descr: item?.descr,
          purchasedate: moment(item?.purchasedate).format("YYYY-MM-DD"),
          itemid: item?.itemid,
          currrcvqty: item?.currrcvqty,
          // extraqtytag: item?.extraqtytag,
          // extraqty: item?.extraqty,
          // retailprice: item?.retailprice,
        })),
      });
      saveporeceivedetail(
        selectedRows?.map((item) => ({
          ppurchaseseqnum: item?.purchaseseqnum,
          ppurchasenum: item?.purchasenum,
          // descr: item?.descr,
          ppurchasedate: moment(item?.purchasedate).format("DD-MMM-YYYY"), //21-OCT-2024
          // itemid: item?.itemid,
          // quantity: item?.quantity,
          // extraqtytag: item?.extraqtytag,
          // extraqty: item?.extraqty,
          // retailprice: item?.retailprice,
        }))
      );
    } else {
      openNotification("error", "Please Enter Select Row Values", 10);
    }
  };
  return (
    <section>
      <h1 className="font-semibold text-base sm:text-lg pt-3 pb-3">
        Purchase Receiving
      </h1>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="purchasenum"
          onRow={(record) => ({
            onClick: () => {
              savePodetail({
                purchaseseqnum: record?.purchaseseqnum,
                purchasenum: record?.purchasenum,
                message: "B",
              });
            },
          })}
        />
      </div>
      <div className="p-2">
        <Table
          columns={tableColumnsDetail}
          dataSource={tabledetail}
          pagination={{ defaultPageSize: 5 }}
          loading={poDetailLoading || updatedetailLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="itemid"
          rowSelection={rowSelection}
        />
      </div>
      <div>
        <Button onClick={onFinish} type="primary">
          Received
        </Button>
      </div>
    </section>
  );
};

export default PurchaseReceivingFinal;
