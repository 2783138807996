import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import AddInventoryBrand from './AddInventoryBrand';
import useTableFilters from '../../../hooks/useTableFilter';
import { useCreateInvBrandMutation, useGetInvBrandQuery, useUpdateInvBrandMutation } from '../inventoryConfiguratorsService';
import UpdateInventoryBrand from './UpdateInventoryBrand';

const InventoryBrand=()=> {
    const { data, isLoading,refetch} = useGetInvBrandQuery();
    const [createbrand, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateInvBrandMutation();
    const [ updatebrand, {data:updatebrandData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateInvBrandMutation();
    const [showAddModal, setShowAddModal] = useState(false);
    const [rowData, setRowData] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const filter = useTableFilters();
    const[tableDatainfo,setTableDatainfo]=useState([]);
    useEffect(()=>{
        if(data){
          setTableDatainfo(data?.data)
        }
      },[data])
      useEffect(() => {
        if (addSuccess) setShowAddModal(false);
         handlerefetch()
      }, [addSuccess]); // eslint-disable-line

      useEffect(() => {
        if (updateSuccess) setShowUpdateModal(false);
        handlerefetch();
      }, [updateSuccess]); // eslint-disable-line
    const tableColumns = [
        {
            title:"Brand ID",
            dataIndex: "bid",
            ...filter("name"),
         
          },
        {
          title:"Brand Name",
          dataIndex: "brandname",
          ...filter("name"),
       
        },

    
          {
            title: "Status",
            render: (_, { status }) =>
              status === "Y" ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">In Active</Tag>
              ),
            sorter: (a, b) => a?.status?.localeCompare(b?.status),
          },
          {
            title: "Action(s)",
            render: (data) => (
              <Tooltip title="Update Inventory Brand Name">
                <Button
                  type="primary"
                  className="btn-warning-primary"
                  icon={<EditFilled />}
                  onClick={() => {
                    setRowData(data);
                    setShowUpdateModal(true);
                  }}
                />
              </Tooltip>
            ),
          },
       
       
      ];
      const handlerefetch=()=>{
        refetch()
      }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Inventory Brand</h1>
      <Tooltip  title="Add New Brand">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
       dataSource={tableDatainfo}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add Inventory Brand"
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addbrandForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddInventoryBrand
  createbrand={createbrand} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  <Modal
      title="Update Inventory Brand"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updatebrandForm"
        loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateInventoryBrand
        rowData={rowData}
        updatebrandData={updatebrandData}
        updatebrand={updatebrand}
      />
    </Modal>

  </section>
  )
}

export default InventoryBrand