import { Button, Modal, Table,  Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import AddMaterialMaster from './AddMaterialMaster'
import { PlusOutlined } from '@ant-design/icons'
import useTableFilters from '../../../hooks/useTableFilter'
import { useCreateMaterialMasterMutation, useGetAllMaterialsQuery } from '../storeConfiguratorsService'

const MaterialMaster=()=> {
  const { data, isLoading,refetch} = useGetAllMaterialsQuery();
  const [creatematerialmaster, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateMaterialMasterMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const[tableDatainfo,setTableDatainfo]=useState([]);
  const filter = useTableFilters();
  useEffect(()=>{
    if(data){
      setTableDatainfo(data?.data)
    }
  },[data])
  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
     handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  const tableColumns = [

    {
      title:"Medicine Name",
      dataIndex: "medname",
      ...filter("medname"),
   
    },
    {
      title:" Type",
      dataIndex: "typeid",
      ...filter("typeid"),
   
    },
    {
      title:"Strength",
      dataIndex: "strengthid",
      ...filter("strengthid"),
   
    },
    {
      title:"Rack No.",
      dataIndex: "rackid",
      ...filter("rackid"),
   
    },
    {
      title:"Manufacture Name",
      dataIndex: "manufactureid",
      ...filter("manufactureid"),
   
    },
    {
      title:"Quantity",
      dataIndex: "qty",
      ...filter("qty"),
   
    },
    {
      title:"Actual Cost",
      dataIndex: "actualpercost",
      ...filter("actualpercost"),
   
    },
    {
      title:"Sell Price",
      dataIndex: "sellprice",
      ...filter("sellprice"),
   
    },

   
  ];
  const handlerefetch=()=>{
    refetch()
  }
  return (
    <section>
    <div className="flex items-center justify-between px-5 py-3 ">
      <h1 className="font-semibold text-base sm:text-lg">Material Master </h1>
      <Tooltip  title="Add Material Master">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className='p-2'>
      <Table
       columns={tableColumns}
      dataSource={tableDatainfo}
      pagination={{ defaultPageSize: 5 }}
      loading={isLoading}
        bordered
        size='small'
        scroll={{ x: "fit-content" }}
        rowKey='type'
 
        
      />
    </div>
    <Modal
    title="Add Material Master "
    open={showAddModal}
    onCancel={() => setShowAddModal(false)}
    centered
    width={1000}
    destroyOnClose
    footer={[
      <Button
        key="1"
        htmlType="submit"
        form="addmaterialmasterForm"
        loading={addLoading}
        type="primary"
      >
        Add
      </Button>,
    
    ]}
  >
<AddMaterialMaster
  creatematerialmaster={creatematerialmaster} 
  handlerefetch={handlerefetch}
  />
  </Modal>

  </section>
  )
}

export default MaterialMaster