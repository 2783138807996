import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { SESSION_STORAGE_PREFIX } from "../../utils/constants";

const initialState = {
  isLoggedIn: false,
  user: {},
  token: sessionStorage.getItem(`${SESSION_STORAGE_PREFIX} clientSecret`) || "",
  menu:
    JSON.parse(
      sessionStorage.getItem(`${SESSION_STORAGE_PREFIX} clientMenu`)
    ) || [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload?.data?.token;
      state.user = jwt_decode(action.payload?.data?.token);
      state.menu = action.payload?.data?.menu;
      sessionStorage.setItem(
        `${SESSION_STORAGE_PREFIX} clientSecret`,
        action.payload?.data?.token
      );
      sessionStorage.setItem(
        `${SESSION_STORAGE_PREFIX} clientMenu`,
        JSON.stringify(action.payload?.data?.menu)
      );
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
      state.user = {};
      sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientSecret`);
      sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientMenu`);
    },
    restoreSession: (state) => {
      try {
        const decoded = jwt_decode(state?.token);
        if (new Date().getTime() / 1000 < decoded.exp) {
          state.user = decoded;
          state.isLoggedIn = true;
        } else {
          sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientSecret`);
          sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientMenu`);
        }
      } catch (err) {
        sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientSecret`);
        sessionStorage.removeItem(`${SESSION_STORAGE_PREFIX} clientMenu`);
        state.isLoggedIn = false;
      }
    },
    setRoleMenu: (state, action) => {
      state.menu = action.payload?.data;
    },
    setOrgUnit: (state, action) => {
      state.user.suborgid = action.payload;
    },
  },
});

export const {
  loginSuccess,
  logoutUser,
  restoreSession,
  setRoleMenu,
  setOrgUnit,
} = authSlice.actions;

export default authSlice.reducer;
