import { Form, Input, Select } from 'antd'
import formValidationMessages from '../../../../utils/formValidationMessages'

const UpdateBusinessModule = ({ updateBussFunc, rowData }) => {
	const onFinish = values =>
		updateBussFunc({
			bussfunc: values.bussfunc.toUpperCase(),
			title: values.title.toUpperCase(),
			path: values.path.toLocaleLowerCase(),
			status: values.status,
			message: 'B',
		})

	return (
		<Form
			layout='vertical'
			onFinish={onFinish}
			id='updateBusinessModuleForm'
			validateMessages={formValidationMessages}
			initialValues={{ ...rowData }}
			className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
			<Form.Item name='moduleid' label='System Module'>
				<Input disabled />
			</Form.Item>
			<Form.Item
				name='bussfunc'
				label='Business Function'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Business Function' disabled />
			</Form.Item>
			<Form.Item
				name='title'
				label='Title'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Title' autoFocus />
			</Form.Item>
			<Form.Item
				name='path'
				label='Path'
				rules={[
					{
						required: true,
					},
				]}>
				<Input placeholder='Enter Path' />
			</Form.Item>
			<Form.Item
				name='status'
				label='Status'
				rules={[
					{
						required: true,
					},
				]}>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
		</Form>
	)
}

export default UpdateBusinessModule
