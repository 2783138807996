import { DatePicker, Form, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment"; // Import moment
import formValidationMessages from "../../../utils/formValidationMessages";
import {
  useLovInvUomQuery,
  useLovItemListQuery,
  useLovPurchaseOrderQuery,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import openNotification from "../../../utils/notification";
const AddInvPurchaseDetail = ({ rowData, createpodetail }) => {
  const [form] = Form.useForm(); // Initialize form instance
  const { data: lovPO } = useLovPurchaseOrderQuery(); // Fetch Purchase Order data
  const { data: lovItemList } = useLovItemListQuery();
  const { data: lovUOM } = useLovInvUomQuery();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  // Handle when the user selects a Purchase Order
  useEffect(() => {
    if (lovItemList) {
      const updatedTableData = lovItemList?.data.map((item) => ({
        code: item.code,
        descr: item.descr,
        item_name: item.item_name,
        packsize: 1,
        extraqty: 0,
      }));
      setTableData(updatedTableData);
    }
  }, [lovItemList]);
  useEffect(() => {
    if (form) {
      // Set the form values when a PO is selected
      form.setFieldsValue({
        purchaseseqnum: rowData.purchaseseqnum, // Set Purchase Seq No
        purchasenum: rowData.purchasenum, // Set Purchase Number
        purchasedate: rowData.purchasedate
          ? moment(rowData.purchasedate)
          : null, // Set Purchase Date
      });
    }
  }, [form]); //eslint-disable-line
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = [...tableData];
    const index = newData.findIndex((item) => key === item.code);
    if (index > -1) {
      newData[index][dataIndex] = value;
      setTableData(newData);
    }
  };
  const filter = useTableFilters();
  const columns = [
    {
      title: "Item Name",
      dataIndex: "descr",
      key: "descr",
      ...filter("descr"),
      sorter: (a, b) => a?.descr?.localeCompare(b?.descr),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          placeholder="Enter Quantity"
          defaultValue={1}
          value={record.quantity}
          onChange={(e) =>
            handleInputChange(record.code, "quantity", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text, record) => (
        <Input
          placeholder="Enter Rate"
          value={record.rate}
          onChange={(e) =>
            handleInputChange(record.code, "rate", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Unit Rate",
    //   dataIndex: "unitrate",
    //   key: "unitrate",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Unit Rate"
    //       value={record.unitrate}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "unitrate", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    {
      title: "UOM",
      dataIndex: "uomid",
      key: "uomid",
      render: (text, record) => (
        <Select
          placeholder="Select UOM"
          value={record.uomid}
          onChange={(value) => {
            handleInputChange(record.code, "uomid", value);
            console.log("value", value);
          }}
          options={lovUOM?.data?.map((item) => ({
            value: item?.code,
            label: `${item?.descr}`,
          }))}
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase()?.includes(input?.toLowerCase())
          }
          // className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Last Received Quantity",
    //   dataIndex: "lastrcvqty",
    //   key: "lastrcvqty",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Last Received Quantity"
    //       value={record.lastrcvqty}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "lastrcvqty", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    // {
    //   title: "Current Quantity",
    //   dataIndex: "currrcvqty",
    //   key: "currrcvqty",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Current Quantity"
    //       value={record.currrcvqty}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "currrcvqty", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    // {
    //   title: "Tax Packet",
    //   dataIndex: "taxpct",
    //   key: "taxpct",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Tax Packet"
    //       value={record.taxpct}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "taxpct", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    // {
    //   title: "Discount Packet",
    //   dataIndex: "discountpct",
    //   key: "discountpct",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Discount Packet"
    //       value={record.discountpct}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "discountpct", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    // {
    //   title: "Discount Amount",
    //   dataIndex: "discountamount",
    //   key: "discountamount",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Discount Amount"
    //       value={record.discountamount}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "discountamount", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    // {
    //   title: "Packet Size",
    //   dataIndex: "packsize",
    //   key: "packsize",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Packet Size"
    //       value={record.packsize}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "packsize", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    {
      title: "Extra Quantity",
      dataIndex: "extraqty",
      key: "extraqty",
      render: (text, record) => (
        <Input
          placeholder="Enter Extra Quantity"
          value={record.extraqty}
          onChange={(e) =>
            handleInputChange(record.code, "extraqty", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Retail Price",
    //   dataIndex: "retailprice",
    //   key: "retailprice",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Retail Price"
    //       value={record.retailprice}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "retailprice", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const onFinish = (values) => {
    const formattedDate = values.purchasedate
      ? moment(values.purchasedate).format("YYYY-MM-DD")
      : null;
    if (selectedRows?.length !== 0) {
      const updateData = selectedRows.map((row) => ({
        ...values,
        descr: row?.item_name,
        purchasedate: formattedDate,
        itemid: row?.code,
        quantity: row?.quantity || 1,
        rate: row?.rate,
        unitrate: row?.rate,
        uomid: row?.uomid,
        lastrcvqty: 0,
        currrcvqty: 0,
        taxpct: 0, //row?.taxpct
        discountpct: 0, //row?.discountpct
        discountamount: 0, //row?.discountamount
        packsize: 1, //row?.packsize
        extraqty: row?.extraqty,
        retailprice: 0,
      }));
      createpodetail({ data: updateData });
    } else openNotification("error", "please Select table row", 10);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        id="addpodetailForm"
        validateMessages={formValidationMessages}
        className="grid grid-cols-5 gap-2"
      >
        {/* First dropdown to select Purchase Order */}
        <Form.Item
          name="purchaseseqnum"
          label="Select Purchase Order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select Purchase Order"
            disabled
            options={lovPO?.data?.map((item) => ({
              value: item?.ponum,
              label: `PO Number: ${item?.ponum}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>

        {/* Purchase Seq No (automatically set based on selected Purchase Order) */}
        <Form.Item
          name="purchaseseqnum"
          label="Purchase Seq No."
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Purchase SeqNo." readOnly />
        </Form.Item>

        {/* Purchase No (automatically set based on selected Purchase Order) */}
        <Form.Item
          name="purchasenum"
          label="Purchase No."
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Purchase No." readOnly />
        </Form.Item>

        {/* Purchase Date (automatically set based on selected Purchase Order) */}
        <Form.Item
          name="purchasedate"
          label="Purchase Date"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker
            placeholder="Enter Purchase Date"
            format="MM/DD/YYYY"
            disabled
          />
        </Form.Item>

        {/* Additional form fields for description, item selection, etc. */}
      </Form>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        rowKey="code"
        scroll={{ x: "fit-content" }}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default AddInvPurchaseDetail;
