import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../../utils/formValidationMessages';

const UpdateBedFacility=({rowData,updatebedfacility,updatebedfacilitesData}) =>{
  const [form] = Form.useForm();
  console.log(rowData)
  const onFinish = (values) =>{
  updatebedfacility({
      ...values,
      status: values?.status,
      facilitycode:rowData?.facilitycode,
      bednum:rowData?.bednum,
      unit:rowData?.unit,
      message: 'B',
    })
  }
  return (
    <Form
    form={form}
    initialValues={{
      facilitytype:rowData?.facilitytype,
      descr:rowData?.descr,
      status:rowData?.status
     
     
    }}
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='updatebedfacilityform'
    
    validateMessages={formValidationMessages}>
    <div className='grid sm:grid-cols-3 grid-cols-3 gap-2'>
    <Form.Item name='facilitytype' label='Facility Type'>
    <Input disabled></Input>
         </Form.Item>
    
      <Form.Item name='descr' label='Description'>
        <Input disabled></Input>
      </Form.Item>
     
      
    
      <Form.Item name='status' label='Status'>
        <Select
          placeholder='Select Status'
          options={[
            { label: 'Active', value: 'Y' },
            { label: 'In Active', value: 'N' },
          ]}
        />
      </Form.Item>
     
    </div>
    
  </Form>
  )
}


export default UpdateBedFacility