import { Button, DatePicker, Form, Modal, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import AddMedicineList from './AddMedicineList';
import AddPatientAppointment from './AddPatientAppointment';
import { useGetPatientRefferedQuery, useGetPatientRefferedbyDateMutation, useUpdateRefferedStatusMutation } from '../referralServices';
import moment from "moment";
import { futureDates } from '../../../utils/dateUtils';

const ReferralList=() =>{

    
    const { data, isLoading,refetch } = useGetPatientRefferedQuery();
    const [ PatientRefferDate, { data: getPatientRefferDate },] = useGetPatientRefferedbyDateMutation();
    const [ updatestatus, {data:updatestatusData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateRefferedStatusMutation();
    const[tableDatainfo,setTableDatainfo]=useState([])

    const [rowData, setRowData] = useState(null);
    const [showAppointmentModal, setAppointmentModal] = useState(false);
    const [showMedicineList, setMedicineList] = useState(false);
  
  console.log("rowData",rowData)

    const [form] = Form.useForm();
    useEffect(()=>{
      if(data){
        setTableDatainfo(data?.data)
      }
    },[data])
    const tableColumns = [
      {
        title: "MR #",
        dataIndex: "regnum",
      },
        {
          title: "Patient Name",
          dataIndex: "patientname",
        },
      
        {
          title: "Patient Type",
          dataIndex: "patienttype",
        },
       
        
        {
          title: "From Hospital",
          dataIndex: "fromhospitalname",
           
        },
        {
          title: "To OPD",
          dataIndex: "opdname",
           
        },
        {
          title: "From Doctor",
          dataIndex: "fromdocname",
           
        },
        {
          title: "Reffer Reason",
          dataIndex: "refreason",
        },
      
        {
          title: "Disease",
          dataIndex: "disease",
        },
        {
            title: "Reffer Date",
            dataIndex: "referdate",
            render:(data)=>(
              <div>
            {  moment(data?.demanddate)?.format("DD-MM-YYYY") }</div>
            )
          },
          

        {
          title: "Action(s)",
          render: (data) => (
            <div className='flex justify-center gap-2'>
         
            <Tooltip title='Medicine List '>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                
                onClick={() => {
                  setRowData(data);
                  setMedicineList(true)
                }}
                
              />
            </Tooltip>
            <Tooltip title="Patient Appointment">
              <Button
                type="primary"
                className="btn-warning-primary"
                icon={<EditFilled />}
                onClick={() => {
                  setRowData(data);
                  setAppointmentModal(true);
                  updatestatus({seqno:data.seqno})
                }}
              />
            </Tooltip>
            </div>
          ),
        },
      ];
      const handlerefetch=()=>{
        refetch()
      }
      const onFinish = (values) => {
        PatientRefferDate({
          fromdate: moment(values?.fromdate)?.format("DD-MMM-YYYY"),
          todate:moment(values?.todate)?.format("DD-MMM-YYYY"),
          message:"B"
        });
      };
  return (
   <section>
            <div>
<h1 className=" col-span-2 flex justify-center items-center font-semibold h-12 text-2xl mt-0">
        Referral List 
        </h1>
</div>
<Form
layout='vertical'
  onFinish={onFinish}
  form={form}
    >
    <div className='p-2 grid sm:grid-cols-3 gap-2'>
     <Form.Item name='fromdate' label='From Date'>
          <DatePicker
            disabledDate={futureDates}
            placeholder='Enter From Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
    <Form.Item name='todate' label='To Date'>
          <DatePicker
            disabledDate={futureDates}
            placeholder='Enter To Date'
            format='DD-MM-YYYY'
          />
        </Form.Item>
        <Form.Item  className='flex justify-end'
      label=' '>
      <Button className='w-48 '
      type="primary" htmlType="submit" >
               Search
              </Button> </Form.Item>
    </div>

</Form>

<div className='p-2'>
        <Table
 
          columns={tableColumns}
         dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='type'
       //   onRow={(record) => {
        //     return {
        //       onClick: () => {
        //         setdemanddetailinfo(record);

        //         getdemanddetail({
                  
        //           demandid: record?.demandid,
               
        //           message: "B",
        //         });
        //       },
        //     };
        //   }}
        //   rowClassName={(record) =>
        //     record === demanddetailinfo
        //       ? "selected-row cursor-pointer"
        //       : "cursor-pointer"
        //   }
        //   rowStyle={(record) =>
        //     record === demanddetailinfo
        //       ? { backgroundColor: "gray", color: "white" }
        //       : {}
        //   }
          
        />
      </div>
      <Modal
        title='Medicine List'
        open={showMedicineList}
        onCancel={() => setMedicineList(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
         
          <Button key='2' onClick={() => setMedicineList(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddMedicineList  rowData={rowData}
        handlerefetch={handlerefetch}    />
      </Modal>
    
    <Modal      
    title="Patient Appointment"
      open={showAppointmentModal}
      onCancel={() => setAppointmentModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
      
        <Button key="2" onClick={() => setAppointmentModal(false)}>
          Cancel
        </Button>,
      ]}>
      <AddPatientAppointment  handlerefetch={handlerefetch}
           rowData={rowData} />
    </Modal>



   </section>
  )
}

export default ReferralList