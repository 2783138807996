import { Tabs } from 'antd'
import React from 'react'
import Employee from './Employee'
import ServiceCategory from './ServiceCategory'
import PriceConfig from './PriceConfig'
import SubService from './SubService'
import SubServicePrice from './SubServicePrice'

function Configuration() {
  return (
    <section className='border border-gainsboro'>
    <div  className="flex items-center justify-between px-5 py-3"
    style={{  backgroundColor: "#00b29c" }}
    >
      <h1 className='font-semibold text-base sm:text-lg text-white'>
        Configurations
      </h1>
    </div>
    <div className='p-5'>
      <Tabs
       items={[
          
          {label:'Employee',key:'1',children:<Employee/>},
          {label:'Service Category',key:'2',children:<ServiceCategory/>},
          {label:'Category Price',key:'3',children:<PriceConfig/>},
          {label:'Sub Service',key:'4',children:<SubService/>},  
          {label:'Sub Service Price',key:'5',children:<SubServicePrice/>},
          
         
         
          
        ]}
      />
    </div>
  </section>
  )
}

export default Configuration