import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import { CameraFilled } from "@ant-design/icons";
import useBase64 from "../../../hooks/useBase64";

const UpdateProducts = ({ rowData, updateproducts, productupdateSuccess }) => {
  const [imageData, getBase64, clearImageData] = useBase64();
  useEffect(() => {
    if (productupdateSuccess) {
      clearImageData();
    }
  }, [productupdateSuccess]); //eslint-disable-line
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updateproducts({
      pid: rowData?.pid,
      pname: values?.pname,
      pdescr: values?.pdescr,
      pprice: values?.pprice,
      pdicnt: values?.pdicnt,
      pnewprice: values?.pnewprice,
      status: values?.status,
      pcategory: values?.pcategory,
      ppic: imageData?.data || rowData?.ppic,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updateproduct"
        form={form}
        initialValues={{
          pname: rowData?.pname,
          pdescr: rowData?.pdescr,
          pprice: rowData?.pprice,
          pdicnt: rowData?.pdicnt,
          pnewprice: rowData?.pnewprice,
          status: rowData?.status,
          pcategory: rowData?.pcategory,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          label="Product Image"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div className="flex gap-2">
            <img
              src={imageData ? imageData?.data : rowData?.ppic}
              alt="patient"
              className="h-[7rem] object-cover"
            />
            <input
              type="file"
              id="file"
              className="hide"
              accept="image/jpg, image/jpeg, image/png"
              onChange={(e) => getBase64(e)}
            />
            <label htmlFor="file" className="cursor-pointer flex gap-0.5">
              <CameraFilled className="text-2xl text-primary hover:text-primaryHover duration-200" />
            </label>
          </div>
        </Form.Item>
        <Form.Item
          name="pname"
          label="Product Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pdescr"
          label="Product Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pprice"
          label="Actual Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pdicnt"
          label="Discount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pnewprice"
          label="New Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pcategory"
          label="Category"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateProducts;
