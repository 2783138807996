import React from 'react'
import PieChart from '../components/PieChart'
import PatientBarChart from '../components/PatientBarChart'
import PatientAreaChart from '../components/PatientAreaChart'
import PatientRadarChart from '../components/PatientRadarChart'
import PatientProgressChart from '../components/PatientProgressChart'
import PatientStackedChart from '../components/PatientStackedChart'
import PatientLineChart from '../components/PatientLineChart'
import PatientComparisonChart from '../components/PatientComparisonChart'

export default function PatientDashboard() {
  return (
    <section className='border-b border-gainsboro'>
      <div className='grid  lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1'>
        <PieChart />
        <PatientBarChart />
        <PatientProgressChart />
      </div>
      <div className='grid  lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1'>
        <PatientAreaChart />
        <PatientLineChart />
        <PatientRadarChart />
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1'>
        <div className='lg:col-span-4 md:col-span-4 sm:col-span-1'>
          <PatientComparisonChart />
        </div>
        <div className='lg:col-span-2 md:col-span-4 sm:col-span-1'>
          <PatientStackedChart />
        </div>
      </div>
    </section>
  )
}
