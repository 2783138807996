import { Form, Input } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddManufacturer = ({createmanufecturer}) => {
  const onFinish = (values) =>{
    createmanufecturer({
        ...values,
        message: 'B',
      })
    }
  return (
    <div>
    <Form
    layout='vertical'
    onFinish={onFinish}
    id='addManufacturerForm'
    validateMessages={formValidationMessages}
    className='grid grid-cols-3 gap-2'
  >
    <Form.Item
      name='name'
      label='Manufacturer Name'
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input placeholder='Enter Manufacturer Name'/>
    </Form.Item>
    <Form.Item name='companyname' label='Company Name'>
      <Input placeholder='Enter Company Name' 
      rules={[
        {
          required: true,
        },
      ]} />
    </Form.Item>
    <Form.Item
      name='companycellno'
      label='Company Cell No'
    >
      <Input placeholder='Enter Cell No' />
    </Form.Item>
  
   
  </Form>
    </div>
  )
}

export default AddManufacturer