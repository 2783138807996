import { Form, Input } from "antd";
import React, { useEffect } from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import useBase64 from "../../../hooks/useBase64";
import { CameraFilled } from "@ant-design/icons";
import productsimg from "../../../assets/images/defaultimg.png";
const AddProducts = ({ saveProduct, productSuccess }) => {
  const [imageData, getBase64, clearImageData] = useBase64();
  useEffect(() => {
    if (productSuccess) {
      clearImageData();
    }
  }, [productSuccess]); //eslint-disable-line
  const onFinish = (values) => {
    saveProduct({
      pname: values?.pname,
      pdescr: values?.pdescr,
      pprice: values?.pprice,
      pdicnt: values?.pdicnt,
      pnewprice: values?.pnewprice,
      pcategory: values?.pcategory,
      ppic: imageData?.data,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="addproduct"
        validateMessages={formValidationMessages}
        className="grid grid-cols-2 gap-2"
      >
        <Form.Item
          label="Product Image"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div className="flex gap-2">
            <img
              src={imageData ? imageData?.data : productsimg}
              alt="patient"
              className="h-[7rem] object-cover"
            />
            <input
              type="file"
              id="file"
              className="hide"
              accept="image/jpg, image/jpeg, image/png"
              onChange={(e) => getBase64(e)}
            />
            <label htmlFor="file" className="cursor-pointer flex gap-0.5">
              <CameraFilled className="text-2xl text-primary hover:text-primaryHover duration-200" />
            </label>
          </div>
        </Form.Item>
        <Form.Item
          name="pname"
          label="Product Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pdescr"
          label="Product Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pprice"
          label="Actual Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pdicnt"
          label="Discount"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pnewprice"
          label="New Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pcategory"
          label="Category"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item name="status" label="Status">
            <Select
              placeholder="Select Status"
              options={[
                { label: "Active", value: "Y" },
                { label: "In Active", value: "N" },
              ]}
            />
          </Form.Item> */}
      </Form>
    </div>
  );
};
export default AddProducts;
