import { api } from "../api/api";

export const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    //create product Admin
    GetProducts: build.query({
      query: () => "pos/getProducts",
    }),
    CreateProducts: build.mutation({
      query: (body) => ({
        url: `pos/createProducts`,
        method: "POST",
        body,
      }),
    }),
    UpdProducts: build.mutation({
      query: (body) => ({
        url: `pos/updProducts`,
        method: "POST",
        body,
      }),
    }),
    //user product
    GetProductdisplay: build.query({
      query: () => "pos/getProductdisplay ",
    }),
    CreateCustomerorder: build.mutation({
      query: (body) => ({
        url: `pos/createCustomerorder`,
        method: "POST",
        body,
      }),
    }),
    //add to card
    CustCartInfo: build.mutation({
      query: (body) => `pos/getCustCartInfo/${body.email}/${body.pasword}`,
    }),
    CreateCustomerCart: build.mutation({
      query: (body) => ({
        url: `pos/CreateCustomerCart`,
        method: "POST",
        body,
      }),
    }),
    UpdateCustomerCart: build.mutation({
      query: (body) => ({
        url: `pos/UpdateCustomerCart`,
        method: "POST",
        body,
      }),
    }),
    SendEmailCustomer: build.mutation({
      query: (body) => ({
        url: `pos/api/email/send`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  //products admin
  useGetProductsQuery,
  useCreateProductsMutation,
  useUpdProductsMutation,
  //product user
  useGetProductdisplayQuery,
  useCreateCustomerorderMutation,
  //add to card
  useCustCartInfoMutation,
  useCreateCustomerCartMutation,
  useUpdateCustomerCartMutation,
  useSendEmailCustomerMutation,
} = productApi;
