import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Button, Tooltip, Modal } from "antd";
import productsimg from "../../../assets/images/defaultimg.png";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import AddProducts from "../modal/AddProducts";
import UpdateProducts from "../modal/UpdateProducts";
import {
  useCreateProductsMutation,
  useGetProductsQuery,
  useUpdProductsMutation,
} from "../productsServices";
const ProductList = () => {
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { data: getproducts, refetch } = useGetProductsQuery();
  const [saveProduct, { isSuccess: productSuccess }] =
    useCreateProductsMutation();
  const [updateproducts, { isSuccess: productupdateSuccess }] =
    useUpdProductsMutation();
  useEffect(() => {
    if (getproducts) {
      setTableDatainfo(getproducts?.data);
    }
  }, [getproducts]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (productSuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [productSuccess]); //eslint-disable-line
  useEffect(() => {
    if (productupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [productupdateSuccess]); //eslint-disable-line
  const columns = [
    {
      title: "Products",
      render: (data) => {
        return (
          <Card className="grid justify-center items-start gap-1">
            <div className="pb-1">
              <img
                src={
                  data?.ppic
                    ? `${data.ppic}?t=${new Date().getTime()}`
                    : productsimg
                }
                alt="product"
                className="w-[10rem] h-[10rem] object-cover rounded-md"
              />
            </div>
            <div className="flex justify-between">
              <div>
                <div className=" text-left font-bold">{data?.pname}</div>
                <div className=" text-left font-medium">
                  Price&nbsp;{data?.pprice}
                </div>
                <div className=" text-left font-medium">
                  Discount&nbsp;{data?.pdicnt}
                </div>
                <div className=" text-left font-medium">
                  New Price&nbsp;{data?.pnewprice}
                </div>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btncustomize"
                  icon={<EditFilled />}
                  onClick={() => {
                    setRowData(data);
                    setShowUpdateModal(true);
                  }}
                />
              </div>
            </div>
          </Card>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">&nbsp;</h1>
        <Tooltip title="Add Title ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <Row gutter={[16, 16]}>
        {tableDatainfo.map((product) => (
          <Col className=" sm:col-span-4 col-span-24" key={product.pid}>
            <Card>
              <Table
                columns={columns}
                dataSource={[product]}
                pagination={false}
                rowKey="pid"
                scroll={{ x: "fit-content" }}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        title="Add Title "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addproduct"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddProducts
          saveProduct={saveProduct}
          productSuccess={productSuccess}
        />
      </Modal>
      <Modal
        title="Update Products"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateproduct"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateProducts
          rowData={rowData}
          updateproducts={updateproducts}
          productupdateSuccess={productupdateSuccess}
        />
      </Modal>
    </div>
  );
};

export default ProductList;
