import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreatenewQuotationMasterMutation,
  useLovCustomerQuery,
  useLovItemListforPOSQuery,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import openNotification from "../../../utils/notification";
import { QuotationPending } from "../modal/QuotationPending";
import { QuotationFinishList } from "../modal/QuotationFinishList";

export const Quotationfinal = () => {
  const [form] = Form.useForm();
  const { data: lovCustomer } = useLovCustomerQuery();
  const { data: LovItemListforPOS } = useLovItemListforPOSQuery();
  const [createqoutation, { data: masterdata, isSuccess: masterSuccess }] =
    useCreatenewQuotationMasterMutation();
  const filter = useTableFilters();
  const itemSelectRef = useRef();
  const itemSelectRefqty = useRef();
  const discountInputRef = useRef(null);
  const dispercentageInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const payableamountInputRef = useRef(null);
  const [discountsis, setDiscountsis] = useState(false);
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const savebuttonRef = useRef(null);
  const itemSelectRefcustomer = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  const [buttonid, setButtonid] = useState(null);
  const [showpendingModal, setShowpendingModal] = useState(false);
  const [showfinishModal, setShowfinishModal] = useState(false);
  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      total: item.price * (item.qty || 1),
    }));
    let totalValue = updatedTableData.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    form.setFieldsValue({
      totalamt: totalValue,
      // finalamount: totalValue,
      // payableamount: totalValue,
    }); //, finalamount: totalValue

    setTableData(updatedTableData);
  }, [tableData, form]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check if F1 key is pressed
      if (e.key === "F1") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (discountInputRef.current) {
          discountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F2") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (dispercentageInputRef.current) {
          dispercentageInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F4") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (payableamountInputRef.current) {
          payableamountInputRef.current.focus(); // Focus on the discount input
        }
      }
      if (e.key === "F5") {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (priceInputRef.current) {
          priceInputRef.current.focus(); // Focus on the discount input
        }
      }

      if (e.altKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault(); // Prevent default browser action for F1 (help)
        if (savebuttonRef.current) {
          savebuttonRef.current.click(); // Focus on the discount input
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleDelete = (record) => {
    setTableData((prevTabledata) =>
      prevTabledata.filter((row) => row.itemid !== record.itemid)
    );
    setRowsToDelete((prevRowsToDelete) => [...prevRowsToDelete, record.itemid]);
  };
  useEffect(() => {
    if (rowsToDelete.length > 0) {
      const updatedData = tableData.filter(
        (row) => !rowsToDelete.includes(row.key)
      );
      setTableData(updatedData);
      setRowsToDelete([]);
    }
  }, [rowsToDelete, tableData]);
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tableData.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTableData(newData);
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const columns = [
    {
      title: "Itemname",
      dataIndex: "item",
      ...filter("item"),
      sorter: (a, b) => a?.item?.localeCompare(b?.item),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },

    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        <Input
          ref={itemSelectRefqty}
          placeholder="Enter Qty"
          defaultValue={1}
          value={record.qty}
          onChange={(e) =>
            handleInputChange(record.itemid, "qty", e.target.value)
          }
          // className=""
        />
      ),
    },
    {
      title: "Price F5",
      dataIndex: "price",
      render: (text, record) => (
        <Input
          ref={priceInputRef}
          placeholder="Enter Price"
          defaultValue={1}
          value={record.price}
          onChange={(e) =>
            handleInputChange(record.itemid, "price", e.target.value)
          }
          className=" w-1/2"
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (_, record) => {
        const total = record.price * (record.qty || 1);
        return <span>{total.toFixed(2)}</span>;
      },
    },
    // {
    //   title: "Discount Price",
    //   dataIndex: "discountprice",
    // },
    {
      title: "Action(s)",
      key: "actions",
      render: (record) => (
        <div className="space-x-0.5">
          <Tooltip title="Delete" placement="left">
            <Button
              danger
              icon={<i className="fa fa-trash"></i>}
              onClick={() => handleDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const onFinish = (values) => {
    const updateData = tableData.map((row) => ({
      itemid: row.itemid,
      itemname: row.item,
      quantity: row.qty || 1,
      rate: row.price,
      totalrate: row.price * (row.qty || 1),
    }));
    if (buttonid === "savepurchase") {
      createqoutation({
        quotationdescr: values?.remarks,
        status: "Y",
        customerid: values?.customerid,
        totalamt: values?.totalamt,
        discamt: values?.discamt || 0,
        discount: values?.discamt,
        totalpaid: values?.payableamount
          ? values?.payableamount
          : values?.totalamt,
        data: updateData,
        message: "B",
      });
    }
    if (buttonid === "savepurchasepending") {
      createqoutation({
        quotationdescr: values?.remarks,
        status: "D",
        customerid: values?.customerid,
        totalamt: values?.totalamt,
        discamt: values?.discamt || 0,
        discount: values?.discamt,
        totalpaid: values?.payableamount
          ? values?.payableamount
          : values?.totalamt,
        data: updateData,
        message: "B",
      });
    }
  };
  return (
    <section>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Qoutation
      </h1>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          customerid: 1,
          totalamt: 0,
          discamt: 0,
          discountper: 0,
          payableamount: 0,
        }}
      >
        <div className=" grid sm:grid-cols-4 gap-1">
          <Form.Item name="invnum" label="Inv Number" className=" mb-0">
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Date" className=" mb-0">
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            name="remarks"
            label="Remarks"
            // className=" sm:row-span-2 mb-0"
          >
            <Input />
          </Form.Item>
          <Form.Item name="vehical" label="Vehical/Bilty No" className=" mb-0">
            <Input />
          </Form.Item>
          <Form.Item name="customerid" label="Customer" className=" mb-0">
            <Select
              ref={itemSelectRefcustomer}
              open={isItemSelectOpencustomer}
              onDropdownVisibleChange={(open) =>
                setIsItemSelectOpencustomer(open)
              }
              placeholder="Select Customer Name"
              onChange={(value) => {
                setIsItemSelectOpencustomer(false);
                const selectedOption = lovCustomer?.data?.find(
                  (item) => item?.customerid === value
                );
                form.setFieldsValue({
                  customername: selectedOption?.name,
                  customeraddress: selectedOption.address,
                  customercellno: selectedOption.cellno,
                });
              }}
              options={lovCustomer?.data?.map((item) => ({
                value: item?.customerid,
                label: `${item?.name}`,
              }))}
              showSearch
              filterOption={(input, option) =>
                option?.label?.toLowerCase()?.includes(input?.toLowerCase())
              }
            />
          </Form.Item>
          {/* <Form.Item name="saleman" label="Saleman" className=" mb-0">
            <Input />
          </Form.Item> */}
          {/* <div className=" grid sm:grid-cols-2 sm:col-span-2 gap-1 mb-0">
            <Form.Item name="code" label="Code" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Name" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="pack" label="Pack" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="qty" label="Qty" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="price" label="Price" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="total" label="Total" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="discount" label="Discount" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="total" label="Total" className=" mb-0">
              <Input />
            </Form.Item>
            <Form.Item name="tax" label="Tax" className=" mb-0">
              <Input />
            </Form.Item>
          </div> */}

          <Form.Item
            name="itemid"
            label="Item Name"
            className=" sm:col-span-3 mb-0"
          >
            <Select
              ref={itemSelectRef}
              open={isItemSelectOpen}
              onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
              placeholder="Select Item Name"
              options={LovItemListforPOS?.data?.map((item) => ({
                searchvalue: item.item_name,
                value: item?.itemid,
                label: (
                  <span className=" grid sm:grid-cols-5 justify-center items-center">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {item?.itemid}
                    </span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                      className=" sm:col-span-2"
                    >
                      {item?.item_name}
                    </span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {item.price}
                    </span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {item.purchaseprice}
                    </span>
                  </span>
                ),
              }))}
              dropdownRender={(menu) => (
                <div>
                  {/* Header */}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr 1fr 1fr",
                      padding: "8px",
                      backgroundColor: "#47a686",
                      fontWeight: "bold",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <span>BarCode</span>
                    <span>Item Name</span>
                    <span>Price</span>
                    <span>Purchase Price</span>
                  </div>
                  {/* Dropdown Menu */}
                  {menu}
                </div>
              )}
              onChange={(value) => {
                setIsItemSelectOpen(false); // Close the dropdown after selecting
                const updatedTableData = LovItemListforPOS?.data.find(
                  (item) => item.itemid === value
                );
                if (updatedTableData) {
                  const duplicate = tableData?.find(
                    (item) => item.itemid === updatedTableData?.itemid
                  );
                  if (!duplicate) {
                    setTableData((prevTableData) => [
                      ...prevTableData,
                      ...[updatedTableData],
                    ]);
                    setTimeout(() => {
                      if (itemSelectRefqty.current) {
                        itemSelectRefqty.current.focus();
                        itemSelectRefqty.current.select();
                      }
                    }, 100);
                  } else
                    openNotification(
                      "error",
                      "Item is Already in The Table",
                      10
                    );
                }
              }}
              showSearch
              filterOption={(input, option) => {
                const inputLower = input.toLowerCase();
                return (
                  option?.searchvalue?.toLowerCase()?.includes(inputLower) ||
                  option?.value?.toString()?.toLowerCase()?.includes(inputLower)
                );
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item className="  mb-0  sm:col-span-4 ">
            <Table
              columns={columns}
              dataSource={tableData}
              size="small"
              scroll={{
                x: "fit-content",
                y: tableData.length > 3 ? 150 : null,
              }}
              rowKey="itemid"
            />
          </Form.Item>
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-1 mb-0 justify-center sm:col-span-4">
            <Form.Item
              name="totalamt"
              label="Total&nbsp;Amount"
              className=" mb-0 "
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="discamt"
              label="Discount&nbsp;Amount&nbsp;F1"
              // {
              //   <span>
              //     Discount&nbsp;Amount
              //     <br />
              //     F1
              //   </span>
              // }
              className=" mb-0"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                ref={discountInputRef}
                onBlur={(e) => {
                  setDiscountsis(true);
                  const total = form.getFieldValue("totalamt");

                  let final = parseInt(total) - parseInt(e.target.value);
                  const dis =
                    (parseInt(e.target.value) / parseInt(total)) * 100;
                  form.setFieldsValue({
                    finalamount: final,
                    discountper: dis,
                    payableamount: final,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              name="discountper"
              label="Discount Percentage F2"
              // {
              //   <span>
              //     Discount Percentage
              //     <br />
              //     F2
              //   </span>
              // }
              className=" mb-0"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                ref={dispercentageInputRef}
                disabled={discountsis}
                onBlur={(e) => {
                  const total = form.getFieldValue("totalamt");

                  let final =
                    parseInt(total) -
                    (parseInt(e.target.value) / 100) * parseInt(total);
                  form.setFieldsValue({
                    finalamount: final,
                    payableamount: final,
                    discamt: (parseInt(e.target.value) / 100) * parseInt(total),
                    nettotal: final,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              name="payableamount"
              label="Net Total F4"
              className=" mb-0"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={payableamountInputRef}
                // onBlur={(e) => {
                //   const total = form.getFieldValue("totalamt");
                //   const prebalance = form.getFieldValue("supplierbalance");
                //   const inv = parseInt(total) - parseInt(e.target.value);
                //   const newbalance = inv + prebalance;
                //   form.setFieldsValue({
                //     invbalance: parseInt(total) - parseInt(e.target.value),
                //     newbalance: newbalance,
                //   });
                // }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className=" w-full"
                onClick={() => {
                  setButtonid("savepurchase");
                }}
                ref={savebuttonRef}
              >
                Save
              </Button>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                setButtonid("savepurchasepending");
                setShowpendingModal(true);
              }}
            >
              Pending
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowfinishModal(true);
              }}
            >
              Finish List
            </Button>
          </div>
        </div>
      </Form>
      <Modal
        title="Pending List"
        open={showpendingModal}
        onCancel={() => setShowpendingModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowpendingModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <QuotationPending
          tableDataMaster={tableData}
          setTableDataMaster={setTableData}
          itemSelectRefqty={itemSelectRefqty}
          handlecancel={() => setShowpendingModal(false)}
        />
      </Modal>
      <Modal
        title="Finish List"
        open={showfinishModal}
        onCancel={() => setShowfinishModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowfinishModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <QuotationFinishList />
      </Modal>
    </section>
  );
};
