import { Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../utils/formValidationMessages'

const AddCustomerInfo = ({createcustomerdetail}) => {
    const onFinish = (values) =>{
        createcustomerdetail({
          ...values,
          message: 'B',
        })
      }
    return (
      <div>
      <Form
      layout='vertical'
   onFinish={onFinish}
   initialValues={{
            email: " ",
          }}
      id='addcustomerdetailForm'
      validateMessages={formValidationMessages}
      className='grid grid-cols-5 gap-2'
    >
      <Form.Item
        name='customername'
        label='Customer Name'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Customer Name'/>
      </Form.Item>

      <Form.Item
        name='customeraddress'
        label='Customer Address'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Customer Address'/>
      </Form.Item>
      <Form.Item
        name='customercellno'
        label='Cell No'
        rules={[
          {
            required: true,
          },
        ]}
   >
        <Input placeholder='Enter Cell No'/>
      </Form.Item>

      <Form.Item
        name='email'
        label='Email'
  
   >
        <Input placeholder='Enter Email'/>
      </Form.Item>
    </Form>
      </div>
    )
  }
  
export default AddCustomerInfo