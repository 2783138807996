import { EditFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import useTableFilters from "../../../hooks/useTableFilter";
import AddCity from "../modals/AddCity";
import UpdateCity from "../modals/UpdateCity";
import {
  useCreateHmsCityMutation,
  useGetHmscityQuery,
  // useLovHmsCityQuery,
  // useLovHmsProvinceQuery,
  useUpdateHmsCityMutation,
} from "../employeeServices";

const CityPage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const { data: getHmsCity, refetch } = useGetHmscityQuery();
  // const { data: lovhmsProvince } = useLovHmsProvinceQuery();
  // const { data: getlovCity } = useLovHmsCityQuery();
  const [saveCity, { isSuccess: citySuccess }] = useCreateHmsCityMutation();
  const [updatecitydata, { isSuccess: cityupdateSuccess }] =
    useUpdateHmsCityMutation();
  useEffect(() => {
    if (getHmsCity) {
      setTableDatainfo(getHmsCity?.data);
    }
  }, [getHmsCity]);
  const handleRefetch = () => {
    refetch();
  };
  useEffect(() => {
    if (citySuccess) {
      handleRefetch();
      setShowAddModal(false);
    }
  }, [citySuccess]); //eslint-disable-line
  useEffect(() => {
    if (cityupdateSuccess) {
      handleRefetch();
      setShowUpdateModal(false);
    }
  }, [cityupdateSuccess]); //eslint-disable-line
  const tableColumns = [
    {
      title: "City Code",
      dataIndex: "citycode",
      ...filter("citycode"),
    },
    {
      title: "Province ID",
      dataIndex: "provid",
      ...filter("provid"),
    },
    {
      title: "Description",
      dataIndex: "description",
      ...filter("description"),
    },

    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update City">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">City</h1>
        <Tooltip title="Add City ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          //   loading={isLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="citycode"
        />
      </div>
      <Modal
        title="Add City "
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addcity"
            // loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
        ]}
      >
        <AddCity
          saveCity={saveCity}
          // lovhmsProvince={lovhmsProvince}
          // getlovCity={getlovCity}
        />
      </Modal>

      <Modal
        title="Update City"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updatecity"
            // loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateCity
          rowData={rowData}
          updatecitydata={updatecitydata}
          // lovhmsProvince={lovhmsProvince}
          // getlovCity={getlovCity}
        />
      </Modal>
    </section>
  );
};

export default CityPage;
