import { api } from '../api/api'



const CustomerConfigurationApi = api.injectEndpoints({
    
	endpoints: build => ({
  
        getCustomerInfo: build.query({
            query: () => `pos/getCustomerInfo`,
            
          }), 

          CreateInvCustomerInfo: build.mutation({
            query: (body) => ({
              url: "pos/createInvCustomerInfo",
              method: "POST",
              body,
             
            }),
          }), 
          
          UpdateInvCustomerInfo: build.mutation({
            query: body => ({
              url: `pos/updateInvCustomerInfo`,
              method: 'POST',
              body,
            }),
        
          }),
      
        
    
  }),

 
})

export const {
useGetCustomerInfoQuery,
useCreateInvCustomerInfoMutation,
useUpdateInvCustomerInfoMutation,
        
} = CustomerConfigurationApi
