import React from 'react'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more' // Import the highcharts-more module
import HighchartsReact from 'highcharts-react-official'
import { useGetPatientComparisonQuery } from '../DashboardService'
HighchartsMore(Highcharts)
const PatientProgressChart = () => {
  const { data: patient } = useGetPatientComparisonQuery()
  const apiData = [
    { name: 'Visit', y: parseInt(patient?.data[0]?.visit) || 0 },
    { name: 'Registered', y: parseInt(patient?.data[0]?.registered) || 0 },
  ]
  const isDataEmpty = apiData === null
  const options = {
    chart: {
      type: 'bar',
      inverted: true,
      marginLeft: 135,
    },
    title: {
      text: [],
    },
    xAxis: {
      categories: apiData.map(item => item.name),
      title: {
        text: null,
      },
    },
    yAxis: {
      gridLineWidth: 0,
      plotBands: [
        { from: 0, to: 150, color: '#FFFFFF' },
        { from: 150, to: 225, color: '#FFFFFF' },
        { from: 225, to: 9000, color: '#FFFFFF' },
      ],
      title: null,
      labels: {
        format: '{value}',
      },
    },
    tooltip: {
      pointFormat: '<b>{point.y}</b>',
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          enabled: true,
          format: '<b>{point.y}</b>',
        },
      },
      series: {
        stacking: 'normal',
        borderWidth: 0,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        name: 'Visit',
        data: apiData.map(item => item.y),
        color: '#5CC0F6',
      },
      {
        name: 'Registered',
        data: apiData.map(item => item.target),
        color: '#004291',
      },
    ],
  }

  return (
    <div className='border border-border rounded-xl shadow-lg mx-2 my-2'>
      <h4 className='font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white'>
        Patient Daily Count
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {/* {isDataEmpty === false ? (
        <p className='text-center py-4 text-gray-500'>No data available for the chart.</p>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )} */}
    </div>
  )
}

export default PatientProgressChart
