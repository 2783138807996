
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useGetPatientVisitQuery } from '../DashboardService'
export default function PieChart() {
  const { data: todayPat } = useGetPatientVisitQuery()
  const dataFromAPI = todayPat?.data || []
  const data = dataFromAPI.map(item => ({
    name: item.lable,
    y: Number(item.value),
  }))
  // const data = [
  //   { name: 'Beni', y: Number(dataFromAPI.beni) },
  //   { name: 'Ahd', y: Number(dataFromAPI.ahd) },
  //   { name: 'HO', y: Number(dataFromAPI.ho) },
  //   { name: 'Emerg', y: Number(dataFromAPI.emerg) },
  //   { name: 'Other Ref ', y: Number(dataFromAPI.oref) },
  //   { name: 'Panel', y: Number(dataFromAPI.panel) },
  //   { name: 'Shaheed', y: Number(dataFromAPI.shd) },
  //   { name: 'CNE', y: Number(dataFromAPI.cne) },
  //   { name: 'Emp', y: Number(dataFromAPI.empl) },
  // ]
  const colors = Highcharts.getOptions().colors.map((c, i) =>
    Highcharts.color(Highcharts.getOptions().colors[0])
      .brighten((i - 3) / 7)
      .get()
  )
  const options = {
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: '',
      align: 'left',
    },
    tooltip: {
      pointFormat: '{point.y}',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors,
        borderRadius: 5,
        dataLabels: {
          enabled: true,
          format: '{point.name}:{point.y}',
          distance: 10,
        },
      },
      showInLegend: false,
    },
    series: [
      {
        name: data?.name,
        data,
      },
    ],
  }
  // const options = {
  //   chart: {
  //     type: 'pie',
  //   },
  //   title: {
  //     text: 'Patient Visit',
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       colors: COLORS,
  //       dataLabels: {
  //         enabled: true,
  //         format: '<b>{point.name}</b>: {point.percentage:.1f} %',
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: 'Visit Percentage',
  //       data,
  //     },
  //   ],
  // }

  return (
    <div className='border border-border rounded-xl shadow-lg mx-2 my-2'>
      <h4 className='font-bold border-b border-border px-2 py-2 bg-ghost-white w-full rounded-tl-xl rounded-tr-xl bg-bg text-white'>
        Patient Type Wise Visit (Yearly)
      </h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
