import { Checkbox, Form, Input, Select } from 'antd'
import React from 'react'
import formValidationMessages from '../../../../utils/formValidationMessages'
import { useLovbedtypeQuery } from '../../wardConfiguratorsService';

const AddNomncltreDetails=({ updatenomncltrestatus,rowData})=> {
  const { data: lovbedtype } = useLovbedtypeQuery();
  
  const onFinish = (values) =>
  updatenomncltrestatus({
    ...values,
    status: rowData?.status,
  })

  return (
    <Form
    layout='vertical'
    onFinish={onFinish}
    autoComplete='off'
    id='UpdateNomncltreStatus'
    initialValues={{
        ...rowData,
      }}
    validateMessages={formValidationMessages}
  >
    <div className='grid sm:grid-cols-3 grid-cols-1 gap-2'>
    
      <Form.Item name='type' label='Bed Type'>
        <Select disabled
          placeholder='Select Type'
          options={lovbedtype?.data?.map((item) => ({
            value: item?.type,
            label: item?.descr,
          }))}
        />
      </Form.Item>
      <Form.Item name='descr' label='Description of Nomncltre'>
        <Input disabled
        placeholder='Enter Description' />
      </Form.Item>
      <Form.Item
				name='status'
				label='Status'>
				<Select
					placeholder='Select Status'
					options={[
						{ value: 'Y', label: 'Active' },
						{ value: 'N', label: 'In Active' },
					]}
				/>
			</Form.Item>
    </div>
    <div className='grid grid-cols-5 md:grid-cols-5 sm:grid-cols-1 gap-2 p-2 py-1'   >
    <Form.Item name="ncchg" valuePropName='checked' initialValue={false}>
                <Checkbox >NC Charges</Checkbox>
              </Form.Item>
              <Form.Item name="mochg" valuePropName='checked' initialValue={false}>
                <Checkbox>MO Charges</Checkbox>
              </Form.Item>
              <Form.Item name="bedchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Room/Bed Charges</Checkbox>
              </Form.Item>
              <Form.Item name="foodchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Food Charges</Checkbox>
              </Form.Item>
              <Form.Item name="adchg" valuePropName='checked' initialValue={false}>
                <Checkbox>Admission Charges</Checkbox>
              </Form.Item>            
              
</div>       
  </Form>
  )
}

export default AddNomncltreDetails