import {  Table } from 'antd';
import React, { useEffect } from 'react'
import { useGetPatMedHistoryMutation } from '../referralServices';

const AddMedicineList=({rowData})=> {
  const [Medhistory, { data: medhistory,isLoading: phLoading }] = useGetPatMedHistoryMutation();
  
  useEffect(()=>{
  
    if(rowData)
    {
      Medhistory({
        regnum: rowData?.regnum,
       
      });
    }
  },[rowData])//eslint-disable-line
  const Columns = [
    {
      title: "User ID",
      dataIndex: "userid",
    
      
    },  
    {
        title: "RegNum",
        dataIndex: "regnum",
      
        
      },
     
    {
      title: "Medicine Name",
      dataIndex: "medicinename",
    },

    {
      title: "Medicine Quatity",
      dataIndex: "medqty",
     
    },

    
];

  return (
    <div>
        <Table
        dataSource={medhistory?.data}
          columns={Columns}
          // onFinish={onFinish}
          id='medicineForm'
         
         
          pagination={{ defaultPageSize: 3 }}
        loading={phLoading}
          bordered
          size='small'
          scroll={{ x: "fit-content" }}
          rowKey='facilitycode'
          onRow={(record) => {
            return {
              onClick: () => { 

              },
            };
          }}
        />
    
      </div>
  )
}

export default AddMedicineList