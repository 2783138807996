import { useState, useEffect } from "react";
import { Button, Table, Tooltip, Tag, Modal } from "antd";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import {
  useCreateUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "../securityService";
import useTableFilters from "../../../hooks/useTableFilter";
import UpdateUser from "../components/manageUsers/UpdateUser";
import AddUser from "../components/manageUsers/AddUser";

const ManageUsers = () => {
  const { data, isLoading } = useGetAllUsersQuery();
  const [createUser, { isSuccess: addSuccess, isLoading: addLoading }] =
    useCreateUserMutation();
  const [
    updateUserFunc,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateUserMutation();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [rowData, setRowData] = useState();
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
  }, [updateSuccess]); // eslint-disable-line

  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
  }, [addSuccess]); // eslint-disable-line

  const tableColumns = [
    {
      title: "Username",
      dataIndex: "userid",
      ...filter("userid"),
      sorter: (a, b) => a?.userid?.localeCompare(b?.userid),
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      ...filter("fullname"),
      sorter: (a, b) => a?.fullname?.localeCompare(b?.fullname),
    },
    {
      title: "Role(s)",
      render: (_, { roleResponses }) =>
        roleResponses?.map((item) => item?.desc)?.join(", "),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...filter("email"),
    },
    {
      title: "Status",
      render: (_, { status }) =>
        status === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update User">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <section>
      <div className="flex items-center justify-between px-5 py-3 ">
        <h1 className="font-semibold text-base sm:text-lg">Manage Users</h1>
        <Tooltip title="Add New User">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModal(true)}
          />
        </Tooltip>
      </div>
      <div>
        <Table
          dataSource={data?.data}
          columns={tableColumns}
          pagination={{ defaultPageSize: 10 }}
          loading={isLoading}
          bordered
          size="small"
          // scroll={{ x: 'fit-content' }}
          rowKey="userid"
        />
      </div>

      <Modal
        title="Update User"
        open={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="updateUserForm"
            loading={updateLoading}
            type="primary"
          >
            Update
          </Button>,
          <Button key="2" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <UpdateUser updateUserFunc={updateUserFunc} rowData={rowData} />
      </Modal>

      <Modal
        title="Add New User"
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addUserForm"
            loading={addLoading}
            type="primary"
          >
            Add
          </Button>,
          <Button key="2" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <AddUser createUser={createUser} />
      </Modal>
    </section>
  );
};

export default ManageUsers;
