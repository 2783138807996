import { Form, Input, Select } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateCountry = ({ rowData, updateCountrydata, lovhmsCountry }) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updateCountrydata({
      cntryid: values?.cntryid,
      cntrycode: values?.cntrycode,
      description: values?.description,
      status: values?.status,
      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updatecountry"
        form={form}
        initialValues={{
          description: rowData?.description,
          cntryid: rowData?.cntryid,
          cntrycode: rowData?.cntrycode,
          status: rowData?.status,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="cntryid"
          label="Country ID"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="cntrycode" label="Country Code">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          {/* <Select
            placeholder="Select Country id"
            onChange={(value) => {
              const selectedOption = lovhmsCountry?.data?.find(
                (item) => item?.descr === value
              );
              form.setFieldsValue({
                cntrycode: selectedOption?.code,
              });
            }}
            options={lovhmsCountry?.data?.map((item) => ({
              value: item?.descr,
              label: item?.descr,
            }))}
          /> */}
          <Input />
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select
            placeholder="Select Status"
            options={[
              { label: "Active", value: "Y" },
              { label: "In Active", value: "N" },
            ]}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateCountry;
