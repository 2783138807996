import React, { useEffect, useState } from "react";
import { useCustCartInfoMutation } from "../../productsServices";
import { Button, Divider, Form, Input, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import openNotification from "../../../../utils/notification";

import bgimg from "../../../../assets/images/bglogin.png";
import bgBlank from "../../../../assets/images/bgblank.png";
export const LoginCard = () => {
  const navigate = useNavigate();
  const [
    saveCard,
    { data: gettocard, isSuccess: cardSuccess, isError: cardError, isLoading },
  ] = useCustCartInfoMutation();
  const onFinish = (values) => {
    saveCard({
      email: values?.email,
      pasword: values?.pasword,
      message: "B",
    });
  };
  useEffect(() => {
    if (cardSuccess) {
      navigate("/addtocard", { state: gettocard });
    }
  }, [cardSuccess]); //eslint-disable-line
  useEffect(() => {
    if (cardError) {
      openNotification("error", "Check credentials");
    }
  }, [cardError]); //eslint-disable-line
  const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return windowWidth;
  };
  const imageUrl = useWindowWidth() >= 650 ? bgimg : bgBlank;
  return (
    <div className="flex justify-center items-center h-screen bg-[#96dcc4] ">
      <div
        className="flex justify-center items-center h-[600px] w-[600px]   bg-no-repeat bg-center bg-cover "
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className=" sm:w-[284px] sm:ml-[7px] sm:mt-[49px]">
          <Form onFinish={onFinish} layout="vertical">
            <Form.Item
              name="email"
              autoComplete="off"
              rules={[{ required: true }]}
              className="mb-0 border-none"
            >
              <Input
                placeholder="Enter username"
                size="large"
                prefix={<UserOutlined className=" text-[#47a686]" />}
                className=" border-none  "
              />
            </Form.Item>
            <Divider className="border-[#c0d5d6] mt-1 mb-1 bg-white my-4 border-t-2" />
            <Form.Item
              name="pasword"
              autoComplete="on"
              rules={[{ required: true }]}
              className="mb-2 border-none "
            >
              <Input.Password
                placeholder="Enter password"
                size="large"
                prefix={<LockOutlined className=" text-[#47a686]" />}
                className=" border-none "
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
                className="bg-[#47a686] w-full sm:pt-[12px] sm:h-[47px]"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
