import React, { useEffect } from 'react'
import { useState} from "react";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import {  EditFilled} from "@ant-design/icons";
import AddWardBedTypeMaintenance from './AddWardBedTypeMaintenance';
import UpdateWardBedTypeMaintenance from './UpdateWardBedTypeMaintenance';
import useTableFilters from '../../../../hooks/useTableFilter';
import { useCreateBedTypeMutation, useGetBedInformationQuery, useUpdateBedTypeStatusMutation } from '../../wardConfiguratorsService';

const WardBedTypeMaintenance=() => {

  const { data, isLoading,refetch } = useGetBedInformationQuery();
  const [createbedtype, { isSuccess: addSuccess, isLoading: addLoading }] = useCreateBedTypeMutation();
  const [ updatebedtype, {data:updatebedData, isSuccess: updateSuccess, isLoading: updateLoading }, ] = useUpdateBedTypeStatusMutation();
  const [rowData, setRowData] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const[tableDatabedinfo,setTableDatabedinfo]=useState([])
  useEffect(()=>{
    if(data){
      setTableDatabedinfo(data?.data)
    }
  },[data])
  const filter = useTableFilters();

  useEffect(() => {
    if (updateSuccess) setShowUpdateModal(false);
    handlerefetch();
  }, [updateSuccess]); // eslint-disable-line


  useEffect(() => {
    if (addSuccess) setShowAddModal(false);
    handlerefetch()
  }, [addSuccess]); // eslint-disable-line
  
  const tableColumns = [
    {
      title: "Description",
      dataIndex: "descr",
      ...filter("descr"),
      sorter: (a, b) => a?.descr?.localeCompare(b?.descr),
    },
    {
      title: "Type",
      dataIndex: "type",
       ...filter("type"),
      sorter: (a, b) => a?.type - b?.type,
      defaultSortOrder: "descend",
    },
    {
      title: "Status",
      render: (record) =>
      record.status === "Y" ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">In Active</Tag>
        )
      // sorter: (a, b) => a?.status?.localeCompare(b?.status),
    },
    
    {
      title: "Action(s)",
      render: (data) => (
        <Tooltip title="Update Ward Bed Maintenance">
          <Button
            type="primary"
            className="btn-warning-primary"
            icon={<EditFilled />}
            onClick={() => {
              setRowData(data);
              setShowUpdateModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];
const handlerefetch=()=>{
  refetch()
}
  return (
    <section className="border border-gainsboro">
    <div className="flex items-center justify-end px-5 py-3 bg-lotion border-b border-gainsboro">
      <Tooltip title="Add Ward Bed Type ">
        <Button
          type="primary"
          icon={<EditFilled />}
          onClick={() => setShowAddModal(true)}
        />
      </Tooltip>
    </div>
    <div className="p-5">
      <Table 
        dataSource={tableDatabedinfo}
        columns={tableColumns}
        pagination={{ defaultPageSize: 5 }}
        loading={isLoading}
        bordered
        size="small"
        rowKey="type"
      />
    </div>

    <Modal
      title="Add Ward Bed Type "
      open={showAddModal}
      onCancel={() => setShowAddModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="addWardBedTypeMaintenanceForm"
          loading={addLoading}
          type="primary"
        >
          Add
        </Button>,
        <Button key="2" onClick={() => setShowAddModal(false)}>
          Cancel
        </Button>,
      ]}
    >
    
      <AddWardBedTypeMaintenance  createbedtype={createbedtype} handlerefetch={handlerefetch}/>
    </Modal>

    <Modal
      title="Update Ward Bed Maintenance"
      open={showUpdateModal}
      onCancel={() => setShowUpdateModal(false)}
      centered
      width={1000}
      destroyOnClose
      footer={[
        <Button
          key="1"
          htmlType="submit"
          form="updateWardBedTypeMaintenanceForm"
          loading={updateLoading}
          type="primary"
        >
          Update
        </Button>,
        <Button key="2" onClick={() => setShowUpdateModal(false)}>
          Cancel
        </Button>,
      ]}
    >
      <UpdateWardBedTypeMaintenance
        rowData={rowData}
        updatebedtype={updatebedtype}
        updatebedData={updatebedData}
      />
    </Modal>
  </section>
  )
}

export default WardBedTypeMaintenance